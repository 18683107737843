import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Button from '../../../../components/Button';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import { formatTimeAgo, sortArrayByKey } from '../../../../utils';
import CircularLoader from '../../../../components/CircularLoader';
import MentionTextEditor from '../../../../components/TextEditor/menuTextEditor';

const TaskComment = ({ commentData, usersList, taskData, showAlert, setTaskData, currentUser }) => {
    const [isSubmitCommentLoading, setIsSubmitCommentLoading] = useState(false);
    const [isDisableButton, setIsDisableButton] = useState(true);
    const [editorData, setEditorData] = useState({
        value: "",
        tagIds: []
    });

    const handleChangeContent = (obj = {}) => {
        setEditorData({
            value: obj?.html,
            tagIds: [...new Set(obj?.mentions?.map(userData => parseInt(userData?.id)))]
        })
        const isEmpty = !obj?.html?.replace(/&nbsp;/g, ' ')
            ?.replace(/<(.|\n)*?>/g, '')
            ?.trim();
        setIsDisableButton(isEmpty);
    }

    const handleSubmitComment = async () => {
        try {
            setIsSubmitCommentLoading(true);
            const body = {
                task_id: taskData?.id,
                comment: editorData?.value ?? '',
                userIds: editorData?.tagIds ?? [],
                task_user_id: taskData?.user_id,
            };
            const response = await axiosInstance.post(API.createComment, body);
            if (response?.status === 200) {
                showAlert(response?.data?.message);
                setTaskData(prev => ({
                    ...prev,
                    TaskComments: [...prev?.TaskComments, response?.data?.data]
                }))
                setIsDisableButton(true);
            }
            setIsSubmitCommentLoading(false);
        } catch (error) {
            console.error(error);
            setIsSubmitCommentLoading(false);
        }
    };

    const handleCancelComment = () => {
        setIsDisableButton(true);
    };

    const getUserDataByID = (userId) => {
        return usersList?.find(userData => userData?.id === userId)
    }

    return (
        <Box>
            {isSubmitCommentLoading ?
                <CircularLoader height={'100%'} minHeight={'500px'} />
                :
                <>
                    {commentData?.length
                        ? sortArrayByKey(commentData, 'desc', 'createdAt')?.map((commentDetail, index) => (
                            <React.Fragment key={index}>
                                <Box mb={2}>
                                    <Box display={"flex"} gap={1} pb={2}>
                                        <Box minWidth={34} width={34} height={34} p={"3px"}>
                                            <Box minWidth={28} width={28} height={28}>
                                                <BackgroundLetterAvatars alt="Remy Sharp"
                                                    user={commentDetail?.User ? commentDetail?.User : getUserDataByID(commentDetail?.created_by)}
                                                    src={commentDetail?.User ? commentDetail?.User?.profile_img : getUserDataByID(commentDetail?.created_by)?.profile_img}
                                                    sx={{ width: 28, height: 28, fontSize: '80%' }} />
                                            </Box>
                                        </Box>
                                        <Box gap={"5px"} flex={1} className="text-editor">
                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.17px"}>
                                                <b>{commentDetail?.User ? commentDetail?.User?.name : getUserDataByID(commentDetail?.created_by)?.name}</b> commented
                                            </Typography>
                                            <Box color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.17px"}
                                                sx={{
                                                    'ul': {
                                                        'li': {
                                                            padding: "0px 0px 0px 15px",
                                                            listStyle: "disc",
                                                            display: "list-item",
                                                            listStylePosition: "inside",
                                                        }
                                                    },
                                                    'ol': {
                                                        'li': {
                                                            padding: "0px 0px 0px 15px",
                                                            display: "list-item",
                                                            listStylePosition: "inside",
                                                        }
                                                    },
                                                }}>
                                                <span dangerouslySetInnerHTML={{ __html: commentDetail?.comment ?? "" }} />
                                            </Box>
                                            <Typography color={"rgba(0, 0, 0, 0.5)"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.17px"} mt={0.5}>
                                                {formatTimeAgo(commentDetail?.createdAt, true)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                </Box>
                            </React.Fragment>
                        ))
                        : null}
                    <Box mb={2}>
                        <Box display={"flex"} gap={1} pb={2}>
                            <Box minWidth={34} width={34} height={34} p={"3px"}>
                                <Box minWidth={28} width={28} height={28}>
                                    <BackgroundLetterAvatars
                                        alt="Remy Sharp"
                                        user={currentUser}
                                        src={currentUser?.profile_img}
                                        sx={{ width: 28, height: 28, fontSize: '80%', }} />
                                </Box>
                            </Box>
                            <Box gap={"5px"} flex={1}>
                                {/* TextEditor */}
                                <MentionTextEditor
                                    atMentions={usersList}
                                    hashTags={[]}
                                    value={``}
                                    onChange={handleChangeContent}
                                />
                                {/* TextEditor */}
                                <Box display={"flex"} alignItems={"center"} gap={1} mt={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        type='button'
                                        sx={{ boxShadow: 'none', textTransform: 'none' }}
                                        onClick={() => handleSubmitComment()}
                                        disabled={isDisableButton || isSubmitCommentLoading}
                                        isButtonLoading={isSubmitCommentLoading}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        size="medium"
                                        sx={{ boxShadow: 'none', textTransform: 'none' }}
                                        onClick={() => handleCancelComment()}
                                        disabled={isSubmitCommentLoading}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
        </Box>
    );
};

export default TaskComment;
