import { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { clearAllCookies } from './utils';
import { useNavigate } from 'react-router-dom';
import URLS from './routes/urls';
import { useAlert } from './hook/useAlert';
import { useDispatch } from 'react-redux';
import { clearStorage } from './redux/slices/userSlice';
import { clearActionData } from './redux/slices/activityActionDataSlice';
import { decryptData } from './crypto';
import useAuthentication from './hook/useAuthentication';

const SSEComponent = () => {
    const router = useNavigate();
    const showAlert = useAlert();
    const dispatch = useDispatch();
    const { isAuthenticated, getCurrentUser } = useAuthentication();

    useEffect(() => {

        if (isAuthenticated()) {
            let eventSource = null;

            const connectSSE = () => {
                if (eventSource) {
                    eventSource.close();
                }
                const user = getCurrentUser();
                console.log('userId', user?.id)
                console.info("Attempting to connect to SSE...");
                eventSource = new EventSource(`${process.env.REACT_APP_SOCKET_URL}/events?userId=${user?.id}`);

                eventSource.onmessage = (event) => {
                    let eventData;
                    if (process.env.REACT_APP_NODE_ENV !== 'development') {
                        eventData = decryptData(event.data)
                    } else {
                        eventData = JSON.parse(event.data);
                    }
                    const { type, token } = eventData;
                    if (Object.keys(eventData)?.length > 0) {
                        if (type === 'Logout') {
                            showAlert('Your session has expired.', 'error');
                            secureLocalStorage.clear();
                            clearAllCookies();
                            dispatch(clearStorage());
                            dispatch(clearActionData());
                            router(URLS.Login, { replace: true });
                        } else if (type === 'Login' && token) {
                            const storedToken = secureLocalStorage.getItem('token');
                            if (storedToken !== token) {
                                showAlert('Your session has expired.', 'error');
                                secureLocalStorage.clear();
                                clearAllCookies();
                                dispatch(clearStorage());
                                dispatch(clearActionData());
                                router(URLS.Login, { replace: true });
                            }
                        }
                    } else {
                        console.info('No event data found.');
                    }
                };

                eventSource.onopen = () => {
                    console.info("SSE connection opened!");
                };

                eventSource.onerror = () => {
                    console.warn("SSE error occurred. Reconnecting in 3 seconds...");
                    eventSource?.close();
                    setTimeout(() => {
                        connectSSE();
                    }, 1000)
                };
            };

            connectSSE();

            return () => {
                eventSource?.close();
                console.info("SSE connection closed!");
            };
        }
    }, [dispatch, getCurrentUser, isAuthenticated, router, showAlert]);

    return null;
};

export default SSEComponent;
