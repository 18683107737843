import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import Button from "../Button";
import { getFormattedDate, } from "../../utils";
import useAuthentication from "../../hook/useAuthentication";
import moment from "moment";
import { WEEKDAYS } from "../../constants/default-values";

const MenuChartDateRangePicker = ({ dateRange, onChange, maxDate, ...rest }) => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();

    const [dates, setDates] = useState(dateRange);
    const [filter, setFilter] = useState("This Month");
    const [tempDates, setTempDates] = useState(dates);

    const [lastAppliedDates, setLastAppliedDates] = useState(dates);
    const [lastAppliedFilter, setLastAppliedFilter] = useState("This Month");
    const datePickerRef = useRef(null);
    const isManuallyClosed = useRef(false);


    function getMonthNumber(monthName) {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const monthIndex = months.indexOf(monthName);
        return monthIndex !== -1 ? monthIndex + 1 : null;
    }

    const getHeader = () => {
        const header = datePickerRef.current?.querySelector(".rmdp-header-values");
        const [month, year] = header?.innerText.split(",");
        return { month, year: parseInt(year, 10) };
    };

    const navigateToMonthAndYear = async (targetMonthName, targetYear) => {
        const prevButton = datePickerRef.current?.querySelector(".rmdp-left");
        const nextButton = datePickerRef.current?.querySelector(".rmdp-right");

        while (true) {
            const { month: currentMonthName, year: currentYear } = getHeader();
            const currentMonthNumber = getMonthNumber(currentMonthName);
            const targetMonthNumber = getMonthNumber(targetMonthName);

            if (currentMonthNumber === null || targetMonthNumber === null) return;

            // Stop when both year and month match the target
            if (currentYear === targetYear && currentMonthNumber === targetMonthNumber) break;

            // Navigate based on year and month comparison
            if (currentYear < targetYear || (currentYear === targetYear && currentMonthNumber < targetMonthNumber)) {
                nextButton?.click();
            } else {
                prevButton?.click();
            }

            // Wait briefly for the UI to update
            await new Promise(resolve => setTimeout(resolve, 300));
        }
    };

    const setPresetRange = (option) => {
        const today = new Date();
        let startDate, endDate;
        setFilter(option)

        let targetMonth, targetYear;

        switch (option) {
            case "Today":
                startDate = today;
                targetMonth = moment().format("MMMM");
                targetYear = moment().year();
                // endDate = today;
                break;
            case "Yesterday":
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 1);
                targetMonth = moment(new Date(startDate)).format("MMMM");
                targetYear = moment(new Date(startDate)).year();
                // endDate = startDate;
                break;
            case "This Week":
                startDate = new Date(today);
                startDate.setDate(today.getDate() - today.getDay());
                endDate = new Date(today);
                endDate.setDate(startDate.getDate() + 6);
                targetMonth = moment(new Date(startDate)).format("MMMM");
                targetYear = moment(new Date(startDate)).year();
                break;
            case "Last Week":
                startDate = new Date(today);
                startDate.setDate(today.getDate() - today.getDay() - 7);
                endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 6);
                targetMonth = moment(new Date(startDate)).format("MMMM");
                targetYear = moment(new Date(startDate)).year();
                break;
            case "This Month":
                startDate = new Date(currentUser?.organization?.createdAt) < new Date(today.getFullYear(), today.getMonth(), 1) ? new Date(today.getFullYear(), today.getMonth(), 1) : new Date(currentUser?.organization?.createdAt);
                endDate = today.getDate() < new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate() ? today : new Date(today.getFullYear(), today.getMonth() + 1, 0);
                targetMonth = moment().format("MMMM");
                targetYear = moment().year();
                break;
            case "Last Month":
                startDate = new Date(currentUser?.organization?.createdAt) < new Date(today.getFullYear(), today.getMonth() - 1, 1) ? new Date(today.getFullYear(), today.getMonth() - 1, 1) : new Date(currentUser?.organization?.createdAt);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                targetMonth = moment(new Date(startDate)).format("MMMM");
                targetYear = moment(new Date(startDate)).year();
                break;
            case "Custom Range":
                startDate = tempDates[0];
                endDate = tempDates[1];
                targetMonth = moment(new Date(startDate)).format("MMMM");
                targetYear = moment(new Date(startDate)).year();
                break;
            default:
                return;
        }

        const updatedDates = endDate ? [startDate, endDate] : [startDate];

        setTempDates(updatedDates);
        onChange(updatedDates);
        navigateToMonthAndYear(targetMonth, targetYear);
    };

    const handleApply = () => {
        isManuallyClosed.current = true;
        setDates(tempDates);
        setLastAppliedDates(tempDates);
        setLastAppliedFilter(filter);
        onChange(tempDates)
        datePickerRef?.current?.closeCalendar();
        setTimeout(() => { isManuallyClosed.current = false }, 100);
    };

    const handleCancel = () => {
        isManuallyClosed.current = true;
        setTempDates(lastAppliedDates);
        setFilter(lastAppliedFilter);
        onChange(lastAppliedDates);
        datePickerRef?.current?.closeCalendar();
        setTimeout(() => { isManuallyClosed.current = false }, 100);
    };

    const handleClose = () => {
        if (!isManuallyClosed.current) {
            setTempDates(lastAppliedDates);
            setFilter(lastAppliedFilter);
            onChange(lastAppliedDates);
        }
    }

    return (
        <DatePicker
            className="chart-range-picker"
            ref={datePickerRef}
            id="date-range-with-menu"
            minDate={new Date(currentUser?.organization?.createdAt)}
            maxDate={maxDate}
            value={tempDates}
            weekDays={WEEKDAYS}
            onChange={(newDates) => {
                let allDates = newDates?.map((date) => new Date(date))
                setTempDates(allDates);
                setFilter("Custom Range");
                onChange(allDates)
            }}
            onClose={() => handleClose()}
            format="MM/DD/YYYY"
            range
            rangeHover
            numberOfMonths={2}
            plugins={[]}
            mapDays={({ date, today, selectedDate }) => {
                let props = {};
                if (date < new Date(currentUser?.organization?.createdAt) || date > maxDate) {
                    props.style = {
                        color: "#ccc",
                    };
                }
                return props;
            }}

            render={<TextField
                label={'Select Date Range'}
                autoComplete="off"
                onKeyDown={(event) => event.preventDefault()}
                id="tags-standard"
                variant="standard"
                sx={{
                    minHeight: "30px",
                    width: { xs: 220, lg: 264 },
                    marginTop: "10px",
                    "label": {
                        top: "-10px",
                    },
                    ".MuiInputBase-formControl": {
                        minHeight: "30px",
                        flexWrap: "wrap",
                        paddingTop: "0px",
                        marginTop: '8px'
                    },
                    "p": {
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                    ".MuiInputBase-input": {
                        opacity: 0,
                        height: "22px",
                        flex: '1',
                        border: "none",
                        top: 0,
                        bottom: "1px",
                        background: "transparent",
                        position: "absolute",
                    },
                    ".rmdp-input:focus": {
                        border: "none",
                        boxShadow: "none",
                    },
                    ".MuiChip-sizeSmall": {
                        marginLeft: '0px !important',
                        marginRight: '8px'
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <Stack spacing={1} direction="row" flexWrap="wrap">
                            {tempDates?.map((date, index, array) => (
                                <React.Fragment key={index}>
                                    <Typography>{typeof date === "string" ? date : getFormattedDate(new Date(date), "DD/MM/YYYY", true)}</Typography>
                                    {array?.length !== 1 && index === 0 ? <Typography>{" - "}</Typography> : null}
                                </React.Fragment>
                            ))}
                        </Stack>
                    ),
                    readOnly: true
                }}
                {...rest}
                className="rmdp-input-with-menu"
                {...rest}
            />
            }
        >
            <Box className="filter-date" sx={{ display: "flex", justifyContent: "space-between", width: "150px", minWidth: "150px", borderRight: '1px solid #D9D9D9' }} py={2}>
                <Stack direction="column" sx={{ width: "100%", alignItems: "flex-start" }}>
                    {
                        ["Today", "Yesterday", "This Week", "Last Week", "This Month", "Last Month", "Custom Range"].map((label) => (
                            <Button
                                key={label}
                                onClick={() => setPresetRange(label)}
                                variant={filter === label ? "contained" : "text"}
                                sx={{ color: filter === label ? "white" : "#000", px: 1.5, textTransform: "none", width: '100%', justifyContent: 'flex-start', borderRadius: 0, boxShadow: 0, fontWeight: 500, fontSize: 14, }}
                                disabled={(label === 'Last Month') && moment(moment().startOf("month").toDate()).isBefore(new Date(currentUser?.organization?.createdAt))}
                            >
                                {label}
                            </Button>
                        ))
                    }
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", borderTop: '1px solid #D9D9D9' }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px", alignItems: "center" }} gap={6}>
                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"}>
                        {tempDates.length === 1
                            ? getFormattedDate(tempDates[0], "DD/MM/YYYY", true)
                            : `${getFormattedDate(tempDates[0], "DD/MM/YYYY", true)} - ${getFormattedDate(tempDates[1], "DD/MM/YYYY", true)}`}
                    </Typography>
                    <Stack direction="row" gap={1}>
                        <Button onClick={handleCancel} color="secondary" variant="text" size="medium" sx={{}}>Cancel</Button>
                        <Button onClick={handleApply} color="primary" variant="text" size="medium" sx={{}}>Done</Button>
                    </Stack>
                </Box>
            </Box>
        </DatePicker>
    );
};

export default MenuChartDateRangePicker;