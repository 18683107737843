const wfhRoutes = {
    WorkFromHome: "/request/work-from-home",
    ApplyWorkFromHome: "/request/work-from-home/apply",
    OtherWfhApplicationDetails: "/request/work-from-home/other-wfh-application",
    EditOthersWfhApplication: "/request/work-from-home/other-wfh-application/edit",
    ViewMyWfhApplication: "/request/work-from-home/my-wfh-application/view",
    EditMyWfhApplication: "/request/work-from-home/my-wfh-application/edit",
};

export default wfhRoutes;
