const administratorRoutes = {
    AdministratorHolidays: "/administrator/holidays",
    AdministratorHolidaysCreate: "/administrator/holidays/create",
    AdministratorRoles: "/administrator/roles",
    AdministratorProjectRoles: "/administrator/project-roles",
    AdministratorActivity: "/administrator/activity",
    AdministratorCreateActivity: "/administrator/activity/create",
    AdministratorUpdateActivity: "/administrator/activity/update",
    AdministratorWorkType: "/administrator/workt-type",
    AdministratorOrganisation: "/administrator/organisation",
    AdministratorLeaves: "/administrator/leaves",
};

export default administratorRoutes;
