import React, { useMemo, useState } from 'react'
import Button from '../../../../components/Button'
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Stack, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getPriorotyIcon, getTaskStatus, getTaskTypeIcon, titleCase } from '../../../../utils';
import { PROJECT_TASK_PRIORITY, TASK_TYPE_LIST } from '../../../../constants/default-values';
import { useAlert } from '../../../../hook/useAlert';
import moment from 'moment';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import useAuthentication from '../../../../hook/useAuthentication';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        width: 600,
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const MoveTask = ({
    handleCloseMoveModal,
    openMoveModal,
    HtmlTooltip,
    movableTaskData,
    submitUpdatedMove,
    handleCancelMoveTask,
    projectList }) => {
    const currentUser = useAuthentication().getCurrentUser();
    const showAlert = useAlert();
    const [projectError, setProjectError] = useState("");
    const [selectedProject, setSelectedProject] = useState(null);
    const [isLoading, setIsLoading] = useState({
        moveTaskLoading: false,
    })

    const handleChange = (value) => {
        setSelectedProject(value);
        setProjectError(prev => value ? "" : prev)
    }

    const handleSubmitMoveTask = async () => {
        try {
            setIsLoading(prev => ({ ...prev, isLoading: true }));
            let body = {
                "user_id": movableTaskData?.user_id,
                "project_id": selectedProject,
                "task_id": movableTaskData?.id,
                oldProject: movableTaskData?.Project.title || "",
                moved_by: currentUser?.id,
            }
            let response = await axiosInstance.post(API.moveTaskToAnotherProject, body);
            if (response?.status === 200) {
                showAlert(response?.data?.message)
                submitUpdatedMove(movableTaskData?.id);
                handleCloseMoveModal();
            }
            setIsLoading(prev => ({ ...prev, isLoading: false }))
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, isLoading: false }));
            showAlert("Something went wrong", "error")
        }
    }

    const handleSubmitMove = () => {
        if (selectedProject) {
            handleSubmitMoveTask();
        } else {
            setProjectError("Required")
        }
    }

    const handleCancel = () => {
        setProjectError("");
        handleCancelMoveTask();
    }

    const isTaskMovable = useMemo(() => {
        if (!selectedProject) { return true; }

        const project = projectList?.find(({ id }) => id === selectedProject);

        if (project?.is_private) {
            let memberData = project?.project_members?.find(memberData => memberData?.user_id === movableTaskData?.user_id);
            const joiningDate = moment(memberData?.joining_date, "YYYY-MM-DD")?.startOf('day');;
            const taskCreateDate = moment(movableTaskData?.createdAt)?.startOf('day');;
            if (joiningDate) {
                if (joiningDate.isSameOrBefore(taskCreateDate, 'day')) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return true;
        }

        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    return (
        <BootstrapDialog
            onClose={handleCancel}
            aria-labelledby="customized-dialog-title"
            open={openMoveModal}
        >
            <DialogTitle sx={{ m: 0, py: 2, pl: 3, pr: 8 }} id="customized-dialog-title" color={"dark.800"} fontSize={20} fontWeight={500} lineHeight={"32px"} letterSpacing={"0.15px"}>
                Move <b>{movableTaskData?.Project?.short_code}-{movableTaskData?.task_no ?? 1}</b> task to other project
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => handleCancel()}
                size="small"
                sx={(theme) => ({
                    position: 'absolute',
                    right: 24,
                    top: 20,
                    color: theme.palette.grey[500],
                    p: 0,
                    'svg': {
                        height: 24,
                        width: 24,
                    },
                })}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            <DialogContent dividers>
                <Stack gap={2}>
                    <Stack display={"flex"} direction="row" alignItems={"center"} justifyContent={"space-between"} width={"100%"} bgcolor={"#F6F6F6"} p={1.5} borderRadius={"4px"}>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                            <HtmlTooltip arrow
                                title={
                                    <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"}>{titleCase(TASK_TYPE_LIST[movableTaskData?.type])}</Typography>
                                }>
                                <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                    {getTaskTypeIcon(movableTaskData?.type)}
                                </Box>
                            </HtmlTooltip>
                            <Box display={"flex"} alignItems={"center"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} gap={1} pl={1} width={"390px"}
                                sx={{
                                    '.MuiChip-root': {
                                        margin: 0,
                                    },
                                }}
                            >
                                <Typography color={"secondary"} fontSize={13} fontWeight={700} lineHeight={"15.73px"} sx={{ textDecoration: 'underline' }}> {movableTaskData?.Project?.short_code}-{movableTaskData?.task_no ?? 1}</Typography>
                                <Typography color={"dark.800"} fontSize={14} fontWeight={600} lineHeight={"20px"} letterSpacing={"0.17px"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>{movableTaskData?.title}</Typography>
                            </Box>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                            <HtmlTooltip arrow
                                title={
                                    <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"}>{PROJECT_TASK_PRIORITY[movableTaskData?.priority]}</Typography>
                                }>
                                <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                    {getPriorotyIcon(movableTaskData?.priority)}
                                </Box>
                            </HtmlTooltip>
                            {getTaskStatus(movableTaskData?.status, "chip", "small", "move-task-status-chip")}
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            '.MuiAlert-icon': {
                                minWidth: 22,
                                height: 22,
                                width: 22,
                                p: 0,
                                'svg': {
                                    height: '100%',
                                    width: '100%',
                                }
                            },
                            '.MuiAlert-message': {
                                p: 0,
                            },
                            '.MuiAlert-root': {
                                padding: '8px 10px',
                            },
                        }}
                    >
                        <Alert severity="info">
                            <Box>
                                <List
                                    sx={{
                                        p: 0,
                                        listStyleType: 'decimal',
                                        display: 'list-item',
                                        paddingLeft: '16px',
                                        'li': {
                                            p: 0,
                                            display: 'list-item',
                                        },
                                        '.MuiListItemText-root': {
                                            margin: 0,
                                        },
                                        'span': {
                                            fontWeight: 400,
                                            fontSize: 14,
                                            lineHeight: '21px',
                                            letterSpacing: '0.15px',
                                        }
                                    }}
                                >
                                    <ListItem>
                                        <ListItemText>When you move a task, all its time spent, worklogs, and comments will move along with it to the new project.</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>You can only move tasks that were created after you joined the project.</ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                        </Alert>
                    </Box>
                    <FormControl size="medium" variant="standard" fullWidth>
                        <InputLabel id="select-label">New Project</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select-demo"
                            value={selectedProject || ""}
                            label="Label"
                            onChange={(e) => handleChange(e.target.value)}
                        >
                            {
                                projectList?.filter(projectData => projectData?.id !== movableTaskData?.project_id)?.map((projectData) => (
                                    <MenuItem value={projectData?.id} key={projectData?.id}>
                                        <Box display={"flex"} alignItems={"center"} overflow={"hidden"} gap={"11px"}>
                                            <Typography px={"6px"} py={"1px"} border={"1px solid rgba(4, 127, 224, 0.2)"} bgcolor={"rgba(0, 99, 191, 0.1)"} color={"primary"} fontSize={11} fontWeight={600} lineHeight={"16px"} borderRadius={"3px"}>{projectData?.short_code}</Typography>
                                            <Typography color={"dark.800"} fontSize={16} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.15px"}>{titleCase(projectData?.title)}</Typography>
                                        </Box>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        {!!projectError?.length ? (
                            <FormHelperText sx={{ color: "red.main" }}>
                                {projectError}
                            </FormHelperText>
                        ) : null}
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: "flex-start",
                    padding: '16px 24px !important',
                }}
            >
                <Button autoFocus
                    variant="contained"
                    color="primary"
                    size="medium"
                    sx={{
                        boxShadow: 'none',
                        textTransform: 'none',
                    }}
                    isButtonLoading={isLoading?.moveTaskLoading}
                    disabled={isLoading?.moveTaskLoading || !isTaskMovable}
                    onClick={() => handleSubmitMove()}
                >
                    Move
                </Button>
                <Button
                    variant="text"
                    color="secondary"
                    size="medium"
                    sx={{
                        boxShadow: 'none',
                        textTransform: 'none',
                    }}
                    disabled={isLoading?.moveTaskLoading}
                    onClick={() => handleCancel()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default MoveTask