import authRoutes from "./authRoutes";
import adminRoutes from "./adminRoutes";
import dashboardRoutes from "./dashboardRoutes";
import projectRoutes from "./projectRoutes";
import leaveRoutes from "./leaveRoutes";
import wfhRoutes from "./wfhRoutes";
import inventoryRoutes from "./inventoryRoutes";
import userRoutes from "./userRoutes";
import reportRoutes from "./reportRoutes";
import crmRoutes from "./crmRoutes";
import notificationRoutes from "./notificationRoutes";
import administratorRoutes from "./administratorRoutes";
import miscRoutes from "./miscRoutes";
import repoteesRoutes from "./repoteesRoutes";

const URLS = {
  ...authRoutes,
  ...adminRoutes,
  ...dashboardRoutes,
  ...projectRoutes,
  ...leaveRoutes,
  ...wfhRoutes,
  ...inventoryRoutes,
  ...userRoutes,
  ...reportRoutes,
  ...repoteesRoutes,
  ...crmRoutes,
  ...notificationRoutes,
  ...administratorRoutes,
  ...miscRoutes,
};

export default URLS;
