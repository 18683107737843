// import { Box, Divider, FormControl, IconButton, Menu, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { PROJECT_PHASE_STATUS, PROJECT_TASK_PRIORITY, TASK_STATUS_LIST, TASK_TYPE_LIST } from '../../../../constants/default-values'
import { convertManualTimeToSeconds, convertToBase64, formatDuration, formatTimeAgo, generateUniqueId, getFormattedDate, getPriorotyIcon, getTaskStatus, getTaskTypeIcon } from '../../../../utils'
import Button from '../../../../components/Button'

import {
    Box,
    Divider,
    FormControl,
    IconButton,
    Menu,
    MenuItem,
    Select,
    Stack,
    styled,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    tooltipClasses,
    Typography,
} from "@mui/material";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import { getInputId, titleCase } from "../../../../utils";
import { useAlert } from "../../../../hook/useAlert";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import CircularLoader from "../../../../components/CircularLoader";
import CloseIcon from '@mui/icons-material/Close';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ModeStandbyOutlinedIcon from '@mui/icons-material/ModeStandbyOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import TextEditor from "../../../../components/TextEditor";
import SubjectIcon from '@mui/icons-material/Subject';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TimeInput from '../../../../components/CustomManualTimePicker';
import CustomFileDropZone from '../../../../components/CustomFileDropZone';
import TaskComment from './taskComment';
import TaskActivity from './taskActivity';
import TaskWorklog from './taskWorklog';
import { saveAs } from 'file-saver';
import AttachmentLists from './attachmentLists';
import ICONS from '../../../../constants/icons';
import { useParams } from 'react-router-dom';
import useAuthentication from '../../../../hook/useAuthentication';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoveTask from './moveTask';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        color: '#ffffff',
        maxWidth: 'inherit',
        padding: "10px 12px",
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const UpdateTask = ({
    handleCloseUpdateTaskDrawer,
    projectPhases,
    handleSubmitUpdatedTaskDataInList,
    activeMembersData,
    editableTaskDataId,
    allOrfUsersList,
    projectList,
    handleTaskMove
}) => {
    const currentUser = useAuthentication().getCurrentUser();
    const initialTaskData = {
        title: "",
        status: Object.keys(TASK_STATUS_LIST)[0],
        priority: PROJECT_TASK_PRIORITY['Low'],
        project_phase_id: "",
        type: Object.keys(TASK_TYPE_LIST)[0],
        description: "",
        attachments: [],
        assignee: "",
        estimation: "",
    }

    const initialTaskError = {
        title: null,
        status: null,
        priority: null,
        project_phase_id: null,
        type: null,
        description: null,
        attachments: null,
        assignee: null,
        estimation: null
    }
    const showAlert = useAlert();
    const params = useParams();
    const [taskData, setTaskData] = useState({});
    const [optionalTaskData, setOptionalTaskData] = useState(initialTaskData);
    const [taskErrors, setTaskErrors] = useState({ ...initialTaskError });
    const [isLoading, setIsLoading] = useState({
        taskSubmitLoading: false,
    });
    const [value, setValue] = useState(0);
    const [isEditTaskTitle, setIsEditTaskTitle] = useState(false);
    const [isEditTaskDescription, setIsEditTaskDescription] = useState(false);

    const inputRef = useRef(null);
    const textRef = useRef(null);
    // const maxWidth = 500;
    const maxHeight = 77;

    const [isOverflowing, setIsOverflowing] = useState(false);

    const [loading, setLoading] = useState({
        taskDataLoading: false
    })
    const [isTitleExpanded, setIsTitleExpanded] = useState(false);

    const maxFileSize = 5 * 1000000;
    const maxTotalSize = 10 * 1000000;

    useEffect(() => {
        getUserTaskData();
        // getAllWorkLogData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if (editableTaskData?.id) {
    //         getUserTaskData();
    //     }
    // }, [editableTaskData])

    useEffect(() => {
        if (isEditTaskTitle && inputRef?.current) {
            const length = inputRef?.current?.value?.length;
            inputRef.current.focus();
            inputRef.current.setSelectionRange(length, length);
        }
    }, [isEditTaskTitle]);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                setIsOverflowing(textRef.current.scrollHeight > 78);
            }
        };
        setTimeout(checkOverflow, 100);
    }, [taskData?.title, isTitleExpanded, isEditTaskTitle]);

    const getUserTaskData = async () => {
        try {
            setLoading(prev => ({ ...prev, taskDataLoading: true }))
            const response = await axiosInstance.get(API.getTaskDetailsById(editableTaskDataId));
            if (response?.status === 200) {
                let taskResponse = response?.data?.data?.[0];
                let attachmentFiles = [];
                if (response?.data?.taskAttachments?.length) {
                    const convertToFile = response?.data?.taskAttachments?.filter(value => !!value)?.map((url) => ({
                        id: url?.id,
                        name: url?.name,
                        preview: url?.s3_url,
                        unique_id: generateUniqueId(),
                        size: url?.size,
                        type: url?.type,
                        isExist: true
                    }));
                    attachmentFiles = convertToFile;
                }
                setTaskData({
                    ...taskResponse,
                    attachments: attachmentFiles,
                    assignee: taskResponse?.User?.id,
                    estimation: taskResponse?.estimation ? formatDuration(taskResponse?.estimation ?? 0) : "",
                    originalEstimation: taskResponse?.estimation ?? 0,
                    spent: taskResponse?.spent ?? 0,
                    attachment_references: response?.data?.taskAttachments?.map((data => data?.s3_url)),
                })
                setOptionalTaskData({
                    ...taskResponse,
                    attachments: attachmentFiles,
                    assignee: taskResponse?.User?.id,
                    estimation: taskResponse?.estimation ? formatDuration(taskResponse?.estimation ?? 0) : "",
                    originalEstimation: taskResponse?.estimation ?? 0,
                    spent: taskResponse?.spent ?? 0,
                    attachment_references: response?.data?.taskAttachments?.map((data => data?.s3_url)),
                })
            }
            setLoading(prev => ({ ...prev, taskDataLoading: false }))
        } catch (error) {
            console.error(error);
            setLoading(prev => ({ ...prev, taskDataLoading: false }))
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getFilterdProjectPhase = () => {
        return projectPhases?.filter(phaseData => [PROJECT_PHASE_STATUS['In-Progress'], PROJECT_PHASE_STATUS['Not started']]?.includes(phaseData?.status))
    }

    const handleSubmitUpdateTaskData = async (name, value) => {
        try {
            setIsLoading(prev => ({ ...prev, taskSubmitLoading: true }))
            function convertObjectToFormData(obj) {
                const formData = new FormData();

                formData.append("id", obj?.id);
                formData.append("date", getFormattedDate(new Date(), "YYYY-MM-DD"));
                formData.append("is_eod", true);
                formData.append("project_id", Number(params?.projectId));

                if (name === 'attachment') {
                    value?.filter((value) => !!value?.base64)?.forEach((attachment, index) => {
                        formData.append(`attachments[${index}][name]`, attachment?.name);
                        formData.append(`attachments[${index}][size]`, attachment?.size);
                        formData.append(`attachments[${index}][type]`, attachment?.type);
                        formData.append(`attachments[${index}][base64]`, attachment?.base64);
                        formData.append(`attachments[${index}][unique_id]`, attachment?.unique_id);
                        formData.append(`attachments[${index}][extension]`, `.${attachment?.name?.split('.').pop()}`);
                    });
                    if (obj?.attachment_references?.length) {
                        obj?.attachment_references?.forEach((attachmentData, index) => {
                            formData.append(`attachment[${index}]`, attachmentData);
                        })
                    }
                }
                else if (name === "assignee") {
                    formData.append('user_id', value)
                }
                else if (name === 'project_id') {
                    formData.delete(name)
                    formData.append("project_id", Number(value));
                }
                else if (name === 'estimation') {
                    formData.append("estimation", convertManualTimeToSeconds(value));
                }
                else if (name === 'project_phase_id') {
                    formData.append("project_phase_id", value);
                    if (optionalTaskData?.project_phase_id !== value) {
                        formData.append("is_phase_changed", true);
                    }
                }
                else {
                    formData.append(name, value)
                }
                return formData;
            }
            const formData = convertObjectToFormData(taskData);
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };

            const response = await axiosInstance.put(
                API.updateTask,
                formData,
                config
            );

            if (response?.status === 200) {
                // getAllTaskOfProject(debouncedSearchTerm);
                let taskResponse = response?.data?.task?.[0];
                let attachmentFiles = [];
                if (response?.data?.taskAttachments?.length) {
                    const convertToFile = response?.data?.taskAttachments?.filter(value => !!value)?.map((url) => ({
                        id: url?.id,
                        name: url?.name,
                        preview: url?.s3_url,
                        unique_id: generateUniqueId(),
                        size: url?.size,
                        type: url?.type,
                        isExist: true
                    }));
                    attachmentFiles = convertToFile;
                }
                setTaskData({
                    ...taskResponse,
                    attachments: attachmentFiles,
                    assignee: taskResponse?.User?.id,
                    estimation: taskResponse?.estimation ? formatDuration(taskResponse?.estimation ?? 0) : "",
                    originalEstimation: taskResponse?.estimation ?? 0,
                    spent: taskResponse?.spent ?? 0,
                    attachment_references: response?.data?.taskAttachments?.map((data => data?.s3_url)),
                })
                setOptionalTaskData({
                    ...taskResponse,
                    attachments: attachmentFiles,
                    assignee: taskResponse?.User?.id,
                    estimation: taskResponse?.estimation ? formatDuration(taskResponse?.estimation ?? 0) : "",
                    originalEstimation: taskResponse?.estimation ?? 0,
                    spent: taskResponse?.spent ?? 0,
                    attachment_references: response?.data?.taskAttachments?.map((data => data?.s3_url)),
                })

                let filteresData = response?.data?.task?.map(({ TaskComments, created_by_task, TaskActivities, ...rest }) => rest)?.[0]
                handleSubmitUpdatedTaskDataInList(name, value, taskData?.id, filteresData);
                showAlert(response?.data?.message)
            } else {
                showAlert('something went wrong', 'error')
                if (name === "assignee") {
                    setTaskData(prev => ({
                        ...prev,
                        assignee: optionalTaskData["assignee"]
                    }))
                } else {
                    setTaskData(prev => ({
                        ...prev,
                        [name]: optionalTaskData[name]
                    }))
                }
            }
            setIsLoading(prev => ({ ...prev, taskSubmitLoading: false }))
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, taskSubmitLoading: false }))
            showAlert(error?.response?.data?.message || "Something went wrong.", "error");
            if (name === "assignee") {
                setTaskData(prev => ({
                    ...prev,
                    assignee: optionalTaskData["assignee"]
                }))
            } else {
                setTaskData(prev => ({
                    ...prev,
                    [name]: optionalTaskData[name]
                }))
            }
        }
    }

    const handleChangeTaskData = (e) => {
        let { name, value } = e.target;
        setTaskData(prev => ({
            ...prev,
            [name]: value
        }))
        if (name !== "assignee" || name !== "project_phase_id") {
            if (name === "title") {
                setTaskErrors((prev) => ({
                    ...prev,
                    [name]: value?.trim()?.length ?
                        value?.trim()?.length < 20 ? "Minimum 20 character required" : value?.trim()?.length > 255 ? "Maxmimum 255 characters allowed" : null
                        : "Required"
                }))
            } else {
                setTaskErrors((prev) => ({
                    ...prev,
                    [name]: value?.toString()?.length ? null : "Required"
                }))
            }
        }

        if (name !== "title") {
            handleSubmitUpdateTaskData(name, value);
        }
    }

    // const handleBlurTaskTitle = (e) => {
    //     let { name, value } = e.target;
    //     if (value?.length) {
    //         if (value?.length < 20 || value?.length > 255) {
    //             setTaskErrors((prev) => ({
    //                 ...prev,
    //                 [name]: value?.trim()?.length ?
    //                     value?.trim()?.length < 20 ? "Minimum 20 character required" : value?.trim()?.length > 255 ? "Maxmimum 255 characters allowed" : null
    //                     : "Required"
    //             }))
    //         } else {
    //             setIsEditTaskTitle(false)
    //             handleSubmitUpdateTaskData('title', value);
    //         }
    //     }
    // }

    const handleSubmitTaskTitle = () => {
        if (taskData?.title?.trim()?.length) {
            if (taskData?.title?.trim()?.length < 20 || taskData?.title?.trim()?.length > 255) {
                setTaskErrors((prev) => ({
                    ...prev,
                    'title': taskData?.title?.trim()?.length ?
                        taskData?.title?.trim()?.length < 20 ? "Minimum 20 character required" : taskData?.title?.trim()?.length > 255 ? "Maxmimum 255 characters allowed" : null
                        : "Required"
                }))
            } else {
                setIsEditTaskTitle(false)
                handleSubmitUpdateTaskData('title', taskData?.title);
            }
        }
    }

    const handleCancelTaskTitle = () => {
        setTaskData(prev => ({
            ...prev,
            title: optionalTaskData?.title
        }))
        setIsEditTaskTitle(false)
    }

    const handleChangeDescription = (value) => {
        setTaskData(prev => ({
            ...prev,
            description: value,
        }))
    }

    const handleSubmitDescription = () => {
        let descriptionLength = taskData?.description?.replace(/<(.|\n)*?>/g, '').trim()?.length
        setTaskErrors((prev) => ({
            ...prev,
            description: descriptionLength ? (descriptionLength > 5000) ? 'Up to 5000 characters allowed' : ''
                : 'Reason Required'
        }))
        if (descriptionLength < 5000) {
            handleSubmitUpdateTaskData('description', taskData?.description);
            setIsEditTaskDescription(false);
        }
    }

    const handleCancelDescriptionChange = () => {
        setTaskData(prev => ({
            ...prev,
            'description': optionalTaskData?.description
        }))
        setIsEditTaskDescription(false);
    }

    const handleTimeChange = (newTime, isError) => {
        setTaskData(prev => ({
            ...prev,
            estimation: newTime
        }))
    };

    const onBlurTime = (newTime, isError) => {
        if (newTime !== optionalTaskData?.estimation && !isError) {
            handleSubmitUpdateTaskData('estimation', newTime);
        }
    }

    const fileSizeValidator = (file, type = "file") => {
        const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
        if (type === "validator") {
            if (!allowedFormats.includes(file.type)) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
        } else {
            const allowedFormats = [".jpeg", ".jpg", ".png", ".pdf"];
            const isValidFormat = allowedFormats.some(format => file.name?.toLowerCase()?.endsWith(format));
            if (!isValidFormat) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
            if (file?.size > maxFileSize) {
                showAlert("File size exceeds 5MB limit.", "error");
                return {
                    code: "size-too-large",
                    message: "File size exceeds 5MB limit.",
                };
            }
        }
        return null;
    };

    const totalSizeValidator = (newFiles) => {
        const newTotalSize = newFiles?.reduce((acc, file) => acc + file.size, 0);
        return newTotalSize > maxTotalSize;
    };

    const handleChangeImage = async (event) => {
        const allfiles = event.target.files;
        const files = Array.from(allfiles);
        if (files) {
            const invalidFiles = files
                .map(file => fileSizeValidator(file))
                .filter(error => error !== null);

            if (invalidFiles.length > 0) return;

            if (totalSizeValidator(files)) {
                showAlert("Total size exceeds the limit. Max 10MB allowed.", "error");
                return;
            }

            const updatedFiles = [];

            for (const file of files) {
                let base64 = null;
                if (file instanceof Blob || file instanceof File) {
                    base64 = await convertToBase64(file);
                } else {
                    base64 = file?.base64;
                }
                let fileObject = {
                    name: file?.name,
                    size: file?.size,
                    type: file?.type,
                    preview: URL.createObjectURL(file),
                    unique_id: generateUniqueId(),
                    base64,
                };
                updatedFiles.push(fileObject);
            }
            const limitedFiles = [...updatedFiles]?.filter((item) => item && (item !== null || item !== "null"));
            setTaskData(prev => ({ ...prev, attachments: [...prev?.attachments, ...limitedFiles] }));
            handleSubmitUpdateTaskData('attachment', [...taskData?.attachments, ...limitedFiles]);
        }
    };

    const handleChangeFilesData = (files) => {
        setTaskData(prev => ({
            ...prev,
            attachments: files,
        }))
        handleSubmitUpdateTaskData('attachment', files);
    }

    const getHoursWithPercentage = () => {
        const manualHoursData = taskData?.WorkLogs?.reduce((allTimeData, currentData) => {
            if (currentData?.is_eod) {
                return allTimeData + currentData?.duration
            }
            return allTimeData
        }, 0)

        const autoHoursData = taskData?.WorkLogs?.reduce((allTimeData, currentData) => {
            if (!currentData?.is_eod) {
                return allTimeData + currentData?.duration
            }
            return allTimeData
        }, 0)

        let spentHours = (manualHoursData + autoHoursData);
        let estimationHours = taskData?.originalEstimation ?? 0;
        let manualHours = manualHoursData ?? 0;
        let autoHours = autoHoursData ?? 0;

        let overSpentHours = estimationHours > 0 ? (spentHours - estimationHours) : 0;
        let remainingHours = estimationHours - spentHours;

        let totalHours = overSpentHours < 0 ? estimationHours : spentHours

        let spentHoursPercentage = (spentHours / totalHours) * 100;
        let overSpentHoursPercentage = overSpentHours > 0 ? ((overSpentHours / totalHours) * 100) : 0;
        let remainingHoursPercentage = remainingHours > 0 ? (remainingHours / totalHours) * 100 : 0;
        let estimationPercentageHours = (estimationHours / totalHours) * 100;
        let manualHoursPercentageHours = ((manualHours / totalHours) * 100);
        let autoHoursPercentageHours = ((autoHours / totalHours) * 100);

        const getFinalManualHoursPercentage = overSpentHours > 0 ? ((manualHoursPercentageHours * estimationPercentageHours) / 100) : manualHoursPercentageHours;
        const getFinalAutoHoursPercentage = overSpentHours > 0 ? ((autoHoursPercentageHours * estimationPercentageHours) / 100) : autoHoursPercentageHours;

        return {
            spentHours: spentHours,
            manualHours: manualHours,
            autoHours: autoHours,
            estimationHours: estimationHours,
            overSpentHours: overSpentHours,
            remainingHours: remainingHours,
            spentHoursPercentage: spentHoursPercentage,
            overSpentHoursPercentage: overSpentHoursPercentage,
            remainingHoursPercentage: remainingHoursPercentage,
            manualHoursPercentage: getFinalManualHoursPercentage,
            autoHoursPercentage: getFinalAutoHoursPercentage,
            estimationPercentageHours: estimationPercentageHours,
        }
    }

    const downloadSingleImage = async (url, fileName) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            saveAs(blob, fileName);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    const downloadAllFiles = () => {
        taskData?.attachments.forEach((file) => {
            if (file.isExist) {
                if (file.preview.endsWith('.png') || file.preview.endsWith('.jpg') || file.preview.endsWith('.jpeg')) {
                    downloadSingleImage(file.preview, file.name);
                } else {
                    saveAs(file.preview, file.name);
                }
            }
        });
    };

    const getUpdatedByUser = (updatedUser) => {
        if (updatedUser) {
            return <b>{titleCase(allOrfUsersList?.find(userData => taskData?.updated_by === userData?.id)?.name)}</b>
        } else {
            return ""
        }
    }

    const isDisabled = (key) => {
        if (taskData?.status === 'Working') {
            return key === "Re-Open"
        } else if (taskData?.status === 'Re-Open') {
            return key === "Working"
        } else if (taskData?.status === 'Pause') {
            return ['Block', 'Close']?.includes(key)
        } else if (taskData?.status === 'Block') {
            return ['Pause', 'Close']?.includes(key)
        } else if (taskData?.status === 'Close') {
            return ['Block', 'Pause']?.includes(key)
        } else {
            return false
        }
    }

    const [anchorElMove, setAnchorElMove] = useState(null);
    const [openMoveModal, setOpenMoveModal] = useState(false);
    const openMove = Boolean(anchorElMove);
    // const [selectedProject, setSelectedProject] = useState("");

    const handleClickMove = (event) => {
        setAnchorElMove(event.currentTarget);
    };

    const handleCloseMove = () => {
        setAnchorElMove(null);
    };

    const handleClickOpenMoveModal = () => {
        setAnchorElMove(null);
        setOpenMoveModal(true);
    };

    const handleCloseMoveModal = () => {
        setOpenMoveModal(false);
    };

    // const isShowMoveTask = () => {
    //     if (currentUser?.id && (currentUser?.id?.toString() === taskData?.user_id?.toString())) {
    //         let activeTackerTaskList = currentUserTaskList?.find((taskListData) => {
    //             let sortedWorkLog = sortArrayByKey(taskListData?.WorkLogs?.filter(logData => !logData?.is_eod), "desc", "id")
    //             return sortedWorkLog?.length && (sortedWorkLog?.[0]?.start_time && !sortedWorkLog?.[0]?.end_time)
    //         })
    //         if (activeTackerTaskList)
    //             return (activeTackerTaskList?.id !== taskData?.id)
    //         else
    //             return true
    //     }
    //     return false
    // }

    const isShowMoveTask = () => {
        if (currentUser?.id && (currentUser?.id?.toString() === taskData?.user_id?.toString() || taskData?.created_by_task?.id === currentUser?.id) && taskData?.status !== "Working") {
            return true
        }
        return false
    }

    const submitUpdatedMove = (taskId) => {
        handleTaskMove(taskId);
        handleCloseUpdateTaskDrawer();
        setTimeout(() => {
            handleCloseMoveModal();
        }, 0);
    }

    const handleCancelMoveTask = () => {
        handleCloseMoveModal();
    }

    return (
        <Box width={590} display={"flex"} flexDirection={"column"} flex={1} height={(loading?.taskDataLoading) ? "100vh" : "auto"} justifyContent={(loading?.taskDataLoading) ? "center" : "flex-start"} alignItems={"stretch"}>
            {
                (loading?.taskDataLoading) ? <CircularLoader height={'100%'} minHeight={'500px'} /> :
                    <>
                        <Box bgcolor={"white"} borderBottom={"1px solid rgba(0, 0, 0, 0.12)"} position={"sticky"} top={0} zIndex={1}>
                            <Box px={2} py={"11px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                <Box display={"flex"} alignItems={"center"} gap={2}>
                                    <Box display={"flex"} alignItems={"center"} gap={1}>
                                        <Box minWidth={24} width={24} height={24} display={"flex"} alignItems={"center"} justifyContent={"center"} border={"1px solid rgba(63, 92, 118, 0.2)"} borderRadius={"2px"}>
                                            {getTaskTypeIcon(taskData?.type)}
                                        </Box>
                                        <Typography variant="body1" color={"text"} fontSize={14} fontWeight={700} lineHeight={"17px"}>
                                            {taskData?.Project?.short_code}-{taskData?.task_no ?? 1}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={4}>
                                    {isShowMoveTask() ? <Box>
                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            id="basic-button"
                                            aria-controls={openMove ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openMove ? 'true' : undefined}
                                            onClick={handleClickMove}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorElMove}
                                            open={openMove}
                                            onClose={handleCloseMove}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={handleClickOpenMoveModal}>Move task</MenuItem>
                                        </Menu>
                                    </Box> : null}
                                    <IconButton
                                        aria-label="Close"
                                        size="small"
                                        onClick={() => handleCloseUpdateTaskDrawer()}
                                        sx={{ width: 24, minWidth: 24, height: 24, padding: '2px' }}
                                    >
                                        <CloseIcon fontSize="medium" />
                                    </IconButton>
                                </Box>

                                <MoveTask handleCloseMoveModal={handleCloseMoveModal}
                                    openMoveModal={openMoveModal} HtmlTooltip={HtmlTooltip}
                                    movableTaskData={taskData}
                                    // selectedProject={selectedProject} setSelectedProject={setSelectedProject}
                                    submitUpdatedMove={(taskId) => submitUpdatedMove(taskId)}
                                    handleCancelMoveTask={handleCancelMoveTask} projectList={projectList}
                                />
                            </Box>
                            <Divider />
                            <Box p={1}>
                                {!isEditTaskTitle ?
                                    <Box position={"relative"} width={"100%"}>
                                        <Typography
                                            ref={textRef}
                                            className='task-title' variant="h2" color={"dark.800"} fontSize={20} fontWeight={600} lineHeight={"31px"}
                                            sx={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                minHeight: "40px",
                                                maxHeight: isTitleExpanded ? "none" : maxHeight,
                                                overflow: "hidden",
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: isTitleExpanded ? "none" : "3",
                                                whiteSpace: "pre-wrap",
                                                wordWrap: "break-word",
                                                borderRadius: "4px",
                                                "&:hover": {
                                                    background: '#F4F4F4',
                                                    '~ button': {
                                                        background: isTitleExpanded ? "#ffffff" : "linear-gradient(90deg, transparent, #F4F4F4 25%)",
                                                    },
                                                },
                                            }}
                                            onClick={() => setIsEditTaskTitle(true)}
                                        >
                                            {/* {(isTitleExpanded || taskData?.title?.length <= maxTextLenth) ? taskData?.title : `${taskData?.title?.slice(0, maxTextLenth)}...`} */}
                                            {taskData?.title}
                                        </Typography>
                                        {isOverflowing && (
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                disableRipple={true}
                                                onClick={() => setIsTitleExpanded(!isTitleExpanded)}
                                                sx={{
                                                    textTransform: "none",
                                                    p: '0px 8px',
                                                    position: isTitleExpanded ? "static" : "absolute",
                                                    bottom: '10px',
                                                    right: '8px',
                                                    background: isTitleExpanded ? "#ffffff" : "linear-gradient(90deg, transparent, #ffffff 25%)",
                                                    borderRadius: '0px',
                                                    paddingLeft: isTitleExpanded ? "8px" : "30px",
                                                    marginLeft: isTitleExpanded ? "8px" : "0px",
                                                }}
                                            >
                                                {isTitleExpanded ? "See Less" : "See More"}
                                            </Button>
                                        )}
                                    </Box>
                                    :
                                    <Box
                                        sx={{
                                            padding: "4px 8px 8px",
                                            minHeight: "40px",
                                            borderRadius: "4px",
                                            border: "1px solid #1976d2",
                                            backgroundColor: "#fff",
                                            display: "flex",
                                            flexDirection: 'column',
                                            alignItems: "center",
                                        }}
                                    >
                                        <TextField
                                            inputRef={inputRef}
                                            id="title"
                                            name={"title"}
                                            variant="standard"
                                            fullWidth
                                            multiline
                                            autoFocus
                                            value={taskData?.title}
                                            onChange={(e) => handleChangeTaskData(e)}
                                            // onBlur={(e) => handleBlurTaskTitle(e)}
                                            inputProps={{
                                                disableUnderline: true,
                                                width: 500,
                                                maxWidth: 500,
                                                style: {
                                                    whiteSpace: "pre-wrap",
                                                    wordWrap: "break-word",
                                                    fontSize: "20px",
                                                    fontWeight: 600,
                                                    lineHeight: "30px"
                                                },
                                            }}
                                            InputProps={{ disableUnderline: true }}
                                            error={taskErrors?.title?.length && Boolean(taskErrors.title)}
                                            helperText={taskErrors?.title && taskErrors?.title}
                                        />
                                        <Box display={"flex"} gap={1} mt={"5px"} width={'100%'}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                type='button'
                                                sx={{ boxShadow: 'none', textTransform: 'none' }}
                                                onClick={() => handleSubmitTaskTitle()}
                                                disabled={isLoading.taskSubmitLoading}
                                                isButtonLoading={isLoading.taskSubmitLoading}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="text"
                                                color="inherit"
                                                size="medium"
                                                sx={{ boxShadow: 'none', textTransform: 'none' }}
                                                onClick={() => handleCancelTaskTitle()}
                                                disabled={isLoading.taskSubmitLoading}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box flex={1} overflow={"auto"}>
                            <Box p={2} pb={0}>
                                <Box mb={3} px={1} width={414}>
                                    <Stack spacing={1}>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <TaskAltOutlinedIcon fontSize="small" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Type</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: 0,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '6px 28px 6px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: 600,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl size="small" variant="standard" fullWidth>
                                                    <Select
                                                        labelId="select-user"
                                                        id="select-user"
                                                        label="Task"
                                                        name='type'
                                                        value={taskData?.type || ''}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        inputProps={{ id: getInputId("type", 0) }}
                                                        disabled={isLoading?.taskSubmitLoading}
                                                    >
                                                        {Object.keys(TASK_TYPE_LIST)?.map((key, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={key}
                                                                sx={{
                                                                    padding: 1,
                                                                }}
                                                            >
                                                                <Box display={'flex'}>
                                                                    <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}
                                                                        sx={{ 'svg': { width: 20, height: 20, minWidth: 20 } }}
                                                                    >
                                                                        {getTaskTypeIcon(key)}
                                                                    </Box>
                                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                        {TASK_TYPE_LIST[key]}
                                                                    </Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.type?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.type}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <ModeStandbyOutlinedIcon fontSize="small" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Status</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: taskData?.status?.length ? 0 : 1,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '4px 28px 4px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: taskData?.status?.length ? 600 : 400,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl variant="standard" fullWidth
                                                    sx={{
                                                        '.MuiChip-root': {
                                                            height: 'auto',
                                                            overflow: "hidden",
                                                        },
                                                        '.MuiChip-label': {
                                                            padding: '1px 12px',
                                                            background: '#ffffff',
                                                        },
                                                    }}
                                                >
                                                    <Select
                                                        labelId="status-label"
                                                        id="status"
                                                        name="status"
                                                        value={taskData?.status || ""}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        inputProps={{ id: getInputId("status", 0) }}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (selected?.toString()?.length === 0) {
                                                                return <>Select</>;
                                                            }
                                                            return getTaskStatus(selected, "chip")
                                                        }}
                                                        disabled={isLoading?.taskSubmitLoading}
                                                    >
                                                        {Object.keys(TASK_STATUS_LIST)?.map((key, index) => (
                                                            <MenuItem sx={{
                                                                padding: 1,
                                                                '.MuiChip-root': {
                                                                    height: 'auto',
                                                                    overflow: "hidden",
                                                                },
                                                                '.MuiChip-label': {
                                                                    padding: '3px 12px',
                                                                    background: '#ffffff',
                                                                },
                                                            }}
                                                                disabled={isDisabled(key)}
                                                                key={index}
                                                                value={key}>
                                                                {getTaskStatus(key, "chip")}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.status?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.status}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <Box width={18} height={18} minWidth={18} sx={{ 'svg': { width: 18, height: 18 } }}>
                                                    {ICONS.PriorityIcon}
                                                </Box>
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Priority</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: taskData?.priority?.length ? 0 : 1,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '6px 28px 6px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: taskData?.priority?.length ? 600 : 400,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl size="small" variant="standard" fullWidth>
                                                    <Select
                                                        labelId="select-user"
                                                        id="select-user"
                                                        label="Task"
                                                        name='priority'
                                                        value={taskData?.priority || ''}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        inputProps={{ id: getInputId("priority", 0) }}
                                                        displayEmpty
                                                        disabled={isLoading?.taskSubmitLoading}
                                                        renderValue={(selected) => {
                                                            if (selected?.toString()?.length === 0) {
                                                                return <>Select</>;
                                                            }
                                                            return (
                                                                <Box display={'flex'}>
                                                                    <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                        {getPriorotyIcon(selected)}
                                                                    </Box>
                                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                        {PROJECT_TASK_PRIORITY[selected]}
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        }}
                                                    // disabled={isTracking}
                                                    >
                                                        {Object.keys(PROJECT_TASK_PRIORITY)?.map((key, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={key}
                                                                sx={{
                                                                    padding: 1,
                                                                }}
                                                            >
                                                                <Box display={'flex'}>
                                                                    <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                        {getPriorotyIcon(key)}
                                                                    </Box>
                                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                        {PROJECT_TASK_PRIORITY[key]}
                                                                    </Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.priority?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.priority}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <Box width={18} height={18} minWidth={18} sx={{ 'svg': { width: 18, height: 18 } }}>
                                                    {ICONS.PhaseIcon}
                                                </Box>
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Phase</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: taskData?.project_phase_id?.toString()?.length ? 0 : 1,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '6px 28px 6px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: taskData?.project_phase_id?.toString()?.length ? 600 : 400,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl variant="standard" fullWidth>
                                                    <Select
                                                        labelId="phase-label"
                                                        id="project_phase_id"
                                                        name="project_phase_id"
                                                        value={taskData?.project_phase_id || ''}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        displayEmpty
                                                        disabled={isLoading?.taskSubmitLoading}
                                                        renderValue={(selected) => {
                                                            if (selected?.toString()?.length === 0) {
                                                                return <>Select</>;
                                                            }
                                                            return titleCase(projectPhases?.find(phaseData => phaseData?.id === selected)?.title)
                                                        }}
                                                    >
                                                        {getFilterdProjectPhase()?.map((phaseData, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={phaseData?.id}
                                                                sx={{ padding: 1, fontSize: 14, fontWeight: 500, lineHeight: "24px", letterSpacing: '0.15px', color: 'rgba(0,0,0,0.87)' }}
                                                            >
                                                                {titleCase(phaseData?.title)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.project_phase_id?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.project_phase_id}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <PeopleAltOutlinedIcon fontSize="small" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Assignee</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: taskData?.assignee?.toString()?.length ? 0 : 1,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '6px 28px 6px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: taskData?.assignee?.toString()?.length ? 600 : 400,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl variant="standard" fullWidth>
                                                    <Select
                                                        labelId="assignee-label"
                                                        id="assignee"
                                                        name="assignee"
                                                        value={taskData?.assignee || ''}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        displayEmpty
                                                        disabled={isLoading?.taskSubmitLoading}
                                                        renderValue={(selected) => {
                                                            if (selected?.toString()?.length === 0) {
                                                                return <>Select</>;
                                                            }
                                                            return (
                                                                <Box display={"flex"} alignItems={"center"} width={"100%"}>
                                                                    <Box minWidth={25} width={25} height={25}>
                                                                        <BackgroundLetterAvatars
                                                                            user={activeMembersData?.find(userDetails => userDetails?.id?.toString() === selected?.toString())}
                                                                            sx={{
                                                                                width: "25px",
                                                                                height: "25px",
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Box flex={1} overflow={"hidden"} pl={1}>
                                                                        <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                            {titleCase(activeMembersData?.find(userDetails => userDetails?.id?.toString() === selected?.toString())?.name)}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        }}
                                                    >
                                                        {activeMembersData?.filter(userData => userData?.active)?.map((userData, index) => (
                                                            <MenuItem sx={{ padding: 1, }} key={index} value={userData?.id}>
                                                                <Box display={"flex"} alignItems={"center"} width={"100%"}>
                                                                    <Box minWidth={32} width={32} height={32}>
                                                                        <BackgroundLetterAvatars
                                                                            user={userData}
                                                                            sx={{
                                                                                width: "32px",
                                                                                height: "32px",
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Box flex={1} overflow={"hidden"} pl={1}>
                                                                        <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                            {titleCase(userData?.name)}
                                                                        </Typography>
                                                                        <Typography color={"rgba(63, 92, 118, 0.9)"} fontSize={12} fontWeight={400} lineHeight={"14px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                            {userData?.email}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.assignee?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.assignee}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <WatchLaterOutlinedIcon fontSize="small" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Estimate</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36} sx={{ '> div': { margin: 0, }, 'div': { padding: 0 }, 'input': { height: 27, pl: '6px' }, '.error-box': { position: 'relative', } }}>
                                                {/* Edit Estimate Field */}
                                                {/* <TimeInput value={taskData?.estimation} onChange={handleTimeChange} onBlur={onBlurTime} /> */}
                                                <TimeInput value={taskData?.estimation} onChange={(value, isError) => handleTimeChange(value, isError)} onBlur={(value, isError) => onBlurTime(value, isError)} disabled={isLoading?.taskSubmitLoading} />
                                                {/* Edit Estimate Field */}
                                            </Box>
                                        </Box>

                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <TrackChangesIcon fontSize="small" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Time Tracking</Typography>
                                            </Box>
                                            <Box display={"flex"} alignItems={"center"} flex={1} minHeight={36} pl={"6px"}>
                                                <Box flex={1} mr={"10px"}>
                                                    {getHoursWithPercentage()?.spentHours > 0 ? <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={"5px"}>
                                                        <Typography color={"dark.800"} fontSize={14} fontWeight={600} lineHeight={"17px"} letterSpacing={"0.15px"}>{formatDuration(getHoursWithPercentage()?.spentHours)}</Typography>
                                                        {getHoursWithPercentage()?.remainingHours > 0 ? <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>{formatDuration(getHoursWithPercentage()?.remainingHours)} Remaining</Typography>
                                                            :
                                                            getHoursWithPercentage()?.overSpentHours > 0 ?
                                                                <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"15px"} letterSpacing={"0.15px"}>{formatDuration(getHoursWithPercentage()?.overSpentHours)} Over</Typography> : null}
                                                    </Box> :
                                                        <Typography color={"dark.800"} fontSize={14} fontWeight={600} lineHeight={"17px"} letterSpacing={"0.15px"}>Not Tracked</Typography>
                                                    }
                                                    <Box width={"100%"} bgcolor={"#F0F0F0"} height={3} display={"flex"} mt={0.5}>

                                                        {getHoursWithPercentage()?.autoHours > 0 ?
                                                            <Box
                                                                height={3}
                                                                width={`${getHoursWithPercentage()?.autoHoursPercentage}%`}
                                                                minWidth={`${getHoursWithPercentage()?.autoHoursPercentage}%`}
                                                                bgcolor={"#047FE0"}></Box>
                                                            : null}
                                                        {getHoursWithPercentage()?.manualHours > 0 ?
                                                            <Box
                                                                height={3}
                                                                width={`${getHoursWithPercentage()?.manualHoursPercentage}%`}
                                                                minWidth={`${getHoursWithPercentage()?.manualHoursPercentage}%`}
                                                                bgcolor={"#F0891B"}></Box>
                                                            : null}
                                                        {getHoursWithPercentage()?.remainingHours > 0 ? <Box height={3} width={`${getHoursWithPercentage()?.remainingHoursPercentage}%`} minWidth={`${getHoursWithPercentage()?.remainingHoursPercentage}%`} bgcolor={"#BDBDBD"}></Box> : null}
                                                        {getHoursWithPercentage()?.overSpentHours > 0 ? <Box height={3} width={`${getHoursWithPercentage()?.overSpentHoursPercentage}%`} minWidth={`${getHoursWithPercentage()?.overSpentHoursPercentage}%`} bgcolor={"#ffffff"}>
                                                            <Box ml={0.5} height={3} bgcolor={"#D32F2F"} width={"100%"}></Box>
                                                        </Box> : null}
                                                    </Box>
                                                </Box>

                                                {((getHoursWithPercentage()?.spentHours > 0) || (getHoursWithPercentage()?.manualHours > 0 || getHoursWithPercentage()?.overSpentHours > 0)) ? <HtmlTooltip arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Stack gap={"6px"} >
                                                                <Box display={"flex"} alignItems={"center"} gap={1.5}>
                                                                    <Box minWidth={10} width={10} height={10} bgcolor={"#047FE0"}></Box>
                                                                    <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>Auto Tracking</Typography>
                                                                </Box>
                                                                <Box display={"flex"} alignItems={"center"} gap={1.5}>
                                                                    <Box minWidth={10} width={10} height={10} bgcolor={"#F0891B"}></Box>
                                                                    <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>Manual Tracking</Typography>
                                                                </Box>
                                                                <Box display={"flex"} alignItems={"center"} gap={1.5}>
                                                                    <Box minWidth={10} width={10} height={10} bgcolor={"#D32F2F"}></Box>
                                                                    <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>Time Exceeds</Typography>
                                                                </Box>
                                                            </Stack>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <HelpOutlineIcon fontSize="small" sx={{ color: '#3F5C76', mt: 0.5 }} />
                                                </HtmlTooltip> : null}
                                            </Box>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Divider />
                                <Box my={"18px"} className="text-editor text-desc-box">
                                    <Box
                                        pl={1} display={"flex"} alignItems={"center"} gap={"6px"} mb={"6px"}
                                        onClick={() => setIsEditTaskDescription(true)}
                                    >
                                        <SubjectIcon fontSize="small" sx={{ color: 'dark.main' }} />
                                        <Typography
                                            variant="body2"
                                            color={"dark.main"}
                                            fontSize={14}
                                            fontWeight={600}
                                            sx={{ lineHeight: "21px", letterSpacing: "0.15" }}
                                        >
                                            Description
                                        </Typography>
                                    </Box>
                                    <Box onClick={() => setIsEditTaskDescription(true)}>
                                        {(!isEditTaskDescription || isLoading?.taskSubmitLoading) ? <TextEditor
                                            value={taskData.description}
                                            onChange={(value) => { }}
                                            readOnly={true}
                                            isShowReadMore={true}
                                        /> : null}
                                    </Box>
                                    {
                                        (isEditTaskDescription && !isLoading?.taskSubmitLoading) ?
                                            <Box border={"1px solid #047FE0"} p={"15px"} borderRadius={"4px"}>
                                                <TextEditor
                                                    value={taskData.description}
                                                    onChange={(value) =>
                                                        handleChangeDescription(value)
                                                    }
                                                />
                                                {!!taskErrors?.description?.length ?
                                                    <Typography
                                                        variant="body2"
                                                        fontWeight={400}
                                                        color={'error'}
                                                        sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
                                                    >
                                                        {taskErrors?.description}
                                                    </Typography>
                                                    : null}
                                                <Box display={"flex"} alignItems={"center"} gap={1} mt={1}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="medium"
                                                        type='button'
                                                        sx={{ boxShadow: 'none', textTransform: 'none' }}
                                                        onClick={() => handleSubmitDescription()}
                                                        disabled={isLoading.taskSubmitLoading}
                                                        isButtonLoading={isLoading.taskSubmitLoading}
                                                    >
                                                        Submit
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        color="inherit"
                                                        size="medium"
                                                        sx={{ boxShadow: 'none', textTransform: 'none' }}
                                                        onClick={() => handleCancelDescriptionChange()}
                                                        disabled={isLoading.taskSubmitLoading}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            </Box>
                                            : null}
                                </Box>
                                <Box mb={"18px"} px={1}>
                                    <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                        <Box display={"flex"} alignItems={"center"} gap={"6px"} >
                                            <AttachFileIcon fontSize="small" sx={{ color: 'dark.main' }} />
                                            <Typography color={"text.main"}
                                                fontSize={14}
                                                fontWeight={600}
                                                sx={{ lineHeight: "21px", letterSpacing: "0.15" }}>Attachments</Typography>
                                            {taskData?.attachments?.length ? <Box py={"2px"} px={0.5} h={16} minWidth={24} textAlign={"center"} borderRadius={"50px"} bgcolor={"rgba(211, 215, 222, 0.5)"}>
                                                <Typography color={"text"} fontSize={10} fontWeight={400} lineHeight={"12px"}>{taskData?.attachments?.length}</Typography>
                                            </Box> : null}
                                        </Box>
                                        <Box display={"flex"} alignItems={"center"} gap={2}>
                                            {taskData?.attachments?.length ? <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                                                startIcon={<FileDownloadIcon fontSize="small" />}
                                                onClick={() => downloadAllFiles()}
                                                disabled={isLoading?.taskSubmitLoading}
                                            >
                                                Download All
                                            </Button> : null}
                                            <Button
                                                component="label"
                                                variant="text"
                                                color="primary"
                                                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                                                startIcon={<AddIcon fontSize="small" />}
                                                width={'300px'}
                                            >
                                                Upload
                                                <VisuallyHiddenInput
                                                    name="attachments"
                                                    type="file"
                                                    onChange={handleChangeImage}
                                                    accept="image/*, application/pdf"
                                                    multiple
                                                    disabled={isLoading?.taskSubmitLoading}
                                                />
                                            </Button>
                                        </Box>
                                    </Box>
                                    {!taskData?.attachments?.length ?
                                        <CustomFileDropZone
                                            onDrop={(files) =>
                                                handleChangeFilesData(files)
                                            }
                                            initialFiles={taskData?.attachments}
                                            isConvertToBase64={true}
                                            isDownloadShow={true}
                                            disabled={isLoading?.taskSubmitLoading}
                                        /> : null}

                                    <Box>
                                        {taskData?.attachments?.length ? <Typography color={"dark.600"} fontSize={11} fontWeight={400} lineHeight={"16px"} letterSpacing={"0.17px"} mb={1}>PDF, PNG, JPG (max. 10MB per file)</Typography> : null}
                                        {/* <Box display={"flex"} gap={"10px"}>
                                            <Box border={"1px solid #D3D7DE"} width={170} minWidth={170} gap={1} p={"6px"} borderRadius={"5px"} display={"flex"} alignItems={"center"} overflow={"hidden"}>
                                                <Box minWidth={30} width={30} height={30}>
                                                    <Avatar alt="Remy Sharp" src={userImg} sx={{ width: 30, height: 30 }} />
                                                </Box>
                                                <Box flex={1} overflow={"hidden"}>
                                                    <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"16px"} letterSpacing={"0.17px"} mb={"1px"} textOverflow={"ellipsis"} overflow={"hidden"}>Firstattachm...jpg</Typography>
                                                    <Typography color={"dark.600"} fontSize={10} fontWeight={400} lineHeight={"12px"} letterSpacing={"0.17px"}>100KB</Typography>
                                                </Box>
                                            </Box>
                                        </Box> */}
                                        {taskData?.attachments?.length ?
                                            <Box display={"flex"} gap={"10px"} flexWrap={'wrap'}
                                                sx={{
                                                    'aside': {
                                                        background: 'white !important',
                                                        padding: '0px !important',
                                                        margin: '0px !important'
                                                    }
                                                }}
                                            >
                                                <AttachmentLists attachments={taskData?.attachments} />
                                            </Box> : null}

                                    </Box>
                                </Box>
                                <Divider />
                                <Box py={2} gap={0.5}>
                                    <Typography color={"dark.600"} fontSize={13} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.17px"} display={"block"}><b>Updated</b> {formatTimeAgo(taskData?.updatedAt, true)} {taskData?.updated_by ? 'by' : ''} {getUpdatedByUser(taskData?.updated_by)}</Typography>
                                    <Typography color={"dark.600"} fontSize={13} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.17px"} display={"block"}><b>Created</b> {formatTimeAgo(taskData?.createdAt, true)} by <b>{titleCase(taskData?.created_by_task?.name)}</b></Typography>
                                </Box>

                            </Box>

                            <Box sx={{ width: '100%' }}>
                                <Box sx={{
                                    borderBottom: 1, borderColor: 'divider', padding: '0px 16px',
                                    'button': {
                                        padding: '8px 12px',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '24px',
                                        color: '#3F5C76',
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            color: '#047FE0',
                                        }
                                    },
                                }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Comments" {...a11yProps(0)} />
                                        <Tab label="WorkLogs" {...a11yProps(1)} />
                                        <Tab label="Activities" {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <Box bgcolor="secondary"
                                    flex={1}
                                    overflow="hidden"
                                    sx={{
                                        "> div": {
                                            height: '100%',
                                        },

                                        ".tabspanel > .MuiBox-root": {
                                            padding: "20px",
                                            height: '100%',
                                            "> p": {
                                                height: '100%',
                                            }
                                        }
                                    }}
                                >
                                    <CustomTabPanel value={value} index={0} className="tabspanel">
                                        <TaskComment commentData={taskData?.TaskComments ?? []} usersList={allOrfUsersList} taskData={taskData} showAlert={showAlert} setTaskData={setTaskData} currentUser={currentUser} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1} className="tabspanel">
                                        <TaskWorklog worklogData={taskData?.WorkLogs ?? []} usersList={allOrfUsersList} taskData={taskData} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2} className="tabspanel">
                                        <TaskActivity activityData={taskData?.TaskActivities ?? []} usersList={allOrfUsersList} taskData={taskData} />
                                    </CustomTabPanel>
                                </Box>
                            </Box>
                        </Box>
                        {/* <StopTaskPopup handleCloseStop={handleCloseStop} trackedHours={elapsedTime}
                            closableTaskData={closableTaskData} openStop={openStop} HtmlTooltip={HtmlTooltip}
                            workLogDescription={workLogDescription} setWorkLogDescription={setWorkLogDescription}
                            submitWorklogData={submitWorklogData} cancelWorkLogData={cancelWorkLogData}
                            setWorkLogStatus={setWorkLogStatus} workLogStatus={workLogStatus}
                        /> */}
                    </>
            }
        </Box>
    )
}

export default UpdateTask