/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Button from "../../../../components/Button";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import CircularLoader from "../../../../components/CircularLoader";
import Input from "../../../../components/Input";
import ICONS from "../../../../constants/icons";
import { useAlert } from "../../../../hook/useAlert";
// import Switch from "../../../../components/Switch";
import { usePageTitle } from "../../../../hook/useTitle";
import { getInputId, sortArrayByKey, titleCase } from "../../../../utils";
import TablePagination from "../../../../components/TablePagination";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../routes/urls";
import InformationModal from "../../../../components/InformationModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";

const AdministratorWorkType = () => {
  let initialEditableWorkTypeData = {
    id: null,
    organization_id: null,
    name: "",
    external: false,
    org_year_id: null,
    active: false,
  };
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
    editButton: false,
    addButton: false,
  });
  const [workTypeData, setWorkTypeData] = useState([]);
  const [optionalWorkTypeData, setOptionalWorkTypeData] = useState([]);
  const [tempWorkTypeData, setTempWorkTypeData] = useState([]);
  const [editableWorkType, setEditableWorkType] = useState(initialEditableWorkTypeData);
  const [newWorkType, setNewWorkType] = useState(initialEditableWorkTypeData);
  const [errors, setErrors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [isShowEmptyRow, setIsShowEmptyRow] = useState(false);
  const [isOpenWarningShow, setIsOpenWarningShow] = useState(false);
  const [isOpenDeleteShow, setIsOpenDeleteShow] = useState(false);
  const [deletableWorkType, setDeletableWorkType] = useState({});

  setPageTitle("WorkType");

  useEffect(() => {
    if (currentUser?.organization_id && currentUser?.organization?.work_type_settings?.[0]?.org_year_id) {
      getWorkTypeData(currentUser?.organization_id, currentUser?.organization?.work_type_settings?.[0]?.org_year_id);
    }
  }, []);

  useEffect(() => {
    getDataAccordingPagination();
  }, [page, rowsPerPage]);

  const getDataAccordingPagination = (
    optionalUserRolesListData = optionalWorkTypeData
  ) => {
    let dataToPaginate = [...optionalUserRolesListData];

    if (dataToPaginate && dataToPaginate.length) {
      const startIdx = page * rowsPerPage;
      const endIdx = Math.min(startIdx + rowsPerPage, dataToPaginate?.length);
      const updatedPageData = dataToPaginate.slice(startIdx, endIdx);
      setWorkTypeData(updatedPageData);
      setTempWorkTypeData(updatedPageData);
    } else {
      setWorkTypeData([]);
      setTempWorkTypeData([]);
    }
  };

  const getWorkTypeData = async (organization_id, org_year_id) => {
    try {
      const response = await axiosInstance.get(
        `${API.getWorkTypesByOrganisationYearId(organization_id, org_year_id)}/false`
      );
      if (response.status === 200) {
        let sortedData = sortArrayByKey(response?.data?.data?.[0]?.work_type_settings, "asc", "name");
        setOptionalWorkTypeData(sortedData);
        setTempWorkTypeData(sortedData);
        getDataAccordingPagination(sortedData);
      }
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditRole = (row) => {
    setWorkTypeData(tempWorkTypeData);
    setEditableWorkType(row);
  };

  const handleChange = (e, is_switch = false) => {
    setEditableWorkType((prev) => ({
      ...prev,
      [e.target.name]: is_switch ? e.target.checked : (e.target.name === "external" ? (e.target.value === "true" ? true : false) : e.target.value),
    }));
    setErrors((prev) => ({ ...prev, name: "" }));
  };

  const handleChangeNewName = (e, is_switch = false) => {
    setNewWorkType((prev) => ({
      ...prev,
      [e.target.name]: is_switch ? e.target.checked : (e.target.name === "external" ? (e.target.value === "true" ? true : false) : e.target.value),
    }));
    setErrors((prev) => ({ ...prev, newName: "" }));
  };

  const handleCancel = () => {
    setWorkTypeData(tempWorkTypeData);
    setEditableWorkType(initialEditableWorkTypeData);
    setErrors((prev) => ({ ...prev, name: "" }));
  };

  const handleCancelAddRole = () => {
    setIsShowEmptyRow(false);
    setWorkTypeData(tempWorkTypeData);
    setNewWorkType(initialEditableWorkTypeData);
    setErrors((prev) => ({ ...prev, newName: "" }));
  };

  const handleAddWorkType = async () => {
    try {
      if (isValidate(workTypeData)) {
        setIsLoading((prev) => ({ ...prev, addButton: true }));
        let body = {
          ...newWorkType,
          organization_id: currentUser?.organization_id,
          org_year_id: currentUser?.organization?.work_type_settings?.[0]?.org_year_id,
          name: titleCase(newWorkType?.name?.trim()),
          external: newWorkType?.external,
        };

        const response = await axiosInstance.post(
          API.createOrgWorkType,
          body
        );
        if (response.status === 200) {
          getWorkTypeData(currentUser?.organization_id, currentUser?.organization?.work_type_settings?.[0]?.org_year_id);
          setNewWorkType(initialEditableWorkTypeData);
          setIsShowEmptyRow(false);
          showAlert(response?.data?.message);
          setIsLoading((prev) => ({ ...prev, addButton: false }));
        } else {
          handleCancelAddRole();
          showAlert(
            response?.data?.message || "Something went wrong while adding role",
            "error"
          );
          setIsLoading((prev) => ({ ...prev, addButton: false }));
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, addButton: false }));
    }
  };

  const validateUniqueWorkTye = (workTypeData, type) => {
    const uniqueRoleNames = workTypeData?.filter(
      (role) =>
        (type === "add" ? newWorkType.id : editableWorkType.id) !== role?.id &&
        role.name?.toString()?.toLowerCase()?.trim() ===
        (type === "add"
          ? newWorkType?.name?.toLocaleLowerCase()?.trim()
          : editableWorkType?.name?.toLocaleLowerCase()?.trim())
    );
    if (type === "add" && uniqueRoleNames.length > 0) {
      return false;
    } else if (type === "update" && uniqueRoleNames.length === 1) {
      if (
        editableWorkType?.name?.toLowerCase()?.trim() ===
        uniqueRoleNames[0]?.name?.toLowerCase()?.trim() &&
        editableWorkType?.id === uniqueRoleNames[0]?.id
      )
        return true;
      else return false;
    } else if (type === "update" && uniqueRoleNames.length > 1) {
      return false;
    }
    return true;
  };

  const isValidate = (array, type = "add", statusUpdate = false) => {
    if (!editableWorkType?.name?.length && type !== "add" && !statusUpdate) {
      setErrors((prev) => ({ ...prev, name: "Please enter work type name" }));
      return false;
    } else if (type === "add" && !newWorkType?.name?.length) {
      setErrors((prev) => ({ ...prev, newName: "Please enter work type name" }));
      return false;
    }
    if (!validateUniqueWorkTye(array, type)) {
      if (type === "add") setErrors({ newName: "Work Type names should be unique" });
      else setErrors({ name: "Work Type names should be unique" });
      return false;
    } else {
      setErrors({ name: "", newName: "" });
    }
    return true;
  };

  const handleUpdateWorkType = async (
    editableWorkTypeData = { ...editableWorkType }, isDelete = false
  ) => {
    try {
      if (isValidate(workTypeData, "update") || isDelete) {
        setIsLoading((prev) => ({ ...prev, editButton: true }));
        let updateData = [];
        if (isDelete) {
          updateData = workTypeData?.filter((data) => data?.id !== editableWorkTypeData?.id);
        } else {
          updateData = workTypeData?.map((data) => {
            return data?.id === editableWorkTypeData?.id
              ? {
                ...data,
                name: editableWorkTypeData?.name,
                active: editableWorkTypeData?.active,
                external: editableWorkTypeData?.external
              }
              : data;
          });
        }
        let body = {
          id: editableWorkTypeData?.id,
          name: editableWorkTypeData?.name,
          external: editableWorkTypeData?.external,
          organization_id: currentUser?.organization_id,
          org_year_id: currentUser?.organization?.work_type_settings?.[0]?.org_year_id,
          is_deleted: isDelete
        };
        const response = await axiosInstance.put(API.updateOrgWorkType, body);
        if (response.status === 200) {
          setTempWorkTypeData(updateData);
          setWorkTypeData(updateData);
          if (isDelete) {
            setOptionalWorkTypeData((prev) => prev?.filter(data => data?.id !== editableWorkTypeData?.id))
          } else {
            setOptionalWorkTypeData((prev) =>
              prev?.map((data) => {
                return data?.id === editableWorkTypeData?.id
                  ? {
                    ...data,
                    name: editableWorkTypeData?.name,
                    external: editableWorkTypeData?.external,
                  }
                  : data;
              })
            );
          }
          setEditableWorkType(initialEditableWorkTypeData);
          handleCloseModal();
          showAlert(response?.data?.message);
          setIsLoading((prev) => ({ ...prev, editButton: false }));
        }
      }
    } catch (error) {
      console.error(error);
      showAlert(error?.response?.data?.message);
      setEditableWorkType(initialEditableWorkTypeData);
      setIsLoading((prev) => ({ ...prev, editButton: false }));
    }
  };

  // const handleActiveInactiveWorkType = async (event, worktypeData) => {
  //   try {
  //     const body = { active: event.target.checked };
  //     const response = await axiosInstance.put(API.activeInactiveOrgWorkType(worktypeData?.id), body);
  //     if (response.status === 200) {
  //       showAlert(response?.data?.message);
  //       let updateData = workTypeData?.map((data) => {
  //         return data?.id === worktypeData?.id
  //           ? {
  //             ...data,
  //             active: body.active,
  //           }
  //           : data;
  //       });
  //       setTempWorkTypeData(updateData);
  //       setWorkTypeData(updateData);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
  };

  const handleCreateWorkTye = () => {
    setIsShowEmptyRow(true);
  };

  const handleRedirectLeaveSetting = (row) => {
    navigate(`${URLS.AdministratorLeaves}?tabValue=1&worktypeId=${row?.id}`)
  }

  const handleClickDelete = (workTypeDetails) => {
    setDeletableWorkType(workTypeDetails);
    if (workTypeDetails?.Users?.length) {
      setIsOpenWarningShow(true)
    } else {
      setIsOpenDeleteShow(true)
    }
    handleClose();
  }

  const handleCloseModal = () => {
    setDeletableWorkType({});
    setIsOpenWarningShow(false);
    setIsOpenDeleteShow(false);
  }

  const checkIsDefaultWorkType = (name) => {
    return ['hybrid', 'in-office', 'remote', 'external']?.includes(name?.toLocaleLowerCase())
  }

  const handleCheckIsDisable = (workTypeDetails) => {
    let isUserLength = !!workTypeDetails?.Users?.length;
    let defaultWorkTypeInclude = checkIsDefaultWorkType(workTypeDetails?.name)
    return (isUserLength && defaultWorkTypeInclude)
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: "100%" }}
        p={{ xs: 2, lg: 3, xl: 4 }}
        py={{ xs: 3, lg: 3, xl: 4 }}
        overflow="auto"
      >
        <Grid
          container
          mb={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography
              color="dark.800"
              fontSize={14}
              fontWeight={500}
              lineHeight="24px"
              letterSpacing="0.17px"
            >
              {optionalWorkTypeData?.length || 0} Work Types
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              fontWeight="500"
              sx={{
                color: "white",
                textTransform: "none",
                boxShadow: "none !important",
              }}
              onClick={handleCreateWorkTye}
              isButtonLoading={false}
              disabled={isShowEmptyRow || editableWorkType?.id}
            >
              Create Work Type
            </Button>
          </Grid>
        </Grid>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            boxShadow: "none",
            border: "1px solid #E0E0E0",
            borderRadius: "3px",
          }}
        >
          <TableContainer>
            <Table
              sx={{ width: "100%" }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow
                  sx={{
                    th: {
                      color: "dark.800",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      padding: "6px 16px",
                      background: "#F7F7F7",
                    },
                  }}
                >
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  {/* <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={500}
                        lineHeight="24px"
                        letterSpacing="0.17px"
                      >
                        Status
                      </Typography>
                    </Box>
                  </TableCell> */}
                  <TableCell
                    align="right"
                    sx={{ paddingRight: "38px !important" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isShowEmptyRow ? (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      td: {
                        color: "rgba(0, 0, 0, 0.87)",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.17px",
                        padding: "6px 16px",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        // width: "185px !important"
                        width: "calc(100%/3)",
                      }}
                    >
                      <Input
                        id={getInputId("workType-name")}
                        name="name"
                        variant="standard"
                        type="text"
                        onChange={(e) => handleChangeNewName(e)}
                        value={titleCase(newWorkType?.name)}
                        error={!!errors["newName"]}
                        helperText={errors["newName"]}
                        sx={{ width: "100%", maxWidth: "300px" }}
                        isOnlyAlphabetAllowed={true}
                      />
                    </TableCell>
                    <TableCell>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="external"
                        sx={{ flexDirection: "row" }}
                        size="small"
                        onChange={(e) => handleChangeNewName(e)}
                        value={newWorkType?.external}
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Internal"
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="External"
                        />
                      </RadioGroup>
                    </TableCell>
                    {/* <TableCell align={"center"}>
                      <Switch
                        name="active"
                        id="active"
                        size="small"
                        checked={newWorkType?.active}
                        onChange={(e) => handleChangeNewName(e, true)}
                      />
                    </TableCell> */}
                    <TableCell align="right">
                      <Box sx={{ padding: "5px 0px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fontSize={13}
                          size="small"
                          sx={{
                            textTransform: "none",
                            color: "white",
                            boxShadow: "none !important",
                          }}
                          onClick={handleAddWorkType}
                          isButtonLoading={isLoading?.addButton}
                        >
                          Add
                        </Button>
                        <Button
                          id="cancel"
                          name="cancel"
                          component="button"
                          variant="text"
                          color="secondary"
                          fontSize={13}
                          size="small"
                          sx={{ textTransform: "none" }}
                          onClick={() => handleCancelAddRole()}
                          disabled={isLoading?.addButton}
                          aria-label="Cancel"
                        >
                          Cancel
                        </Button>

                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
                {isLoading?.pageLoading ? (
                  <CircularLoader
                    variant="table"
                    rows={10} 
                    cols={4}
                  />
                ) : (workTypeData.length || isShowEmptyRow) ? (
                  workTypeData.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          sx={{
                            td: {
                              color: "rgba(0, 0, 0, 0.87)",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              letterSpacing: "0.17px",
                              padding: "6px 16px",
                            },
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              // width: "185px !important"
                              width: "calc(100%/3)",
                            }}
                          >
                            {Boolean(row?.id) &&
                              (row?.id === editableWorkType?.id ||
                                (row?.id === editableWorkType?.id) ===
                                editableWorkType?.uniqueId) &&
                              !["administrator"].includes(
                                row?.name?.toLowerCase()
                              ) ? (
                              <Input
                                id="role-name"
                                name="name"
                                variant="standard"
                                type="text"
                                onChange={(e) => handleChange(e)}
                                value={titleCase(editableWorkType?.name)}
                                error={!!errors["name"]}
                                helperText={errors["name"]}
                                sx={{ width: "100%", maxWidth: "300px" }}
                                // isOnlyCapitalFirstLatter={true}
                                isOnlyAlphabetAllowed={true}
                                disabled={checkIsDefaultWorkType(row?.name)}
                              />
                            ) : (
                              row?.name
                            )}
                            <span></span>
                          </TableCell>
                          <TableCell>
                            {Boolean(row?.id) &&
                              (row?.id === editableWorkType?.id ||
                                (row?.id === editableWorkType?.id) ===
                                editableWorkType?.uniqueId) ? (<RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="external"
                                  sx={{ flexDirection: "row" }}
                                  size="small"
                                  onChange={(e) => handleChange(e)}
                                  value={editableWorkType?.external}
                                >
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Internal"
                                    disabled={editableWorkType?.Users?.length}
                                  />
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="External"
                                    disabled={editableWorkType?.Users?.length}
                                  />
                                </RadioGroup>)
                              : (row?.external === true ? "External" : "Internal")}
                          </TableCell>
                          {/* <TableCell align={"center"}>
                            <Switch
                              name="active"
                              id={getInputId("is-active", index)}
                              size="small"
                              checked={row?.active}
                              onChange={(e) => handleActiveInactiveWorkType(e, row)}
                              disabled={row?.Users?.length || row?.id === editableWorkType?.id}
                            />
                          </TableCell> */}
                          <TableCell align="right">
                            {Boolean(row?.id) &&
                              (row?.id === editableWorkType?.id ||
                                (row?.id === editableWorkType?.id) ===
                                editableWorkType?.uniqueId) ? (
                              <Box sx={{ padding: "5px 0px" }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fontSize={13}
                                  size="small"
                                  sx={{
                                    textTransform: "none",
                                    color: "white",
                                    boxShadow: "none !important",
                                  }}
                                  onClick={() => handleUpdateWorkType()}
                                  isButtonLoading={isLoading?.editButton}
                                >
                                  Update
                                </Button>
                                <Button
                                  component="button"
                                  variant="text"
                                  color="secondary"
                                  fontSize={13}
                                  size="small"
                                  sx={{ textTransform: "none" }}
                                  onClick={() => handleCancel(row?.id)}
                                  disabled={isLoading?.editButton}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            ) : (
                              <div
                                style={{
                                  width: "98px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              >
                                <IconButton
                                  sx={{
                                    height: "30px",
                                    width: "30px",
                                    padding: "0px",
                                    borderRadius: "4px",
                                    color: "secondary.main",
                                  }}
                                  id={`basic-button-${index}`}
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, row?.id)}
                                >
                                  <i
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      display: "flex",
                                    }}
                                  >
                                    {ICONS.DotsHorizontal}
                                  </i>
                                </IconButton>
                                <Menu
                                  id={`basic-menu-${index}`}
                                  anchorEl={anchorEl}
                                  open={open && openMenuIndex === row?.id}
                                  onClose={() => handleClose()}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                  sx={{
                                    ".MuiPaper-root": {
                                      boxShadow:
                                        "0px 2px 4px -1px rgba(0,0,0,0.2)",
                                      width: "180px",
                                    },
                                    ".MuiMenuItem-root": {
                                      color: "dark.800",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      lineHeight: "24px",
                                      letterSpacing: "0.15px",
                                    },
                                    ".MuiMenuItem-root:hover": {
                                      background: "rgba(33, 150, 243, 0.04)",
                                    },
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleEditRole(row);
                                      handleClose();
                                    }}
                                    disabled={handleCheckIsDisable(row)}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => handleRedirectLeaveSetting(row)}
                                  >
                                    Go to Leave Setting
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => handleClickDelete(row)}

                                  >
                                    <span style={{ color: '#D32F2F' }}>Delete</span>
                                  </MenuItem>
                                </Menu>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      sx={{ padding: "12px 16px;" }}
                    >
                      <Typography
                        color={"secondary"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontSize={13}
                        sx={{ opacity: "0.5", height: "200px" }}
                      >
                        No Work Type found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={optionalWorkTypeData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <InformationModal
          isOpen={isOpenWarningShow}
          title={<span>Work Type <b>{titleCase(deletableWorkType?.name)}</b> can not be deleted.</span>}
          handleClose={() => handleCloseModal()}
          subList={<span> Work type <b>{titleCase(deletableWorkType?.name)}</b> cannot be deleted because team members are currently assigned to it. To proceed, please reassign or remove all members from <b>{titleCase(deletableWorkType?.name)}</b> before attempting to deleted it.</span>}
          buttonName={"Ok"}
        />
        <ConfirmationModal
          isOpen={isOpenDeleteShow}
          title={"Work-type"}
          deletableDataName={titleCase(deletableWorkType?.name)}
          dataContentName={"Work-type"}
          handleClose={() => setIsOpenDeleteShow(false)}
          onConfirm={() => handleUpdateWorkType(deletableWorkType, true)}
          onCancel={() => handleCloseModal()}
          isButtonLoading={isLoading?.deleteButton}
          subList={
            <span>
              Once you delete this work-type, it can not be restored.
            </span>
          }
        />
      </Box>
    </>
  );
};

export default AdministratorWorkType;
