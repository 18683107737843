/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Avatar,
    AvatarGroup,
    Box,
    Checkbox,
    Divider,
    Drawer,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Select,
    Skeleton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    tooltipClasses,
    Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "../../../../routes/urls";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumb from "../../../../components/Breadcrumb";
import useAuthentication from "../../../../hook/useAuthentication";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import { formatTimeAgo, generateUniqueId, getFormattedDate, getInputId, getPriorotyIcon, getProjectPhaseShortCode, getTaskStatus, getTaskTypeIcon, isProjectEditAccess, isProjectPhaseAccess, secondsToTime, sortArrayByKey, titleCase } from "../../../../utils";
import SearchBoxInput from "../../../../components/SearchBoxInput";
import { PROJECT_TASK_PRIORITY, TASK_TYPE_LIST } from "../../../../constants/default-values";
import SingleDatePicker from "../../../../components/SingleDatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { makeStyles } from "@mui/styles";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import CircularLoader from "../../../../components/CircularLoader";
import { useAlert } from "../../../../hook/useAlert";
import Button from "../../../../components/Button";
import PhaseDetails from "./phaseDetails";
import CreateTask from "./createTask";
import UpdateTask from "./updateTask";
import MembersList from "./membersList";
import PageNotFound from "../../../PageNotFound";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        color: '#ffffff',
        maxWidth: 'inherit',
        padding: 8,
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const ProjectAllDetails = () => {
    const navigate = useNavigate();
    const showAlert = useAlert();
    const params = useParams();
    const classes = useStyles();

    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();

    const [projectData, setProjectData] = useState(null);
    const [userRolesData, setUserRolesData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        pageLoading: true,
        projectUserLoading: false,
        userLoading: true,
        projectRoleLoading: false,
        projectTaskLoading: false,
        projectPhase: false,
        editProjectPhase: false,
        saveProjectPhase: false,
        taskSubmitLoading: false,
    });

    let initialMemeberData = {
        id: null,
        User: null,
        user_id: null,
        project_role_id: "Select",
        joining_date: "",
        is_active: true,
        unique_id: null,
    };
    const initialError = {
        User: "",
        project_role_id: "",
        joining_date: "",
        unique_id: generateUniqueId(),
    }
    const initialProjectPhaseHours = {
        id: null,
        hours: null,
    }

    const initialProjectPhase = {
        id: null,
        start_date: null,
        end_date: null,
        status: null,
        ProjectPhaseHours: []
    }

    const [teamMembersData, setTeamMembersData] = useState([initialMemeberData]);
    const [actualTeamMembersData, setActualTeamMembersData] = useState([]);
    const [inActiveMembersData, setInActiveMembersData] = useState([]);
    const [activeMembersData, setActiveMembersData] = useState([]);
    const [allOrfUsersList, setAllOrfUsersList] = useState([]);
    const [projectAllTask, setProjectAllTask] = useState([]);
    const [optionalProjectAllTask, setOptionalProjectAllTask] = useState([]);
    const [searchString, setSearchString] = useState("");
    // const [userFilter, setUserFilter] = useState(Object.keys(TASK_LIST_FILTER)[0])
    const [isMoreUserExist, setIsMoreUserExist] = useState(false);
    const [allUsersWithActiveMembers, setAllUsersWithActiveMembers] = useState([])
    const [errors, setErrors] = useState([]);

    const [projectPhases, setProjectPhases] = useState([]);
    const [optionalProjectPhases, setOptionalProjectPhases] = useState([]);
    const [isEditPhase, setIsEditPhase] = useState(null);
    const [isEditPhaseHours, setIsEditPhaseHours] = useState(null);
    const [isAddPhase, setIsAddPhase] = useState(false);
    const [editabelPhase, setEditabelPhase] = useState({});

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorManageEl, setAnchorManageEl] = useState(null);
    const open = Boolean(anchorEl);
    const openManage = Boolean(anchorManageEl);
    const [openElem, setOpenElem] = useState(null);
    const [openManageEle, setOpenManageEle] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openPhaseDrawer, setOpenPhaseDrawer] = useState(false);

    const [openTaskDrawer, setOpenTaskDrawer] = useState(false);
    const [openUpdateTaskDrawer, setOpenUpdateTaskDrawer] = useState(false);

    const [openMembersListDrawer, setOpenMembersListDrawer] = useState(false);
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [editableTaskDataId, setEditableTaskDataId] = useState("")
    const [isProjectDataFailed, setIsProjectDataFailed] = useState(false);
    const [projectList, setProjectList] = useState([])

    const handleChangeUserTaskData = (event) => {
        const { target: { value }, } = event;
        setSelectedUserList(
            typeof value === 'string' ? value.split(',') : value,
        );
        if (event?.target?.value?.length) {
            let usersTasks = optionalProjectAllTask?.filter(taskDetails => event?.target?.value?.includes(taskDetails?.user_id))
            if (searchString?.trim()?.length) {
                setProjectAllTask(usersTasks?.filter(taskDetails => taskDetails?.title?.toLowerCase()?.includes(searchString?.trim()?.toLowerCase())))
            } else {
                setProjectAllTask(usersTasks)
            }
        } else {
            if (searchString?.trim()?.length) {
                setProjectAllTask(optionalProjectAllTask?.filter(taskDetails => taskDetails?.title?.toLowerCase()?.includes(searchString?.trim()?.toLowerCase())))
            } else {
                setProjectAllTask(optionalProjectAllTask)
            }
        }
    };

    const handleClearFilter = () => {
        setSelectedUserList([]);
        setSearchString("");
        setProjectAllTask(optionalProjectAllTask)
    }

    useEffect(() => {
        if (currentUser) {
            getProjectRolesData();
            getAllTaskOfProject();
            getAllProjectList();
        }
        if (params?.projectId) {
            getProjectData();
            getAllUserListByOrgId();
            getProjectPhaseData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // getAllTaskOfProject(searchString);
        if (searchString?.trim()?.length) {
            if (selectedUserList?.length) {
                let usersTasks = optionalProjectAllTask?.filter(taskDetails => selectedUserList?.includes(taskDetails?.user_id))
                setProjectAllTask(usersTasks?.filter((taskDetails) => taskDetails?.title?.toLowerCase()?.includes(searchString?.trim()?.toLowerCase()) || taskDetails?.task_no?.toString()?.includes(searchString?.trim()?.toLowerCase())))
            } else {
                setProjectAllTask(optionalProjectAllTask?.filter((taskDetails) => taskDetails?.title?.toLowerCase()?.includes(searchString?.trim()?.toLowerCase()) || taskDetails?.task_no?.toString()?.includes(searchString?.trim()?.toLowerCase())))
            }
        } else {
            if (selectedUserList?.length) {
                let usersTasks = optionalProjectAllTask?.filter(taskDetails => selectedUserList?.includes(taskDetails?.user_id))
                setProjectAllTask(usersTasks)
            } else {
                setProjectAllTask(optionalProjectAllTask)
            }
        }

    }, [searchString]);

    const getAllProjectList = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, projectLoading: true }));
            let queryParams = {
                page: 1,
                limit: 1000,
                search: null,
                orgId: currentUser?.organization_id,
            };
            const response = await axiosInstance.post(
                API.getAllProjectsListWithMembers,
                queryParams
            );

            if (response?.status === 200) {
                setProjectList(response?.data?.data?.rows);
            } else {
                setProjectList([]);
            }
            setIsLoading((prev) => ({ ...prev, projectLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, projectLoading: false }));
        }
    };

    const getProjectData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(
                API.getProjectDetailsById(Number(params?.projectId))
            );
            if (response?.status === 200) {
                setProjectData(response?.data?.data);
            }
            setIsProjectDataFailed(false)
            setIsLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            setIsLoading((prev) => ({ ...prev, pageLoading: false }));
            setIsProjectDataFailed(true)
        }
    };

    const getProjectRolesData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, projectRoleLoading: true }));
            const response = await axiosInstance.get(
                API.organisationProjectRoles(currentUser?.organization_id)
            );
            if (response.status === 200) {
                let sortedData = sortArrayByKey(response?.data?.data, "asc", "title");
                setUserRolesData(sortedData);
            }
            setIsLoading((prev) => ({ ...prev, projectRoleLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, projectRoleLoading: false }));
        }
    };

    const getAllUserListByOrgId = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, userLoading: true }));
            const response = await axiosInstance.get(
                API.organisationUserSetting(currentUser?.organization_id)
            );

            if (response.status === 200) {
                if (response.data?.data) {
                    let sortedData = sortArrayByKey(response?.data?.data, "asc", "name");
                    setAllOrfUsersList(sortedData?.map((value) => ({
                        id: value?.id,
                        name: value?.name,
                        email: value?.email,
                        profile_img: value?.profile_img,
                        organization_id: value?.organization_id,
                        active: value?.active,
                    })))
                    getCurrentProjectUsers(Number(params?.projectId), sortedData)
                } else {
                    setAllOrfUsersList([]);
                }
            }
            setIsLoading((prev) => ({ ...prev, userLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, userLoading: false }));
        }
    };

    const getCurrentProjectUsers = async (projectId, allUserList = allOrfUsersList) => {
        try {
            setIsLoading((prev) => ({ ...prev, projectUserLoading: true }));
            const response = await axiosInstance.get(
                API.getAllProjectUsersList(projectId)
            );
            if (response?.status === 200) {
                if (response?.data?.data?.length) {
                    let activeAllMembersData = response?.data?.data?.filter((list) => list?.is_active);
                    setActiveMembersData(activeAllMembersData);
                    let nonActiveMembersData = response?.data?.data?.filter((list) => !list?.is_active)
                    let allActiveUsersList = allUserList?.filter(userData => !nonActiveMembersData?.find(memberData => memberData?.id === userData?.id))
                    setAllUsersWithActiveMembers(allActiveUsersList)
                    let membersData = [...activeAllMembersData]?.map((memberData) => {
                        return { ...memberData, unique_id: generateUniqueId() };
                    });
                    setInActiveMembersData(nonActiveMembersData);
                    if (response?.data?.data?.length < allActiveUsersList?.length) {
                        setIsMoreUserExist(true)
                    } else {
                        setIsMoreUserExist(false)
                    }
                    setTeamMembersData(sortArrayByKey(membersData, "asc", "User?.name"));
                    setActualTeamMembersData(sortArrayByKey(membersData, "asc", "User?.name"));
                    setErrors(
                        response?.data?.data?.map((member) => {
                            return {
                                User: "",
                                project_role_id: "",
                                joining_date: "",
                            };
                        })
                    );
                } else {
                    let activeMemberList = allUserList?.filter(userData => userData?.active);
                    setAllUsersWithActiveMembers(activeMemberList);
                    if (activeMemberList?.length) {
                        setIsMoreUserExist(true)
                    } else {
                        setIsMoreUserExist(false)
                    }
                    setTeamMembersData([]);
                }
            } else {
                setTeamMembersData([
                    {
                        ...initialMemeberData,
                        unique_id: generateUniqueId(),
                    },
                ]);
            }
            setIsLoading((prev) => ({ ...prev, projectUserLoading: false }));
        } catch (error) {
            setIsLoading((prev) => ({ ...prev, projectUserLoading: false }));
            console.error(error);
        }
    };

    const getAllTaskOfProject = async (searchValue = null) => {
        try {
            setIsLoading((prev) => ({ ...prev, projectTaskLoading: true }));
            let body = {
                "project_id": Number(params?.projectId),
                // "search": searchValue,
                // "user": userFilterStatus
            }
            if (!searchValue) delete body.search
            let response = await axiosInstance.post(API.getAllCreatedTaskListOfProject, body);
            if (response?.status === 200) {
                let usersData = response?.data?.projectTask;
                setOptionalProjectAllTask(response?.data?.projectTask);
                if (searchString?.trim()?.length) {
                    if (selectedUserList?.length) {
                        let usersTasks = usersData?.filter(taskDetails => selectedUserList?.includes(taskDetails?.user_id))
                        setProjectAllTask(usersTasks?.filter(taskDetails => taskDetails?.title?.toLowerCase()?.includes(searchString?.trim()?.toLowerCase())))
                    } else {
                        setProjectAllTask(usersData?.filter(taskDetails => taskDetails?.title?.toLowerCase()?.includes(searchString?.trim()?.toLowerCase())))
                    }
                } else {
                    if (selectedUserList?.length) {
                        let usersTasks = usersData?.filter(taskDetails => selectedUserList?.includes(taskDetails?.user_id))
                        setProjectAllTask(usersTasks?.filter(taskDetails => taskDetails?.title?.toLowerCase()?.includes(searchString?.trim()?.toLowerCase())))
                    } else {
                        setProjectAllTask(usersData);
                    }
                }
            } else {
                setProjectAllTask([]);
                setOptionalProjectAllTask([])
            }
            setIsLoading((prev) => ({ ...prev, projectTaskLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, projectTaskLoading: false }));
        }
    }

    const handleAddNewMember = () => {
        let uniqueId = generateUniqueId();
        let activeTeamMembersData = teamMembersData?.filter((memberData) => memberData?.is_active);

        if ((activeTeamMembersData?.length + 1) < allUsersWithActiveMembers?.length) setIsMoreUserExist(true)
        else setIsMoreUserExist(false)

        setTeamMembersData((prev) => [...prev, { ...initialMemeberData, unique_id: uniqueId, isNew: true }])
        setErrors(prev => [...prev, { ...initialError, unique_id: uniqueId }])
    }

    const handleChange = (event, memberIndex, array, uniqueId) => {
        const { name, value } = event.target;
        setTeamMembersData(prev => prev?.map((memberData) => {
            if (memberData?.unique_id === uniqueId) {
                return { ...memberData, [name]: value, is_active: true }
            }
            return memberData
        }))
        validateMemberData(event, memberIndex, array, uniqueId);
    }

    const validateMemberData = (event, memberIndex, array, uniqueId) => {
        const { name, value } = event.target;
        setErrors(
            array?.map((memberData, index) => {
                if (memberData?.unique_id === uniqueId) {
                    return {
                        ...getErrorDataByUniqeId(memberData?.unique_id),
                        [name]: name === 'project_role_id' ? (value && value !== "Select") ? "" : "Required" : value ? "" : "Required",
                    };
                } else {
                    return {
                        ...getErrorDataByUniqeId(memberData?.unique_id),
                        [name]: getErrorDataByUniqeId(memberData?.unique_id)?.[name]
                            ? getErrorDataByUniqeId(memberData?.unique_id)?.[name]
                            : "",
                    };
                }
            })
        );
    };

    const handleChangeMemberData = (event, memberIndex, array, uniqueId) => {
        let indexOfMember = memberIndex;
        let membersDataList = [...array];
        // let inActiveMembersList = array?.filter((list) => !list?.is_active);
        const { name, value } = event.target;

        let leaveMemberDatalist = [];
        if (name === "User") {
            if (activeMembersData?.find((list) => list?.user_id === value?.id)) {
                let existRecord = activeMembersData?.find(
                    (list) => list?.user_id === value?.id
                );

                if (existRecord) {
                    leaveMemberDatalist = membersDataList?.filter((list) => list?.user_id !== existRecord?.user_id)?.map((memberData, index) => {
                        if (uniqueId === memberData?.unique_id) {
                            return {
                                ...memberData,
                                ...existRecord,
                                project_role_id: existRecord?.project_role_id,
                                joining_date: existRecord?.joining_date,
                                [name]: value,
                                is_active: true,
                                id: existRecord?.id,
                                unique_id: memberData?.unique_id
                            };
                        }
                        return memberData;
                    })
                }
            } else {
                leaveMemberDatalist = membersDataList?.map((memberData, index) => {
                    if (uniqueId === memberData?.unique_id) {
                        return { ...memberData, id: null, [name]: value, is_active: true };
                    }
                    return memberData;
                });
            }
            validateMemberData(event, indexOfMember, membersDataList, uniqueId);
        } else {
            leaveMemberDatalist = membersDataList?.map((memberData, index) => {
                if (uniqueId === memberData?.unique_id) {
                    return { ...memberData, [name]: value, is_active: true };
                }
                return memberData;
            });

            let updatedErrors = [...errors];

            setErrors(
                array?.map((memberData, index) => {
                    if (index === memberIndex) {
                        return {
                            ...updatedErrors?.[index],
                            [name]: value ? "" : "Required",
                        };
                    } else {
                        return {
                            ...updatedErrors?.[index],
                            [name]: updatedErrors?.[index]?.[name]
                                ? updatedErrors?.[index]?.[name]
                                : "",
                        };
                    }
                })
            );
        }

        setTeamMembersData(leaveMemberDatalist);
    }

    const handleChangeDate = (value, memberIndex, uniqueId) => {
        let err = [...errors];
        setTeamMembersData((prev) =>
            prev?.map((memberData, index) => {
                if (memberIndex === index) {
                    err[index].joining_date = "";
                    return { ...memberData, joining_date: new Date(value) };
                }
                return memberData;
            })
        );
        setErrors(err)
    };

    const handleDeleteMemberList = (memberData, memberIndex, uniqueId) => {
        setIsMoreUserExist(true)
        let updatedTeamMembersData = [];
        if (memberData?.id) {
            updatedTeamMembersData = [
                ...teamMembersData?.map((member, index) =>
                    member?.unique_id === uniqueId
                        ? {
                            ...member,
                            is_active: false,
                            removed_date: getFormattedDate(new Date(), "YYYY-MM-DD"),
                        }
                        : { ...member }
                ),
            ];
        } else {
            updatedTeamMembersData = [
                ...teamMembersData?.filter((member, index) => member?.unique_id !== uniqueId),
            ];
        }
        setTeamMembersData(updatedTeamMembersData);
        setErrors((prev) => prev?.filter((member, index) => member?.unique_id !== uniqueId));
    };

    const validateTeamMemberData = () => {
        let error = teamMembersData?.map((memberData, index) => {
            return {
                User: memberData?.User || !memberData?.is_active ? "" : "Required",
                project_role_id: ((memberData?.project_role_id && memberData?.project_role_id !== "Select") || !memberData?.is_active) ? "" : "Required",
                joining_date: memberData?.joining_date || !memberData?.is_active ? "" : "Required",
                unique_id: memberData?.unique_id
            };
        });
        setErrors(error);
        return error?.some(
            (errorData) =>
                errorData?.User?.length ||
                errorData?.project_role_id?.length ||
                errorData?.joining_date?.length
        );
    };

    const handleSubmitData = () => {
        try {
            if (!validateTeamMemberData()) {
                updateMembersList();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const updateMembersList = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, memberDataSubmit: true }));
            let payload = [
                ...teamMembersData
                    ?.filter((memberData) => !!memberData?.User || memberData?.id)
                    ?.map((memberData) => {
                        let updatedMemberData = {
                            id: memberData?.id ?? null,
                            project_id: Number(params?.projectId),
                            user_id: memberData?.User?.id ?? memberData?.user_id,
                            project_role_id: memberData?.project_role_id,
                            joining_date: getFormattedDate(
                                memberData?.joining_date,
                                "YYYY-MM-DD"
                            ),
                            is_active: memberData?.is_active ?? true,
                            removed_date: !memberData?.is_active
                                ? memberData?.removed_date ?? null
                                : null,
                        }
                        let actualMemberData = actualTeamMembersData?.find(
                            (data) => data?.id === memberData?.id
                        );
                        if (updatedMemberData?.user_id !== actualMemberData?.user_id ||
                            updatedMemberData?.joining_date !== actualMemberData?.joining_date ||
                            updatedMemberData?.project_role_id !== actualMemberData?.project_role_id ||
                            updatedMemberData?.is_active !== actualMemberData?.is_active
                        ) {
                            updatedMemberData['is_changed'] = true
                        }
                        return updatedMemberData;
                    }),
            ];

            const response = await axiosInstance.put(
                API.updateProjectMembersData(Number(params?.projectId)),
                { project_members: payload }
            );
            if (response?.status === 200) {
                showAlert(response.data?.message);
                // let activeAllMembersData = response?.data?.data?.filter((list) => list?.is_active);
                // setActiveMembersData(activeAllMembersData);
                // let nonActiveMembersData = response?.data?.data?.filter((list) => !list?.is_active)
                // setAllUsersWithActiveMembers(userList?.filter(userData => !nonActiveMembersData?.find(memberData => memberData?.id === userData?.id)))
                // let membersData = [...activeAllMembersData]?.map((memberData) => {
                //     return { ...memberData, unique_id: generateUniqueId() };
                // });
                // setInActiveMembersData(nonActiveMembersData);
                // if (response?.data?.data?.length < userList?.length) {
                //     setIsMoreUserExist(true)
                // }
                // setTeamMembersData(membersData);
                // setActualTeamMembersData(membersData);
                // setErrors(
                //     response?.data?.data?.map((member) => {
                //         return {
                //             User: "",
                //             project_role_id: "",
                //             joining_date: "",
                //         };
                //     })
                // );
                getCurrentProjectUsers(Number(params?.projectId))
            }
            setIsLoading((prev) => ({ ...prev, memberDataSubmit: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, memberDataSubmit: false }));
        }
    };

    const handleSearch = (value) => {
        setSearchString(value);
    };

    const handleClick = (elem) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const handleOpenManageClick = (elem) => (event) => {
        setAnchorManageEl(event.currentTarget);
        setOpenManageEle(elem);
    }

    const handleManageClose = () => {
        setAnchorManageEl(null);
        setOpenManageEle(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        let membersData = [...activeMembersData]?.map((memberData) => {
            return { ...memberData, unique_id: generateUniqueId() };
        });
        if (membersData?.length < allUsersWithActiveMembers?.length) {
            setIsMoreUserExist(true)
        }
        setTeamMembersData(membersData)
    }

    const getErrorDataByUniqeId = (uniqueId) => {
        return errors?.find(errorData => errorData?.unique_id === uniqueId) ?? null
    }

    const handleClosePhaseDrawer = () => {
        setOpenPhaseDrawer(false);
        setIsEditPhaseHours(null);
        setIsEditPhase(null);
        setIsAddPhase(false);
        setEditabelPhase({})
    }

    const getProjectPhaseHours = (phaseId) => {
        let phase = optionalProjectPhases?.find((phase) => phase?.id === phaseId);
        if (!phase?.ProjectPhaseHours?.length) {
            return 0;
        }
        else {
            return phase?.ProjectPhaseHours?.filter((hour) => !!hour?.id)?.map((hour) => Number.parseFloat(hour?.hours ?? 0)).reduce((a, b) => a + b, 0);
        }
    }

    const getProjectPhaseData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, projectPhase: true }));
            let response = await axiosInstance.get(
                API.getProjectPhases(params?.projectId)
            );
            if (response?.status === 200) {
                let updatedProjectPhases = response?.data?.data?.map((phase) => {
                    let _ProjectPhaseHours = phase?.ProjectPhaseHours?.length > 0 ? phase?.ProjectPhaseHours?.map((hour) => ({ ...hour, uniqueId: generateUniqueId() })) : [];
                    if (_ProjectPhaseHours?.length === 0) {
                        _ProjectPhaseHours = [{ ...initialProjectPhaseHours, uniqueId: generateUniqueId() }]
                    }
                    return {
                        ...phase,
                        ProjectPhaseHours: sortArrayByKey(_ProjectPhaseHours, "asc", "id"),
                        uniqueId: generateUniqueId()
                    }
                })
                setProjectPhases(sortArrayByKey(updatedProjectPhases, "desc", 'id'));
                setOptionalProjectPhases(response?.data?.data);
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading((prev) => ({ ...prev, projectPhase: false }));
        }
    }

    const handleOpenPhaseDrawer = () => {
        handleManageClose();
        getProjectPhaseData();
        setOpenPhaseDrawer(true);
    }

    const handleAddEmptyPhaseHours = (phaseId) => {
        let emptyPhaseHours = { ...initialProjectPhaseHours, uniqueId: generateUniqueId() };
        let data = projectPhases?.map((phase) => {
            if (phase?.id === phaseId) {
                return { ...phase, ProjectPhaseHours: [...phase?.ProjectPhaseHours, emptyPhaseHours] };
            }
            return phase;
        })
        setProjectPhases(data);
        setIsEditPhaseHours(emptyPhaseHours?.uniqueId);
    }

    const handleCloseTaskDrawer = () => {
        setOpenTaskDrawer(false);
    }

    const handleCloseUpdateTaskDrawer = () => {
        setOpenUpdateTaskDrawer(false)
    }

    const handleTaskMove = (taskId) => {
        setProjectAllTask(prev => prev?.filter(taskDetail => taskDetail?.id !== taskId));
        setOptionalProjectAllTask(prev => prev?.filter(taskDetail => taskDetail?.id !== taskId));
        // setCurrentUserTaskList(prev => prev?.filter(taskDetail => taskDetail?.id !== taskId));
    }

    const handleSubmitTaskData = (createdObject = {}) => {
        let allTask = [...optionalProjectAllTask];
        allTask.unshift(createdObject);
        setOptionalProjectAllTask(allTask);

        const addTaskToList = () => {
            let allCurrentTask = [...projectAllTask];
            allCurrentTask.unshift(createdObject);
            setProjectAllTask(allCurrentTask)
        }

        if (searchString?.trim()?.length) {
            if (selectedUserList?.length) {
                if (selectedUserList?.includes(createdObject?.user_id)) {
                    addTaskToList()
                }
            } else {
                if (createdObject?.title?.toLowerCase()?.trim(searchString?.trim()?.toLowerCase())) {
                    addTaskToList()
                }
            }
        } else {
            if (selectedUserList?.length) {
                if (selectedUserList?.includes(createdObject?.user_id)) {
                    addTaskToList()
                }
            } else {
                addTaskToList()
            }
        }
    }

    const handleSubmitUpdateTaskData = async (name, value, taskId, taskResponse) => {
        const getUpdatedTasks = (taskList) => {
            let updatedTaskList = [...taskList];
            let indexOfTask = taskList?.findIndex(data => data?.id === taskId);
            updatedTaskList.splice(indexOfTask, 1)
            updatedTaskList?.unshift(taskResponse);
            return updatedTaskList
        }
        setOptionalProjectAllTask(prev => getUpdatedTasks(prev))

        if (searchString?.trim()?.length) {
            if (selectedUserList?.length) {
                if (selectedUserList?.includes(taskResponse?.user_id)) {
                    setProjectAllTask(prev => getUpdatedTasks(prev))
                } else {
                    setProjectAllTask(prev => prev?.filter(taskData => taskData?.id !== taskResponse?.id))
                }
            } else {
                if (taskResponse?.title?.toLowerCase()?.trim(searchString?.trim()?.toLowerCase())) {
                    setProjectAllTask(prev => getUpdatedTasks(prev))
                } else {
                    setProjectAllTask(prev => prev?.filter(taskData => taskData?.id !== taskResponse?.id))
                }
            }
        } else {
            if (selectedUserList?.length) {
                if (selectedUserList?.includes(taskResponse?.user_id)) {
                    setProjectAllTask(prev => getUpdatedTasks(prev))
                } else {
                    setProjectAllTask(prev => prev?.filter(taskData => taskData?.id !== taskResponse?.id))
                }
            } else {
                setProjectAllTask(prev => getUpdatedTasks(prev))
            }
        }
    }

    const handleClickEditTask = (taskDetails) => {
        handleClose();
        setEditableTaskDataId(taskDetails?.id)
        setOpenUpdateTaskDrawer(true);
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 300,
            },
        },
    };

    return (
        isProjectDataFailed ? <PageNotFound showSideBar={false} /> :
            isLoading?.pageLoading || isLoading?.userLoading ? <CircularLoader /> : <Box width={"100%"} flex={1} display={"flex"} flexDirection={"column"} overflow={"hidden"}>
                <Box width={"100%"} py={1} px={2}
                    sx={{
                        '> div:first-child': {
                            padding: '0px'
                        },
                    }}
                >
                    <Breadcrumb
                        isBack={true}
                        onBackClick={() => navigate(-1)}
                        pageTitle={"Project details"}
                        title={"Projects"}
                        isBottomBorder={false}
                    />
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} padding={"8px 8px 8px 16px"}>
                        <Box display={"flex"} alignItems={"center"} gap={1.5} flex={1}>
                            <Box bgcolor={"rgba(0, 99, 191, 0.1)"} border={"1px solid rgba(4, 127, 224, 0.2)"} minWidth={35} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ borderRadius: "3px", padding: "2px 6px !important" }}>
                                <Typography fontSize={13} fontWeight={600} lineHeight={"19px"} color={"primary.main"}> {projectData?.short_code}</Typography>
                            </Box>
                            <Typography color={"dark.800"} fontSize={24} fontWeight={500} lineHeight={"34px"} letterSpacing={"0.17px"}>{titleCase(projectData?.title)}</Typography>
                        </Box>
                        {!isLoading?.projectUserLoading ?
                            <Box display={"flex"} alignItems={"center"} gap={"14px"}>
                                {activeMembersData?.length ?
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        disableRipple="false"
                                        size="small"
                                        sx={{
                                            padding: 0,
                                            textTransform: 'none',
                                            '&:hover': {
                                                color: '#047FE0',
                                                background: "transparent",
                                            }
                                        }}
                                        onClick={() => setOpenMembersListDrawer(true)}
                                    >
                                        {activeMembersData?.length ?? 0} {activeMembersData?.length > 1 ? 'Members' : 'Member'}
                                    </Button>
                                    : null}
                                {activeMembersData?.length ?
                                    <AvatarGroup
                                        sx={{
                                            flexDirection: "row",
                                            "> div": {
                                                width: "28px",
                                                height: "28px",
                                                fontSize: "11px",
                                                fontWeight: 600,
                                                "&:last-child": {
                                                    marginLeft: '-10px !important'
                                                },
                                                "&:nth-of-type(5)": {
                                                    marginLeft: "-32px !important",
                                                },
                                                "&:first-of-type": {
                                                    marginLeft: "0px !important",
                                                },
                                            },
                                        }}
                                    >
                                        {activeMembersData?.length > 4 ? (
                                            <HtmlTooltip arrow
                                                title={
                                                    <React.Fragment>
                                                        <List
                                                            sx={{
                                                                padding: '0px',
                                                                '.css-tlelie-MuiListItemText-root': {
                                                                    margin: '0',
                                                                },
                                                                'span': {
                                                                    color: 'white',
                                                                    fontWeight: '500',
                                                                    fontSize: '14px',
                                                                    lineHeight: '21px'
                                                                },
                                                                'li': {
                                                                    padding: '0px',
                                                                    marginBottom: '10px',
                                                                    '&:last-child': {
                                                                        marginBottom: '0px',
                                                                    }
                                                                }
                                                            }}>
                                                            {
                                                                sortArrayByKey(activeMembersData, "asc", "id")
                                                                    ?.slice(3)
                                                                    ?.map((memberData, index) => (
                                                                        <ListItem key={index}>
                                                                            <BackgroundLetterAvatars
                                                                                isShowTooltip={true}
                                                                                key={memberData?.User?.id}
                                                                                user={memberData?.User}
                                                                                sx={{
                                                                                    width: "24px",
                                                                                    height: "24px",
                                                                                    fontSize: "100%",
                                                                                    marginRight: '9px',
                                                                                }}
                                                                            />
                                                                            <ListItemText primary={titleCase(memberData?.User?.name)} />
                                                                        </ListItem>
                                                                    ))
                                                            }
                                                        </List>
                                                    </React.Fragment>
                                                }
                                            >
                                                <Avatar id={getInputId('userAvtar')} sx={{ marginLeft: "-32px !important", background: "transparent" }}>
                                                    <Typography
                                                        height={"100%"}
                                                        width={"100%"}
                                                        fontSize={11}
                                                        fontWeight={600}
                                                        display={"flex"}
                                                        alignItems={"center"}
                                                        justifyContent={"center"}
                                                        sx={{ background: "rgba(0,0,0,0.55)" }}
                                                    >
                                                        +{activeMembersData?.length - 4}
                                                    </Typography>
                                                </Avatar>
                                            </HtmlTooltip>
                                        ) : null}
                                        {activeMembersData?.length ? (activeMembersData?.length > 4
                                            ? sortArrayByKey(activeMembersData, "asc", "id")
                                                ?.slice(0, 4)?.reverse()
                                                ?.map((memberData, index) => (
                                                    <BackgroundLetterAvatars
                                                        id={getInputId('userAvtar')}
                                                        isShowTooltip={true}
                                                        key={`${memberData?.User?.id}-${index}-index`}
                                                        user={memberData?.User}
                                                        sx={{
                                                            width: "32px",
                                                            height: "32px",
                                                            fontSize: "90%",
                                                        }}
                                                    />
                                                ))
                                            : sortArrayByKey(activeMembersData, "asc", "id")?.map(
                                                (memberData, index) => (
                                                    <BackgroundLetterAvatars
                                                        id={getInputId('userAvtar')}
                                                        isShowTooltip={true}
                                                        key={`${memberData?.User?.id}-${index}`}
                                                        user={memberData?.User}
                                                        sx={{
                                                            width: "32px",
                                                            height: "32px",
                                                            fontSize: "90%",
                                                        }}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <Typography
                                                height={"100%"}
                                                width={"100%"}
                                                fontSize={12}
                                                fontWeight={700}
                                                display={"flex"}
                                            >
                                                -
                                            </Typography>
                                        )}
                                    </AvatarGroup>
                                    : null}

                                {isProjectPhaseAccess(currentUser) ? <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    sx={{ textTransform: "none", boxShadow: 'none' }}
                                    onClick={toggleDrawer(true)}
                                >
                                    Invite
                                </Button> : null}
                                {isProjectPhaseAccess(currentUser) ? <Box>
                                    <IconButton
                                        aria-label="more"
                                        id="edit-project-button"
                                        aria-controls={openManage ? 'edit-project' : undefined}
                                        aria-expanded={openManage ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleOpenManageClick(true)}
                                        sx={{ minWidth: 30, width: 30, height: 30, padding: "5px", }}
                                    >
                                        <MoreHorizIcon sx={{ fontSize: 20, color: "#3F5C76" }} />
                                    </IconButton>
                                    <Menu
                                        id="edit-project"
                                        MenuListProps={{
                                            'aria-labelledby': 'edit-project-button',
                                        }}
                                        anchorEl={anchorManageEl}
                                        open={openManageEle}
                                        onClose={handleManageClose}
                                        sx={{
                                            'li': {
                                                color: 'dark.800',
                                            },
                                        }}
                                    >
                                        <MenuItem onClick={() => handleOpenPhaseDrawer()}>Manage Phase</MenuItem>
                                        {isProjectEditAccess(currentUser) ? <MenuItem onClick={() => navigate(`${URLS.UpdateProject}/${params?.projectId}?tabValue=details`)}>
                                            Edit Project
                                        </MenuItem> : null}
                                    </Menu>
                                </Box> : null}
                            </Box> :
                            <Box width={300} height={35}>
                                <Skeleton animation="wave" width={"100%"} height={"100%"} />
                            </Box>}
                    </Box>
                </Box>
                <Divider />
                <Box
                    p={{ xs: 2, lg: 3, xl: 4 }}
                    py={{ xs: 3, lg: 3, xl: 4 }}
                    flex={1}
                    overflow={"auto"}
                >
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={2}>
                        <Box display={"flex"} gap={3}>
                            <Box width={230} minWidth={230}>
                                <SearchBoxInput
                                    label="Search"
                                    variant="standard"
                                    placeholder="Task"
                                    handleSearch={handleSearch}
                                    value={searchString}
                                    isOverwritePlaceholder={true}
                                    size="small"
                                    fullWidth
                                />
                            </Box>
                            <Box width={180} minWidth={180}>
                                <FormControl size="small" variant="standard" fullWidth>
                                    <InputLabel id="select-label">User</InputLabel>
                                    {projectData?.is_private ?
                                        <Select
                                            labelId="select-user"
                                            id="select-user"
                                            label="User"
                                            name='user'
                                            multiple
                                            value={selectedUserList}
                                            onChange={handleChangeUserTaskData}
                                            inputProps={{ id: getInputId("group", 0) }}
                                            renderValue={(selected) => {
                                                return activeMembersData?.filter(userDetails => selected?.includes(userDetails?.User?.id))?.map(data => titleCase(data?.User?.name))?.join(', ')
                                            }}
                                            MenuProps={MenuProps}
                                            sx={{ backgroundColor: 'transparent' }}
                                        >
                                            {activeMembersData?.map((memberData) => (
                                                <MenuItem key={memberData?.User?.id} value={memberData?.User?.id} sx={{
                                                    margin: '0px 5px',
                                                }}>
                                                    <Box width={"100%"} position={"relative"} display={"flex"} alignItems={"center"}
                                                        sx={{
                                                            '.MuiCheckbox-root': {
                                                                padding: '0px 8px 0px 0px'
                                                            },
                                                        }}
                                                    >
                                                        <Checkbox sx={{ minWidth: '32px' }} checked={selectedUserList?.includes(memberData?.User?.id)} size="medium" disableRipple="false" />
                                                        <Box display={"flex"} alignItems={"center"} gap={1} overflow={"hidden"}>
                                                            <Box minWidth={32} maxWidth={32} width={32}>
                                                                <BackgroundLetterAvatars
                                                                    sx={{
                                                                        width: "32px",
                                                                        height: "32px",
                                                                        fontSize: "90%",
                                                                    }}
                                                                    user={memberData?.User}
                                                                />
                                                            </Box>
                                                            <Box overflow={"hidden"}>
                                                                <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"}>{titleCase(memberData?.User?.name)}</Typography>
                                                                <Typography variant="body1" color={"rgba(63, 92, 118, 0.9)"} fontSize={12} fontWeight={400} lineHeight={"14px"} overflow={"hidden"} maxWidth={"100%"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>{memberData?.User?.email}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select> :
                                        <Select
                                            labelId="select-user"
                                            id="select-user"
                                            label="User"
                                            name='user'
                                            multiple
                                            value={selectedUserList}
                                            onChange={handleChangeUserTaskData}
                                            inputProps={{ id: getInputId("group", 0) }}
                                            renderValue={(selected) => {
                                                return allOrfUsersList?.filter(userDetails => selected?.includes(userDetails?.id))?.map(data => titleCase(data?.name))?.join(', ')
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {allOrfUsersList?.filter(userData => userData?.active)?.map((memberData) => (
                                                <MenuItem key={memberData?.id} value={memberData?.id}>
                                                    <Checkbox checked={selectedUserList?.includes(memberData?.id)} />
                                                    <Box display={"flex"} alignItems={"center"} gap={1}>
                                                        <Box minWidth={32} maxWidth={32} width={32}>
                                                            <BackgroundLetterAvatars
                                                                sx={{
                                                                    width: "32px",
                                                                    height: "32px",
                                                                    fontSize: "90%",
                                                                }}
                                                                user={memberData}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"}>{titleCase(memberData?.name)}</Typography>
                                                            <Typography variant="body1" color={"rgba(63, 92, 118, 0.9)"} fontSize={12} fontWeight={400} lineHeight={"14px"}>{memberData?.email}</Typography>
                                                        </Box>
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    }
                                </FormControl>
                            </Box>
                            {(searchString?.trim()?.length || selectedUserList?.length) ? <Button variant='text' color='primary'
                                size="small"
                                sx={{ marginTop: '16px !important', textTransform: 'none' }}
                                onClick={() => handleClearFilter()}>Clear Filters</Button>
                                : null}
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={{ textTransform: "none", boxShadow: 'none' }}
                            onClick={() => setOpenTaskDrawer(true)}
                        >
                            Create Task
                        </Button>
                    </Box>
                    <Box>
                        <Paper
                            sx={{
                                border: "1px solid #E0E0E0",
                                borderRadius: "3px",
                                boxShadow: "none",
                                // maxHeight: "100%", 
                            }}
                        >
                            <TableContainer>
                                <Table>
                                    <TableHead
                                        sx={{
                                            'th': {
                                                color: "dark.800",
                                                fontWeight: 500,
                                                fontSize: 13,
                                                lineHeight: "24px",
                                                letterSpacing: "0.17px",
                                                padding: "8px",
                                                textAlign: "left",
                                                background: "rgba(245, 248, 250, 1)",
                                            },
                                        }}
                                    >
                                        <TableRow>
                                            <TableCell sx={{ maxWidth: 0 }}>Tasks</TableCell>
                                            <TableCell sx={{ width: 70, minWidth: 70, maxWidth: 70 }}></TableCell>
                                            <TableCell sx={{ width: 86, minWidth: 86, maxWidth: 86 }}>Spent</TableCell>
                                            <TableCell sx={{ width: 100, minWidth: 100, maxWidth: 100 }}>Status</TableCell>
                                            <TableCell sx={{ width: 40, minWidth: 40, maxWidth: 40 }}></TableCell>
                                            <TableCell sx={{ width: 150, minWidth: 150, maxWidth: 150 }}>Last Updated</TableCell>
                                            <TableCell sx={{ width: 50, minWidth: 50, maxWidth: 50 }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            "tr:last-child td": {
                                                border: "none",
                                            },
                                            "td": {
                                                padding: "5px 8px",
                                            },
                                            "tr:hover .task-title > p": {
                                                color: "#047FE0",
                                            },
                                        }}
                                    >
                                        {isLoading?.projectTaskLoading ? <CircularLoader variant="table" cols={7} rows={10} /> : projectAllTask?.length ? sortArrayByKey(projectAllTask, "desc", "updatedAt")?.map((taskData, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell sx={{ maxWidth: 0, cursor: 'pointer' }} onClick={() => handleClickEditTask(taskData)}>
                                                    <Box display={"flex"} alignItems={"center"} gap={1} whiteSpace={"nowrap"} className="task-title">
                                                        <HtmlTooltip arrow
                                                            title={
                                                                <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"}>{titleCase(TASK_TYPE_LIST[taskData?.type])}</Typography>
                                                            }>
                                                            <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                {/* <AddRoundedIcon sx={{ fontSize: "18px" }} /> */}
                                                                {getTaskTypeIcon(taskData?.type)}
                                                            </Box>
                                                        </HtmlTooltip>

                                                        <Typography color="secondary.main" fontSize={13} fontWeight={700} lineHeight={"16px"} textTransform={"uppercase"} sx={{ textDecoration: "underline" }}>
                                                            {`${taskData?.Project?.short_code?.toUpperCase()}-${taskData?.task_no}`}
                                                        </Typography>
                                                        <Typography color="dark.800" fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"} maxWidth={"100%"} textOverflow={"ellipsis"} overflow={"hidden"}>
                                                            {taskData?.title}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleClickEditTask(taskData)}>
                                                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"6px"}>
                                                        <Box minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"100%"}>
                                                            {taskData?.ProjectPhase ? <HtmlTooltip arrow
                                                                title={
                                                                    <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"}>{titleCase(taskData?.ProjectPhase?.title)}</Typography>
                                                                }>
                                                                <Box bgcolor={"secondary.main"} display={"flex"} alignItems={"center"} justifyContent={"center"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"100%"}>
                                                                    <Typography color={"white"} fontSize={11} fontWeight={700} lineHeight={"13px"}>
                                                                        {getProjectPhaseShortCode(taskData?.ProjectPhase)}
                                                                    </Typography>
                                                                </Box>
                                                            </HtmlTooltip> : null}
                                                        </Box>
                                                        <HtmlTooltip arrow
                                                            title={
                                                                <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"}>{titleCase(PROJECT_TASK_PRIORITY[taskData?.priority])}</Typography>
                                                            }>
                                                            <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"#E75252"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                {getPriorotyIcon(taskData?.priority)}
                                                            </Box>
                                                        </HtmlTooltip>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleClickEditTask(taskData)}>
                                                    <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                                                        <Typography color={"dark.800"} fontSize={13} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.17px"}>{secondsToTime(taskData?.spent ?? 0)}</Typography>
                                                        <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"18px"} letterSpacing={"0.17px"}>hrs</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleClickEditTask(taskData)}>
                                                    {getTaskStatus(taskData?.status, "chip")}
                                                </TableCell>
                                                <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleClickEditTask(taskData)}>
                                                    {taskData?.User?.id ? <BackgroundLetterAvatars
                                                        isShowTooltip={true}
                                                        key={taskData?.User?.id}
                                                        user={taskData?.User}
                                                        sx={{
                                                            width: "24px",
                                                            height: "24px",
                                                            fontSize: "90%",
                                                            marginRight: '9px',
                                                        }}
                                                    /> : "-"}
                                                </TableCell>
                                                <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleClickEditTask(taskData)}>
                                                    <Typography color={"dark.600"} fontSize={13} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.17px"}>
                                                        {formatTimeAgo(taskData?.updatedAt)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={open ? 'long-menu' : undefined}
                                                        aria-expanded={open ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleClick(taskData)}
                                                        sx={{ minWidth: 30, width: 30, height: 30, padding: "5px" }}
                                                    >
                                                        <MoreHorizIcon sx={{ fontSize: 20, color: "#3F5C76" }} />
                                                    </IconButton>
                                                    <Menu
                                                        id="long-menu"
                                                        MenuListProps={{
                                                            'aria-labelledby': 'long-button',
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={open && openElem?.id === taskData?.id}
                                                        onClose={handleClose}
                                                        sx={{
                                                            'li': {
                                                                color: 'dark.800',
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => handleClickEditTask(taskData)}>Edit</MenuItem>
                                                        {/* <MenuItem>
                                                        <Typography color={"error"} fontSize={16} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.15px"}>Archive</Typography>
                                                    </MenuItem> */}
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        )) :
                                            <TableRow key={1}>
                                                <TableCell
                                                    colSpan={7}
                                                    align="center"
                                                    sx={{ padding: "12px 16px;" }}
                                                >
                                                    <Typography
                                                        color={"secondary"}
                                                        display={"flex"}
                                                        alignItems={"center"}
                                                        justifyContent={"center"}
                                                        fontSize={13}
                                                        sx={{ opacity: "0.5", height: "200px" }}
                                                    >
                                                        No task found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                    <Drawer open={openDrawer} anchor="right" onClose={() => handleCloseDrawer()}>
                        <Box width={720} flex={1} flexDirection={"column"} display={"flex"} overflow={"hidden"}>
                            <Box p={2} display={"flex"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography color={"dark.800"} fontSize={16} fontWeight={400} lineHeight={"25px"} letterSpacing={"0.15px"} mr={0.5} display={"inline-block"}>Team of </Typography>
                                    <Typography color={"dark.800"} fontSize={16} fontWeight={600} lineHeight={"25px"} letterSpacing={"0.15px"} display={"inline-block"}>{titleCase(projectData?.title)}</Typography>
                                </Box>
                                <IconButton
                                    aria-label="Close"
                                    size="small"
                                    // onClose={() => toggleDrawer(false)}
                                    sx={{ width: 24, minWidth: 24, height: 24, padding: '2px' }}
                                    onClick={() => handleCloseDrawer()}
                                >
                                    <CloseIcon fontSize="medium" />
                                </IconButton>
                            </Box>
                            <Divider />
                            <Box flex={1} p={3} overflow={"auto"}>
                                <TableContainer sx={{ overflow: "visible" }}>
                                    <Table>
                                        <TableHead
                                            sx={{
                                                'th': {
                                                    color: "rgba(0, 0, 0, 0.87)",
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                    lineHeight: "24px",
                                                    letterSpacing: "0.17px",
                                                    background: 'transparent',
                                                    padding: "8px 8px 5px 8px",
                                                    borderBottom: "2px solid rgba(0, 0, 0, 0.12)"
                                                }
                                            }}
                                        >
                                            <TableRow >
                                                <TableCell>{teamMembersData?.filter(memberData => memberData?.is_active)?.length ?? 0} Members</TableCell>
                                                <TableCell sx={{ width: 166, minWidth: 166, maxWidth: 166 }}>Project Role</TableCell>
                                                <TableCell sx={{ width: 166, minWidth: 166, maxWidth: 166 }}>Joining Date</TableCell>
                                                <TableCell sx={{ width: 50, minWidth: 50, maxWidth: 50 }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            sx={{
                                                'td': {
                                                    borderBottom: "none",
                                                    padding: '8px',
                                                }
                                            }}
                                        >
                                            {
                                                teamMembersData?.length ? teamMembersData?.map((memberData, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {
                                                            memberData?.is_active ?
                                                                <TableRow hover
                                                                    sx={{
                                                                        '&:hover': {
                                                                            '.delete-btn': {
                                                                                opacity: '1',
                                                                            },
                                                                        },
                                                                        "td": {
                                                                            verticalAlign: "top",
                                                                        }
                                                                    }}
                                                                >
                                                                    <TableCell>
                                                                        {memberData?.isNew ?
                                                                            <Grid
                                                                                item
                                                                                pr={3}
                                                                                mb={{ xs: 3, md: 0 }}
                                                                                flex={{ xs: "0 0 50%", md: "0 0 37%" }}
                                                                            >
                                                                                <FormControl
                                                                                    className={classes.margin}
                                                                                    variant="standard"
                                                                                    sx={{ margin: "0px !important", width: "100%" }}
                                                                                >
                                                                                    <AutocompleteSelect
                                                                                        // label="Select Member"
                                                                                        name="User"
                                                                                        id={getInputId("User")}
                                                                                        onBlur={(event) =>
                                                                                            validateMemberData(event, index, array, memberData?.unique_id)
                                                                                        }
                                                                                        onChange={(event) =>
                                                                                            handleChangeMemberData(event, index, array, memberData?.unique_id)
                                                                                        }
                                                                                        options={allOrfUsersList?.filter(
                                                                                            (data) =>
                                                                                                !teamMembersData?.find(
                                                                                                    (member) =>
                                                                                                        member?.User?.id === data?.id && member?.is_active
                                                                                                ) &&
                                                                                                !inActiveMembersData?.find(
                                                                                                    (member) => member?.User?.id === data?.id
                                                                                                )
                                                                                        )}
                                                                                        disabledOptions={allOrfUsersList?.filter((data) =>
                                                                                            teamMembersData?.find(
                                                                                                (member) => member?.User?.id === data?.id && member?.is_active
                                                                                            )
                                                                                        )}
                                                                                        isOptionEqualToValue={(option, value) => allOrfUsersList?.find(user => user.id === value?.id) ?? {}}
                                                                                        value={allOrfUsersList?.find(user => user.id === memberData?.User?.id) || {}}
                                                                                        disableClearable={(!memberData?.User && !memberData?.project_role_id && !memberData?.joining_date)}
                                                                                    />
                                                                                    {!!getErrorDataByUniqeId(memberData?.unique_id)?.User ? (
                                                                                        <FormHelperText sx={{ color: "red.main" }}>
                                                                                            {getErrorDataByUniqeId(memberData?.unique_id)?.User}
                                                                                        </FormHelperText>
                                                                                    ) : null}
                                                                                </FormControl>
                                                                            </Grid>
                                                                            : <Box display={"flex"} alignItems={"center"} gap={1}>
                                                                                <Box minWidth={32} maxWidth={32} width={32}>
                                                                                    <BackgroundLetterAvatars
                                                                                        sx={{
                                                                                            width: "32px",
                                                                                            height: "32px",
                                                                                            fontSize: "90%",
                                                                                        }}
                                                                                        user={memberData?.User}
                                                                                    />
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography color={"dark.800"} fontSize={16} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"}>{titleCase(memberData?.User?.name)}</Typography>
                                                                                    <Typography variant="body1" color={"rgba(63, 92, 118, 0.9)"} fontSize={12} fontWeight={400} lineHeight={"14px"}>{memberData?.User?.email}</Typography>
                                                                                </Box>
                                                                            </Box>}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <FormControl size="medium" variant="standard" fullWidth>
                                                                            {/* <InputLabel id="select-label">Select</InputLabel> */}
                                                                            <Select
                                                                                labelId="select-label"
                                                                                name="project_role_id"
                                                                                id="select-demo"
                                                                                label=""
                                                                                value={memberData?.project_role_id ?? ""}
                                                                                onChange={(event) =>
                                                                                    handleChange(event, index, array, memberData?.unique_id)
                                                                                }
                                                                                onBlur={(event) =>
                                                                                    validateMemberData(event, index, array, memberData?.unique_id)
                                                                                }
                                                                            >
                                                                                {memberData?.project_role_id === "Select" ? <MenuItem key={index} value={"Select"} disabled={true}>
                                                                                    Select
                                                                                </MenuItem> : null}
                                                                                {userRolesData?.map(({ id, title }, index) => (
                                                                                    <MenuItem key={index} value={id} disabled={false}>
                                                                                        {titleCase(title)}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                            {!!getErrorDataByUniqeId(memberData?.unique_id)?.project_role_id ? (
                                                                                <FormHelperText sx={{ color: "red.main" }}>
                                                                                    {getErrorDataByUniqeId(memberData?.unique_id)?.project_role_id}
                                                                                </FormHelperText>
                                                                            ) : null}
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid
                                                                            item
                                                                            pr={3}
                                                                            mb={{ xs: 3, md: 0 }}
                                                                            flex={{ xs: "0 0 50%", md: "0 0 31%" }}
                                                                            sx={{
                                                                                input: {
                                                                                    width: "100%",
                                                                                    height: 30,
                                                                                    border: "none !important",
                                                                                    boxShadow: "none !important",
                                                                                    borderRadius: "0px",
                                                                                    paddingBottom: "6px",
                                                                                    paddingLeft: "28px",
                                                                                    borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
                                                                                    margin: "0",
                                                                                    position: "relative",
                                                                                    background: "transparent",
                                                                                    color: "dark.800",
                                                                                    fontSize: 14,
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "24px",
                                                                                    letterSpacing: "0.15px",
                                                                                },
                                                                                "input:hover": {
                                                                                    borderBottomColor: "#333333 !important",
                                                                                },
                                                                                "input:focus": {
                                                                                    borderBottomColor: "#047FE0 !important",
                                                                                },
                                                                                ".rmdp-container": {
                                                                                    minWidth: "100px",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <div style={{ height: 1 }}>
                                                                                <CalendarTodayIcon
                                                                                    sx={{
                                                                                        fontSize: 20,
                                                                                        color: "#3F5C76",
                                                                                        marginBottom: "-5px",
                                                                                        marginLeft: "2px",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <SingleDatePicker
                                                                                id={getInputId("joining_date")}
                                                                                value={new Date(memberData?.joining_date) || ""}
                                                                                name="joining_date"
                                                                                onChange={(event) =>
                                                                                    handleChangeDate(event, index, memberData?.unique_id)
                                                                                }
                                                                                isMultiple={false}
                                                                                onOpen={() => { }}
                                                                                minDate={projectData?.start_date ? new Date(projectData?.start_date) : new Date(currentUser?.organization?.createdAt)}
                                                                                isDisableRequest={true}
                                                                            />
                                                                            {!!getErrorDataByUniqeId(memberData?.unique_id)?.joining_date ? (
                                                                                <FormHelperText sx={{ color: "red.main" }}>
                                                                                    {getErrorDataByUniqeId(memberData?.unique_id)?.joining_date}
                                                                                </FormHelperText>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            size="small"
                                                                            onClose={toggleDrawer(false)}
                                                                            sx={{ width: 40, minWidth: 40, height: 40, opacity: '0', }}
                                                                            className="delete-btn"
                                                                            onClick={() =>
                                                                                handleDeleteMemberList(
                                                                                    memberData,
                                                                                    index,
                                                                                    memberData?.unique_id
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon fontSize="medium" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                                : null}
                                                    </React.Fragment>
                                                )) : null
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box my={2}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="medium"
                                        sx={{ textTransform: "none", boxShadow: 'none' }}
                                        onClick={() => handleAddNewMember()}
                                        disabled={!isMoreUserExist}
                                    >
                                        Add Member
                                    </Button>
                                </Box>
                            </Box>
                            <Divider />
                            <Box p={2.5} display={"flex"} gap={2.5}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    sx={{ textTransform: "none", boxShadow: 'none' }}
                                    onClick={() => handleSubmitData()}
                                    disabled={isLoading?.memberDataSubmit}
                                    isButtonLoading={isLoading.formSubmitting}
                                >
                                    Update
                                </Button>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    size="medium"
                                    onClick={() => handleCloseDrawer()}
                                    disabled={isLoading?.memberDataSubmit}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Drawer>

                    <Drawer open={openMembersListDrawer} anchor="right" onClose={() => setOpenMembersListDrawer(false)}>
                        <Box width={420} flex={1} flexDirection={"column"} display={"flex"} overflow={"hidden"}>
                            <MembersList
                                setOpenMembersListDrawer={setOpenMembersListDrawer}
                                teamMembersData={teamMembersData}
                                projectData={projectData} />
                        </Box>
                    </Drawer>

                    <Drawer open={openPhaseDrawer} anchor="right" onClose={() => handleClosePhaseDrawer()}>
                        <PhaseDetails projectPhases={projectPhases}
                            setProjectPhases={setProjectPhases}
                            optionalProjectPhases={optionalProjectPhases}
                            handleClosePhaseDrawer={handleClosePhaseDrawer}
                            getProjectPhaseHours={getProjectPhaseHours}
                            handleAddEmptyPhaseHours={handleAddEmptyPhaseHours}
                            isEditPhase={isEditPhase}
                            isEditPhaseHours={isEditPhaseHours}
                            setIsEditPhaseHours={setIsEditPhaseHours}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            showAlert={showAlert}
                            projectData={projectData}
                            params={params}
                            getProjectPhaseData={getProjectPhaseData}
                            setIsEditPhase={setIsEditPhase}
                            initialProjectPhase={initialProjectPhase}
                            isAddPhase={isAddPhase}
                            setIsAddPhase={setIsAddPhase}
                            editabelPhase={editabelPhase}
                            setEditabelPhase={setEditabelPhase}
                        />
                    </Drawer>

                    <Drawer open={openTaskDrawer} anchor="right" onClose={() => null}>
                        <CreateTask
                            handleCloseTaskDrawer={handleCloseTaskDrawer}
                            projectPhases={projectPhases}
                            // handleSubmitTaskData={(taskDetails) => handleSubmitTaskData(taskDetails)}
                            handleSubmitTaskData={handleSubmitTaskData}
                            activeMembersData={projectData?.is_private ? activeMembersData?.map(value => ({ ...value?.User })) : allOrfUsersList?.filter(userData => userData?.active)}
                        />
                    </Drawer>

                    <Drawer open={openUpdateTaskDrawer} anchor="right" onClose={handleCloseUpdateTaskDrawer}>
                        <UpdateTask
                            handleCloseUpdateTaskDrawer={handleCloseUpdateTaskDrawer}
                            projectPhases={projectPhases}
                            // handleSubmitUpdatedTaskDataInList={(name, value, taskId, taskResponse) => handleSubmitUpdateTaskData(name, value, taskId, taskResponse)}
                            handleSubmitUpdatedTaskDataInList={handleSubmitUpdateTaskData}
                            activeMembersData={projectData?.is_private ? activeMembersData?.map(value => ({ ...value?.User })) : allOrfUsersList?.filter(userData => userData?.active)}
                            editableTaskDataId={editableTaskDataId}
                            allOrfUsersList={allOrfUsersList}
                            projectList={projectList}
                            handleTaskMove={(movableData) => handleTaskMove(movableData)}
                        />
                    </Drawer>
                </Box>
            </Box>
    )
}

export default ProjectAllDetails;