import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { useNavigate } from 'react-router-dom';
import { PROJECT_REPORTS } from '../../../constants/default-values';
import MembersTimeTracking from './ProjectCharts/membersTimeTracking';
import TaskEstimationComparision from './ProjectCharts/taskEstimationComparision';
import TeamsReport from './ProjectCharts/teamsReport';
import API from '../../../axios/api';
import axiosInstance from '../../../axios';
import useAuthentication from '../../../hook/useAuthentication';
import { titleCase } from '../../../utils';
import reportImg from '../../../assets/images/members-time-report.png';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const ProjectReport = () => {
    const navigate = useNavigate();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const [tabValue, setTabValue] = useState(Object.keys(PROJECT_REPORTS)[0]);
    const [projectList, setProjectList] = useState([]);
    const [isLoading, setIsLoading] = useState({
        project: false,
    });
    const [selectedProject, setSelectedProject] = useState("");
    const [selectedProjectData, setSelectedProjectData] = useState({})


    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        getAllProjectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllProjectList = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, project: true }));

            let queryParams = {
                isForList: true,
                orgId: currentUser?.organization_id,
            };
            const response = await axiosInstance.post(
                API.getAllProjectsListWithMembers,
                queryParams
            );

            if (response?.status === 200) {
                setProjectList(response?.data?.data?.rows);
            } else {
                setProjectList([]);
            }
            setIsLoading((prev) => ({ ...prev, project: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, project: false }));
        }
    };

    const handleChangeProject = (value) => {
        setSelectedProject(value);
        setSelectedProjectData(projectList?.find(projectData => projectData?.id === value))
    }

    return (
        <>
            <Box width="100%" bgcolor="white" display="flex" flexDirection="column"
                sx={{
                    '> div:first-of-type': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: 'white',
                    }
                }}
            >
                <Breadcrumb isBack={true} pageTitle={"Project Report"} title={"reports"} onBackClick={() => navigate(-1)} />
                <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto" flex={1}>
                    <Stack spacing={3}>
                        <Grid item width={265} maxWidth={265}>
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel htmlFor="range_label">Select Project</InputLabel>
                                <Select
                                    inputProps={{ id: "range_label" }}
                                    id="projectId"
                                    value={selectedProject || ""}
                                    onChange={(event, ...rest) => {
                                        handleChangeProject(event?.target?.value)
                                    }}
                                    name="projectId"
                                    label="Select User"
                                    sx={{
                                        ".MuiSelect-select": {
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: "dark.800",
                                        },
                                    }}
                                >
                                    {projectList?.map((item) => (
                                        <MenuItem key={item?.id} value={item?.id}>
                                            {titleCase(item?.title)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {selectedProject ?
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        {Object.entries(PROJECT_REPORTS)?.map((projectChartObj, index) => (
                                            <Tab label={projectChartObj[1]} value={projectChartObj[0]} key={index} />
                                        ))}
                                    </Tabs>
                                </Box>
                                <Box
                                    bgcolor="secondary"
                                    sx={{
                                        ".tabs-detail > .MuiBox-root": {
                                            padding: "0px",
                                        },
                                    }}
                                >
                                    <Box>
                                        <CustomTabPanel value={tabValue} index={Object.keys(PROJECT_REPORTS)[0]} className="tabs-detail">
                                            <MembersTimeTracking selectedProject={selectedProject} isProjectLoading={isLoading?.project} selectedProjectData={selectedProjectData} />
                                        </CustomTabPanel>
                                        <CustomTabPanel value={tabValue} index={Object.keys(PROJECT_REPORTS)[1]} className="tabs-detail">
                                            <TaskEstimationComparision selectedProject={selectedProject} isProjectLoading={isLoading?.project} selectedProjectData={selectedProjectData} />
                                        </CustomTabPanel>
                                        <CustomTabPanel value={tabValue} index={Object.keys(PROJECT_REPORTS)[2]} className="tabs-detail">
                                            <TeamsReport selectedProject={selectedProject} isProjectLoading={isLoading?.project} selectedProjectData={selectedProjectData} />
                                        </CustomTabPanel>
                                    </Box>
                                </Box>
                            </Box>
                            : <Box bgcolor={"#FAFBFF"} display={"flex"} flexDirection={"column"} py={"60px"} px={6} alignItems={"center"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }}>
                                <Typography
                                    variant="body1"
                                    color="dark.800"
                                    fontSize={14}
                                    fontWeight={400}
                                    mb={2.5}
                                >
                                    Please select the project
                                </Typography>
                                <Box>
                                    <img alt='404 page' src={reportImg} style={{ maxWidth: '100%', width: '420px' }} />
                                </Box>
                            </Box>}
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default ProjectReport