import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./texteditor.css";
import Button from "../Button";

const TextEditor = ({ value, onChange, readOnly = false, isShowReadMore = false, ...rest }) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "align", { list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = ["bold", "italic", "underline", "list", "bullet"];

  const [code, setCode] = useState(value || "");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const editorRef = useRef(null);
  const maxHeight = 62;

  useEffect(() => {
    setCode(value || "");
  }, [value]);

  useEffect(() => {
    const checkOverflow = () => {
      if (editorRef.current) {
        setIsOverflowing(editorRef.current.scrollHeight > maxHeight);
      }
    };
    setTimeout(checkOverflow, 100);
  }, [value, isExpanded]);

  const handleContentChange = (content) => {
    setCode(content);
    onChange(content);
  };

  const handleToggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {isShowReadMore ? (
        <div>
          <div
            ref={editorRef}
            className={`ql-editor ${isExpanded ? "show-desc-box" : ""}`}
            style={{
              maxHeight: isExpanded ? "none" : maxHeight,
              overflow: "hidden",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
            dangerouslySetInnerHTML={{ __html: code }}
          ></div>
          {isOverflowing && (
            <Button
              variant="text"
              color="primary"
              size="small"
              className="see-more-desc-btn"
              onClick={(e) => {
                e.stopPropagation();
                handleToggleReadMore();
              }}
              disableRipple={true}
              sx={{
                textTransform: "none",
                p: '0px 8px',
                position: isExpanded ? "static" : "absolute",
                bottom: '5px',
                right: '5px',
                background: isExpanded ? "#ffffff" : "linear-gradient(90deg, transparent, #ffffff 25%)",
                borderRadius: '0px',
                paddingLeft: isExpanded ? "8px" : "30px",
              }}
            >
              {isExpanded ? "See Less" : "See More"}
            </Button>
          )}
        </div>
      ) : (
        <ReactQuill
          id="editor-0"
          name="editor-0"
          placeholder="Type Here"
          theme="snow"
          modules={readOnly ? { toolbar: false } : modules}
          formats={formats}
          value={code}
          onChange={handleContentChange}
          readOnly={readOnly}
          InputProps={{ id: "editor" }}
          {...rest}
        />
      )}
    </div>
  );
};

export default TextEditor;