import URLS from "../routes/urls";
import ICONS from "../constants/icons";

const ReportsSidebarSettings = [
    {
        name: "Reports",
        icon: ICONS.ReportIcon,
        href: URLS.Reports,
    }
];

export default ReportsSidebarSettings;
