/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import Button from "../../../../components/Button";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import CircularLoader from "../../../../components/CircularLoader";
import Input from "../../../../components/Input";
import ICONS from "../../../../constants/icons";
import { useAlert } from "../../../../hook/useAlert";
import Switch from "../../../../components/Switch";
import { usePageTitle } from "../../../../hook/useTitle";
import { getInputId, sortArrayByKey, titleCase } from "../../../../utils";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import TablePagination from "../../../../components/TablePagination";

const AdministratorProjectRoles = () => {
  let initialEditableRole = {
    id: null,
    title: "",
    is_active: true,
  };
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const showAlert = useAlert();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
    deleteButton: false,
    editButton: false,
    addButton: false,
  });
  const [userRolesData, setUserRolesData] = useState([]);
  const [optionalUserRolesData, setOptionalUserRolesData] = useState([]);
  const [tempUserRolesData, setTempUserRolesData] = useState([]);
  const [editableRole, setEditableRole] = useState(initialEditableRole);
  const [newRole, setNewRole] = useState(initialEditableRole);
  const [isOpen, setIsOpen] = useState(false);
  const [deletableRole, setDeletableRole] = useState({});
  const [errors, setErrors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [isShowEmptyRow, setIsShowEmptyRow] = useState(false);

  setPageTitle("Roles");

  useEffect(() => {
    getUserRolesData();
  }, []);

  useEffect(() => {
    getDataAccordingPagination();
  }, [page, rowsPerPage]);

  const getDataAccordingPagination = (
    optionalUserRolesListData = optionalUserRolesData
  ) => {
    let dataToPaginate = [...optionalUserRolesListData];

    if (dataToPaginate && dataToPaginate.length) {
      const startIdx = page * rowsPerPage;
      const endIdx = Math.min(startIdx + rowsPerPage, dataToPaginate?.length);
      const updatedPageData = dataToPaginate.slice(startIdx, endIdx);
      setUserRolesData(updatedPageData);
      setTempUserRolesData(updatedPageData);
    } else {
      setUserRolesData([]);
      setTempUserRolesData([]);
    }
  };

  const getUserRolesData = async () => {
    try {
      const response = await axiosInstance.get(
        API.organisationProjectRoles(currentUser?.organization_id)
      );
      if (response.status === 200) {
        let sortedData = sortArrayByKey(response?.data?.data, "asc", "title");
        // setUserRolesData(sortedData);
        setOptionalUserRolesData(sortedData);
        setTempUserRolesData(sortedData);
        getDataAccordingPagination(sortedData);
      }
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditRole = (title, roleId, is_active) => {
    setUserRolesData(tempUserRolesData);
    setEditableRole({
      id: roleId,
      title: title,
      is_active: is_active,
    });
  };

  const handleCloseDelete = () => {
    setIsOpen(false);
    setDeletableRole({});
    setIsLoading((prev) => ({ ...prev, deleteButton: false }));
  };

  const handleDeletableRole = (roleData) => {
    setDeletableRole(roleData);
  };

  const handleDeleteRole = async (roleId) => {
    try {
      setIsLoading((prev) => ({ ...prev, deleteButton: true }));
      const response = await axiosInstance.delete(
        API.organisationProjectRoles(roleId)
      );
      if (response.status === 200) {
        setOptionalUserRolesData((prev) =>
          prev?.filter((roleData) => roleData?.id !== roleId)
        );
        getDataAccordingPagination(
          optionalUserRolesData?.filter((roleData) => roleData?.id !== roleId)
        );
        userRolesData?.length === 1 && setPage(page > 1 ? page - 1 : 0);
        showAlert("User role delete successfully.");
        handleCloseDelete();
      } else {
        handleCloseDelete();
      }
    } catch (error) {
      console.error(error);
      handleCloseDelete();
    }
  };

  const handleChange = (e, is_switch = false) => {
    setEditableRole((prev) => ({
      ...prev,
      [e.target.name]: is_switch ? e.target.checked : e.target.value,
    }));
    setErrors((prev) => ({ ...prev, title: "" }));
  };

  const handleChangeNewName = (e, is_switch = false) => {
    setNewRole((prev) => ({
      ...prev,
      [e.target.name]: is_switch ? e.target.checked : e.target.value,
    }));
    setErrors((prev) => ({ ...prev, newName: "" }));
  };

  const handleCancel = () => {
    setUserRolesData(tempUserRolesData);
    setEditableRole(initialEditableRole);
    setErrors((prev) => ({ ...prev, title: "" }));
  };

  const handleCancelAddRole = () => {
    setIsShowEmptyRow(false);
    setUserRolesData(tempUserRolesData);
    setNewRole(initialEditableRole);
    setErrors((prev) => ({ ...prev, newName: "" }));
  };

  const handleAddRole = async () => {
    try {
      if (isValidate(userRolesData)) {
        setIsLoading((prev) => ({ ...prev, addButton: true }));
        let role = {
          ...newRole,
          title: titleCase(newRole?.title?.trim()),
          orgId: currentUser?.organization_id,
        };
        delete role?.uniqueId;
        delete role?.id;

        const response = await axiosInstance.post(
          API.organisationProjectRoles(currentUser?.organization_id),
          role
        );
        if (response.status === 200) {
          getUserRolesData();
          // setEditableRole(initialEditableRole);
          setNewRole(initialEditableRole);
          setIsShowEmptyRow(false);
          showAlert(response?.data?.message);
          setIsLoading((prev) => ({ ...prev, addButton: false }));
        } else {
          handleCancelAddRole();
          showAlert(
            response?.data?.message || "Something went wrong while adding role",
            "error"
          );
          setIsLoading((prev) => ({ ...prev, addButton: false }));
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, addButton: false }));
    }
  };

  const validateUniqueRoles = (userRolesData, type) => {
    const uniqueRoleNames = userRolesData?.filter(
      (role) =>
        (type === "add" ? newRole.id : editableRole.id) !== role?.id &&
        role.title?.toString()?.toLowerCase()?.trim() ===
        (type === "add"
          ? newRole?.title?.toLocaleLowerCase()?.trim()
          : editableRole?.title?.toLocaleLowerCase()?.trim())
    );
    if (type === "add" && uniqueRoleNames.length > 0) {
      return false;
    } else if (type === "update" && uniqueRoleNames.length === 1) {
      if (
        editableRole?.title?.toLowerCase()?.trim() ===
        uniqueRoleNames[0]?.title?.toLowerCase()?.trim() &&
        editableRole?.id === uniqueRoleNames[0]?.id
      )
        return true;
      else return false;
    } else if (type === "update" && uniqueRoleNames.length > 1) {
      return false;
    }
    return true;
  };

  const isValidate = (array, type = "add", statusUpdate = false) => {
    if (!editableRole?.title?.length && type !== "add" && !statusUpdate) {
      setErrors((prev) => ({ ...prev, title: "Please enter role name" }));
      return false;
    } else if (type === "add" && !newRole?.title?.length) {
      setErrors((prev) => ({ ...prev, newName: "Please enter role name" }));
      return false;
    }
    if (!validateUniqueRoles(array, type)) {
      if (type === "add") setErrors({ newName: "Role names should be unique" });
      else setErrors({ title: "Role names should be unique" });
      return false;
    } else {
      setErrors({ title: "", newName: "" });
    }
    return true;
  };

  const handleUpdateRole = async (
    editableRoleData = { ...editableRole },
    statusUpdate = false
  ) => {
    try {
      if (isValidate(userRolesData, "update", statusUpdate)) {
        setIsLoading((prev) => ({ ...prev, editButton: true }));
        let updateData = userRolesData?.map((data) => {
          return data?.id === editableRoleData?.id
            ? {
              ...data,
              title: editableRoleData?.title,
              is_active: editableRoleData?.is_active,
            }
            : data;
        });
        let role = {
          title: editableRoleData?.title,
          is_active: editableRoleData?.is_active,
          orgId: currentUser?.organization_id,
        };
        const response = await axiosInstance.put(
          API.organisationProjectRoles(editableRoleData?.id),
          role
        );
        if (response.status === 200) {
          setTempUserRolesData(updateData);
          setUserRolesData(updateData);
          setOptionalUserRolesData((prev) =>
            prev?.map((data) => {
              return data?.id === editableRoleData?.id
                ? {
                  ...data,
                  title: editableRoleData?.title,
                  is_active: editableRoleData?.is_active,
                }
                : data;
            })
          );
          setEditableRole(initialEditableRole);
          showAlert(response?.data?.message);
          setIsLoading((prev) => ({ ...prev, editButton: false }));
        } else {
          setUserRolesData(updateData);
          setOptionalUserRolesData((prev) =>
            prev?.map((data) => {
              return data?.id === editableRoleData?.id
                ? {
                  ...data,
                  title: editableRoleData?.title,
                  is_active: editableRoleData?.is_active,
                }
                : data;
            })
          );
          setEditableRole(initialEditableRole);
          showAlert(response?.data?.message);
          setIsLoading((prev) => ({ ...prev, editButton: false }));
        }
      } else {
        setIsLoading((prev) => ({ ...prev, editButton: false }));
      }
    } catch (error) {
      console.error(error);
      showAlert(error?.response?.data?.message);
      setEditableRole(initialEditableRole);
      setIsLoading((prev) => ({ ...prev, editButton: false }));
    }
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
  };

  const handleCreateRole = () => {
    setIsShowEmptyRow(true);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: "100%" }}
        p={{ xs: 2, lg: 3, xl: 4 }}
        py={{ xs: 3, lg: 3, xl: 4 }}
        overflow="auto"
      >
        <Grid
          container
          mb={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography
              color="dark.800"
              fontSize={14}
              fontWeight={500}
              lineHeight="24px"
              letterSpacing="0.17px"
            >
              {optionalUserRolesData?.length || 0} Project Roles
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              fontWeight="500"
              sx={{
                color: "white",
                textTransform: "none",
                boxShadow: "none !important",
              }}
              onClick={() => handleCreateRole()}
              isButtonLoading={false}
              disabled={isShowEmptyRow}
            >
              Add Project Role
            </Button>
          </Grid>
        </Grid>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            boxShadow: "none",
            border: "1px solid #E0E0E0",
            borderRadius: "3px",
          }}
        >
          <TableContainer>
            <Table
              sx={{ width: "100%" }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow
                  sx={{
                    th: {
                      color: "dark.800",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      padding: "6px 16px",
                      background: "#F7F7F7",
                    },
                  }}
                >
                  <TableCell>Name</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={500}
                        lineHeight="24px"
                        letterSpacing="0.17px"
                      >
                        Status
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ paddingRight: "38px !important" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isShowEmptyRow ? (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      td: {
                        color: "rgba(0, 0, 0, 0.87)",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.17px",
                        padding: "6px 16px",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        // width: "185px !important"
                        width: "calc(100%/3)",
                      }}
                    >
                      <Input
                        id={getInputId("role-name")}
                        name="title"
                        variant="standard"
                        type="text"
                        onChange={(e) => handleChangeNewName(e)}
                        value={titleCase(newRole?.title)}
                        error={!!errors["newName"]}
                        helperText={errors["newName"]}
                        sx={{ width: "100%", maxWidth: "300px" }}
                        // isOnlyCapitalFirstLatter={true}
                        isOnlyAlphabetAllowed={true}
                      />
                    </TableCell>
                    <TableCell align={"center"}>
                      <Switch
                        name="is_active"
                        id="is_active"
                        size="small"
                        checked={newRole?.is_active}
                        onChange={(e) => handleChangeNewName(e, true)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ padding: "5px 0px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fontSize={13}
                          size="small"
                          sx={{
                            textTransform: "none",
                            color: "white",
                            boxShadow: "none !important",
                          }}
                          onClick={() => handleAddRole()}
                          isButtonLoading={isLoading?.addButton}
                        >
                          Add
                        </Button>
                        <Button
                          id="cancel"
                          name="cancel"
                          component="button"
                          variant="text"
                          color="secondary"
                          fontSize={13}
                          size="small"
                          sx={{ textTransform: "none" }}
                          onClick={() => handleCancelAddRole()}
                          disabled={isLoading?.addButton}
                          aria-label="Cancel"
                        >
                          Cancel
                        </Button>

                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
                {isLoading?.pageLoading ? (
                  <CircularLoader
                    variant="table"
                    rows={10} 
                    cols={4}
                  />
                ) : (userRolesData.length || isShowEmptyRow) ? (
                  userRolesData.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          sx={{
                            td: {
                              color: "rgba(0, 0, 0, 0.87)",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              letterSpacing: "0.17px",
                              padding: "6px 16px",
                            },
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              // width: "185px !important"
                              width: "calc(100%/3)",
                            }}
                          >
                            {Boolean(row?.id) &&
                              (row?.id === editableRole?.id ||
                                (row?.id === editableRole?.id) ===
                                editableRole?.uniqueId) &&
                              !["administrator"].includes(
                                row?.title?.toLowerCase()
                              ) ? (
                              <Input
                                id="role-name"
                                name="title"
                                variant="standard"
                                type="text"
                                onChange={(e) => handleChange(e)}
                                value={titleCase(editableRole?.title)}
                                error={!!errors["title"]}
                                helperText={errors["title"]}
                                sx={{ width: "100%", maxWidth: "300px" }}
                                // isOnlyCapitalFirstLatter={true}
                                isOnlyAlphabetAllowed={true}
                              />
                            ) : (
                              row?.title
                            )}
                            <span></span>
                          </TableCell>
                          {/* <TableCell align={"center"}>
                            <Switch
                              name="is_active"
                              id="is_active"
                              size="small"
                              checked={
                                Boolean(row?.id) &&
                                (row?.id === editableRole?.id ||
                                  (row?.id === editableRole?.id) ===
                                    editableRole?.uniqueId)
                                  ? editableRole?.is_active
                                  : row?.is_active
                              }
                              onChange={(e) => handleChange(e, true)}
                              disabled={row?.id !== editableRole?.id}
                            />
                          </TableCell> */}
                          <TableCell align={"center"}>
                            <Switch
                              name="is_active"
                              id={getInputId("is-active", index)}
                              size="small"
                              checked={row?.is_active}
                              onChange={(e) => {
                                // setUserRolesData((prev) => ({
                                //   ...prev,
                                //   is_active: e.target.value,
                                // }));
                                setUserRolesData((prev) =>
                                  prev?.map((roleData) => {
                                    if (roleData?.id === row?.id)
                                      return {
                                        ...roleData,
                                        is_active: e.target.checked,
                                      };
                                    return roleData;
                                  })
                                );
                                handleUpdateRole(
                                  {
                                    ...row,
                                    is_active: !row?.is_active,
                                  },
                                  true
                                );
                              }}
                              disabled={row?.id === editableRole?.id}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {Boolean(row?.id) &&
                              (row?.id === editableRole?.id ||
                                (row?.id === editableRole?.id) ===
                                editableRole?.uniqueId) ? (
                              <Box sx={{ padding: "5px 0px" }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fontSize={13}
                                  size="small"
                                  sx={{
                                    textTransform: "none",
                                    color: "white",
                                    boxShadow: "none !important",
                                  }}
                                  onClick={() => handleUpdateRole()}
                                  isButtonLoading={isLoading?.editButton}
                                >
                                  Update
                                </Button>
                                <Button
                                  component="button"
                                  variant="text"
                                  color="secondary"
                                  fontSize={13}
                                  size="small"
                                  sx={{ textTransform: "none" }}
                                  onClick={() => handleCancel(row?.id)}
                                  disabled={isLoading?.editButton}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            ) : (
                              <div
                                style={{
                                  width: "98px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              >
                                <IconButton
                                  sx={{
                                    height: "30px",
                                    width: "30px",
                                    padding: "0px",
                                    borderRadius: "4px",
                                    color: "secondary.main",
                                  }}
                                  id={`basic-button-${index}`}
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, row?.id)}
                                >
                                  <i
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      display: "flex",
                                    }}
                                  >
                                    {ICONS.DotsHorizontal}
                                  </i>
                                </IconButton>
                                <Menu
                                  id={`basic-menu-${index}`}
                                  anchorEl={anchorEl}
                                  open={open && openMenuIndex === row?.id}
                                  onClose={() => handleClose()}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                  sx={{
                                    ".MuiPaper-root": {
                                      boxShadow:
                                        "0px 2px 4px -1px rgba(0,0,0,0.2)",
                                      width: "180px",
                                    },
                                    ".MuiMenuItem-root": {
                                      color: "dark.800",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      lineHeight: "24px",
                                      letterSpacing: "0.15px",
                                    },
                                    ".MuiMenuItem-root:hover": {
                                      background: "rgba(33, 150, 243, 0.04)",
                                    },
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleEditRole(
                                        row?.title,
                                        row?.id,
                                        row?.is_active
                                      );
                                      handleClose();
                                    }}
                                  >
                                    Rename
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleDeletableRole(row);
                                      setIsOpen(true);
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      sx={{ padding: "12px 16px;" }}
                    >
                      <Typography
                        color={"secondary"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontSize={13}
                        sx={{ opacity: "0.5", height: "200px" }}
                      >
                        No Project role found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={optionalUserRolesData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <ConfirmationModal
          isOpen={isOpen}
          title={"Role"}
          deletableDataName={titleCase(deletableRole?.title)}
          dataContentName={"Role"}
          handleClose={() => setIsOpen(false)}
          onConfirm={() => handleDeleteRole(deletableRole?.id)}
          onCancel={() => handleCloseDelete()}
          isButtonLoading={isLoading?.deleteButton}
          subList={
            <span>
              This will remove <b>{titleCase(deletableRole?.title)}</b> from
              your organization's common Roles list.
            </span>
          }
        />
      </Box>
    </>
  );
};

export default AdministratorProjectRoles;
