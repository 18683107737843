import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import reportImg from '../../../../assets/images/teams-report.png';
import useAuthentication from '../../../../hook/useAuthentication';
import { DateObject } from 'react-multi-date-picker';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import { convertDateFromFormat, formatDuration, generateFileName, getFormattedDate, getStartDateBaseOnOnboarding, secondsToTime, sortArrayByKey, titleCase } from '../../../../utils';
import Button from '../../../../components/Button';
import ReactApexChart from 'react-apexcharts';
import CircularLoader from '../../../../components/CircularLoader';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuChartDateRangePicker from '../../../../components/DateRangePicker/MenuChartDateRangePicker';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import FileSaver from 'file-saver';
import ICONS from '../../../../constants/icons';

const TeamsReport = ({ selectedProject, isProjectLoading, selectedProjectData }) => {
    const [sortType, setSortType] = useState("desc");
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    let onboardingDate = new Date(currentUser?.organization?.createdAt);
    let startDateOfMonth = new Date(getStartDateBaseOnOnboarding(new Date(new DateObject().toFirstOfMonth()), onboardingDate)) > new DateObject().toFirstOfMonth() ? new Date(getStartDateBaseOnOnboarding(new Date(new DateObject().toFirstOfMonth()), onboardingDate)) : new DateObject().toFirstOfMonth()
    let initialDateRangeChange = [
        startDateOfMonth,
        new Date(),
    ];
    const [isLoading, setIsLoading] = useState({
        report: false,
        projectPhase: false,
    });
    const [projectPhases, setProjectPhases] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [teamsReportByRole, setTeamsReportByRole] = useState({});
    const [expandedRow, setExpandedRow] = useState([])

    const [queryParams, setQueryParams] = useState({
        userData: {
            id: currentUser?.id
        },
        startDate: startDateOfMonth,
        endDate: new DateObject().toDate(),
        dateRange: initialDateRangeChange,
    });
    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            type: 'pie',
            height: 350,
        },
        labels: [],
        colors: ['#357AF6', '#5CC8BE', '#AF52DE', '#5856D6', '#7BDEFF', '#05B01B', '#EF6C00', '#F5D94E', '#3F5C76'],
        tooltip: {
            custom: function ({ seriesIndex, w }) {
                const seriesColor = w?.config?.colors?.[seriesIndex];
                return `
                    <div class="custom-tooltip" style="
                        background: ${seriesColor};
                        color: #fff;
                        padding: 5px 10px;
                        border-radius: 5px;
                        font-size: 14px;
                    ">
                        ${w?.globals?.labels?.[seriesIndex]}
                    </div>
                `;
            }
        },
        plotOptions: {
            pie: {
                // offsetX: -100,
                offsetY: 0,
                customScale: 1,
            },
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'right',
                        align: 'middle',
                        offsetX: 20,
                        offsetY: 0,
                    },
                },
            },
        ],
        legend: {
            show: true,
            position: 'right',
            floating: false,
            offsetY: 10,
            // offsetX: 50,
            itemMargin: {
                // horizontal: 5,
                vertical: 5
            },
            markers: {
                width: 12,
                height: 12,
                shape: 'square'
            },
            onItemClick: {
                toggleDataSeries: false,
            },
            onItemHover: {
                highlightDataSeries: false,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: (val) => {
                return `${val?.toFixed(2)}%`;
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: -20,
            offsetY: -100,
            dropShadow: {
                enabled: false,
            },
        },
    });

    useEffect(() => {
        if (selectedProject) {
            setSelectedPhase(null);
            getProjectPhaseData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    useEffect(() => {
        if (series?.length) {
            setOptions((prev => ({
                ...prev,
                legend: {
                    ...prev?.legend,
                    markers: {
                        width: 12,
                        height: 12,
                        shape: 'square',
                    },
                    formatter: (seriesName, opts) => {
                        const index = opts?.seriesIndex;
                        const total = series?.[index]?.seconds;
                        const totalPercentage = series?.[index]?.y;
                        return `${seriesName} <b>${secondsToTime(total)}</b> (<b>${totalPercentage}%</b>)`;
                    }
                },
            })))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [series])

    const getLabelColor = (role) => {
        if (options?.labels?.length && !!role) {
            const index = options?.labels?.findIndex(value => value?.toLowerCase() === role?.toLowerCase())
            if (index !== -1) {
                const color = options?.colors?.[index];
                return color;
            }
        }
        return "transparent"
    }

    const handleChangeDatePicker = (dateRange) => {
        const newStartDate = new Date(dateRange?.[0]).toISOString();
        const newEndDate = new Date(dateRange?.[1] || dateRange?.[0]).toISOString();

        setQueryParams(prev => ({
            ...prev,
            startDate: newStartDate,
            endDate: newEndDate,
            dateRange,
        }));
    }

    const getTeamsReportData = async () => {
        try {
            let body = {
                "chartType": "pie",
                "project_id": selectedProject,
                startDate: convertDateFromFormat(new Date(queryParams?.startDate)),
                endDate: convertDateFromFormat(new Date(queryParams?.endDate)),
                project_phase_id: selectedPhase
            }
            let response = await axiosInstance.post(API.getTaskChartData, body)
            if (response?.status === 200) {
                let chartDetails = response?.data?.taskChartData ?? [];
                if (chartDetails?.length) {
                    let totalDuration = 0;
                    let groupByProjectRole = chartDetails?.reduce((addAllLeaveData, currentData) => {
                        let key = currentData?.project_role ?? "Unassigned";
                        totalDuration = totalDuration + (currentData?.duration ?? 0);
                        if (!addAllLeaveData[key]) {
                            addAllLeaveData[key] = [];
                        }

                        addAllLeaveData[key].push(currentData);

                        return addAllLeaveData;
                    }, {})

                    const finalGroup = {};

                    Object.keys(groupByProjectRole)?.forEach(role => {
                        finalGroup[role] = groupByProjectRole[role]?.reduce((functionedItem, currentItem) => {
                            const userId = currentItem.user_id;
                            if (!functionedItem[userId]) {
                                functionedItem[userId] = { duration: 0, user: currentItem?.User, total: totalDuration };
                            }
                            functionedItem[userId].duration += currentItem.duration;
                            return functionedItem;
                        }, {});
                    });

                    setTeamsReportByRole(finalGroup);

                    const sumDurations = (data) => {
                        const result = [];
                        for (let project_role in data) {
                            let totalCountOfRole = data[project_role]?.reduce((acc, task) => acc + task.duration, 0);
                            result.push({
                                name: titleCase(project_role),
                                type: 'pie',
                                x: project_role,
                                y: parseFloat(((totalCountOfRole * 100) / totalDuration).toFixed(2)),
                                seconds: totalCountOfRole,
                            })
                        }
                        return result;
                    };

                    setOptions(prev => ({ ...prev, labels: Object.keys(groupByProjectRole) }))
                    const totalDurations = sumDurations(groupByProjectRole);
                    setSeries(totalDurations)
                } else {
                    setSeries([])
                }
            }
            setIsLoading((prev) => ({ ...prev, report: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, report: false }));
        }
    }

    const handleSearch = () => {
        setIsLoading((prev) => ({ ...prev, report: true }));
        getTeamsReportData();
    };

    const getProjectPhaseData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, projectPhase: true }));
            let response = await axiosInstance.get(
                API.getProjectPhases(selectedProject)
            );
            if (response?.status === 200) {
                let sortedPhases = sortArrayByKey(response?.data?.data, "asc", 'id')
                setProjectPhases(sortedPhases);
                if (sortedPhases?.length && sortedPhases?.length === 1) {
                    setSelectedPhase(sortedPhases[0]?.id)
                }
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading((prev) => ({ ...prev, projectPhase: false }));
        }
    }

    const handleRowClick = (date) => {
        setExpandedRow((prev) => prev.includes(date) ? prev?.filter(name => name !== date) : [...prev, date])
    }

    const handleSortClick = (sortTypeString) => {
        setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
    }

    const sortedKeys = Object.keys(teamsReportByRole)?.sort((a, b) => sortType === 'asc' ? new Date(a) - new Date(b) : new Date(b) - new Date(a));

    const sortedData = {};
    sortedKeys?.forEach(key => {
        sortedData[key] = teamsReportByRole[key];
    });

    const getTotalHours = () => {
        return series?.reduce((acc, value) => acc + (value?.seconds ?? 0), 0)
    }

    const getTotalWorkingHours = (userDurationData) => {
        return userDurationData?.reduce((totalCount, currentData) => {
            totalCount += currentData?.duration
            return totalCount
        }, 0)
    }

    const getDurationPercentage = (userTrack = {}) => {
        let duration = userTrack?.duration;
        let totalDuration = userTrack?.total;
        if (duration && totalDuration) {
            return parseFloat(((duration * 100) / totalDuration).toFixed(2))
        }
        return 0;
    }

    const getUserTaskPercentage = (duration = 0, totalDuration = 0) => {
        if (duration && totalDuration) {
            return parseFloat(((duration * 100) / totalDuration).toFixed(2))
        }
        return 0;
    }

    const getTotalOfAllUsersPercentage = (userDurationData) => {
        let totalDuration = userDurationData?.reduce((totalCount, currentData) => {
            totalCount += currentData?.duration
            return totalCount
        }, 0);

        let totalDurationOfAllRole = userDurationData?.[0]?.total;
        if (totalDurationOfAllRole && totalDuration) {
            return parseFloat(((totalDuration * 100) / totalDurationOfAllRole).toFixed(2))
        }
    }

    const exportChartData = async () => {
        try {
            setIsLoading(prev => ({ ...prev, exportChartLoading: true }))
            let body = {
                chartType: "pie",
                project_id: selectedProject,
                startDate: convertDateFromFormat(new Date(queryParams?.startDate)),
                endDate: convertDateFromFormat(new Date(queryParams?.endDate)),
                project_phase_id: selectedPhase
            };
            const response = await axiosInstance.post(API.getExportRepotParams, body);

            if (response?.status === 200) {
                if (response?.data) {
                    let base64Input = Object.values(response.data).join("");
                    var selectedMimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                    const decodedData = atob(base64Input);
                    const byteArray = new Uint8Array(decodedData?.length);
                    for (let i = 0; i < decodedData.length; i++) {
                        byteArray[i] = decodedData.charCodeAt(i);
                    }

                    const blob = new Blob([byteArray], { type: selectedMimeType });
                    // await FileSaver.saveAs(blob, 'teamsReportData.xlsx');
                    await FileSaver.saveAs(blob, generateFileName('Project_TeamWise_Time'));
                }
            }
            setIsLoading(prev => ({ ...prev, exportChartLoading: false }))
        } catch (error) {
            console.error("Error downloading file", error);
            setIsLoading(prev => ({ ...prev, exportChartLoading: false }))
        }
    };

    return (
        isProjectLoading ?
            <CircularLoader height='500px' /> :
            <Box flex={1} overflow={"visible"} display={"flex"} mt={4}>
                <Box display={"flex"} flexDirection={"column"} sx={{ width: '100%', flex: '1', overflow: 'visible' }} gap={3}>
                    <Box display={'flex'} alignItems={"center"} justifyContent={'space-between'}>
                        <Box display="flex" gap={2}>
                            <Grid className='report-date-range-picker' item width={265} maxWidth={265} sx={{ '.MuiTextField-root': { width: '100% !important' } }}>
                                <MenuChartDateRangePicker
                                    dateRange={queryParams?.dateRange}
                                    onChange={(dateRange) => handleChangeDatePicker(dateRange)}
                                    maxDate={new Date()}
                                    width={370}
                                />
                            </Grid>
                            {selectedProject ? <Grid item sx={{ width: { xs: 180, lg: 264 } }}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="range_label">Select Phase</InputLabel>
                                    <Select
                                        inputProps={{ id: "range_label" }}
                                        id="phase"
                                        value={selectedPhase || ""}
                                        onChange={(event, ...rest) => {
                                            setSelectedPhase(event?.target?.value)
                                        }}
                                        name="phase"
                                        label="Select Phase"
                                        sx={{
                                            ".MuiSelect-select": {
                                                fontSize: 14,
                                                fontWeight: 400,
                                                color: "dark.800",
                                            },
                                        }}
                                    >
                                        {projectPhases?.map((item) => (
                                            <MenuItem key={item?.id} value={item?.id}>
                                                {titleCase(item?.title)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid> : null}
                            <Grid item sx={{ alignContent: 'center' }}>
                                <Button
                                    sx={{ color: "white", textTransform: 'none', boxShadow: 'none' }}
                                    onClick={handleSearch}
                                    disabled={!selectedProject || !selectedPhase || isLoading?.report}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Box>
                        {series?.length ? <Button
                            variant="outlined"
                            color="secondary"
                            size="medium"
                            // startIcon={<ExpandIcon />}
                            sx={{ color: "secondary.main", boxShadow: "none", border: '1px solid #D4D9DE', textTransform: "none", display: "flex", alignItems: "center", 'svg': { display: 'block', } }}
                            onClick={() => exportChartData(prev => !prev)}
                            disabled={!selectedProject || isLoading?.exportChartLoading}
                        >
                            <i style={{ width: 17, height: 12, minWidth: 17, marginRight: '8px' }}>
                                {ICONS.ExportFileIcon}
                            </i>
                            Export
                        </Button> : null}
                    </Box>
                    {series?.length ?
                        <>
                            <Box border={'1px solid #E0E0E0'} borderRadius={'3px'} sx={{ padding: '24px' }}>
                                <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                                    <Box sx={{ paddingLeft: '17px' }}>
                                        <Typography
                                            variant="span"
                                            color="dark.800"
                                            fontSize={14}
                                            fontWeight={400}
                                            lineHeight={'20px'}
                                            letterSpacing={'0.17px'}
                                        >
                                            Project Report of different teams of <b>{titleCase(selectedProjectData?.title)}</b> project for
                                        </Typography>
                                        <Typography
                                            variant="span"
                                            color="dark.800"
                                            fontSize={14}
                                            fontWeight={700}
                                            lineHeight={'20px'}
                                            letterSpacing={'0.17px'}
                                        >
                                            {' '}{convertDateFromFormat(new Date(queryParams?.startDate)) === convertDateFromFormat(new Date(queryParams?.endDate))
                                                ? getFormattedDate(convertDateFromFormat(new Date(queryParams?.startDate)))
                                                : `${getFormattedDate(convertDateFromFormat(new Date(queryParams?.startDate)))} - ${getFormattedDate(convertDateFromFormat(new Date(queryParams?.endDate)))}`}
                                        </Typography>
                                    </Box>
                                    <Box display={'flex'} justifyContent={'space-between'} gap={1}>
                                        <Typography
                                            variant="span"
                                            color="dark.800"
                                            fontSize={14}
                                            fontWeight={400}
                                            lineHeight={'20px'}
                                            letterSpacing={'0.17px'}
                                            whiteSpace={"nowrap"}
                                        >
                                            Total Task Hours <b>{formatDuration(getTotalHours())}</b>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                                    <Box sx={{ width: '50%' }} className="teams-report-pie-wrapper">
                                        <ReactApexChart type="pie" height={375} options={options} series={series?.map((item) => item?.y)} />
                                    </Box>
                                    <Box sx={{ width: '50%' }}></Box>
                                </Box>
                            </Box>
                            <Box>
                                <Typography
                                    variant="span"
                                    color="dark.800"
                                    fontSize={14}
                                    fontWeight={400}
                                    lineHeight={'20px'}
                                    letterSpacing={'0.17px'}
                                >
                                    Project Report of different teams of <b>{titleCase(selectedProjectData?.title)}</b> project for
                                </Typography>
                                <Typography
                                    variant="span"
                                    color="dark.800"
                                    fontSize={14}
                                    fontWeight={700}
                                    lineHeight={'20px'}
                                    letterSpacing={'0.17px'}
                                >
                                    {' '}{convertDateFromFormat(new Date(queryParams?.startDate)) === convertDateFromFormat(new Date(queryParams?.endDate))
                                        ? getFormattedDate(convertDateFromFormat(new Date(queryParams?.startDate)))
                                        : `${getFormattedDate(convertDateFromFormat(new Date(queryParams?.startDate)))} - ${getFormattedDate(convertDateFromFormat(new Date(queryParams?.endDate)))}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Paper
                                    sx={{
                                        border: "1px solid #E0E0E0",
                                        borderRadius: "3px",
                                        boxShadow: 'none',
                                    }}
                                >
                                    <TableContainer>
                                        <Table>
                                            <TableHead
                                                sx={{
                                                    th: {
                                                        color: "dark.800",
                                                        fontWeight: 500,
                                                        fontSize: 14,
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        padding: "5px 16px",
                                                        textAlign: "left",
                                                        background: "rgba(245, 248, 250, 1)",
                                                    },
                                                }}
                                            >
                                                <TableRow>
                                                    <TableCell sx={{ minWidth: 200, width: "25%", maxWidth: "25%" }}>
                                                        <TableSortLabel
                                                            active={true}
                                                            direction={sortType}
                                                            onClick={() => handleSortClick(sortType)}
                                                        >
                                                            Teams
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sx={{ minWidth: 250, width: "35%", maxWidth: "0px" }}>Team Members</TableCell>
                                                    <TableCell sx={{ minWidth: 170, width: "20%", maxWidth: "20%" }}>Duration (In Hours)</TableCell>
                                                    <TableCell sx={{ minWidth: 170, width: "20%", maxWidth: "20%" }}>Duration (In %)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                sx={{
                                                    "tr:hover": {
                                                        background: "rgba(247, 247, 247, 1)",
                                                    },
                                                    td: {
                                                        color: "dark.800",
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        lineHeight: "20px",
                                                        letterSpacing: "0.17px",
                                                        padding: "7px 16px",
                                                        textAlign: "left",
                                                    },
                                                }}
                                            >
                                                {
                                                    Object.entries(sortedData)?.length ? (
                                                        Object.entries(sortedData)?.map((projectRoleData, index) => {
                                                            let isOpen = expandedRow?.includes(projectRoleData?.[0]);
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        sx={{ cursor: 'pointer' }}
                                                                        onClick={() => handleRowClick(projectRoleData?.[0])}
                                                                    >
                                                                        <TableCell
                                                                            component="th"
                                                                            align='left'
                                                                            color='secondary'
                                                                            fontSize="14"
                                                                            fontWeight='500'
                                                                            sx={{ padding: '11px 16px' }}
                                                                            colSpan={2}
                                                                        >
                                                                            <Grid item display='flex' alignItems='center' gap={1} color="dark.800" fontSize={14} fontWeight={400} sx={{ lineHeight: '20px' }}>
                                                                                {expandedRow?.includes(projectRoleData[0]) ? <ExpandMoreIcon sx={{ fontSize: '18px' }} /> : <ChevronRightIcon sx={{ fontSize: '18px' }} />}
                                                                                <span style={{ width: '12px', height: '12px', borderRadius: '2px', background: getLabelColor(projectRoleData?.[0]) }}></span>
                                                                                {`${projectRoleData?.[0]}`}
                                                                            </Grid>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <b>{secondsToTime(getTotalWorkingHours(Object.values(projectRoleData[1])))}</b>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <b>{getTotalOfAllUsersPercentage(Object.values(projectRoleData[1]))}%</b>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {isOpen ?
                                                                        <>
                                                                            {Object?.entries(projectRoleData[1])?.map((roleMembersDetails, subIndex) => (
                                                                                <TableRow key={subIndex}>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell sx={{ maxWidth: 0 }}>
                                                                                        <Box display={'none'} gap={2}>
                                                                                            <Typography color={"dark"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                                {titleCase(roleMembersDetails[0])}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box display={"flex"} alignItems={"center"} gap={0.5} overflow={"hidden"}>
                                                                                            <BackgroundLetterAvatars
                                                                                                user={roleMembersDetails[1]?.user}
                                                                                                src={roleMembersDetails[1]?.user?.img}
                                                                                                sx={{ width: 26, height: 26, minWidth: 26, fontSize: '75%' }} />
                                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>{titleCase(roleMembersDetails[1]?.user?.name)}</Typography>
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography color={"dark"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                            {secondsToTime(roleMembersDetails[1]?.duration ?? 0)} ({getUserTaskPercentage(roleMembersDetails[1]?.duration ?? 0, getTotalWorkingHours(Object.values(projectRoleData[1])))}%)
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography color={"dark"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                            {getDurationPercentage(roleMembersDetails[1])}%
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </>
                                                                        : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    ) : (
                                                        <TableRow
                                                            sx={{
                                                                "&:hover": {
                                                                    background: "transparent !important",
                                                                },
                                                            }}
                                                        >
                                                            <TableCell
                                                                colSpan={3}
                                                                align="center"
                                                                sx={{ padding: "12px 16px;", border: 'none !important' }}
                                                            >
                                                                <Typography
                                                                    color={"dark.800"}
                                                                    display={"flex"}
                                                                    alignItems={"center"}
                                                                    justifyContent={"center"}
                                                                    fontSize={14}
                                                                    sx={{
                                                                        opacity: "1",
                                                                        height: "200px",
                                                                        color: "dark.800",
                                                                    }}
                                                                >
                                                                    No records found.
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </>
                        :
                        <Box bgcolor={"#FAFBFF"} display={"flex"} flexDirection={"column"} py={"60px"} px={6} alignItems={"center"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }}>
                            {(selectedProject && selectedPhase) ? <Typography
                                variant="body1"
                                color="dark.800"
                                fontSize={14}
                                fontWeight={400}
                                mb={2.5}
                            >
                                No data available
                            </Typography> :
                                <Typography
                                    variant="body1"
                                    color="dark.800"
                                    fontSize={14}
                                    fontWeight={400}
                                    mb={2.5}
                                >
                                    Select <b style={{ fontWeight: 700 }}>Project</b> to see team wise tracking details
                                </Typography>}
                            <Box>
                                <img alt='404 page' src={reportImg} style={{ maxWidth: '100%', width: '420px' }} />
                            </Box>
                        </Box>}
                </Box>
            </Box>
    )
}

export default TeamsReport