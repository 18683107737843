const miscRoutes = {
    Holidays: "/holidays",
    AccessDenied: "/page-not-found",
    TermsAndConditions: "/terms-and-conditions",
    PrivacyPolicy: "/privacy-policy",
    CAPrivacyNotice: "/ca-privacy-notice",
    InCompleteOnBoarding: "/incomplete-onboard-process",
};

export default miscRoutes;
