const leaveRoutes = {
    Leaves: "/request/leaves",
    ApplyLeave: "/request/leaves/leave/apply",
    OtherLeaveDetails: "/request/leaves/other-leave",
    ViewMyLeaveApplication: "/request/leaves/my-leave/view",
    EditMyLeaveApplication: "/request/leaves/my-leave/edit",
    EditOthersLeaveApplication: "/request/leaves/other-leave/edit",
};

export default leaveRoutes;
