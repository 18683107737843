import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import { Box, Collapse, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { getApplicationNumber, getFormattedDate, getLeaveStatus, isDashedYearBasedOnMonth, sortArrayByKey, sortRequestArrayByDate, titleCase, updateSystemGeneratedLeaveApplicationStatus } from '../../../utils'
import { ReactComponent as ExpandIcon } from "../../../assets/images/expand-icon.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/images/collapse-icon.svg";
import { useNavigate } from 'react-router-dom';
import URLS from '../../../routes/urls';
import LaunchIcon from '@mui/icons-material/Launch';
import BackgroundLetterAvatars from '../../../components/BackgroundLetterAvatars';
import CircularLoader from '../../../components/CircularLoader';
import axiosInstance from '../../../axios';
import API from '../../../axios/api';
import { LEAVE_STATUS } from '../../../constants/default-values';
import useAuthentication from '../../../hook/useAuthentication';
import Breadcrumb from '../../../components/Breadcrumb';
import reportImg from '../../../assets/images/report-img.png';

const LeaveReport = () => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();
    const [isExpandAll, setIsExpandAll] = useState(false);
    const [open, setOpen] = useState([]);
    const [isLoading, setIsLoading] = useState({
        reportData: false,
        years: true,
        users: true,
    });
    const [leaveReportData, setLeaveReportData] = useState([]);
    const [formData, setFormData] = useState({ year: "", userId: null });
    const [activeYear, setActiveYear] = useState({});
    const [leaveReportyears, setLeaveReportYears] = useState([]);
    const [organizationUserList, setOrganizationUserList] = useState([]);
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


    useEffect(() => {
        getAllHolidayYears();
        getUserSettingData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formData.year && formData.userId) {
            getUserLeaveReportData(formData.userId, formData.year);
            setIsExpandAll(false);
            setOpen([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        if (isExpandAll) {
            setOpen(leaveReportData?.map((leaveData) => leaveData?.month));
        } else {
            setOpen([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandAll])

    const getUserSettingData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, users: true }))
            const response = await axiosInstance.get(
                `${API.organisationUserSetting(currentUser?.organization_id)}`
            );
            if (response.status === 200) {
                let sortedNames = sortArrayByKey(response?.data?.data)
                setOrganizationUserList(sortedNames);
                setIsLoading((prev) => ({ ...prev, users: false }))
            } else {
                setIsLoading((prev) => ({ ...prev, users: false }))
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, users: false }))
        }
    };

    const getAllHolidayYears = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, years: true }));
            const response = await axiosInstance.get(
                API.getAllHolidaysYear(currentUser.organization_id)
            );
            if (response.status === 200) {
                let sortedYear = response?.data?.result?.sort((a, b) =>
                    a?.year?.toString()?.localeCompare(b?.year?.toString())
                );
                setLeaveReportYears(sortedYear);
                let activeYear = sortedYear?.find(yearData => yearData?.active);
                setFormData((prev) => ({ ...prev, year: activeYear?.id }));
                setActiveYear(activeYear)
            }
            setIsLoading((prev) => ({ ...prev, years: false }));
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading((prev) => ({ ...prev, years: false }));
        }
    };

    const getUserLeaveReportData = async (userId, yearId) => {
        try {
            setIsLoading((prev) => ({ ...prev, reportData: true }))
            const response = await axiosInstance.get(API.getUserLeaveReportById(userId, yearId));
            if (response?.status === 200) {
                let updatedStatusdata = response?.data?.data?.map((reportData) => {
                    if (reportData?.leaveData?.length) {
                        return {
                            ...reportData,
                            leaveData: reportData?.leaveData?.map((leaveDetails) => {
                                return updateSystemGeneratedLeaveApplicationStatus(leaveDetails)
                            })
                        }
                    }
                    return reportData;
                })

                setLeaveReportData(updatedStatusdata);
            } else {
                setLeaveReportData([]);
            }
            setIsLoading((prev) => ({ ...prev, reportData: false }))
        } catch (error) {
            console.error(error);
            setLeaveReportData([]);
            setIsLoading((prev) => ({ ...prev, reportData: false }))
        }
    }

    const handleYearChange = (event) => {
        setFormData((prev) => ({ ...prev, year: event.target.value }));
    };

    const handleLeaveClick = (leave) => {
        let { user_id, id } = leave;
        navigate(`${URLS.OtherLeaveDetails}/${user_id}/${id}`);
    };

    const getYearObject = (yearId) => {
        return leaveReportyears?.find((year) => year?.id === yearId);
    }

    const getMonthData = (month, index) => {
        let findLastMonthOfYear = leaveReportData?.findIndex((data) => data?.month?.toLocaleLowerCase() === "december");
        return `${month} ${findLastMonthOfYear >= index ? getYearObject(formData.year)?.year : (Number(getYearObject(formData.year)?.year) + 1)}`
    }

    const isGreaterMonth = (month, index) => {
        let findLastMonthOfYear = leaveReportData?.findIndex((data) => data?.month?.toLocaleLowerCase() === months[new Date()?.getMonth()]?.toLocaleLowerCase());
        return findLastMonthOfYear < index || getYearObject(formData.year)?.year > activeYear?.year;
    }

    const getLeaveCount = (leaveDayData) => {
        if (leaveDayData?.leave_duration === "full") {
            return 1
        } else {
            return `0.5 ${leaveDayData?.leave_duration ===
                "first_half"
                ? "(1st Half)"
                : leaveDayData?.leave_duration ===
                    "second_half"
                    ? "(2nd Half)"
                    : ""
                }`
        }
    }

    const getLeaveSetOff = (leaveDayData) => {
        if (leaveDayData?.leave_duration === "full") {
            return leaveDayData?.is_paid === 1 ? "Fully Paid" : leaveDayData?.is_paid === 0.5 ? "Half Paid / Half Unpaid" : "Fully Unpaid"
        } else {
            return leaveDayData?.is_paid === 0.5 ? "Fully Paid" : "Fully Unpaid"
        }
    }

    const getTotalLeavesCount = (leaveApplications) => {
        return leaveApplications?.reduce((prevData, leaveDetails) => {
            prevData.count = leaveDetails?.leave_days?.length + prevData?.count;
            return prevData;
        }, { count: 0 })?.count;
    }

    const getPaidTakenLeaveByType = (leaveApplications, leaveType) => {
        let totalLeaveCount = leaveApplications?.length ? leaveApplications?.reduce((prevData, leaveDetails) => {
            if (leaveDetails?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.toLowerCase() === leaveType?.toLowerCase()) {
                let totalLeaveCount = leaveDetails?.leave_days?.reduce((currentLeavePrevCountData, currentLeaveData) => {
                    if (currentLeaveData?.leave_duration === "full") {
                        if (leaveDetails?.status !== LEAVE_STATUS?.Cancelled) {
                            if (currentLeaveData?.is_paid > 0)
                                currentLeavePrevCountData.count = 1 + currentLeavePrevCountData?.count;
                        }
                    } else {
                        if (leaveDetails?.status !== LEAVE_STATUS?.Cancelled) {
                            if (currentLeaveData?.is_paid > 0)
                                currentLeavePrevCountData.count = 0.5 + currentLeavePrevCountData?.count;
                        }
                    }
                    return currentLeavePrevCountData;
                }, { count: 0 })?.count
                prevData.count = totalLeaveCount + prevData?.count;
            }
            return prevData;
        }, { count: 0 })?.count : 0;
        return totalLeaveCount;
    }

    const getUnPaidTakenLeaveByType = (leaveApplications) => {
        let totalLeaveCount = leaveApplications?.length ? leaveApplications?.reduce((prevData, leaveDetails) => {
            let totalLeaveCount = leaveDetails?.leave_days?.reduce((currentLeavePrevCountData, currentLeaveData) => {
                if (currentLeaveData?.leave_duration === "full") {
                    if (leaveDetails?.status !== LEAVE_STATUS?.Cancelled) {
                        if (!(currentLeaveData?.is_paid > 0))
                            currentLeavePrevCountData.count = 1 + currentLeavePrevCountData?.count;
                    }
                } else {
                    if (leaveDetails?.status !== LEAVE_STATUS?.Cancelled) {
                        if (!(currentLeaveData?.is_paid > 0))
                            currentLeavePrevCountData.count = 0.5 + currentLeavePrevCountData?.count;
                    }
                }
                return currentLeavePrevCountData;
            }, { count: 0 })?.count
            prevData.count = totalLeaveCount + prevData?.count;
            return prevData;
        }, { count: 0 })?.count : 0;
        return totalLeaveCount;
    }

    const getTotalPaidLeavesCount = (paidCasualLeave = 0, paidSickLeave = 0, unPaidLeave = 0) => {
        return paidCasualLeave + paidSickLeave + unPaidLeave;
    }

    return (
        <React.Fragment>
            <Box width="100%" bgcolor="white" display="flex" flexDirection="column"
                sx={{
                    '> div:first-of-type': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: 'white',
                    }
                }}
            >
                <Breadcrumb isBack={true} pageTitle={"Leave Report"} title={"reports"} onBackClick={() => navigate(-1)} />
                <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto" flex={1}>
                    {(isLoading?.users || isLoading?.years) ?
                        <CircularLoader height="500px" /> :
                        <Box display={"flex"} sx={{ width: '100%', flex: '1', overflow: 'hidden' }}>
                            <Box display={"flex"} flexDirection={"column"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }}>
                                <Stack spacing={4} pb={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box display="flex" gap={2} justifyContent="space-between" alignItems="center">
                                            <Grid item width={265} maxWidth={265}>
                                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                                    <InputLabel htmlFor="year_label-1">
                                                        Select Year
                                                    </InputLabel>
                                                    <Select
                                                        id='year-range'
                                                        name='year'
                                                        inputProps={{ id: "year_label-1" }}
                                                        value={formData.year}
                                                        onChange={(e) => handleYearChange(e)}
                                                        label="Select Year"
                                                        sx={{
                                                            ".MuiSelect-select": {
                                                                fontSize: 14,
                                                                fontWeight: 400,
                                                                color: "dark.800",
                                                            },
                                                        }}
                                                    >
                                                        {leaveReportyears?.map((yearData, index) => (
                                                            <MenuItem value={yearData?.id} key={index}>
                                                                {isDashedYearBasedOnMonth(
                                                                    currentUser?.organization?.renewal_month,
                                                                    yearData?.year?.toString()
                                                                )?.yearDashedFormat}
                                                                {/* {yearData?.year} */}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item sx={{ width: { xs: 180, lg: 264 } }}>
                                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                                    <InputLabel htmlFor="range_label">Select User</InputLabel>
                                                    <Select
                                                        inputProps={{ id: "range_label" }}
                                                        id="user_id"
                                                        value={formData?.userId || ""}
                                                        onChange={(event, ...rest) => {
                                                            setFormData((prev) => ({ ...prev, userId: event?.target?.value }))
                                                        }}
                                                        name="userId"
                                                        label="Select User"
                                                        sx={{
                                                            ".MuiSelect-select": {
                                                                fontSize: 14,
                                                                fontWeight: 400,
                                                                color: "dark.800",
                                                            },
                                                        }}
                                                    >
                                                        {organizationUserList?.map((item, index) => (
                                                            <MenuItem key={item?.id} value={item?.id}>
                                                                {titleCase(item?.name)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Box>
                                        <Grid item display={"flex"} justifyContent={"flex-end"} gap={2}>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="medium"
                                                startIcon={!isExpandAll ? <ExpandIcon /> : <CollapseIcon />}
                                                sx={{ color: "black", BorderColor: '##ddd', boxShadow: "none", textTransform: "none", minWidth: '130px' }}
                                                onClick={() => setIsExpandAll(prev => !prev)}
                                                disabled={!leaveReportData?.length}
                                            >
                                                {!isExpandAll ? "Expand All" : "Collapse All"}
                                            </Button>
                                        </Grid>
                                    </Box>
                                </Stack>
                                <Box flex={1} overflow={"hidden"} display={"flex"}>
                                    {leaveReportData?.length ?
                                        <TableContainer sx={{ border: '1px solid #ddd', borderRadius: '3px', overflow: 'overlay' }}>
                                            <Table
                                                aria-labelledby="tableTitle"
                                                size={'medium'}
                                                aria-label="collapsible table"
                                            >
                                                <TableHead sx={{ position: 'sticky', top: '0', zIndex: '10' }}>
                                                    <TableRow fontSize={14} fontWeight={500} lineheight={'24px'} letterSpacing={'0.17px'} alignitems={'center'} sx={{ backgroundColor: '#F5F8FA', textAlign: 'center', height: '36px', maxHeight: '36px' }}>
                                                        <TableCell sx={{ minWidth: '250px', padding: '6px 16px 6px 16px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Month</TableCell>
                                                        <TableCell sx={{ minWidth: '105px', padding: '6px 16px 6px 16px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Paid Casual</TableCell>
                                                        <TableCell sx={{ minWidth: '105px', padding: '6px 16px 6px 16px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Paid Sick</TableCell>
                                                        <TableCell sx={{ minWidth: '105px', padding: '6px 16px 6px 16px', boxShadow: 'inset -2px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Unpaid</TableCell>
                                                        <TableCell sx={{ minWidth: '105px', padding: '6px 16px 6px 16px', boxShadow: 'inset 0px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Total</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={{}}>
                                                    {isLoading?.reportData ?
                                                        <CircularLoader variant="table" cols={9} rows={10} /> :
                                                        leaveReportData?.map((monthData, index) => (
                                                            <React.Fragment key={index}>
                                                                <TableRow hover style={{ padding: '6px 16px 6px 16px' }}
                                                                    sx={{ height: '40px', maxHeight: '40px', cursor: "pointer", backgroundColor: (months[new Date().getMonth()] === monthData?.month && getYearObject(formData.year)?.year === activeYear?.year) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }}
                                                                    onClick={() => setOpen(prev => prev?.includes(monthData?.month) ? prev?.filter(list => list !== monthData?.month) : [...prev, monthData?.month])}>
                                                                    <TableCell style={{ padding: '6px 16px 6px 16px' }}
                                                                        sx={{ boxShadow: open?.includes(monthData?.month) ? 'inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', alignContent: "center" }}
                                                                    >
                                                                        <IconButton size="small">
                                                                            {!open?.includes(monthData?.month) ? <ExpandIcon /> : <CollapseIcon />}
                                                                        </IconButton>
                                                                        <Typography
                                                                            component="span" fontSize={14}
                                                                            fontWeight={open?.includes(monthData?.month) ? 700 : 400}
                                                                            lineHeight={'20px'} letterSpacing={'0.17px'}
                                                                            sx={{ color: '#3F5C76', verticalAlign: 'middle' }}>
                                                                            {getMonthData(monthData?.month, index)}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    {/* Paid Casual */}
                                                                    <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', textAlign: 'center' }}>
                                                                        {isGreaterMonth(monthData?.month, index) ?
                                                                            getPaidTakenLeaveByType(monthData?.leaveData, 'Casual Leave') ? getPaidTakenLeaveByType(monthData?.leaveData, 'Casual Leave') : "-" :
                                                                            getPaidTakenLeaveByType(monthData?.leaveData, 'Casual Leave')}
                                                                    </TableCell>
                                                                    {/* Paid Sick */}
                                                                    <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -1px 0px 0px #ddd' : 'inset -1px 0px 0px #ddd', textAlign: 'center' }}>
                                                                        {isGreaterMonth(monthData?.month, index) ?
                                                                            getPaidTakenLeaveByType(monthData?.leaveData, 'Sick Leave') ? getPaidTakenLeaveByType(monthData?.leaveData, 'Sick Leave') : "-" :
                                                                            getPaidTakenLeaveByType(monthData?.leaveData, 'Sick Leave')}
                                                                    </TableCell>
                                                                    {/* Unpaid */}
                                                                    <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ boxShadow: open?.includes(monthData?.month) ? ' inset -2px 0px 0px #ddd' : 'inset -2px 0px 0px #ddd', textAlign: 'center', color: '#D32F2F', fontWeight: '600' }}>
                                                                        <Box display={'flex'} justifyContent={'center'} flexDirection={"column"} gap={0.5} alignItems={'center'}>
                                                                            {isGreaterMonth(monthData?.month, index) ?
                                                                                getUnPaidTakenLeaveByType(monthData?.leaveData) ? getUnPaidTakenLeaveByType(monthData?.leaveData) : "-" :
                                                                                getUnPaidTakenLeaveByType(monthData?.leaveData)}
                                                                        </Box>
                                                                    </TableCell>
                                                                    {/* Total */}
                                                                    <TableCell style={{ padding: '6px 16px 6px 16px' }} sx={{ textAlign: 'center', fontWeight: '600' }}>
                                                                        <Box display={'flex'} justifyContent={'center'} flexDirection={"column"} gap={0.5} alignItems={'center'}>
                                                                            {isGreaterMonth(monthData?.month, index) ?
                                                                                getTotalPaidLeavesCount(getPaidTakenLeaveByType(monthData?.leaveData, 'Casual Leave'), getPaidTakenLeaveByType(monthData?.leaveData, 'Sick Leave'), getUnPaidTakenLeaveByType(monthData?.leaveData)) ?
                                                                                    getTotalPaidLeavesCount(getPaidTakenLeaveByType(monthData?.leaveData, 'Casual Leave'), getPaidTakenLeaveByType(monthData?.leaveData, 'Sick Leave'), getUnPaidTakenLeaveByType(monthData?.leaveData)) : "-" :
                                                                                getTotalPaidLeavesCount(getPaidTakenLeaveByType(monthData?.leaveData, 'Casual Leave'), getPaidTakenLeaveByType(monthData?.leaveData, 'Sick Leave'), getUnPaidTakenLeaveByType(monthData?.leaveData))}
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{}}>
                                                                    <TableCell sx={{ padding: '0px', border: 'none !important' }} colSpan={9}>
                                                                        <Collapse in={open?.includes(monthData?.month)} timeout="auto" unmountOnExit sx={{ borderLeft: "4px solid #3F5C76", borderBottom: '1px solid #ddd' }}>
                                                                            <Box margin={1} padding={'16px'}>
                                                                                {!getTotalLeavesCount(monthData?.leaveData) ?
                                                                                    <Typography fontSize={14} fontWeight={400} lineHeight={"14px"} letterSpacing={"0.24px"} sx={{ textAlign: 'center' }}>
                                                                                        No Leaves
                                                                                    </Typography>
                                                                                    :
                                                                                    <Box sx={{ border: '1px solid #ddd', borderRadius: '3px' }}>
                                                                                        <Table size="small" aria-label="leave-details">
                                                                                            <TableHead>
                                                                                                <TableRow sx={{
                                                                                                    'th': {
                                                                                                        backgroundColor: '#F5F8FA'
                                                                                                    },
                                                                                                }}>
                                                                                                    <TableCell sx={{ width: '140px', minWidth: '140px' }}>Date</TableCell>
                                                                                                    <TableCell sx={{ width: '100px', minWidth: '100px' }}>Leave</TableCell>
                                                                                                    <TableCell sx={{ width: '120px', minWidth: '120px' }}>Type</TableCell>
                                                                                                    <TableCell sx={{ width: '140px', minWidth: '140px' }}>Full / Half</TableCell>
                                                                                                    <TableCell sx={{ width: '184px', minWidth: '184px' }}>Set Off</TableCell>
                                                                                                    <TableCell sx={{ width: '120px', minWidth: '120px' }}>Status</TableCell>
                                                                                                    <TableCell sx={{ minWidth: '250px' }}>By</TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {!getTotalLeavesCount(monthData?.leaveData) ? null : sortRequestArrayByDate(monthData?.leaveData, "leave_days", "leave_date")?.map((leaveDetails) => (
                                                                                                    leaveDetails?.leave_days?.map((leaveDays, index) => (
                                                                                                        <TableRow
                                                                                                            key={index}
                                                                                                            sx={{
                                                                                                                '&:hover': { background: '#F7F7F7' },
                                                                                                                '&:hover .launchIcon': {
                                                                                                                    display: 'inline-flex',
                                                                                                                },
                                                                                                                '&:hover .leaveId': {
                                                                                                                    color: '#047FE0',
                                                                                                                    textDecoration: 'underline',
                                                                                                                },
                                                                                                                '&:hover .leave-details': {
                                                                                                                    opacity: 1,
                                                                                                                    display: 'flex',
                                                                                                                },
                                                                                                                '&:hover .user-info': {
                                                                                                                    opacity: 0,
                                                                                                                },
                                                                                                                '&:hover .avatar': {
                                                                                                                    opacity: 0,
                                                                                                                },
                                                                                                                '&:last-child td': {
                                                                                                                    border: 'none',
                                                                                                                }
                                                                                                            }}>
                                                                                                            <TableCell>{getFormattedDate(leaveDays?.leave_date, "DD/MM/YYYY")}</TableCell>
                                                                                                            <TableCell>
                                                                                                                <Box
                                                                                                                    sx={{
                                                                                                                        display: 'flex',
                                                                                                                        alignItems: 'center',
                                                                                                                        color: 'black',
                                                                                                                        cursor: 'pointer',
                                                                                                                        '&:hover .launchIcon': {
                                                                                                                            display: 'inline-flex',
                                                                                                                        },
                                                                                                                        '&:hover .leaveId': {
                                                                                                                            color: '#047FE0',
                                                                                                                            textDecoration: 'underline',
                                                                                                                        },
                                                                                                                    }}
                                                                                                                    onClick={() => handleLeaveClick(leaveDetails)}
                                                                                                                >
                                                                                                                    <Typography
                                                                                                                        fontSize={14}
                                                                                                                        fontWeight={500}
                                                                                                                        lineHeight={"14px"}
                                                                                                                        letterSpacing={"0.17px"}
                                                                                                                        className="leaveId"
                                                                                                                        sx={{
                                                                                                                            textDecoration: 'none',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {getApplicationNumber(leaveDetails?.application_num)}
                                                                                                                    </Typography>
                                                                                                                    <LaunchIcon
                                                                                                                        className="launchIcon"
                                                                                                                        sx={{
                                                                                                                            fontSize: 16,
                                                                                                                            marginLeft: '4px',
                                                                                                                            display: 'none',
                                                                                                                            color: '#047FE0',
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </Box>
                                                                                                            </TableCell>
                                                                                                            <TableCell>{titleCase(leaveDetails?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.split(" ")?.[0])}</TableCell>
                                                                                                            <TableCell>{getLeaveCount(leaveDays)}</TableCell>
                                                                                                            <TableCell>{getLeaveSetOff(leaveDays)}</TableCell>
                                                                                                            <TableCell>
                                                                                                                <Box
                                                                                                                    display={'flex'}
                                                                                                                    alignItems={'center'}>
                                                                                                                    {getLeaveStatus(leaveDetails?.status, "chip")}
                                                                                                                </Box>
                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                <Box
                                                                                                                    display={'flex'}
                                                                                                                    alignItems={'center'}
                                                                                                                    sx={{
                                                                                                                        position: 'relative',
                                                                                                                        '& .leave-details': {
                                                                                                                            opacity: 0,
                                                                                                                            display: 'none',
                                                                                                                            position: 'absolute',
                                                                                                                            left: 0,
                                                                                                                            top: 0,
                                                                                                                            bottom: 0,
                                                                                                                            color: '#047FE0',
                                                                                                                            transition: 'opacity 0.9s ease',
                                                                                                                            cursor: 'pointer',
                                                                                                                        },
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {leaveDetails?.processedBy ? <>
                                                                                                                        <BackgroundLetterAvatars
                                                                                                                            user={leaveDetails?.processedBy?.[0]}
                                                                                                                            sx={{ width: 30, height: 30, mr: 1, fontSize: "13px", fontWeight: '500' }}
                                                                                                                            className="avatar"
                                                                                                                        />
                                                                                                                        <Typography
                                                                                                                            fontSize={14}
                                                                                                                            fontWeight={400}
                                                                                                                            lineHeight={'20px'}
                                                                                                                            letterSpacing={'0.17px'}
                                                                                                                            className="user-info"
                                                                                                                        >
                                                                                                                            {titleCase(leaveDetails?.processedBy?.[0]?.name)}{" at "}
                                                                                                                            {getFormattedDate(leaveDetails?.process_date, "DD/MM/YYYY")}
                                                                                                                        </Typography>
                                                                                                                    </> : <Box className="avatar user-info">-</Box>}
                                                                                                                    <Box
                                                                                                                        sx={{
                                                                                                                            display: 'flex',
                                                                                                                            alignItems: 'center',
                                                                                                                            color: 'black',
                                                                                                                            cursor: 'pointer',
                                                                                                                        }}
                                                                                                                        className="leave-details"
                                                                                                                        onClick={() => handleLeaveClick(leaveDetails)}
                                                                                                                    >
                                                                                                                        <Typography
                                                                                                                            fontSize={14}
                                                                                                                            fontWeight={500}
                                                                                                                            lineHeight={'20px'}
                                                                                                                            letterSpacing={'0.17px'}
                                                                                                                            sx={{
                                                                                                                                textDecoration: 'underline',
                                                                                                                                color: '#047FE0'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Leave Details
                                                                                                                        </Typography>
                                                                                                                        <LaunchIcon
                                                                                                                            className="launchIcon"
                                                                                                                            sx={{
                                                                                                                                fontSize: 16,
                                                                                                                                marginLeft: '4px',
                                                                                                                                display: 'none',
                                                                                                                                color: '#047FE0',
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    ))
                                                                                                ))}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </Box>
                                                                                }
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> :
                                        <Box bgcolor={"#FAFBFF"} display={"flex"} flexDirection={"column"} py={"60px"} px={6} alignItems={"center"} sx={{ width: '100%', flex: '1', overflow: 'hidden', marginTop: "24px" }}>
                                            <Typography
                                                variant="body1"
                                                color="dark.800"
                                                fontSize={14}
                                                fontWeight={400}
                                                mb={2.5}
                                            >
                                                Select <b style={{ fontWeight: 700 }}>User</b> to see their leave details
                                            </Typography>
                                            <Box>
                                                <img alt='404 page' src={reportImg} style={{ maxWidth: '100%', width: '420px' }} />
                                            </Box>
                                        </Box>}
                                </Box>
                            </Box>
                        </Box>}
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default LeaveReport;
