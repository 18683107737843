import { Box, Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import reportImg from '../../../../assets/images/members-time-report.png';
import useAuthentication from '../../../../hook/useAuthentication';
import { DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import { convertDateFromFormat, formatDuration, generateFileName, getApplyDateTime, getFormattedDate, getStartDateBaseOnOnboarding, secondsToTime, titleCase } from '../../../../utils';
import Button from '../../../../components/Button';
import { PROJECT_REPORTS_FILTER } from '../../../../constants/default-values';
import ReactApexChart from 'react-apexcharts';
import CircularLoader from '../../../../components/CircularLoader';
import { styled } from "@mui/styles";
import MemberTimeTableData from './memberTimeTableData';
import MenuChartDateRangePicker from '../../../../components/DateRangePicker/MenuChartDateRangePicker';
import FileSaver from 'file-saver';
import ICONS from '../../../../constants/icons';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        overflowX: 'hidden',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
    },
}));

const MembersTimeTracking = ({ selectedProject, isProjectLoading, selectedProjectData }) => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    let onboardingDate = new Date(currentUser?.organization?.createdAt);
    let startDateOfMonth = new Date(getStartDateBaseOnOnboarding(new Date(new DateObject().toFirstOfMonth()), onboardingDate)) > new DateObject().toFirstOfMonth() ? new Date(getStartDateBaseOnOnboarding(new Date(new DateObject().toFirstOfMonth()), onboardingDate)) : new DateObject().toFirstOfMonth()
    let initialDateRangeChange = [
        startDateOfMonth,
        new Date(),
    ];

    const [isLoading, setIsLoading] = useState({
        users: false,
        report: false
    });
    const [queryParams, setQueryParams] = useState({
        userData: {
            id: currentUser?.id
        },
        startDate: startDateOfMonth,
        endDate: new DateObject().toDate(),
        dateRange: initialDateRangeChange,
    });
    const [duration, setDuration] = useState(Object.keys(PROJECT_REPORTS_FILTER)[0]);
    const [totalDuration, setTotalDuration] = useState(0)

    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            id: 'stacked-bar-chart-members',
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const selectedCategory = config.w.config.series[config.seriesIndex]?.name;
                    const selectedValue = config?.w?.config?.series[config?.seriesIndex]?.data[config?.dataPointIndex];
                    // const selectedLabel = config?.w?.config?.xaxis?.categories[config?.dataPointIndex];
                    const selectedColor = config.w.config.colors?.[config.seriesIndex];
                    setModalDetails(prev => ({
                        isShow: true,
                        date: selectedValue?.x,
                        taskDetails: selectedValue?.userTaskData,
                        seconds: selectedValue?.seconds,
                        dateLabel: selectedValue?.fullDateRange,
                        userName: selectedCategory,
                        color: selectedColor,
                        userId: selectedValue?.userTaskData?.[0]?.user_id,
                        actualDate: selectedValue?.actualDate,
                    }))
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // columnWidth: '40%',
                columnWidth: '22px',
                borderRadius: 0,
            },
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 0,
        },
        xaxis: {
            categories: [],
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                formatter: (value) => {
                    if (value === null || value === undefined) return "0 h";
                    return `${value}h`;
                },
            },
        },
        legend: {
            position: 'bottom',
            offsetY: 10,
            markers: {
                width: 12,
                height: 12,
            },
            showForSingleSeries: true,
        },
        colors: ['#047FE0', '#A539B1', '#7BDEFF', '#05B01B', '#EF6C00', '#F5D94E', '#3F5C76'],
        tooltip: {
            enabled: true,
            shared: false,
            intersect: false,
            hideEmptySeries: true,
            theme: false,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let tooltipHTML = `<div style="padding: 12px; background: #444444;">`;
                let totalTime = 0;
                let activeSeriesCount = 0;
                let inactiveSeriesIndex = w?.globals?.collapsedSeriesIndices;

                w?.globals?.seriesNames.forEach((name, i) => {
                    const activeData = w?.globals?.initialSeries[i]?.data?.[dataPointIndex];

                    if (activeData && activeData.seconds !== null && activeData.seconds !== undefined && !inactiveSeriesIndex?.includes(i)) {
                        totalTime += activeData.seconds;
                        activeSeriesCount++;
                    }
                });

                if (activeSeriesCount === 0) return null;

                tooltipHTML += `<div style="color: #FFFFFF; font-size: 500; font-size: 14px; line-height: 21px; margin-bottom: 10px;">
                                    ${secondsToTime(totalTime)} hours spent on ${getFormattedDate(w?.globals?.initialSeries[seriesIndex]?.data?.[dataPointIndex]?.actualDate)}
                                </div>`;

                w?.globals?.seriesNames.forEach((name, i) => {
                    const activeData = w?.globals?.initialSeries[i]?.data?.[dataPointIndex];

                    if (activeData && activeData.seconds !== null && activeData.seconds !== undefined && !inactiveSeriesIndex?.includes(i)) {
                        tooltipHTML += `
                            <div style="display:flex; align-items: align-item:flex-start;">
                                <div style="height:14px; min-width:14px; flex: 0 0 14px; background-color:${w?.globals?.colors?.[i]}; border-radius: 2px; margin-right: 5px;margin-top: 2px;display:block;"></div>
                                <div style="color: #FFFFFF; font-size: 400; font-size: 12px; line-height: 17px;letter-spacing: 0.17px;">
                                    ${name} (${secondsToTime(activeData.seconds)})
                                </div>
                            </div>`;
                    }
                });

                tooltipHTML += `</div>`;
                return tooltipHTML;
            },
            position: function (data, { seriesIndex, dataPointIndex, w }) {
                let y = w.globals.seriesCumulative[dataPointIndex]
                    ? w.globals.seriesCumulative[dataPointIndex][seriesIndex]
                    : 0;
                return {
                    top: `${y - 20}px`,
                    left: `${data?.ctx?.cursorX}px`
                };
            }
        },
    });

    const [modalDetails, setModalDetails] = useState({
        isShow: false,
        date: null,
        taskDetails: [],
        seconds: null,
        dateLabel: '',
        userName: '',
        color: '',
        userId: null,
        actualDate: '',
    });

    const seriesTotals = series?.map(s => s?.data?.reduce((acc, value) => acc + (value?.seconds ?? 0), 0));
    const handleModalClose = () => {
        setModalDetails({
            isShow: false,
            date: null,
            taskDetails: [],
            seconds: null,
            dateLabel: '',
            userName: '',
            color: '',
            userId: null,
            actualDate: '',
        })
    }

    useEffect(() => {
        if (series?.length) {
            setOptions((prev => ({
                ...prev,
                legend: {
                    ...prev?.legend,
                    formatter: (seriesName, opts) => {
                        const index = opts?.seriesIndex;
                        const total = seriesTotals[index];
                        const isDisabled = opts?.w?.globals?.collapsedSeriesIndices?.includes(index);
                        return isDisabled
                            ? `<span style="text-decoration: line-through; color: gray;">${seriesName} (${secondsToTime(total)})</span>`
                            : `${seriesName} (${secondsToTime(total)})`;
                    },
                },
            })))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [series])

    const handleChangeDatePicker = (dateRange) => {
        const newStartDate = new Date(dateRange?.[0]).toISOString();
        const newEndDate = new Date(dateRange?.[1] || dateRange?.[0]).toISOString();

        setQueryParams(prev => ({
            ...prev,
            startDate: newStartDate,
            endDate: newEndDate,
            dateRange,
        }));
    }

    const handleSearch = () => {
        setIsLoading((prev) => ({ ...prev, report: true }));
        getmembersTimeTrackingReportData()
    };


    const getDatesBetween = (keys = [], startDate, endDate) => {
        const dates = [];
        let currentDate = moment(startDate);

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
            let date = currentDate.format('YYYY-MM-DD');
            dates.push({ chartDate: currentDate.format('DD/MM'), actualDate: currentDate.format('YYYY-MM-DD'), isExist: keys?.includes(date) });
            currentDate.add(1, 'days');
        }
        return dates;
    }

    const getLableDate = (startDates, startDate, endDate) => {
        let start = moment(startDate).startOf('week');
        let end = moment(endDate).endOf('week');
        let weekStartDates = [];

        while (start.isBefore(end) || start.isSame(end, 'day')) {
            let currentDate = start.clone();
            let after7day = currentDate.clone().endOf('week');
            let date = start.format('YYYY-MM-DD');

            weekStartDates.push({
                chartDate: `${start.format('DD/MM')} - ${after7day.format('DD/MM')}`,
                actualDate: start.format('YYYY-MM-DD'),
                fullDateRange: `${start.format('DD/MM/YYYY')} - ${after7day.format('DD/MM/YYYY')}`,
                isExist: startDates?.includes(date)
            });
            start.add(1, 'week');
        }

        return weekStartDates;
    }

    const getRangeOfDates = (startDate) => {
        let momentDate = moment(startDate);
        let currentDate = momentDate.clone();
        let after7day = currentDate.clone().add(1, 'week');

        return `${currentDate.format('DD/MM/YYYY')} - ${after7day.format('DD/MM/YYYY')}`
    }

    const getMonthLableDate = (startDates, startDate, endDate) => {
        let start = moment(startDate).startOf('month');
        let end = moment(endDate).endOf('month');
        let monthStartDates = [];

        while (start.isBefore(end) || start.isSame(end, 'day')) {
            let date = start.format('YYYY-MM-DD');

            monthStartDates.push({
                chartDate: `${getApplyDateTime(start, "MMM YYYY")}`,
                actualDate: date,
                fullDateRange: `${getApplyDateTime(start, "MMM YYYY")}`,
                isExist: startDates?.includes(date)
            });
            start.add(1, 'month');
        }
        return monthStartDates;
    }

    const getmembersTimeTrackingReportData = async () => {
        try {
            let startDate = convertDateFromFormat(new Date(queryParams?.startDate));
            let endDate = convertDateFromFormat(new Date(queryParams?.endDate));
            let body = {
                chartType: "bar",
                project_id: selectedProject,
                startDate: startDate,
                endDate: endDate,
                duration: duration
            }
            let response = await axiosInstance.post(API.getTaskChartData, body)
            if (response?.status === 200) {
                let chartDetails = response?.data?.taskChartData ?? {};

                if (Object.keys(chartDetails)?.length) {
                    let allValues = Object.values(chartDetails)?.flat(Infinity);
                    let totalDuration = allValues?.reduce((total, currentData) => {
                        total += currentData?.duration ?? 0;
                        return total;
                    }, 0);
                    setTotalDuration(totalDuration)
                } else {
                    setTotalDuration(0)
                }

                if (duration === 'daily') {
                    if (Object.keys(chartDetails)?.length) {
                        let chartDetails = response?.data?.taskChartData ?? [];
                        let allValues = Object.values(chartDetails)?.flat(Infinity);
                        const groupedByUser = allValues?.reduce((addAllUserData, currentData) => {
                            const userName = currentData.User?.name;

                            if (!addAllUserData[userName]) {
                                addAllUserData[userName] = [];
                            }

                            addAllUserData[userName].push(currentData);

                            return addAllUserData;
                        }, {});
                        let allDates = getDatesBetween(Object.keys(chartDetails), startDate, endDate)
                        setOptions(prev => ({
                            ...prev,
                            type: 'datetime',
                            xaxis: {
                                ...prev.xaxis,
                                categories: allDates?.map(dateData => dateData?.chartDate)
                            }
                        }))

                        let seriesData = [];
                        Object.keys(groupedByUser)?.map((key) => {
                            let userSeriesData = {
                                name: titleCase(key),
                                type: 'bar',
                            }
                            let currentUserTaskTimeOfAllDates = [];
                            let currentUserTaskDurationOfAllDates = [];

                            let userTaskData = groupedByUser[key];
                            allDates?.map(dateData => {
                                if (dateData?.isExist) {
                                    let currentUserTasks = chartDetails[dateData?.actualDate]?.filter(taskDetail => userTaskData?.find(userTaskDetails => userTaskDetails?.id === taskDetail?.id))
                                    let currentDateData = [...currentUserTasks];

                                    if (currentDateData?.length) {
                                        let totaltaskData = currentDateData?.reduce((totalTimeData, currentTaskDetails) => {
                                            totalTimeData += currentTaskDetails?.duration;
                                            return totalTimeData;
                                        }, 0)
                                        currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: ((totaltaskData) / 3600) ?? null, userTaskData: currentDateData, seconds: totaltaskData, actualDate: dateData?.actualDate, fullDateRange: dateData?.actualDate })
                                        currentUserTaskDurationOfAllDates.push(totaltaskData ?? 0)
                                    } else {
                                        currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: null, userTaskData: null, seconds: null, actualDate: dateData?.actualDate, fullDateRange: dateData?.actualDate })
                                        currentUserTaskDurationOfAllDates.push(null)
                                    }
                                } else {
                                    currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: null, userTaskData: null, seconds: null, actualDate: dateData?.actualDate, fullDateRange: dateData?.actualDate })
                                    currentUserTaskDurationOfAllDates.push(null)
                                }
                                return null;
                            })
                            userSeriesData['data'] = currentUserTaskTimeOfAllDates;
                            userSeriesData['customData'] = currentUserTaskDurationOfAllDates;
                            seriesData.push(userSeriesData);
                            return null
                        })
                        setSeries(seriesData);
                    } else {
                        setSeries([])
                    }
                }
                else if (duration === 'weekly') {
                    if (Object.keys(chartDetails)?.length) {
                        let chartDetails = response?.data?.taskChartData ?? [];
                        let allValues = Object.values(chartDetails)?.flat(Infinity);
                        const groupedByUser = allValues?.reduce((addAllUserData, currentData) => {
                            const userName = currentData.User?.name;

                            if (!addAllUserData[userName]) {
                                addAllUserData[userName] = [];
                            }

                            addAllUserData[userName].push(currentData);

                            return addAllUserData;
                        }, {});
                        let allDates = getLableDate(Object.keys(chartDetails), startDate, endDate)
                        setOptions(prev => ({
                            ...prev,
                            type: 'datetime',
                            xaxis: {
                                ...prev.xaxis,
                                categories: allDates?.map(dateData => dateData?.chartDate)
                            }
                        }))

                        let seriesData = [];
                        Object.keys(groupedByUser)?.map((key) => {
                            let userSeriesData = {
                                name: titleCase(key),
                                type: 'bar',
                            }
                            let currentUserTaskTimeOfAllDates = [];
                            let currentUserTaskDurationOfAllDates = [];

                            let userTaskData = groupedByUser[key];

                            allDates?.map(dateData => {
                                if (dateData?.isExist) {
                                    let currentUserTasks = chartDetails[dateData?.actualDate]?.filter(taskDetail => userTaskData?.find(userTaskDetails => userTaskDetails?.id === taskDetail?.id))
                                    let currentDateData = [...currentUserTasks];

                                    if (currentDateData?.length) {
                                        let totaltaskData = currentDateData?.reduce((totalTimeData, currentTaskDetails) => {
                                            totalTimeData += currentTaskDetails?.duration;
                                            return totalTimeData;
                                        }, 0)
                                        currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: ((totaltaskData) / 3600) ?? null, userTaskData: currentDateData, seconds: totaltaskData, actualDate: dateData?.actualDate, fullDateRange: getRangeOfDates(dateData?.actualDate) })
                                        currentUserTaskDurationOfAllDates.push(totaltaskData ?? 0)
                                    } else {
                                        currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: null, userTaskData: null, seconds: null, actualDate: dateData?.actualDate, fullDateRange: getRangeOfDates(dateData?.actualDate) })
                                        currentUserTaskDurationOfAllDates.push(null)
                                    }
                                } else {
                                    currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: null, userTaskData: null, seconds: null, actualDate: dateData?.actualDate, fullDateRange: getRangeOfDates(dateData?.actualDate) })
                                    currentUserTaskDurationOfAllDates.push(null)
                                }
                                return null;
                            })
                            userSeriesData['data'] = currentUserTaskTimeOfAllDates;
                            userSeriesData['customData'] = currentUserTaskDurationOfAllDates;
                            seriesData.push(userSeriesData);
                            return null
                        })
                        setSeries(seriesData);
                    } else {
                        setSeries([])
                    }
                }
                else if ('monthly') {
                    if (Object.keys(chartDetails)?.length) {
                        let chartDetails = response?.data?.taskChartData ?? [];
                        let allValues = Object.values(chartDetails)?.flat(Infinity);
                        const groupedByUser = allValues?.reduce((addAllUserData, currentData) => {
                            const userName = currentData.User?.name;

                            if (!addAllUserData[userName]) {
                                addAllUserData[userName] = [];
                            }

                            addAllUserData[userName].push(currentData);

                            return addAllUserData;
                        }, {});
                        let allDates = getMonthLableDate(Object.keys(chartDetails), startDate, endDate);
                        setOptions(prev => ({
                            ...prev,
                            type: 'datetime',
                            xaxis: {
                                ...prev.xaxis,
                                categories: allDates?.map(dateData => dateData?.chartDate)
                            }
                        }))

                        let seriesData = [];
                        Object.keys(groupedByUser)?.map((key) => {
                            let userSeriesData = {
                                name: titleCase(key),
                                type: 'bar',
                            }
                            let currentUserTaskTimeOfAllDates = [];
                            let currentUserTaskDurationOfAllDates = [];

                            let userTaskData = groupedByUser[key];

                            allDates?.map(dateData => {
                                if (dateData?.isExist) {
                                    let currentUserTasks = chartDetails[dateData?.actualDate]?.filter(taskDetail => userTaskData?.find(userTaskDetails => userTaskDetails?.id === taskDetail?.id))
                                    let currentDateData = [...currentUserTasks];

                                    if (currentDateData?.length) {
                                        let totaltaskData = currentDateData?.reduce((totalTimeData, currentTaskDetails) => {
                                            totalTimeData += currentTaskDetails?.duration;
                                            return totalTimeData;
                                        }, 0)
                                        currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: ((totaltaskData) / 3600) ?? null, userTaskData: currentDateData, seconds: totaltaskData, actualDate: dateData?.actualDate, fullDateRange: dateData?.fullDateRange })
                                        currentUserTaskDurationOfAllDates.push(totaltaskData ?? 0)
                                    } else {
                                        currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: null, userTaskData: null, seconds: null, actualDate: dateData?.actualDate, fullDateRange: dateData?.fullDateRange })
                                        currentUserTaskDurationOfAllDates.push(null)
                                    }
                                } else {
                                    currentUserTaskTimeOfAllDates.push({ x: dateData?.chartDate, y: null, userTaskData: null, seconds: null, actualDate: dateData?.actualDate, fullDateRange: dateData?.fullDateRange })
                                    currentUserTaskDurationOfAllDates.push(null)
                                }
                                return null;
                            })
                            userSeriesData['data'] = currentUserTaskTimeOfAllDates;
                            userSeriesData['customData'] = currentUserTaskDurationOfAllDates;
                            seriesData.push(userSeriesData);
                            return null
                        })
                        setSeries(seriesData);
                    } else {
                        setSeries([])
                    }
                }
            }
            setIsLoading((prev) => ({ ...prev, report: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, report: false }));
            setTotalDuration(0);
            setSeries([]);
        }
    }

    const exportChartData = async () => {
        try {
            setIsLoading(prev => ({ ...prev, exportChartLoading: true }))
            let startDate = convertDateFromFormat(new Date(queryParams?.startDate));
            let endDate = convertDateFromFormat(new Date(queryParams?.endDate));
            let body = {
                chartType: "bar",
                project_id: selectedProject,
                startDate: startDate,
                endDate: endDate,
                duration: duration
            };
            const response = await axiosInstance.post(API.getExportRepotParams, body);

            if (response?.status === 200) {
                if (response?.data) {
                    let base64Input = Object.values(response.data).join("");
                    var selectedMimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                    const decodedData = atob(base64Input);
                    const byteArray = new Uint8Array(decodedData?.length);
                    for (let i = 0; i < decodedData.length; i++) {
                        byteArray[i] = decodedData.charCodeAt(i);
                    }

                    const blob = new Blob([byteArray], { type: selectedMimeType });
                    // await FileSaver.saveAs(blob, 'membersTrackingData.xlsx');
                    await FileSaver.saveAs(blob, generateFileName('Project_User_Time_Daily'));
                }
            }
            setIsLoading(prev => ({ ...prev, exportChartLoading: false }))
        } catch (error) {
            console.error("Error downloading file", error);
            setIsLoading(prev => ({ ...prev, exportChartLoading: false }))
        }
    };

    return (
        isProjectLoading ?
            <CircularLoader height='500px' /> :
            <Box flex={1} overflow={"visible"} display={"flex"} mt={4}>
                <Box display={"flex"} flexDirection={"column"} sx={{ width: '100%', flex: '1', overflow: 'visible' }} gap={3}>
                    <Box display={'flex'} alignItems={"center"} justifyContent={'space-between'}>
                        <Box display="flex" gap={2}>
                            <Grid className='report-date-range-picker' item width={265} maxWidth={265} sx={{ '.MuiTextField-root': { width: '100% !important' } }}>
                                <MenuChartDateRangePicker
                                    dateRange={queryParams?.dateRange}
                                    onChange={(dateRange) => handleChangeDatePicker(dateRange)}
                                    maxDate={new Date()}
                                    width={370}
                                />
                            </Grid>
                            <Grid item sx={{ width: { xs: 180, lg: 264 } }}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="group_label">Group By</InputLabel>
                                    <Select
                                        inputProps={{ id: "duration" }}
                                        id="duration"
                                        value={duration || ""}
                                        onChange={(event, ...rest) => {
                                            setDuration(event?.target?.value)
                                        }}
                                        name="duration"
                                        label="Group By"
                                        sx={{
                                            ".MuiSelect-select": {
                                                fontSize: 14,
                                                fontWeight: 400,
                                                color: "dark.800",
                                            },
                                        }}
                                    >
                                        {Object.entries(PROJECT_REPORTS_FILTER)?.map((item, index) => (
                                            <MenuItem key={index} value={item[0]}>
                                                {titleCase(item[1])}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ alignContent: 'center' }}>
                                <Button
                                    sx={{ color: "white", textTransform: 'none', boxShadow: 'none' }}
                                    onClick={handleSearch}
                                    disabled={!selectedProject || isLoading?.report}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Box>
                        {series?.length ? <Button
                            variant="outlined"
                            color="secondary"
                            size="medium"
                            // startIcon={<ExpandIcon />}
                            sx={{ color: "secondary.main", boxShadow: "none", border: '1px solid #D4D9DE', textTransform: "none", display: "flex", alignItems: "center", 'svg': { display: 'block', } }}
                            onClick={() => exportChartData(prev => !prev)}
                            disabled={!selectedProject || isLoading?.exportChartLoading}
                        >
                            <i style={{ width: 17, height: 12, minWidth: 17, marginRight: '8px' }}>
                                {ICONS.ExportFileIcon}
                            </i>
                            Export
                        </Button> : null}
                    </Box>

                    {series?.length ?
                        <Box border={'1px solid #E0E0E0'} borderRadius={'3px'} sx={{ padding: '24px' }}>
                            <Box sx={{ paddingLeft: '17px' }} display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"} mb={3.5}>
                                <Box>
                                    <Typography
                                        variant="span"
                                        color="dark.800"
                                        fontSize={14}
                                        fontWeight={400}
                                        lineHeight={'20px'}
                                        letterSpacing={'0.17px'}
                                    >
                                        <b>{titleCase(selectedProjectData?.title)}</b> Project Report for
                                        <b>{' '}{convertDateFromFormat(new Date(queryParams?.startDate)) === convertDateFromFormat(new Date(queryParams?.endDate))
                                            ? getFormattedDate(convertDateFromFormat(new Date(queryParams?.startDate)))
                                            : `${getFormattedDate(convertDateFromFormat(new Date(queryParams?.startDate)))} - ${getFormattedDate(convertDateFromFormat(new Date(queryParams?.endDate)))}`}</b>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="span"
                                        color="dark.800"
                                        fontSize={14}
                                        fontWeight={400}
                                        lineHeight={'20px'}
                                        letterSpacing={'0.17px'}
                                    // >Total Hours <b>120h 35m</b></Typography>
                                    >Total Hours <b>{formatDuration(totalDuration)}</b></Typography>
                                </Box>
                            </Box>

                            <ReactApexChart
                                options={options}
                                series={series}
                                type="bar"
                                height={350}
                            />
                        </Box>
                        :
                        <Box bgcolor={"#FAFBFF"} display={"flex"} flexDirection={"column"} py={"60px"} px={6} alignItems={"center"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }}>
                            {selectedProject ? <Typography
                                variant="body1"
                                color="dark.800"
                                fontSize={14}
                                fontWeight={400}
                                mb={2.5}
                            >
                                No data available
                            </Typography> :
                                <Typography
                                    variant="body1"
                                    color="dark.800"
                                    fontSize={14}
                                    fontWeight={400}
                                    mb={2.5}
                                >
                                    Select <b style={{ fontWeight: 700 }}>Project</b> to see their Project details
                                </Typography>}
                            <Box>
                                <img alt='404 page' src={reportImg} style={{ maxWidth: '100%', width: '420px' }} />
                            </Box>
                        </Box>}
                </Box>
                <BootstrapDialog
                    open={modalDetails?.isShow}
                    onClose={() => handleModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        // alignItems: 'flex-start',
                        '.MuiDialog-paper': {
                            maxWidth: '100%',
                            width: '1000px',
                            ' > div': {
                                overflow: 'hidden',
                                display: 'flex',
                                '> div': {
                                    display: 'flex',
                                }
                            },
                        },
                        // '.MuiDialog-container': { alignItems: 'flex-start' }
                    }}
                >
                    <DialogContent sx={{ width: '100%' }}>
                        <Box sx={{ width: '100%' }}>
                            <MemberTimeTableData duration={duration} modalDetails={modalDetails}
                                handleModalClose={handleModalClose}
                                selectedProjectData={selectedProjectData} />
                        </Box>
                    </DialogContent>
                </BootstrapDialog>
            </Box>
    )
}

export default MembersTimeTracking