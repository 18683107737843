import DashboardSidebarSettings from "./dashboard";
import ProjectsSidebarSettings from "./projects";
import RequestSidebarSettings from "./requests";
import HolidaySidebarSettings from "./holidays";
import CRMSidebarSettings from "./crm";
import ReportsSidebarSettings from "./reports";
import UsersSidebarSettings from "./users";
import AdministratorSidebarSettings from "./administrator";

const SIDEBAR_SETTINGS = [
    ...DashboardSidebarSettings,
    ...ProjectsSidebarSettings,
    ...RequestSidebarSettings,
    ...HolidaySidebarSettings,
    ...UsersSidebarSettings,
    ...ReportsSidebarSettings,
    ...CRMSidebarSettings,
    ...AdministratorSidebarSettings,
];

export default SIDEBAR_SETTINGS;
