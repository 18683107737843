import URLS from "../routes/urls";
import CampaignIcon from '@mui/icons-material/Campaign';

const CRMSidebarSettings = [
    {
        name: "CRM",
        icon: <CampaignIcon />,
        subMenu: [
            {
                name: "Transactions",
                href: URLS.EmailTransactions,
            },
            {
                name: "Email Templates",
                href: URLS.EmailTemplates,
            },
            {
                name: "Contacts List",
                href: URLS.CampaignsContactList,
            },
        ],
    },
];

export default CRMSidebarSettings;
