import URLS from "../routes/urls";
import ICONS from "../constants/icons";

const HolidaySidebarSettings = [
    {
        name: "Holidays",
        icon: ICONS.Holidays,
        href: URLS.Holidays,
    },
];

export default HolidaySidebarSettings;
