import React, { useState, useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";

const TimeInput = ({ value = "", onChange, onBlur, disabled = false }) => {
    const [timeInput, setTimeInput] = useState(value);
    const [inputError, setInputError] = useState(false);

    const validTimePattern = /^\s*(\d+)\s*[hH](?:\s*(\d+)\s*[mM])?\s*$|^\s*(\d+)\s*[mM]\s*$/;

    const isValidTimeInput = (input) => validTimePattern.test(input.trim());

    const parseTimeInput = (input) => {
        if (!validTimePattern.test(input)) return { hours: 0, minutes: 0 }; // Reject invalid cases

        const normalizedInput = input.replace(/\s+/g, " ").trim(); // Normalize spaces
        const timePattern = /^(\d+)\s*[hH](?:\s*(\d+)\s*[mM])?$|^(\d+)\s*[mM]$/;
        const match = timePattern.exec(normalizedInput);

        const hours = match?.[1] ? parseInt(match[1], 10) : 0;
        const minutes = match?.[2] ? parseInt(match[2], 10) : match?.[3] ? parseInt(match[3], 10) : 0;

        return { hours, minutes };
    };

    const normalizeTime = ({ hours, minutes }) => {
        const extraHours = Math.floor(minutes / 60);
        const normalizedMinutes = minutes % 60;
        return {
            hours: hours + extraHours,
            minutes: normalizedMinutes,
        };
    };

    const handleTimeChange = (e) => {
        const input = e.target.value;

        setTimeInput(input);

        if (isValidTimeInput(input)) {
            setInputError(false);

            const parsedTime = parseTimeInput(input);
            const normalized = normalizeTime(parsedTime);

            if (onChange) {
                onChange(`${normalized.hours ? `${normalized.hours}h` : ""} ${normalized.minutes ? `${normalized.minutes}m` : ""}`.trim(), inputError);
            }
        }
    };

    const handleBlurTime = (e) => {
        const input = e?.target?.value.trim();
        if (isValidTimeInput(input)) {
            const parsedTime = parseTimeInput(input);
            if (parsedTime.hours === 0 && parsedTime.minutes === 0) {
                setInputError(true);
                onBlur?.(input, true);
                return;
            }

            const normalized = normalizeTime(parsedTime);
            const formattedTime = `${normalized.hours ? `${normalized.hours}h` : ""} ${normalized.minutes ? `${normalized.minutes}m` : ""}`.trim();

            setTimeInput(formattedTime);
            onBlur?.(formattedTime, false);
        } else {
            setInputError(true);
            onBlur?.(input, true);
        }
    };


    useEffect(() => {
        setTimeInput(value);
    }, [value]);

    return (
        <Box sx={{ position: 'relative', marginBottom: inputError ? '30px' : '15px', cursor: 'pointer' }}>
            <TextField
                variant="standard"
                size="medium"
                fullWidth
                value={timeInput}
                onChange={handleTimeChange}
                onBlur={handleBlurTime}
                placeholder="0h"
                disabled={disabled}
                InputProps={{
                    disableUnderline: true,
                    sx: {
                        padding: '4px 4px 3px 4px',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        // border: '1px solid #ccc',
                        borderBottom: '2px solid transparent',
                        bgcolor: '#fff',
                        '&:hover': {
                            bgcolor: '#F4F4F4',
                        },
                        '&:focus-within': {
                            bgcolor: '#fff',
                            borderBottom: '2px solid #1976d2',
                            borderRadius: '0px',
                            fontWeight: '400'
                        },
                        fontSize: '14px',
                        fontWeight: '600'
                    },
                }}
                sx={{
                    height: 'auto',
                    paddingLeft: '6px',
                }}
                error={inputError}
            />
            {inputError ? (
                <Box
                    sx={{
                        color: 'red',
                        fontSize: '12px',
                        position: 'absolute',
                        top: '100%',
                        marginTop: '4px',
                        paddingLeft: '6px',
                    }}
                    className="error-box"
                    width={270}
                >
                    <Typography fontSize={14} color={"red.main"}>
                        Invalid format! Use '1h', '1h 15m', or '5m'.
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};

export default TimeInput;








