/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import URLS from '../../../../routes/urls';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../../hook/useTitle';
import { sortArrayByKey, titleCase } from '../../../../utils';
import API from '../../../../axios/api';
import axiosInstance from '../../../../axios';
import useAuthentication from '../../../../hook/useAuthentication';
import { toast } from 'react-toastify';
import CircularLoader from '../../../../components/CircularLoader';
import ICONS from '../../../../constants/icons';
import TablePagination from '../../../../components/TablePagination';
import { setAllActivityData } from '../../../../redux/slices/activityActionDataSlice';
import { useDispatch } from 'react-redux';

const AdministratorActivity = () => {
  const navigate = useNavigate();
  const { getCurrentUser } = useAuthentication();
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const [allActivity, setAllActivity] = useState([]);
  const [optionalAllActivity, setOptionalAllActivity] = useState([]);
  const [workTypeData, setWorkTypeData] = useState([]);
  const [loading, setLoading] = useState({
    pageLoading: true,
    workTypeLoading: true,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const open = Boolean(anchorEl);

  const { setPageTitle } = usePageTitle();
  setPageTitle("Activity");

  useEffect(() => {
    getDataAccordingPagination(optionalAllActivity);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (currentUser?.organization_id) {
      getAllActivityData();
      getWorkTypesByOrganizationIdData();
    }
  }, [])

  const getDataAccordingPagination = (optionalActivityData) => {
    if (optionalActivityData) {
      let dataToPaginate = [...optionalActivityData];

      if (dataToPaginate && dataToPaginate.length) {
        const startIdx = page * rowsPerPage;
        const endIdx = Math.min(startIdx + rowsPerPage, dataToPaginate?.length);
        const updatedPageData = dataToPaginate.slice(startIdx, endIdx);
        setAllActivity(updatedPageData);
      } else {
        setAllActivity([]);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllActivityData = async () => {
    try {
      setLoading((prev) => ({ ...prev, pageLoading: true }))
      const response = await axiosInstance.get(
        API.getAllActivity(currentUser?.organization_id)
      );
      if (response.status === 200) {
        if (response?.data?.data?.length) {
          let sortedActivity = sortArrayByKey(response?.data?.data, 'asc', 'title')
          getDataAccordingPagination(sortedActivity);
          let getCurrentWorkTypeActivity = sortedActivity?.filter(activityData => activityData?.org_work_type_ids?.includes(currentUser?.org_work_type_setting_id))
          dispatch(setAllActivityData(getCurrentWorkTypeActivity || []));
          setOptionalAllActivity(sortedActivity);
        } else {
          dispatch(setAllActivityData([]));
          setAllActivity(response?.data?.data);
        }
      } else {
        toast.error(response?.data?.message);
        setAllActivity([]);
      }
      setLoading((prev) => ({ ...prev, pageLoading: false }))
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }))
    }
  };

  const getWorkTypesByOrganizationIdData = async () => {
    try {
      setLoading((prev) => ({ ...prev, workTypeLoading: true }))
      const response = await axiosInstance.get(
        API.getWorkTypesByOrganisationId(currentUser?.organization_id)
      );
      if (response.status === 200) {
        setWorkTypeData(response?.data?.data)
      }
      setLoading((prev) => ({ ...prev, workTypeLoading: false }))
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, workTypeLoading: false }))
    }
  };

  const handleClickActivity = (id) => {
    navigate(`${URLS.AdministratorUpdateActivity}/${id}`)
  }

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index)
  };


  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null)
  };

  const getWorkTypeNameById = (idArray) => {
    return workTypeData.filter((workTypeDetail) => idArray.includes(workTypeDetail?.id))?.map((data) => data?.name)?.join(', ')
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ width: "100%" }}
      p={{ xs: 2, lg: 3, xl: 4 }}
      py={{ xs: 3, lg: 3, xl: 4 }}
      overflow="auto"
    >
      <Grid container mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Grid item display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
          <Typography color={"dark.800"} fontSize={16} fontWeight={400} lineHeight={"22px"} letterSpacing={"0.17px"}>
            {allActivity?.length ? allActivity?.length : null}
            {allActivity?.length ? allActivity?.length > 1 ? ' Activities' : ' Activity' : null}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", boxShadow: "none", color: 'white' }}
            onClick={() => navigate(URLS.AdministratorCreateActivity)}
          >
            Add Activity
          </Button>
        </Grid>
      </Grid>
      <Box>
        <Paper
          sx={{
            border: "1px solid #E0E0E0",
            borderRadius: "3px",
            boxShadow: 'none',
          }}>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  th: {
                    color: "dark.800",
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: "24px",
                    letterSpacing: "0.17px",
                    padding: "5px 16px",
                    textAlign: "left",
                    background: "rgba(245, 248, 250, 1)",
                  },
                }}
              >
                <TableRow>
                  <TableCell>Activity Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Work Type</TableCell>
                  <TableCell sx={{ width: '90px', maxWidth: '90px', minWidth: '90px', textAlign: 'center !important' }} >Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "tr:hover": {
                    background: "rgba(247, 247, 247, 1)",
                  },
                  td: {
                    color: "dark.800",
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: "20px",
                    letterSpacing: "0.17px",
                    padding: "7px 16px",
                    textAlign: "left",
                    height: "45px",
                  },
                }}>
                {loading?.pageLoading || loading?.workTypeLoading ? (
                  <CircularLoader
                    variant="table"
                    rows={10}
                    cols={4}
                  />
                ) :
                  allActivity?.length ?
                    allActivity?.map((activityData, index) => (
                      <TableRow key={index}>
                        <TableCell>{activityData?.title}</TableCell>
                        <TableCell>{titleCase(activityData?.types)}</TableCell>
                        <TableCell>{getWorkTypeNameById(activityData?.org_work_type_ids)}</TableCell>
                        <TableCell sx={{ textAlign: 'center !important' }}>
                          <IconButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e) => handleClick(e, activityData?.id)}
                            sx={{
                              height: "30px",
                              width: "30px",
                              padding: "0px",
                              borderRadius: "4px",
                            }}
                          >
                            <i
                              style={{
                                height: "20px",
                                width: "20px",
                                display: "flex",
                              }}
                            >
                              {ICONS.DotsHorizontal}
                            </i>
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open && openMenuIndex === activityData?.id}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={() => handleClickActivity(activityData?.id)}>Edit</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))
                    : <TableRow
                      style={{
                        height: 50,
                      }}
                    >
                      <TableCell colSpan={4}>
                        <Typography fontSize={'15px'} fontWeight={500} sx={{ textAlign: 'center' }}>No Activity Available</Typography>
                      </TableCell>
                    </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={optionalAllActivity?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default AdministratorActivity