import URLS from "../routes/urls";
import ICONS from "../constants/icons";

const UsersSidebarSettings = [
    {
        name: "Users",
        icon: ICONS.Users,
        href: URLS.Users,
    }
];

export default UsersSidebarSettings;
