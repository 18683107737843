/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Card,
  Container,
  Grid,
  TableSortLabel,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../../components/Button";
import URLS from "../../../routes/urls.js";
import { useNavigate } from "react-router-dom";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import Switch from "../../../components/Switch";
import SearchBoxInput from "../../../components/SearchBoxInput/index.js";
import { changeOrganisationStatus } from "../../../axios/service.js";
import { useAlert } from "../../../hook/useAlert";
import Highlighter from "../../../components/Highlighter/index.js";
import CircularLoader from "../../../components/CircularLoader/index.js";
import { getInputId, sortArrayBaseOnRole, titleCase } from "../../../utils/index.js";
import { usePageTitle } from "../../../hook/useTitle.js";
import TablePagination from "../../../components/TablePagination/index.jsx";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  const { setPageTitle } = usePageTitle();
  const [organizationList, setOrganizationList] = useState([]);
  const [totalOrganisationCount, setTotalOrganisationCount] = useState(0);
  const [isReadMoreIndex, setIsReadMoreIndex] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 0,
    pageSize: 10,
    sort: "asc",
    searchString: "",
  });
  const [loading, setLoading] = useState({
    pageLoading: true,
  })

  setPageTitle("Dashboard");

  const getAllOrganizationData = async () => {
    try {
      // setLoading((prev) => ({ ...prev, pageLoading: true }));
      let queryParamsPageIncrease = { ...queryParams };
      queryParamsPageIncrease.page += 1;
      const response = await axiosInstance.get(API.getAllOrganization, {
        params: queryParamsPageIncrease,
      });
      if (response.status === 200) {
        if (response.data?.data?.length) {
          setOrganizationList(response.data?.data);
          setTotalOrganisationCount(response?.data?.pagination?.recordsCount);
        } else {
          setOrganizationList([]);
        }
      }
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  useEffect(() => {
    getAllOrganizationData();
  }, [queryParams]);

  const handleOnChangeActiveStatus = async (e, index, orgId) => {
    let statusValue = e?.target?.checked;
    const response = await changeOrganisationStatus(orgId, statusValue);
    if (response.status === 200) {
      const updatedOrganizationList = [...organizationList];
      updatedOrganizationList[index].active = statusValue;
      setOrganizationList(updatedOrganizationList);
      showAlert(response?.data?.message, "success");
      getAllOrganizationData();
    } else {
      showAlert("something went wrong", "error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setQueryParams((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    }));
  };

  const handleSortByName = () => {
    setQueryParams((prev) => ({
      ...prev,
      sort: prev.sort === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearch = (value) => {
    setQueryParams((prev) => ({
      ...prev,
      searchString: value,
    }));
  };

  const handleShowMoreEmail = (index) => {
    if (isReadMoreIndex?.includes(index)) {
      setIsReadMoreIndex((prev) =>
        prev.filter((selected) => selected !== index)
      );
    } else {
      setIsReadMoreIndex((prev) => [...prev, index]);
    }
  };

  return (
    <Box sx={{ marginTop: "-190px" }} position="relative" zIndex={1}>
      <Container>
        <Typography variant="h6" color="white" marginBottom="12px">
          Organisations
        </Typography>
        <Card sx={{ bgcolor: "bgColor", boxShadow: '0px 31px 95px rgba(0, 0, 0, 0.12)' }}>
          <Box>
            <Box p={3.5}>
              <Grid
                justifyContent="space-between"
                alignItems="center"
                display="flex"
              >
                <Grid item>
                  <SearchBoxInput
                    label="Search"
                    variant="standard"
                    placeholder="a Name, Email or Organisation"
                    handleSearch={handleSearch}
                    value={queryParams?.searchString}
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    size="small"
                    sx={{
                      boxShadow: 0,
                      color: "white",
                      "&:hover": { boxShadow: 0 },
                      height: "36px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => navigate(URLS.AddOrganisation)}
                  >
                    Add organisation
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: 0 }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ bgcolor: "bgColor", padding: "8px 16px" }}
                      >
                        <TableSortLabel
                          active={true}
                          direction={queryParams.sort}
                          onClick={() => { organizationList?.length && handleSortByName() }}
                        >
                          Organisation name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "bgColor", padding: "8px 16px" }}
                      >
                        Admins
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "bgColor", padding: "8px 16px" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "bgColor", padding: "8px 16px" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* <TableRowSkeleton rows={queryParams.pageSize} cols={4} />  */}
                    {loading.pageLoading ?
                      <CircularLoader variant="table" rows={10} cols={4} />
                      :
                      organizationList?.length ? (
                        organizationList?.map((organizeData, index) => {
                          return (
                            <React.Fragment key={index}>
                              <TableRow
                                hover
                                sx={{
                                  "&:hover": {
                                    ".edit-btn": {
                                      opacity: "1",
                                      visibility: "visible",
                                    },
                                  },
                                }}
                                key={index}
                              >
                                <TableCell sx={{ padding: "12px 16px;" }}>
                                  <Typography
                                    variant="caption"
                                    mr={1}
                                    color="dark.800"
                                    fontSize={13}
                                    sx={{
                                      textTransform: "capitalize",
                                      fontWeight: "500",
                                      display: "inline-block",
                                    }}
                                  >
                                    <Highlighter
                                      value={titleCase(organizeData?.name)}
                                      term={queryParams?.searchString}
                                      highlightClassName={"highlighted"}
                                    />
                                  </Typography>
                                  {!organizeData?.is_invite_accepted ? (
                                    <Typography
                                      color="dark.800"
                                      fontSize={12}
                                      lineHeight="18px"
                                      sx={{
                                        display: "inline-block",
                                        background: "#EBEBEB",
                                        padding: "2px 6px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      Invited
                                    </Typography>
                                  ) : null}
                                </TableCell>
                                <TableCell sx={{ padding: "12px 16px;" }}>
                                  <Grid item>
                                    {/* <Grid item sx={{display: 'inline-block'}}> */}
                                    <Grid
                                      item
                                      sx={
                                        isReadMoreIndex?.includes(index)
                                          ? { display: "block" }
                                          : { display: "inline-block" }
                                      }
                                    >
                                      {(queryParams?.searchString?.length > 0
                                        ? sortArrayBaseOnRole(organizeData?.Users) : isReadMoreIndex?.includes(index)
                                          ? sortArrayBaseOnRole(organizeData?.Users)
                                          : sortArrayBaseOnRole(
                                            organizeData?.Users
                                          ).slice(0, 1)
                                      )?.map((user, index2) => (
                                        <React.Fragment key={index + index2}>
                                          <Grid item>
                                            {user?.name ? (
                                              <Typography
                                                variant="caption"
                                                mr={0.5}
                                                sx={{
                                                  fontWeight: "500",
                                                  display: "inline-block",
                                                }}
                                                fontSize={13}
                                              >
                                                <Highlighter
                                                  value={titleCase(user?.name)}
                                                  term={queryParams?.searchString}
                                                  highlightClassName={"highlighted"}
                                                />
                                              </Typography>

                                            ) : null}

                                            <Typography
                                              variant="caption"
                                              color="dark.800"
                                              sx={{
                                                opacity: "1",
                                                fontWeight: "400",
                                                display: "inline-block",
                                              }}
                                              mr={0.7}
                                              fontSize={13}
                                            >
                                              (
                                              <Highlighter
                                                value={user?.email}
                                                term={queryParams?.searchString}
                                                highlightClassName={"highlighted"}
                                              />
                                              )
                                            </Typography>
                                          </Grid>
                                        </React.Fragment>
                                      ))}
                                    </Grid>
                                    {!queryParams?.searchString?.length > 0 && organizeData?.Users?.length > 1 ? (
                                      <Button
                                        variant="text"
                                        size="small"
                                        sx={{
                                          minWidth: "inherit",
                                          fontWeight: "500",
                                          display: "inline-block",
                                        }}
                                        onClick={() => handleShowMoreEmail(index)}
                                      >
                                        {isReadMoreIndex?.includes(index)
                                          ? "See Less"
                                          : `+${organizeData?.Users?.length - 1}`}
                                      </Button>
                                    ) : null}
                                  </Grid>
                                </TableCell>
                                <TableCell sx={{ padding: "12px;" }}>
                                  <Switch
                                    id={getInputId("status", organizeData?.id)}
                                    name="status"
                                    // defaultChecked
                                    size="small"
                                    checked={organizeData?.active || false}
                                    onChange={(e) =>
                                      handleOnChangeActiveStatus(
                                        e,
                                        index,
                                        organizeData?.id
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell sx={{ padding: "12px;" }}>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    sx={{
                                      opacity: "0",
                                      visibility: "hidden",
                                      transition: 1,
                                    }}
                                    className="edit-btn"
                                    onClick={() =>
                                      navigate({
                                        pathname: `${URLS.EditOrganisation}/${organizeData?.id}`,
                                      })
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            align="center"
                            sx={{ padding: "12px 16px;" }}
                          >
                            <Typography
                              color={"secondary"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              fontSize={13}
                              sx={{ opacity: "0.5", height: "236px" }}
                            >
                              No records found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalOrganisationCount}
                rowsPerPage={queryParams.pageSize}
                page={queryParams.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default AdminDashboard;
