import React, { useEffect, useState } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import {
    BoldButton,
    ItalicButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
    AlignTextLeftButton,
} from "@draft-js-plugins/buttons";
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Box, Typography } from "@mui/material";
import BackgroundLetterAvatars from "../BackgroundLetterAvatars";
import { titleCase } from "../../utils";
import "./menuTextEditor.css";
import mentionsStyles from './MentionsStyles.module.css';

const mentionPlugin = createMentionPlugin({
    mentionPrefix: "@",
    supportWhitespace: true,
    theme: mentionsStyles,
});
const { MentionSuggestions } = mentionPlugin;

const toolbarPlugin = createToolbarPlugin({
    theme: {
        toolbarStyles: {
            toolbar: "toolbar",
        },
        buttonStyles: {
            active: "toolbar-button-active",
            button: "toolbar-button",
            buttonWrapper: "toolbar-button-wrapper",
        },
    }
});
const { Toolbar } = toolbarPlugin;

const plugins = [mentionPlugin, toolbarPlugin];

const MentionTextEditor = ({ value = "", onChange, onBlur = () => { }, readOnly = false, placeholder = "Type here...", atMentions = [] }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [suggestions, setSuggestions] = useState(atMentions);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (value) {
            const blocksFromHtml = htmlToDraft(value);
            const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [value]);

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        const rawContentState = convertToRaw(newEditorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState, null, null, (entity) => {
            const entityType = entity?.type;
            if (entityType === "mention") {
                const mention = entity?.data?.mention;
                const mentionName = `@${entity?.data?.mention?.name}`;
                const stringHtml = `<a href="#${mention?.id}" data-id="${mention?.id}" data-name="${mention?.name}" data-email="${mention?.email}" class="mention-link">${mentionName}</a>`;
                return stringHtml;
            }
        });
        const mentions = Object.values(rawContentState?.entityMap).map((entity) => entity?.data?.mention);
        if (onChange) onChange({ html: htmlContent, mentions: mentions ?? [] });
    };

    return (
        <div className="editor-container task-comment-box">
            {!readOnly ? (
                <Toolbar>
                    {(externalProps) => (
                        <React.Fragment>
                            <BoldButton {...externalProps} />
                            <ItalicButton {...externalProps} />
                            <UnderlineButton {...externalProps} />
                            <AlignTextLeftButton {...externalProps} />
                            <UnorderedListButton {...externalProps} />
                            <OrderedListButton {...externalProps} />
                        </React.Fragment>
                    )}
                </Toolbar>
            ) : null}

            <Editor
                editorState={editorState}
                onChange={handleEditorChange}
                placeholder={placeholder}
                plugins={plugins}
            />

            <MentionSuggestions
                open={open}
                onOpenChange={setOpen}
                suggestions={suggestions}
                onSearchChange={({ value }) => {
                    setSuggestions(
                        atMentions.filter((mention) =>
                            mention.name.toLowerCase().includes(value.toLowerCase())
                        )
                    );
                }}
                entryComponent={({ mention, theme, isFocused, ...props }) => (
                    <div className={`mention-item ${isFocused ? "focused" : ""}`} {...props}>
                        <Box display={"flex"} alignItems={"center"} gap={1} overflow={"hidden"}>
                            <Box minWidth={32} maxWidth={32} width={32}>
                                <BackgroundLetterAvatars
                                    sx={{
                                        width: "32px",
                                        height: "32px",
                                        fontSize: "90%",
                                    }}
                                    user={mention}
                                />
                            </Box>
                            <Box overflow={"hidden"} className="mention-user-name">
                                <Typography
                                    color={"dark.800"}
                                    fontSize={15}
                                    fontWeight={500}
                                    lineHeight={"24px"}
                                    letterSpacing={"0.17px"}
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    overflow={"hidden"}
                                >
                                    {titleCase(mention?.name)}
                                </Typography>
                            </Box>
                        </Box>
                    </div>
                )}
            />
        </div>
    );
};

export default MentionTextEditor;