import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import { formatTimeAgo, secondsToTime, sortArrayByKey } from '../../../../utils';
import EmptyPage from '../../../../components/EmptyPage';

const TaskWorklog = ({ worklogData, usersList, taskData }) => {
    return (
        <Box>
            <Box mb={2}
                sx={{
                    '.empty-box': {
                        height: 150,
                    },
                }}
            >
                {worklogData?.length ?
                    sortArrayByKey(worklogData, "desc", "updatedAt")?.map((workLogDetails, index) => (
                        <React.Fragment key={index}>
                            <Box display={"flex"} gap={1} pb={2}>
                                <Box minWidth={34} width={34} height={34} p={"3px"}>
                                    <Box minWidth={28} width={28} height={28}>
                                        <BackgroundLetterAvatars alt="Remy Sharp"
                                            user={workLogDetails?.User}
                                            src={workLogDetails?.User?.profile_img}
                                            sx={{ width: 28, height: 28, fontSize: '80%', }} />
                                    </Box>
                                </Box>
                                <Box gap={"5px"} flex={1}>
                                    <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.17px"}><b>{workLogDetails?.User?.name}</b> logged <b>{secondsToTime(workLogDetails?.duration ?? 0)} hours</b></Typography>
                                    <Box color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.17px"}
                                        sx={{
                                            'ul': {
                                                'li': {
                                                    padding: "0px 0px 0px 15px",
                                                    listStyle: "disc",
                                                    display: "list-item",
                                                    listStylePosition: "inside",
                                                }
                                            },
                                            'ol': {
                                                'li': {
                                                    padding: "0px 0px 0px 15px",
                                                    display: "list-item",
                                                    listStylePosition: "inside",
                                                }
                                            },
                                        }}>
                                        <span dangerouslySetInnerHTML={{ __html: workLogDetails?.description }} />
                                    </Box>
                                    <Typography color={"rgba(0, 0, 0, 0.5)"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.17px"} mt={"5px"}>
                                        {formatTimeAgo(workLogDetails?.updatedAt, true)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider />
                        </React.Fragment>
                    ))
                    : <EmptyPage title="No worklog exist." isButtonShow={false} />
                }
            </Box>
        </Box>
    )
}

export default TaskWorklog