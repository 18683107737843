import ICONS from "../constants/icons";
import URLS from "../routes/urls";

const AdministratorSidebarSettings = [
    {
        name: "Administrator",
        icon: ICONS.Administrator,
        subMenu: [
            {
                name: "Organisation",
                href: URLS.AdministratorOrganisation,
            },
            {
                name: "Leaves",
                href: URLS.AdministratorLeaves,
            },
            {
                name: "Holidays",
                href: URLS.AdministratorHolidays,
            },
            {
                name: "Roles",
                href: URLS.AdministratorRoles,
            },
            {
                name: "Project Roles",
                href: URLS.AdministratorProjectRoles,
            },
            {
                name: "Activity",
                href: URLS.AdministratorActivity,
            },
            {
                name: "Work Type",
                href: URLS.AdministratorWorkType,
            },
        ],
    },
];

export default AdministratorSidebarSettings;
