import React, { useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import { Box, Divider, IconButton, Stack, Tooltip, tooltipClasses, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { styled } from '@mui/styles';
import { formatDuration, getApplyDateTime, getFormattedDate, getTaskStatus, getTaskTypeIcon, secondsToTime, sortArrayByKey, titleCase } from '../../../../utils';
import { TASK_TYPE_LIST } from '../../../../constants/default-values';
import moment from 'moment';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import { useAlert } from '../../../../hook/useAlert';
import useAuthentication from '../../../../hook/useAuthentication';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        padding: "8px 12px",
    },
}));

const WorkLogDetails = ({ queryParams, sidebarData, handleCloseDrawer, selectedProjectData }) => {
    const { getCurrentUser, } = useAuthentication();
    const currentUser = getCurrentUser();
    const showAlert = useAlert();
    const [worklogList, setWorklogList] = useState([]);
    const [isShowNextButton, setIsShowNextButton] = useState(true);
    const [isShowPreviousButton, setIsShowPreviousButton] = useState(true)
    const [isLoading, setIsLoading] = useState({
        worklogLoading: false,
        isPreviousLoading: false,
        isNextLoading: false
    })

    useEffect(() => {
        setWorklogList(sidebarData?.WorkLogs?.map(data => {
            return {
                ...data,
                isInRange: true
            }
        }) ?? [])
    }, [sidebarData?.WorkLogs])

    const isShowNext = () => {
        let todayDate = moment(new Date()).startOf("day");
        let selectedLastDay = moment(new Date(queryParams?.endDate)).startOf("day");
        return selectedLastDay.isBefore(todayDate);
    }

    const isShowPrevious = () => {
        let orgCreateDate = moment(new Date(currentUser?.organization?.createdAt)).startOf("day");
        let selectedStartDay = moment(new Date(queryParams?.startDate)).startOf("day");
        return orgCreateDate.isBefore(selectedStartDay);
    }

    const handleClickMoreWorkLog = async (clickedButton = "previous") => {
        try {
            setIsLoading(prev => ({ ...prev, worklogLoading: true, [clickedButton === "previous" ? "isPreviousLoading" : "isNextLoading"]: true }))
            let body = {
                "task_id": sidebarData?.id,
                "start_date": clickedButton === "previous" ? moment(sidebarData?.createdAt).format("YYYY-MM-DD") : moment(queryParams?.endDate).add(1, "day").format("YYYY-MM-DD"),
                "end_date": clickedButton === "previous" ? moment(queryParams?.startDate).subtract(1, "day").format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")
            }

            let response = await axiosInstance.post(API.getTaskWorklogData, body);
            if (response?.status === 200) {
                let workLogs = sortArrayByKey(response?.data?.workLogData, "asc", 'date');
                (clickedButton === "previous") ? setWorklogList(prev => [...workLogs, ...prev]) : setWorklogList(prev => [...prev, ...workLogs]);
                (clickedButton === "previous") ? setIsShowPreviousButton(false) : setIsShowNextButton(false)
            } else {
                showAlert("Something went wrong", "error")
            }
            setIsLoading(prev => ({ ...prev, worklogLoading: false, [clickedButton === "previous" ? "isPreviousLoading" : "isNextLoading"]: false }))
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, worklogLoading: false, [clickedButton === "previous" ? "isPreviousLoading" : "isNextLoading"]: false }))
            showAlert("Something went wrong", "error")
        }
    }

    const getWorkLogHours = () => {
        return sidebarData?.WorkLogs?.reduce((totalHours, currentData) => {
            totalHours += currentData?.duration ?? 0;
            return totalHours
        }, 0)
    }

    return (
        <Box width={582} overflow={"hidden"} display={"flex"} flexDirection={"column"}>
            <Stack gap={1} py={"14px"} px={2} borderBottom={"1px solid #E2E4EC"}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2} width={"100%"}>
                    <Box display={"flex"} gap={'4px'}>
                        <Typography color={"#333333"} fontSize={14} fontWeight={600} lineHeight={"17px"}>Worklogs of {selectedProjectData?.short_code}-{sidebarData?.task_no}</Typography>
                        <Typography color={"#333333"} fontSize={14} fontWeight={500} lineHeight={"17px"}>({selectedProjectData?.title})</Typography>
                    </Box>
                    <IconButton
                        size="small"
                        className="edit-btn"
                        onClick={() => handleCloseDrawer()}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
                <Box display={"flex"} gap={1}>
                    <HtmlTooltip arrow
                        title={
                            <React.Fragment>
                                <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"19px"}>{TASK_TYPE_LIST[sidebarData?.type]}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                            {getTaskTypeIcon(sidebarData?.type)}
                        </Box>
                    </HtmlTooltip>
                    <Typography color={"text"} fontSize={15} fontWeight={600} lineHeight={"22px"} pt={"2px"}>{selectedProjectData?.short_code}-{sidebarData?.task_no} - {sidebarData?.title}</Typography>
                </Box>
                <Stack gap={0.5}>
                    <Box display={"flex"} alignItems={"start"}>
                        <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"} p={1} gap={0.5}>
                            <Typography color={"text"} fontSize={13} fontWeight={500} lineHeight={"19px"}>Current Status</Typography>
                            <Box
                                sx={{
                                    ".MuiChip-root": {
                                        'margin': '0px',
                                        'span': {
                                            fontSize: 12,
                                            fontWeight: 400,
                                            lineHeight: '18px'
                                        }
                                    }
                                }}
                            >
                                {getTaskStatus(sidebarData?.status, "chip")}
                            </Box>
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"} p={1} gap={0.5}>
                            <Typography color={"text"} fontSize={13} fontWeight={500} lineHeight={"19px"}>Original Estimation</Typography>
                            <Typography color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={"24px"}>{formatDuration(sidebarData?.estimation ?? 0)?.length ? formatDuration(sidebarData?.estimation ?? 0) : "0h"}</Typography>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
            <Box flex={1} overflow={"auto"}>
                <Box py={"10px"} px={2} display={"flex"} alignItems={"center"} justifyContent={'space-between'}>
                    <Box borderLeft={"3px solid"} borderColor={"#047FE0"} paddingLeft={"10px"} display={"flex"} alignItems={"center"} gap={"10px"}>
                        <Typography color={"text"} fontSize={14} fontWeight={500} lineHeight={"21px"}>Duration</Typography>
                        {/* <Typography color={"text"} fontSize={14} fontWeight={700} lineHeight={"21px"}>01/07/2024 - 01/09/2024</Typography> */}
                        <Stack spacing={1} direction="row" flexWrap="wrap" alignItems={'center'}>
                            {queryParams?.dateRange?.map((date, index, array) => (
                                <React.Fragment key={index}>
                                    <Typography variant='span' color={"text"} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>{typeof date === "string" ? date : getFormattedDate(new Date(date), "DD/MM/YYYY", true)}</Typography>
                                    {array?.length !== 1 && index === 0 ? <Typography color={'text'} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>{" - "}</Typography> : null}
                                </React.Fragment>
                            ))}
                        </Stack>
                    </Box>
                    <Typography color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={"24px"}>{secondsToTime(getWorkLogHours() ?? 0)}</Typography>
                </Box>
                <Divider />
                <Stack p={2} gap={1}>
                    {(isShowPrevious() && isShowPreviousButton && sidebarData?.is_previous) ? <Button variant="outlined" startIcon={<ArrowUpwardIcon />}
                        sx={{
                            border: "1px solid #E2E4EC",
                            gap: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textTransform: "none",
                            fontWeight: 600,
                            fontSize: 13,
                            width: "100%",
                        }}
                        onClick={() => handleClickMoreWorkLog("previous")}
                        disabled={isLoading?.worklogLoading}
                        isButtonLoading={isLoading?.isPreviousLoading}
                    >
                        Previous Worklogs
                    </Button> : null}
                    <Box>
                        {
                            worklogList?.length ?
                                worklogList?.map((workLogDetails, index) => (
                                    <Box borderLeft={workLogDetails?.isInRange ? "3px solid #047FE0" : ""} key={index}>
                                        <Stack gap={1} p={2} bgcolor={workLogDetails?.isInRange ? "#F7F7F7" : "transparent"} borderBottom={"1px solid #E2E4EC"}>
                                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"22px"}><b>{getApplyDateTime(workLogDetails?.date, "DD")}</b> {getApplyDateTime(workLogDetails?.date, "MMMM, YYYY")}</Typography>
                                                <Box display={"flex"} width={"70px"} alignItems={"center"} gap={"6px"}>
                                                    {!workLogDetails?.is_eod ? <HtmlTooltip arrow placement="top"
                                                        title={
                                                            <React.Fragment>
                                                                <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"19px"}>Tracked</Typography>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <TimerOutlinedIcon fontSize='small' sx={{ color: "#2E7D32" }} />
                                                    </HtmlTooltip> : null}
                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={"24px"}>{secondsToTime(workLogDetails?.duration)}</Typography>
                                                </Box>
                                            </Box>
                                            <Box color={"dark"} fontSize={14} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.17px"}
                                                sx={{
                                                    'ul': {
                                                        'li': {
                                                            padding: "0px 0px 0px 7px",
                                                            listStyle: "disc",
                                                            display: "list-item",
                                                            listStylePosition: "inside",
                                                        }
                                                    },
                                                    'ol': {
                                                        'li': {
                                                            padding: "0px 0px 0px 7px",
                                                            display: "list-item",
                                                            listStylePosition: "inside",
                                                        }
                                                    },
                                                }}>
                                                <span dangerouslySetInnerHTML={{ __html: workLogDetails?.description }} />
                                            </Box>
                                            <Box display={"flex"} alignItems={"center"} gap={0.5}>
                                                <Typography color={"rgba(51, 51, 51, 0.7)"} fontSize={14} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.17px"}>By</Typography>
                                                <Typography color={"text"} fontSize={14} fontWeight={600} lineHeight={"24px"} letterSpacing={"0.17px"}>{titleCase(workLogDetails?.User?.name)}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                )) :
                                <Box display={'flex'} alignItems={'center'}>
                                    <Typography
                                        color={"secondary"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        fontSize={13}
                                        sx={{ opacity: "0.5", height: "200px" }}
                                    >
                                        No worklog found.
                                    </Typography>
                                </Box>
                        }
                    </Box>
                    {(isShowNext() && isShowNextButton && sidebarData?.is_next) ? <Button variant="outlined" startIcon={<ArrowDownwardIcon />}
                        sx={{
                            border: "1px solid #E2E4EC",
                            gap: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textTransform: "none",
                            fontWeight: 600,
                            fontSize: 13,
                            width: "100%",
                        }}
                        onClick={() => handleClickMoreWorkLog("next")}
                        disabled={isLoading?.worklogLoading}
                        isButtonLoading={isLoading?.isNextLoading}
                    >
                        Next Worklogs
                    </Button> : null}
                </Stack>
            </Box>
        </Box>
    )
}

export default WorkLogDetails