/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import API from "../../../../../axios/api";
import axiosInstance from "../../../../../axios";
import useAuthentication from "../../../../../hook/useAuthentication";
import CircularLoader from "../../../../../components/CircularLoader";
import { useNavigate, useParams } from "react-router-dom";
import EditLeaveDetails from "./EditLeaveDetails";
import EditLeave from "./EditLeave";
import { usePageTitle } from "../../../../../hook/useTitle";
import { getAllHolidayData } from "../../../../../redux/slices/leaveDataSlice";
import { useDispatch, useSelector } from "react-redux";
import URLS from "../../../../../routes/urls";
import { LEAVE_DURATION_TYPE, LEAVE_STATUS } from "../../../../../constants/default-values";
import { convertTimeToDate, getFormattedDate } from "../../../../../utils";
import PageNotFound from "../../../../PageNotFound";
import { useAlert } from "../../../../../hook/useAlert";

const EditMyLeaveApplication = () => {
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const params = useParams();
  const { setPageTitle } = usePageTitle();

  setPageTitle("Edit Leave");
  let initialLeaveData = {
    user_leave_type_setting_id: null,
    informed_authorities: [],
    leave_dates: [],
    attachments: [],
    attachment_references: [],
    description: "",
    apply_date: new Date(),
    club_leave_count: 0,
    is_leave_type_changed: false,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const { holidaySettingData } = useSelector((state) => state?.leaveData);
  const { conflictDateArray, allLeaveDays, isHalfToFull } = useSelector((state) => state.leaveApplicationData);

  const [isAppliedLeave, setIsAppliedLeave] = useState(true);
  const [autoGeneratedLeaveDays, setAutoGeneratedLeaveDays] = useState([]);
  const [leaveData, setLeaveData] = useState(initialLeaveData);
  const [errors, setErrors] = useState({});
  const [userLeaveTypes, setUserLeaveTypes] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [organizationUserList, setOrganizationUserList] = useState([]);
  const [leaveSnapshot, setLeaveSnapshot] = useState(null);
  const [deletedDays, setDeletedDays] = useState([]);
  const [isLoading, setIsLoading] = useState({
    leaveType: true,
    users: true,
    leaveDetails: true,
  })
  const [halfRequestData, setHalfRequestData] = useState([]);
  const [optionalLeaveDays, setOptionalLeaveDays] = useState([]);

  useEffect(() => {
    if (currentUser?.id) {
      if (Number(currentUser?.id) === Number(params?.userId)) {
        getUserLeaveTypeData();
        getUserSettingData(params?.userId);
        getYears();
        getUserLeaveBalance();
      } else {
        navigate(URLS.AccessDenied)
      }
    }
  }, []);

  useEffect(() => {
    if (!!holidaySettingData?.halfDayLeave?.length || !!holidaySettingData?.halfDayDates?.length) {
      setHalfRequestData([...holidaySettingData?.halfDayLeave, ...holidaySettingData?.halfDayDates?.map(wfhData => {
        return {
          ...wfhData,
          leave_date: wfhData?.date
        }
      })])
    }
  }, [holidaySettingData])

  const getUserLeaveBalance = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, spanLoading: true }))
      const response = await axiosInstance.get(API.getLeaveBalance(currentUser?.id));
      if (response.status === 200) {
        setLeaveSnapshot(response?.data?.result);
        setIsLoading((prev) => ({ ...prev, spanLoading: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, spanLoading: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, spanLoading: false }))
    }
  };

  const getLeaveDates = (isGetType = false, leaveDaysData) => {
    if (isGetType) {
      return leaveDaysData?.map((leaveData) => ({
        leave_date: leaveData?.leave_date,
        leave_duration: leaveData?.leave_duration || "full",
        paid: leaveData?.paid || 0,
        unpaid: leaveData?.unpaid || 0,
        id: leaveData?.id || null,
        in_time: leaveData?.in_time || null,
        out_time: leaveData?.out_time || null,
        rules: leaveData?.rules || [],
        specialRequest: leaveData?.specialRequest || [],
        is_changed: leaveData?.is_changed || false
      }))
    }
    else {
      return leaveDaysData?.map((leaveData) => !!leaveData?.leave_date ? getFormattedDate(leaveData?.leave_date) : leaveData);
    }
  }

  const handleSubmitLeaveSummary = async (body, leaveDetails) => {
    try {
      setIsLoading((prev) => ({ ...prev, leaveDetails: true }));
      const response = await axiosInstance.post(
        API.applyLeaveSummary(currentUser?.id),
        body
      );
      if (response.status === 200) {
        const responseData = response?.data?.result;
        setIsAppliedLeave(true);
        setLeaveData((prev) => ({
          ...prev,
          ...leaveDetails,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: responseData?.leave_days,
          remaining_days: responseData?.remaining_days,
          remaining_credits: responseData?.remaining_credits,
          // user_leave_type_setting_id: body?.user_leave_type_setting_id
        }));
        setOptionalLeaveDays(responseData?.leave_days)
        let data = responseData?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
        setAutoGeneratedLeaveDays(prev => [...prev, ...data])
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      } else {
        showAlert(response?.response?.data?.message, "error");
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const handleSubmitLeaveSummaryWithoutCredit = async (body, leaveDetails) => {
    try {
      setIsLoading((prev) => ({ ...prev, leaveDetails: true }));
      const response = await axiosInstance.post(
        API.applyLeaveSummaryWithoutCredit(currentUser?.id),
        body
      );
      if (response.status === 200) {
        const responseData = response?.data?.result;
        setIsAppliedLeave(true);
        setLeaveData((prev) => ({
          ...prev,
          ...leaveDetails,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: responseData?.leave_days,
          remaining_days: responseData?.remaining_days,
          remaining_credits: responseData?.remaining_credits,
          user_leave_type_setting_id: body?.user_leave_type_setting_id
        }));
        setOptionalLeaveDays(responseData?.leave_days)
        let data = responseData?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
        setAutoGeneratedLeaveDays(prev => [...prev, ...data])
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      } else {
        showAlert(response?.response?.data?.message, "error");
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      showAlert(error?.response?.data?.message, "error");
    }
  }

  const handleSubmitExistHalfLeaveDays = async (leaveDaysData, leaveDetails) => {
    const payload = {
      user_leave_type_setting_id: leaveDetails?.user_leave_type_setting_id,
      club_leave_count: leaveDetails?.club_leave_count || 0,
      leave_days: [...getLeaveDates(true, leaveDaysData)],
      remaining_days: leaveDetails?.remaining_days || 0,
      is_edit: true,
      leave_application_id: parseInt(params?.appId),
      is_leave_type_changed: leaveDetails?.is_leave_type_changed || false,
    };

    if (leaveDetails?.allow_special_credits) {
      handleSubmitLeaveSummary(payload, leaveDetails);
    }
    else {
      handleSubmitLeaveSummaryWithoutCredit(payload, leaveDetails)
    }
  }

  const getUserLeave = async (user_id, id) => {
    try {
      const response = await axiosInstance.get(API.getLeaveById(user_id, id));
      if (response.status === 200) {
        if (response?.data?.result?.status !== LEAVE_STATUS.Pending && response?.data?.result?.status !== "sys-generated") {
          navigate(`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=active`, { replace: true })
        } else {
          let leaveDays = response?.data?.result?.leave_days?.map(value => {
            let leave_duration_time = Object.keys(LEAVE_DURATION_TYPE)[2] === value?.leave_duration;
            if (leave_duration_time) {
              return {
                ...value,
                is_changed: false
              }
            } else {
              let leave_duration_time =
                Object.keys(LEAVE_DURATION_TYPE)[0] === value?.leave_duration
                  ? "in_time"
                  : "out_time"
              return {
                ...value,
                is_changed: false,
                [leave_duration_time]: convertTimeToDate(value?.[leave_duration_time])
              }
            }
          })
          if (isHalfToFull) {
            let existPreviousLeaveDayData = leaveDays?.filter(leaveData => (conflictDateArray ?? [])?.find(conflictLeaveData => conflictLeaveData?.leave_date === leaveData?.leave_date))?.map(levaeData => {
              return {
                ...levaeData,
                leave_duration: 'full',
                is_changed: true,
              }
            })
            let allLeaves = [...existPreviousLeaveDayData, ...[...allLeaveDays ?? [], ...leaveDays ?? []]?.filter(leaveData => !((conflictDateArray ?? [])?.find(conflictLeaveData => conflictLeaveData?.leave_date === leaveData?.leave_date)))]
            setLeaveData({
              ...initialLeaveData,
              ...response?.data?.result,
              leave_days: leaveDays,
            });
            setIsLoading((prev) => ({ ...prev, leaveDetails: true }));
            await handleSubmitExistHalfLeaveDays(allLeaves, { ...initialLeaveData, ...response?.data?.result });
            // setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
          } else {
            setLeaveData({
              ...initialLeaveData,
              ...response?.data?.result,
              // leave_days: response?.data?.result?.leave_days?.map(value => ({
              //   ...value,
              //   is_changed: false
              // })),

              leave_days: leaveDays,
            });
            setOptionalLeaveDays(leaveDays)
            let data = response?.data?.result?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
            setAutoGeneratedLeaveDays(data)
            setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
          }
        }
      } else {
        setLeaveData({});
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      }
    } catch (error) {
      console.error(error);
      setLeaveData({});
      setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
    }
  };

  const getUserLeaveTypeData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, leaveType: true }))
      const response = await axiosInstance.get(
        API.getUserLeaveTypeData(currentUser?.id)
      );
      if (response.status === 200) {
        setUserLeaveTypes(response?.data.results);
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveType: false }))
    }
  };

  const getUserSettingData = async (userId) => {
    try {
      setIsLoading((prev) => ({ ...prev, users: true }))
      const response = await axiosInstance.get(API.organisationUserWithInformationAuthorityByUserId(userId));
      if (response.status === 200) {
        let reporting_authorities = response?.data?.data?.reporting_authorities;
        let second_reporting_authorities = response?.data?.data?.second_reporting_authorities;
        let defaultUsers = response?.data?.data?.defaultUsers;
        let users = [];
        if (reporting_authorities && reporting_authorities?.length > 0) {
          users.push(...reporting_authorities);
        }
        if (second_reporting_authorities && second_reporting_authorities?.length > 0) {
          users.push(...second_reporting_authorities);
        }
        if (users?.length <= 0) {
          users.push(...defaultUsers);
        }
        setOrganizationUserList(users || []);
        getUserLeave(params?.userId, params?.appId);
        setIsLoading((prev) => ({ ...prev, users: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, users: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, users: false }))
    }
  };

  const getYears = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, holiday: true }))
      const response = await axiosInstance.get(
        `${API.getAllHolidaysYear(currentUser?.organization_id)}`
      );
      if (response.status === 200) {
        let sortedYear = response?.data?.result?.sort((a, b) =>
          a?.year?.toString()?.localeCompare(b?.year?.toString())
        );
        let activeYear = sortedYear?.find(yearData => yearData?.active);
        dispatch(getAllHolidayData({
          organization_id: currentUser?.organization_id,
          year_Id: activeYear?.id || sortedYear[0]?.id,
          user_id: currentUser?.id
        }))
        setIsLoading((prev) => ({ ...prev, holiday: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, holiday: false }))
    }
  }

  return ((isLoading?.leaveType || isLoading?.users || isLoading?.leaveDetails) ?
    <CircularLoader /> :
    leaveData && Object.keys(leaveData)?.length === 0 ?
      <PageNotFound showSideBar={false} /> :
      isAppliedLeave ? (
        <EditLeaveDetails
          leaveSnapshot={leaveSnapshot}
          setIsAppliedLeave={setIsAppliedLeave}
          setLeaveData={setLeaveData}
          setAutoGeneratedLeaveDays={setAutoGeneratedLeaveDays}
          autoGeneratedLeaveDays={autoGeneratedLeaveDays}
          leaveData={leaveData}
          setErrors={setErrors}
          userLeaveTypes={userLeaveTypes}
          organizationUserList={organizationUserList}
          errors={errors}
          currentUser={currentUser}
          setDeletedDays={setDeletedDays}
          deletedDays={deletedDays}
          deletedImages={deletedImages}
          setDeletedImages={setDeletedImages}
          setHalfRequestData={setHalfRequestData}
          halfRequestData={halfRequestData}
          optionalLeaveDays={optionalLeaveDays}
        />
      ) : (
        <EditLeave
          leaveSnapshot={leaveSnapshot}
          setIsAppliedLeave={setIsAppliedLeave}
          setLeaveData={setLeaveData}
          setAutoGeneratedLeaveDays={setAutoGeneratedLeaveDays}
          leaveData={leaveData}
          setErrors={setErrors}
          errors={errors}
          userLeaveTypes={userLeaveTypes}
          organizationUserList={organizationUserList}
          currentUser={currentUser}
          setDeletedDays={setDeletedDays}
          deletedDays={deletedDays}
          setDeletedImages={setDeletedImages}
          deletedImages={deletedImages}
          setHalfRequestData={setHalfRequestData}
          halfRequestData={halfRequestData}
          optionalLeaveDays={optionalLeaveDays}
        />
      )
  )
};

export default EditMyLeaveApplication;