import { Box, Drawer, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import reportImg from '../../../../assets/images/task-estimation-report.png';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import { convertDateFromFormat, formatDuration, generateFileName, getFormattedDate, getStartDateBaseOnOnboarding, getTaskTypeIcon, secondsToTime, sortArrayByKey, titleCase } from '../../../../utils';
import useAuthentication from '../../../../hook/useAuthentication';
import { DateObject } from 'react-multi-date-picker';
import Button from '../../../../components/Button';
import CircularLoader from '../../../../components/CircularLoader';
import ReactApexChart from 'react-apexcharts';
import MenuChartDateRangePicker from '../../../../components/DateRangePicker/MenuChartDateRangePicker';
import TaskIcon from "../../../../assets/images/feature-icon.svg";
import BugIcon from "../../../../assets/images/bug-icon.svg";
import ImprovementIcon from "../../../../assets/images/inhancement-icon.svg";
import { TASK_TYPE_LIST } from '../../../../constants/default-values';
import { styled } from '@mui/styles';
import ICONS from '../../../../constants/icons';
import WorkLogDetails from './workLogDetails';
import FileSaver from 'file-saver';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        padding: "8px 12px",
    },
}));

const TaskEstimationComparision = ({ selectedProject, isProjectLoading, selectedProjectData }) => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    let onboardingDate = new Date(currentUser?.organization?.createdAt);
    let startDateOfMonth = new Date(getStartDateBaseOnOnboarding(new Date(new DateObject().toFirstOfMonth()), onboardingDate)) > new DateObject().toFirstOfMonth() ? new Date(getStartDateBaseOnOnboarding(new Date(new DateObject().toFirstOfMonth()), onboardingDate)) : new DateObject().toFirstOfMonth()
    let initialDateRangeChange = [
        startDateOfMonth,
        new Date(),
    ];
    const [isLoading, setIsLoading] = useState({
        users: false,
        report: false,
    });
    const [queryParams, setQueryParams] = useState({
        userData: {
            id: currentUser?.id
        },
        startDate: startDateOfMonth,
        endDate: new DateObject().toDate(),
        dateRange: initialDateRangeChange,
        userId: null,
    });
    const [organizationUserList, setOrganizationUserList] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('all');
    const [allTaskDataList, setAllTaskDataList] = useState([]);
    const [sidebarData, setSidebarData] = useState({})
    const [openLogDrawer, setOpenLogDrawer] = useState(false);

    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            id: 'stacked-bar-chart-task',
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // columnWidth: '40%',
                columnWidth: '22px',
                borderRadius: 0,
                dataLabels: {
                    position: "top",
                    orientation: "vertical",
                }
            },
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
            active: {
                filter: {
                    type: 'none',
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                let seriesData = w?.globals?.initialSeries || [];
                let hiddenSeries = w?.globals?.collapsedSeriesIndices || [];

                let stackColor = seriesData[seriesIndex]?.data?.[dataPointIndex]?.color;

                if (stackColor === "#E3E3E3DE") {
                    return "No Est.";
                }

                function isSeriesVisible(index) {
                    return !hiddenSeries.includes(index);
                }

                let stackValues = seriesData?.map((s) => s?.data?.[dataPointIndex]?.y || 0);
                // let stackTotalValues = seriesData?.map((s) => s?.data?.[dataPointIndex]?.totalTime || 0);
                let stackActualSpentValues = seriesData?.map((s) => s?.data?.[dataPointIndex]?.currentTime || 0);

                let highestVisibleIndex = -1;
                for (let i = stackValues?.length - 1; i >= 0; i--) {
                    if (stackValues[i] && isSeriesVisible(i)) {
                        highestVisibleIndex = i;
                        break;
                    }
                }
                if (seriesIndex === highestVisibleIndex) {
                    let activeStackTotal = stackActualSpentValues?.reduce((acc, curr, index) => {
                        if (isSeriesVisible(index)) {
                            acc += curr
                        }
                        return acc;
                    }, 0)
                    // return secondsToTime(stackTotalValues[seriesIndex]);
                    return secondsToTime(activeStackTotal);
                }

                return "";
            },
            orientation: "vertical",
            style: {
                fontSize: '10px',
                colors: ['#454545'],
                fontWeight: '400',
            },
            offsetY: 5,
            hideOverflowingLabels: false,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 0,
        },
        xaxis: {
            labels: {
                rotate: -90,
                rotateAlways: true,
                style: {
                    fontSize: '12px',
                    fontWeight: 400,
                },
            },
            categories: [],
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            title: {
                text: 'Time taken compared to estimation',
                style: {
                    fontSize: '11px',
                    fontWeight: 400,
                },
            },
            labels: {
                formatter: (value) => {
                    if (value === null || value === undefined) return "0%";
                    return `${value}%`;
                },
            },
        },
        legend: {
            position: 'bottom',
            offsetY: 10,
            markers: {
                width: 12,
                height: 12,
            },
            onItemClick: {
                toggleDataSeries: false,
            },
            onItemHover: {
                highlightDataSeries: false,
            },
        },
        colors: ['#2A8F37', '#E75252'],
        fill: {
            colors: [function ({ value, seriesIndex, dataPointIndex, w }) {
                return w?.globals?.initialSeries?.[seriesIndex]?.data?.[dataPointIndex]?.color || "#E3E3E3DE"
            }]
        },

        tooltip: {
            // enabled: true,
            shared: true,
            intersect: false,
            // hideEmptySeries: true,
            theme: false,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let totalTime = 0;
                let inactiveSeriesIndex = w?.globals?.collapsedSeriesIndices;
                if (inactiveSeriesIndex?.length > 1) return null;
                w?.globals?.seriesNames?.forEach((name, i) => {
                    const value = w?.globals?.initialSeries[i]?.data?.[dataPointIndex]?.currentTime;
                    if (value !== null && value !== undefined && !inactiveSeriesIndex?.includes(i)) {
                        totalTime = totalTime + value ?? 0
                    }
                });
                if (totalTime < 1) return null
                let taskDetails = w?.globals?.initialSeries?.[0]?.data?.[dataPointIndex]?.taskDetails;
                let taskNo = taskDetails?.task_no;
                let shotCode = taskDetails?.short_code;
                let userName = w?.globals?.initialSeries?.[0]?.data?.[dataPointIndex]?.userName;
                // let workLogs = w?.globals?.initialSeries?.[0]?.data?.[dataPointIndex]?.workLogs;
                let status = taskDetails?.status;
                let type = taskDetails?.type;
                let title = taskDetails?.title;
                let priority = taskDetails?.priority;

                let tooltipHTML = `<div class="chart-tooltip-box">`;
                tooltipHTML += `
                   <div style="font-size: 13px; color: #fff;">
                        <div class="project-title">
                            <div style="display:flex; background:#fff; float:left; align-items:center; justify-content:center; min-width:22px; max-width:22px; width:22px; height:22px; margin-right: 8px; border-radius:2px; border:1px solid rgba(63, 92, 118, 0.2);">
                                <img src="${getIconsByType(type)}" alt="Task Icon" style="width: 16px; height: 16px;">
                            </div>
                            <span style="color: #fff; font-weight: 600; font-size: 15px; line-height: 22px;padding-top: 1px">${shotCode} - ${taskNo} - ${title}</span>
                        </div>

                         <div style="">
                            <div style="display: flex; align-items:center; justify-content: space-between; margin-bottom: 2px;min-height: 20px">
                                <span style="color: #fff; font-weight: 500; font-size: 13px; line-height: 19px;">Current Status</span>
                                <div style="display: inline-flex; align-items: center; padding: 0px 8px; background-color: transaprent; color: #fff; font-size: 12px; line-height: 18px; font-weight: 400; border-radius: 16px; border: 1px solid #ffffff;height: 20px;">
                                    ${titleCase(status)}
                                </div>
                            </div>

                            <div style="display: flex; align-items:center; justify-content: space-between; margin-bottom: 2px;min-height: 20px">
                                <span style="color: #fff; font-weight: 500; font-size: 13px; line-height: 19px;">Estimation</span>
                                <span style="color: #fff; font-weight: 500; font-size: 13px; line-height: 19px;">${taskDetails?.estimation ? formatDuration(taskDetails?.estimation) : 0}</span>
                            </div>

                            <div style="display: flex; align-items:center; justify-content: space-between; margin-bottom: 2px;min-height: 20px">
                                <span style="color: #fff; font-weight: 500; font-size: 13px; line-height: 19px;">Time Tracked</span>
                                <span style="color: #fff; font-weight: 500; font-size: 13px; line-height: 19px;">${getTrackedTime(taskDetails?.estimation, taskDetails?.spent)}</span>
                            </div>   
                            
                            <div style="display: flex; align-items:center; justify-content: space-between; margin-bottom: 2px;min-height: 20px">
                                <span style="color: #fff; font-weight: 500; font-size: 13px; line-height: 19px;">Assign to</span>
                                <span style="color: #fff; font-weight: 500; font-size: 13px; line-height: 19px;">${titleCase(userName)}</span>
                            </div> 
                        </div>
                        
                        ${getTrackMsg(taskDetails?.estimation, taskDetails?.spent, status, priority) ?
                        `<div style="margin-top: 8px; font-size: 12px; color: #777;font-style:italic">
                                ${getTrackMsg(taskDetails?.estimation, taskDetails?.spent, status, priority)}
                            </div>`
                        : ''}
                    </div>
                    `
                tooltipHTML += `</div>`;
                return tooltipHTML
            },
        },
    });

    useEffect(() => {
        if (selectedProject) {
            selectedProjectData?.is_private ? getCurrentProjectUsers(selectedProject) : getUserSettingData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject])

    const getTrackedTime = (estimation, spent) => {
        let spentHours = formatDuration(spent);
        let estimationHours = formatDuration(estimation);
        if (estimation) {
            if (spent > estimation) {
                let extraHours = formatDuration(spent - estimation);
                return `<span style="color: #fff;">
                    ${spentHours}
                    <span style="color: #FFA4A4;">
                        (Over By ${extraHours})
                     </span>
                </span>`
            } else if (spentHours === estimationHours) {
                return `<span style="color: #fff;">${spentHours}</span>`
            } else if (spent < estimation) {
                let earlyHours = formatDuration(estimation - spent);
                return `<span style="color: #fff;">
                    ${spentHours} 
                    <span style="color: #05B01B;">
                        (${earlyHours} Remaining)
                     </span>
                </span>`
            }
        }
        return spentHours;
    }

    const getTrackMsg = (estimation, spent, status, priority = "Hight") => {
        let spentHours = formatDuration(spent);
        if (estimation) {
            if (spent > estimation) {
                let extraHours = formatDuration(spent - estimation);
                if (status === 'To-Do') {
                    return `
                        <span style="color: #FFA4A4;">
                           This task is in to-do and overdue by ${extraHours}.
                         </span>`
                } else if (status === 'Working') {
                    let extraHours = formatDuration(spent - estimation);
                    return `
                        <span style="color: #FFA4A4;">
                           This task is in progress and overdue by ${extraHours}.
                         </span>`
                } else if (status === 'Pause') {
                    let extraHours = formatDuration(spent - estimation);
                    return `
                        <span style="color: #FFA4A4;">
                           This task is paused, exceeded the time by ${extraHours}.
                         </span>`
                } else if (status === 'Close') {
                    let extraHours = formatDuration(spent - estimation);
                    return `
                        <span style="color: #FFA4A4;">
                           This task was completed but exceeded the time by ${extraHours}.
                         </span>`
                } else if (status === 'Review') {
                    let extraHours = formatDuration(spent - estimation);
                    return `
                        <span style="color: #FFA4A4;">
                           This task is in review, exceeded the time by ${extraHours}.
                         </span>`
                } else if (status === 'Re-Open') {
                    let extraHours = formatDuration(spent - estimation);
                    return `
                        <span style="color: #FFA4A4;">
                           This task is re-opened and exceeded the time by ${extraHours}.
                         </span>`
                } else if (status === 'Deffered') {
                    let extraHours = formatDuration(spent - estimation);
                    return `
                        <span style="color: #FFA4A4;">
                           This task is deferred after ${spentHours}, exceeding by ${extraHours}.
                         </span>`
                } else {
                    return null
                }
            } else if (spent < estimation) {
                let earlyHours = formatDuration(estimation - spent);
                if (status === 'To-Do') {
                    return `
                        <span style="color: #05B01B;">
                           This task is in to-do with ${earlyHours} left.
                         </span>`
                } else if (status === 'Working') {
                    return `
                        <span style="color: #05B01B;">
                           This task is in progress with ${earlyHours} left.
                         </span>`
                } else if (status === 'Pause') {
                    return `
                        <span style="color: #05B01B;">
                           This task is paused with ${earlyHours} left.
                         </span>`
                } else if (status === 'Close') {
                    return `
                        <span style="color: #05B01B;">
                           This task was completed early by ${earlyHours}.
                         </span>`
                } else if (status === 'Review') {
                    return `
                        <span style="color: #05B01B;">
                           This task in review, completed early by ${earlyHours}.
                         </span>`
                } else if (status === 'Re-Open') {
                    return `
                        <span style="color: #05B01B;">
                           This task is re-opened, with ${earlyHours} left.
                         </span>`
                } else if (status === 'Deffered') {
                    return `
                        <span style="color: #05B01B;">
                           This task is deferred after ${spentHours} spent, with ${earlyHours} left.
                         </span>`
                } else {
                    return null
                }
            } else {
                if (status === 'To-Do' && !spent) {
                    return `
                        <span style="color: #fff;">
                           This task is not started yet.
                         </span>`
                }
                return null
            }
        }
        return null;
    }

    // const getTotalBoxes = (workLogs) => {
    //     let totalEstimation = workLogs?.[0]?.Task?.estimation;
    //     let someOfTask = 0;
    //     return sortArrayByKey(workLogs, "asc", "id")?.map((taskData => {
    //         let spent = taskData?.Task?.spent;
    //         let estimation = totalEstimation;
    //         let spentHours = formatDuration(spent);
    //         let estimationHours = formatDuration(estimation);
    //         if (estimation) {
    //             if (spent > estimation) {
    //                 someOfTask = someOfTask + taskData?.duration;
    //                 if (someOfTask > totalEstimation) {
    //                     return "#E75252"
    //                 } else {
    //                     return "#05B01B"
    //                 }
    //             } else if (spentHours === estimationHours) {
    //                 return "#05B01B"
    //             } else if (spent < estimation) {
    //                 return "#05B01B"
    //             }
    //         }
    //         return "#05B01B"
    //     }))
    // }

    const getIconsByType = (type) => {
        if (type === TASK_TYPE_LIST.Task) {
            return TaskIcon
        } else if (type === TASK_TYPE_LIST.Improvement) {
            return ImprovementIcon
        } else if (type === TASK_TYPE_LIST.Bug) {
            return BugIcon
        }
        return TaskIcon
    }

    const handleChangeDatePicker = (dateRange) => {
        const newStartDate = new Date(dateRange?.[0]).toISOString();
        const newEndDate = new Date(dateRange?.[1] || dateRange?.[0]).toISOString();

        setQueryParams(prev => ({
            ...prev,
            startDate: newStartDate,
            endDate: newEndDate,
            dateRange,
        }));
    }

    const getUserSettingData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, users: true }))
            const response = await axiosInstance.get(
                `${API.organisationUserSetting(currentUser?.organization_id)}`
            );
            if (response.status === 200) {
                let sortedNames = sortArrayByKey(response?.data?.data?.filter(userData => userData?.active), "asc", "name")
                setOrganizationUserList(sortedNames);
                setIsLoading((prev) => ({ ...prev, users: false }))
            } else {
                setIsLoading((prev) => ({ ...prev, users: false }))
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, users: false }))
            setOrganizationUserList([])
        }
    };

    const getCurrentProjectUsers = async (projectId) => {
        try {
            setIsLoading((prev) => ({ ...prev, users: true }));
            const response = await axiosInstance.get(
                API.getAllProjectUsersList(projectId)
            );
            if (response?.status === 200) {
                if (response?.data?.data?.length) {
                    let activeAllMembersData = sortArrayByKey(response?.data?.data?.filter((list) => list?.is_active)?.map(userData => {
                        return {
                            id: userData?.User?.id,
                            name: userData?.User?.name,
                            email: userData?.User?.email
                        }
                    }), "asc", "name");
                    setOrganizationUserList(activeAllMembersData);
                } else {
                    setOrganizationUserList([])
                }
            } else {
                setOrganizationUserList([])
            }
            setIsLoading((prev) => ({ ...prev, users: false }));
        } catch (error) {
            setIsLoading((prev) => ({ ...prev, users: false }));
            console.error(error);
            setOrganizationUserList([])
        }
    };

    const handleSearch = () => {
        setIsLoading((prev) => ({ ...prev, report: true }));
        // if (!validateDate(queryParams)) {
        //     // getSummary();
        // } else {
        //     setIsLoading((prev) => ({ ...prev, report: false }));
        // }
        // if (selectedUserId) {
        getTaskComparisionReportData()
        // }
    };

    const getTaskComparisionReportData = async () => {
        try {
            let startDate = convertDateFromFormat(new Date(queryParams?.startDate));
            let endDate = convertDateFromFormat(new Date(queryParams?.endDate));
            let body = {
                "chartType": "line",
                "project_id": selectedProject,
                startDate: startDate,
                endDate: endDate,
                ...(selectedUserId === 'all' ? {} : { userId: selectedUserId })
            }
            let response = await axiosInstance.post(API.getTaskChartData, body)
            if (response?.status === 200) {
                let chartDetails = sortArrayByKey(response?.data?.taskChartData, "asc", "task_id") ?? [];
                setAllTaskDataList(chartDetails?.map((taskData) => {
                    return {
                        ...taskData,
                        overSpent: taskData?.estimation ? taskData?.spent > taskData?.estimation ? taskData?.spent - taskData?.estimation : 0 : 0,
                        savedTime: taskData?.estimation ? taskData?.estimation > taskData?.spent ? taskData?.estimation - taskData?.spent : 0 : 0,
                    }
                }));
                if (chartDetails?.length) {
                    const keysOfTask = sortArrayByKey(chartDetails, "asc", "task_no")?.map((taskData) => `${titleCase(selectedProjectData?.short_code)}-${taskData?.task_no}`);
                    let seriesData = [];
                    // let keysOfTime = ['Normal Estimation', 'Spent less', 'Over Spent'];
                    let keysOfTime = ['Normal Estimation', 'Over Spent'];
                    keysOfTime?.map((key) => {
                        let taskSeriesData = {
                            name: titleCase(key),
                            type: 'bar',
                        }
                        let currentStackTimeDataOfAllTask = [];
                        sortArrayByKey(chartDetails, "asc", "task_no")?.map((taskData) => {
                            let taskDetail = {
                                spent: taskData?.spent,
                                estimation: taskData?.estimation,
                                title: taskData?.title,
                                task_no: taskData?.task_no,
                                type: taskData?.type,
                                status: taskData?.status,
                                short_code: selectedProjectData?.short_code,
                                priority: taskData?.priority
                            }
                            if (taskData?.estimation > 0) {
                                let spentTime = taskData?.spent ?? 0;
                                let estimationTime = taskData?.estimation;
                                let spentTimePercentage = ((spentTime * 100) / estimationTime);

                                if (key === 'Normal Estimation') {
                                    if (spentTimePercentage > 0 && spentTimePercentage <= 100) {
                                        currentStackTimeDataOfAllTask.push({ x: `${titleCase(selectedProjectData?.short_code)}-${taskData.task_no}`, y: spentTimePercentage, taskDetails: taskDetail, totalTime: spentTime, spent: spentTime, userName: taskData?.WorkLogs?.[0]?.User?.name, color: '#2A8F37', currentTime: spentTime })
                                    } else if (spentTimePercentage > 100) {
                                        let overTime = Math.abs(spentTime - estimationTime);
                                        currentStackTimeDataOfAllTask.push({ x: `${titleCase(selectedProjectData?.short_code)}-${taskData.task_no}`, y: 100, taskDetails: taskDetail, totalTime: spentTime, spent: overTime, userName: taskData?.WorkLogs?.[0]?.User?.name, color: '#2A8F37', currentTime: estimationTime })
                                    }
                                } else if (key === 'Over Spent') {
                                    if (spentTimePercentage > 100) {
                                        let overTime = Math.abs(spentTime - estimationTime) ?? 0
                                        let overTimePercentage = spentTimePercentage - 100 ?? 0;
                                        currentStackTimeDataOfAllTask.push({ x: `${titleCase(selectedProjectData?.short_code)}-${taskData.task_no}`, y: overTimePercentage, taskDetails: taskDetail, totalTime: spentTime, spent: overTime, userName: taskData?.WorkLogs?.[0]?.User?.name, color: '#E75252', currentTime: overTime })
                                    } else {
                                        currentStackTimeDataOfAllTask.push({ x: `${titleCase(selectedProjectData?.short_code)}-${taskData.task_no}`, y: null, taskDetails: taskDetail, totalTime: spentTime, spent: estimationTime, userName: taskData?.WorkLogs?.[0]?.User?.name, color: '#E75252', currentTime: 0 })
                                    }
                                }
                            } else {
                                if (key === 'Normal Estimation') {
                                    currentStackTimeDataOfAllTask.push({ x: `${titleCase(selectedProjectData?.short_code)}-${taskData.task_no}`, y: 5, taskDetails: taskDetail, totalTime: taskData?.spent, spent: taskData?.spent, userName: taskData?.WorkLogs?.[0]?.User?.name, color: '#E3E3E3DE', currentTime: taskData?.spent })
                                } else {
                                    currentStackTimeDataOfAllTask.push({ x: `${titleCase(selectedProjectData?.short_code)}-${taskData.task_no}`, y: null, taskDetails: taskDetail, totalTime: null, spent: null, userName: taskData?.WorkLogs?.[0]?.User?.name, color: '#E3E3E3DE', currentTime: 0 })
                                }
                            }
                            return null
                        })
                        taskSeriesData['data'] = currentStackTimeDataOfAllTask;
                        seriesData.push(taskSeriesData);
                        return null;
                    })
                    setSeries(seriesData)

                    const numPoints = seriesData?.[0]?.data?.length;
                    const summedYValues = Array(numPoints)?.fill(0);

                    seriesData?.forEach((series) => {
                        series?.data?.forEach((point, index) => {
                            summedYValues[index] += point?.y ?? 0;
                        });
                    });
                    let maxSummedY = Math.ceil(Math.max(...summedYValues) / 50) * 50;
                    maxSummedY = maxSummedY < 100 ? 100 : maxSummedY

                    setOptions(prev => ({
                        ...prev,
                        xaxis: {
                            ...prev?.xaxis,
                            categories: keysOfTask,
                            labels: {
                                ...prev?.xaxis?.labels,
                                rotate: -90,
                                rotateAlways: true,
                            },
                        },
                        yaxis: {
                            ...prev?.yaxis,
                            min: 0,
                            max: maxSummedY,
                            tickAmount: maxSummedY / 50,
                            labels: {
                                formatter: (value) => `${value?.toFixed(0)}%`,
                            },
                        },
                    }));
                } else {
                    setSeries([]);
                }
            } else {
                setSeries([]);
                setAllTaskDataList([]);
            }
            setIsLoading((prev) => ({ ...prev, report: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, report: false }));
        }
    }

    const getTotalTimeData = () => {
        return allTaskDataList?.reduce((functionedTasks, cuurentTask) => {
            return {
                estimation: functionedTasks?.estimation + (cuurentTask?.estimation ?? 0),
                spent: functionedTasks?.spent + (cuurentTask?.spent ?? 0),
                saved: functionedTasks?.saved + (cuurentTask?.savedTime ?? 0),
                over: functionedTasks?.over + (cuurentTask?.overSpent ?? 0)
            }
        }, { estimation: 0, spent: 0, saved: 0, over: 0 })
    }

    const handleClickOpenDrawer = (taskData) => {
        setOpenLogDrawer(true)
        setSidebarData(taskData)
    }

    const handleCloseDrawer = () => {
        setOpenLogDrawer(false);
        setSidebarData({})
    }

    const exportChartData = async () => {
        try {
            setIsLoading(prev => ({ ...prev, exportChartLoading: true }))
            let startDate = convertDateFromFormat(new Date(queryParams?.startDate));
            let endDate = convertDateFromFormat(new Date(queryParams?.endDate));
            let body = {
                chartType: "line",
                project_id: selectedProject,
                startDate: startDate,
                endDate: endDate,
                ...(selectedUserId === 'all' ? {} : { userId: selectedUserId })
            };
            const response = await axiosInstance.post(API.getExportRepotParams, body);

            if (response?.status === 200) {
                if (response?.data) {
                    let base64Input = Object.values(response.data).join("");
                    var selectedMimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                    const decodedData = atob(base64Input);
                    const byteArray = new Uint8Array(decodedData?.length);
                    for (let i = 0; i < decodedData.length; i++) {
                        byteArray[i] = decodedData.charCodeAt(i);
                    }

                    const blob = new Blob([byteArray], { type: selectedMimeType });
                    await FileSaver.saveAs(blob, generateFileName('Project_Task_Productivity'));
                }
            }
            setIsLoading(prev => ({ ...prev, exportChartLoading: false }))
        } catch (error) {
            console.error("Error downloading file", error);
            setIsLoading(prev => ({ ...prev, exportChartLoading: false }))
        }
    };

    const selectedUserName = () => {
        if (selectedUserId !== 'all') {
            return titleCase(organizationUserList?.find(userData => userData?.id === selectedUserId)?.name)
        }
        return null
    }

    return (
        isProjectLoading ?
            <CircularLoader height='500px' /> :
            <>
                <style>
                    {`
                        .apexcharts-bar-area:hover {
                            box-shadow: none !important;
                            filter: none !important;
                        }
                    `}
                </style>
                <Box flex={1} overflow={"visible"} display={"flex"} mt={4}>
                    <Box display={"flex"} flexDirection={"column"} sx={{ width: '100%', flex: '1', overflow: 'visible', }} gap={3}>
                        <Box display={'flex'} alignItems={"center"} justifyContent={'space-between'}>
                            <Box display="flex" gap={2}>
                                <Grid className='report-date-range-picker' item width={265} maxWidth={265} sx={{ '.MuiTextField-root': { width: '100% !important' } }}>
                                    <MenuChartDateRangePicker
                                        dateRange={queryParams?.dateRange}
                                        onChange={(dateRange) => handleChangeDatePicker(dateRange)}
                                        maxDate={new Date()}
                                        width={370}
                                        disabled={isLoading?.users}
                                    />
                                </Grid>
                                <Grid item sx={{ width: { xs: 180, lg: 264 } }}>
                                    <FormControl variant="standard" sx={{ width: "100%" }}>
                                        <InputLabel htmlFor="range_label">Select Member</InputLabel>
                                        <Select
                                            inputProps={{ id: "range_label" }}
                                            id="user_id"
                                            value={selectedUserId || ""}
                                            onChange={(event, ...rest) => {
                                                setSelectedUserId(event?.target?.value)
                                            }}
                                            name="userId"
                                            label="Select Member"
                                            sx={{
                                                ".MuiSelect-select": {
                                                    fontSize: 14,
                                                    fontWeight: 400,
                                                    color: "dark.800",
                                                },
                                            }}
                                            disabled={isLoading?.users}
                                        >
                                            {(!organizationUserList?.length && !isLoading?.users) ?
                                                <MenuItem key={"null"} value={null}>
                                                    {titleCase("No Option")}
                                                </MenuItem> : null}
                                            {(organizationUserList?.length || isLoading?.users) ?
                                                <MenuItem key={"all"} value={'all'}>
                                                    {titleCase("All")}
                                                </MenuItem> : null}
                                            {organizationUserList?.map((item, index) => (
                                                <MenuItem key={item?.id} value={item?.id}>
                                                    {titleCase(item?.name)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item sx={{ alignContent: 'center' }}>
                                    <Button
                                        sx={{ color: "white", textTransform: 'none', boxShadow: 'none' }}
                                        onClick={handleSearch}
                                        disabled={!selectedProject || !selectedUserId || isLoading?.report || isLoading?.users}
                                    >
                                        Apply
                                    </Button>
                                </Grid>
                            </Box>
                            {series?.length ? <Button
                                variant="outlined"
                                color="secondary"
                                size="medium"
                                // startIcon={<ExpandIcon /> }
                                sx={{ color: "secondary.main", boxShadow: "none", border: '1px solid #D4D9DE', textTransform: "none", display: "flex", alignItems: "center", 'svg': { display: 'block', } }}
                                onClick={() => exportChartData(prev => !prev)}
                                disabled={!selectedProject || isLoading?.exportChartLoading}
                            >
                                <i style={{ width: 17, height: 12, minWidth: 17, marginRight: '8px' }}>
                                    {ICONS.ExportFileIcon}
                                </i>
                                Export
                            </Button> : null}
                        </Box>
                        {
                            (isLoading?.users || isLoading?.report) ?
                                <CircularLoader height='500px' /> :
                                series?.length ?
                                    <>
                                        <Box border={'1px solid #E0E0E0'} borderRadius={'3px'} sx={{ padding: '24px' }}>
                                            <Box sx={{ paddingLeft: '17px' }} display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"} mb={3.5}>
                                                <Box>
                                                    <Typography
                                                        variant="span"
                                                        color="dark.800"
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        lineHeight={'20px'}
                                                        letterSpacing={'0.17px'}
                                                    >
                                                        Task Comparison Report {selectedUserId !== 'all' ? 'of' : ''} <b>{`${selectedUserId !== 'all' ? selectedUserName() : ''}`}</b> for
                                                        <b>{' '}{convertDateFromFormat(new Date(queryParams?.startDate)) === convertDateFromFormat(new Date(queryParams?.endDate))
                                                            ? getFormattedDate(convertDateFromFormat(new Date(queryParams?.startDate)))
                                                            : `${getFormattedDate(convertDateFromFormat(new Date(queryParams?.startDate)))} - ${getFormattedDate(convertDateFromFormat(new Date(queryParams?.endDate)))}`}</b>
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        variant="span"
                                                        color="dark.800"
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        lineHeight={'20px'}
                                                        letterSpacing={'0.17px'}
                                                    >Total Hours <b>{formatDuration(getTotalTimeData()?.spent)}</b></Typography>
                                                </Box>
                                            </Box>

                                            <ReactApexChart
                                                options={options}
                                                series={series}
                                                type="bar"
                                                height={350}
                                            />
                                        </Box>
                                        <Box display={'flex'} gap={0.5} alignItems={'center'}>
                                            <Typography variant='span' color={"dark"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                Duration
                                            </Typography>
                                            {/* {' '}  */}
                                            <Stack spacing={1} direction="row" flexWrap="wrap" alignItems={'center'}>
                                                {queryParams?.dateRange?.map((date, index, array) => (
                                                    <React.Fragment key={index}>
                                                        <Typography variant='span' color={"dark"} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>{typeof date === "string" ? date : getFormattedDate(new Date(date), "DD/MM/YYYY", true)}</Typography>
                                                        {array?.length !== 1 && index === 0 ? <Typography>{" - "}</Typography> : null}
                                                    </React.Fragment>
                                                ))}
                                            </Stack>
                                            {selectedUserId !== 'all' ? <Typography variant='span' color={"dark"} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>{`(${selectedUserName()} - ${formatDuration(getTotalTimeData()?.spent)})`}</Typography> : null}
                                        </Box>
                                        <Drawer open={openLogDrawer} anchor="right" onClose={() => handleCloseDrawer()}>
                                            <WorkLogDetails queryParams={queryParams} sidebarData={sidebarData} handleCloseDrawer={handleCloseDrawer} selectedProjectData={selectedProjectData} />
                                        </Drawer>
                                        <Box>
                                            <Paper
                                                sx={{
                                                    border: "1px solid #E0E0E0",
                                                    borderRadius: "3px",
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead
                                                            sx={{
                                                                th: {
                                                                    color: "dark.800",
                                                                    fontWeight: 500,
                                                                    fontSize: 14,
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "0.17px",
                                                                    padding: "3px 16px",
                                                                    textAlign: "left",
                                                                    background: "rgba(245, 248, 250, 1)",
                                                                },
                                                            }}
                                                        >
                                                            <TableRow>
                                                                <TableCell>Tasks</TableCell>
                                                                <TableCell sx={{ width: 100, maxWidth: 100, minWidth: 100 }}>Worklog</TableCell>
                                                                <TableCell sx={{ width: 120, maxWidth: 120, minWidth: 120 }}>Estimation</TableCell>
                                                                <TableCell sx={{ width: 120, maxWidth: 120, minWidth: 120 }}>Time Spent</TableCell>
                                                                <TableCell sx={{ width: 120, maxWidth: 120, minWidth: 120 }}>Saved Time</TableCell>
                                                                <TableCell sx={{ width: 120, maxWidth: 120, minWidth: 120 }}>Over Spent</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody
                                                            sx={{
                                                                "tr:hover": {
                                                                    background: "rgba(247, 247, 247, 1)",
                                                                    '.task-name': {
                                                                        color: "#047FE0",
                                                                        textDecoration: "underline",
                                                                    },
                                                                    '.external-link-icon': {
                                                                        opacity: 1,
                                                                        visibility: "visible",
                                                                    }
                                                                },
                                                                td: {
                                                                    color: "dark.800",
                                                                    fontWeight: 400,
                                                                    fontSize: 14,
                                                                    lineHeight: "20px",
                                                                    letterSpacing: "0.17px",
                                                                    padding: "8px 16px",
                                                                    textAlign: "left",
                                                                },
                                                            }}
                                                        >
                                                            {
                                                                allTaskDataList?.length ?
                                                                    <>
                                                                        {sortArrayByKey(allTaskDataList, "asc", "task_no")?.map((taskData, index) =>
                                                                        (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ maxWidth: 0, minWidth: 300, cursor: "pointer" }}>
                                                                                    <Box display={'flex'} alignItems={"center"} gap={1} onClick={() => handleClickOpenDrawer(taskData)}>
                                                                                        <HtmlTooltip arrow
                                                                                            title={
                                                                                                <React.Fragment>
                                                                                                    <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"19px"}>{TASK_TYPE_LIST[taskData?.type]}</Typography>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        >
                                                                                            <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                                                {getTaskTypeIcon(taskData?.type)}
                                                                                            </Box>
                                                                                        </HtmlTooltip>
                                                                                        <Box overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"}>
                                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"} overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"} className='task-name'>
                                                                                                {selectedProjectData?.short_code}-{taskData?.task_no} - {taskData?.title}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box width={14} minWidth={14} height={14} color={"primary.800"} className="external-link-icon" visibility={"hidden"} sx={{ opacity: 0, }}>
                                                                                            {ICONS.ExternalLinkIcon}
                                                                                        </Box>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCell sx={{
                                                                                    'svg': {
                                                                                        fontSize: 22,
                                                                                        color: "black",
                                                                                    }
                                                                                }}>
                                                                                    {ICONS.WorkLogIcon}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography color={"dark"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                        {taskData?.estimation ? secondsToTime(taskData?.estimation ?? 0) : "-"}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography color={"dark"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                        {secondsToTime(taskData?.spent ?? 0)}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography color={"#05B01B"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                        {taskData?.estimation ? secondsToTime(taskData?.savedTime) : "-"}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography color={"#E75252"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                        {taskData?.estimation ? secondsToTime(taskData?.overSpent) : "-"}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        <TableRow key={allTaskDataList?.length}
                                                                            sx={{
                                                                                'td': {
                                                                                    padding: '11px 16px',
                                                                                }
                                                                            }}
                                                                        >
                                                                            <TableCell>
                                                                                <Typography color={"secondary"} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                    Total Hours
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell sx={{
                                                                                'svg': {
                                                                                    fontSize: 22,
                                                                                    color: "black",
                                                                                }
                                                                            }}>
                                                                                {ICONS.WorkLogIcon}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography color={"dark"} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                    {/* { secondsToTime(taskData?.estimation ?? 0)} */}
                                                                                    {secondsToTime(getTotalTimeData()?.estimation)}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography color={"dark"} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                    {secondsToTime(getTotalTimeData()?.spent)}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography color={"#05B01B"} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                    {secondsToTime(getTotalTimeData()?.saved)}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography color={"#E75252"} fontSize={14} fontWeight={700} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                    {secondsToTime(getTotalTimeData()?.over)}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                    : (
                                                                        <TableRow
                                                                            sx={{
                                                                                "&:hover": {
                                                                                    background: "transparent !important",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <TableCell
                                                                                colSpan={6}
                                                                                align="center"
                                                                                sx={{ padding: "12px 16px;", border: 'none !important' }}
                                                                            >
                                                                                <Typography
                                                                                    color={"dark.800"}
                                                                                    display={"flex"}
                                                                                    alignItems={"center"}
                                                                                    justifyContent={"center"}
                                                                                    fontSize={14}
                                                                                    sx={{
                                                                                        opacity: "1",
                                                                                        height: "200px",
                                                                                        color: "dark.800",
                                                                                    }}
                                                                                >
                                                                                    No records found.
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Box>
                                    </>
                                    :
                                    <Box bgcolor={"#FAFBFF"} display={"flex"} flexDirection={"column"} py={"60px"} px={6} alignItems={"center"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }}>
                                        {(selectedProject) ? <Typography
                                            variant="body1"
                                            color="dark.800"
                                            fontSize={14}
                                            fontWeight={400}
                                            mb={2.5}
                                        >
                                            No data available
                                        </Typography> :
                                            <Typography
                                                variant="body1"
                                                color="dark.800"
                                                fontSize={14}
                                                fontWeight={400}
                                                mb={2.5}
                                            >
                                                Select <b style={{ fontWeight: 700 }}>Project</b> and <b style={{ fontWeight: 700 }}>User</b> to see their Task comparison Report
                                            </Typography>}
                                        <Box>
                                            <img alt='404 page' src={reportImg} style={{ maxWidth: '100%', width: '420px' }} />
                                        </Box>
                                    </Box>
                        }
                    </Box>
                </Box>
            </>
    )
}

export default TaskEstimationComparision