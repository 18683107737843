/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Grid, Typography, Modal, Box } from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { base64ToBlob, convertToBase64, generateUniqueId, titleCase } from "../../utils";
import { useAlert } from "../../hook/useAlert";
import PDFViewer from "../PDFViewer";
import ICONS from "../../constants/icons";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { saveAs } from 'file-saver';

const img = {
    display: "inline-block",
    width: "auto",
    height: "100%",
    "WebkitUserDrag": "none",
};

const focusedStyle = {
    borderColor: " #808080"
};

const acceptStyle = {
    borderColor: "#517c58"
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const CustomFileDropZoneDocument = ({
    onDrop,
    initialFiles = [],
    fileURLs = [],
    readOnly = false,
    maxTotalSize = 10 * 1000000,
    onDeleteImage,
    isConvertToBase64 = false,
    disabled = false,
    uniqueId
}) => {
    const showAlert = useAlert();
    const maxFileSize = 5 * 1000000;
    const [acceptedFilesList, setAcceptedFilesList] = useState(initialFiles || []);
    const [previewFile, setPreviewFile] = useState(null);

    useEffect(() => {
        if (!!fileURLs?.length) {
            const convertToFile = fileURLs?.filter(value => !!value)?.map((urlData) => ({
                name: urlData?.doc_url?.substring(urlData?.doc_url.lastIndexOf("/") + 1),
                preview: urlData?.doc_url,
                unique_id: generateUniqueId(),
                id: urlData?.id ?? null,
                doc_name: urlData?.doc_name
            }));
            setAcceptedFilesList((prevFiles) => [...convertToFile]);
            // setAcceptedFilesList((prevFiles) => [...prevFiles, ...convertToFile]);
        }
    }, [fileURLs]);

    useEffect(() => {
        return () => acceptedFilesList?.forEach((file) => URL.revokeObjectURL(file?.preview));
    }, []);

    const fileSizeValidator = (file, type = "file") => {
        const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
        if (type === "validator") {
            if (!allowedFormats.includes(file.type)) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, DOC, DOCX and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
        } else {
            const allowedFormats = [".jpeg", ".jpg", ".png", ".pdf", ".doc", ".docx"];
            const isValidFormat = allowedFormats.some(format => file.name?.toLowerCase()?.endsWith(format));
            if (!isValidFormat) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, DOC, DOCX, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
            if (file?.size > maxFileSize) {
                showAlert("File size exceeds 5MB limit.", "error");
                return {
                    code: "size-too-large",
                    message: "File size exceeds 5MB limit.",
                };
            }
        }
        return null;
    };

    const downloadSingleImage = async (url, fileName) => {
        try {
            const response = await fetch(url);
            const blob = await response?.blob();
            saveAs(blob, fileName);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    const totalSizeValidator = (newFiles) => {
        const totalSize = acceptedFilesList?.reduce((acc, file) => acc + file.size, 0);
        const newTotalSize = newFiles?.reduce((acc, file) => acc + file.size, 0);
        return totalSize + newTotalSize > maxTotalSize;
    };

    const { getRootProps, getInputProps, isDragAccept, isDragReject, isFocused } = useDropzone({
        // accept: {
        //     "image/*": [".jpeg", ".jpg", ".png", ".pdf", ".doc", ".docx"],
        // },
        accept: {
            "image/*": [".jpeg", ".jpg", ".png"],
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        },
        onDrop: async (newlyAddedFiles) => {
            const invalidFiles = newlyAddedFiles
                .map(file => fileSizeValidator(file))
                .filter(error => error !== null);

            if (invalidFiles.length > 0) return;

            if (totalSizeValidator(newlyAddedFiles)) {
                showAlert("Total size exceeds the limit. Max 10MB allowed.", "error");
                return;
            }

            const updatedFiles = [];

            for (const file of newlyAddedFiles) {
                if (isConvertToBase64) {
                    let base64 = null;
                    if (file instanceof Blob || file instanceof File) {
                        base64 = await convertToBase64(file);
                    } else {
                        base64 = file?.base64;
                    }
                    let fileObject = {
                        name: file?.name,
                        size: file?.size,
                        type: file?.type,
                        preview: URL.createObjectURL(file),
                        unique_id: generateUniqueId(),
                        base64,
                    };
                    updatedFiles.push(fileObject);
                } else {
                    updatedFiles.push(Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        unique_id: generateUniqueId(),
                    }));
                }
            }
            const limitedFiles = [...acceptedFilesList, ...updatedFiles]?.filter((item) => item && (item !== null || item !== "null"));
            setAcceptedFilesList(limitedFiles);
            onDrop(limitedFiles);
        },
        validator: (file) => fileSizeValidator(file, "validator"),
    });

    const handleDeleteFile = (file) => {
        // const updatedFiles = acceptedFilesList?.filter((item) => item.unique_id !== file.unique_id)?.filter((item) => item && (item !== null || item !== "null"));
        const updatedFiles = acceptedFilesList?.filter((item) => item.unique_id !== file.unique_id)?.filter((item) => item && (item !== null || item !== "null"));
        setAcceptedFilesList(updatedFiles);
        // onDrop(updatedFiles);
        if (!!fileURLs?.length) {
            onDeleteImage({ ...file, parentUniqueId: uniqueId });
        }
    };

    const openPreview = (file) => {
        if (file?.id) {
            setPreviewFile(file);
        }
    };

    const closePreview = () => {
        setPreviewFile(null);
    };

    const thumbs = acceptedFilesList?.map((file, index) => (
        <Box key={file?.unique_id} flex={1} maxWidth={{ xs: 220, lg: 270, xl: 334 }} width={{ xs: 220, lg: 270, xl: 334 }} display={"flex"} flexDirection={"column"} gap={0.5}>
            <Box display={"flex"} alignItems={"center"} p={0.5} gap={"10px"}
                sx={{
                    transition: 'all 0.25s ease-in-out',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    'button': {
                        opacity: 0,
                        transition: 'all 0.25s ease-in-out',
                    },
                    '&:hover': {
                        background: 'white',
                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.19)',
                        'p': {
                            color: '#047FE0',
                        },
                        'button': {
                            opacity: 1,
                        },
                    },
                }}
            >
                <Box width={24} maxWidth={24} minWidth={24} height={24} borderRadius={"5px"} overflow={"hidden"}>
                    {file?.name?.toLowerCase()?.includes(".pdf") ?
                        <Box onClick={() => openPreview(file)}>
                            {ICONS.PDFIcon}
                        </Box> :
                        (file?.name?.toLowerCase()?.includes(".doc") || file?.name?.toLowerCase()?.includes(".docx")) ?
                            <Box onClick={() => openPreview(file)}>
                                {ICONS.PDFIcon}
                            </Box> :
                            <img
                                alt="attachment"
                                src={file?.base64 ? URL.createObjectURL(base64ToBlob(file?.base64, file?.type)) : file?.preview}
                                style={{
                                    ...img, cursor: readOnly ? "pointer" : null,
                                }}
                                onClick={() => openPreview(file)}
                            />}

                    <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.17px"} flex={1} overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"}>{titleCase(file?.name)}</Typography>
                    <IconButton
                        size="small"
                        color="primary"
                        sx={{ p: "1px", width: 20, minWidth: 20, maxWidth: 20 }}>
                        <FileDownloadIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                    <IconButton
                        size="small"
                        color="error"
                        sx={{ p: "1px", width: 20, minWidth: 20, maxWidth: 20 }}>
                        <DeleteIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    ));

    const style = useMemo(() => {
        const borderColor = isFocused ? focusedStyle.borderColor : isDragAccept ? acceptStyle.borderColor : isDragReject ? rejectStyle.borderColor : "rgba(81, 95, 124, 0.25)";

        return {
            border: `1px dashed ${borderColor}`,
            ...(isFocused || isDragAccept || isDragReject ? {} : {
                borderColor: "rgba(81, 95, 124, 0.25)"
            }),
            cursor: "pointer",
            padding: "10px 8px",
            borderRadius: "4px",
            "&:hover": {
                borderColor: "primary.main",
            },
        };
    }, [isFocused, isDragAccept, isDragReject]);

    return (
        <>
            <Box display={'flex'} flexDirection={'row'} className="attached-box">
                <Box display={'flex'} flexDirection={'row'} width={{ xs: 240, lg: 340 }} minWidth={{ xs: 240, lg: 340 }} maxWidth={{ xs: 240, lg: 340 }}>
                    <Grid
                        item
                        {...getRootProps({ className: "dropzone" })}
                        style={style}
                        display="flex"
                        alignItems="center"
                    >
                        <input {...getInputProps()} disabled={disabled} />
                        <i
                            style={{
                                height: "40px",
                                maxWidth: "40px",
                                flex: "0 0 40px",
                                padding: "10px",
                            }}
                        >
                            <UploadFileOutlinedIcon color="primary" sx={{ fontSize: "20px" }} />
                        </i>
                        <Box ml={1}>
                            <Typography
                                color="primary"
                                fontSize={14}
                                fontWeight={400}
                                mr={0.5}
                                className="upload-text"
                                sx={{
                                    display: "inline-block",
                                    lineHeight: "24px",
                                    letterSpacing: "0.15px",
                                    textDecoration: "underline",
                                }}
                            >
                                Click to upload
                            </Typography>
                            <Typography
                                color="dark.800"
                                fontSize={14}
                                fontWeight={400}
                                className="drag-text"
                                sx={{
                                    display: "inline-block",
                                    lineHeight: "24px",
                                    letterSpacing: "0.15px",
                                }}
                            >
                                or drag and drop
                            </Typography>
                            <Typography
                                color="dark.800"
                                fontSize={11}
                                fontWeight={400}
                                mt={0.5}
                                className="size-text"
                                sx={{
                                    display: "block",
                                    lineHeight: "15px",
                                    letterSpacing: "0.17px",
                                }}
                            >
                                PDF, PNG, JPG (max. 10MB per file)
                            </Typography>
                        </Box>
                    </Grid>
                </Box>

                {/* Modal for image preview */}

                <Modal
                    open={Boolean(previewFile)}
                    onClose={closePreview}
                    aria-labelledby="image-preview"
                    aria-describedby="image-preview-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: '80vw',
                        maxHeight: '80vh',
                        overflow: 'auto'
                    }}>
                        {(previewFile?.name?.toLowerCase()?.includes(".pdf") || previewFile?.doc_name?.toLowerCase()?.includes(".pdf")) ? <PDFViewer url={previewFile?.preview} /> :
                            (previewFile?.name?.toLowerCase()?.includes(".doc") || previewFile?.name?.toLowerCase()?.includes(".docx") || previewFile?.doc_name?.toLowerCase()?.includes(".doc")
                                || previewFile?.doc_name?.toLowerCase()?.includes(".docx")) ? <img src={previewFile?.preview} alt="No preview available" style={{ maxWidth: '100%' }} /> :
                                <img src={previewFile?.preview} alt="preview" style={{ maxWidth: '100%' }} />
                        }
                    </Box>
                </Modal>
            </Box>
            {thumbs?.length ?
                <Box display={'flex'} flexDirection={'column'}>
                    {acceptedFilesList?.map((file, index) => (
                        <Box key={file?.unique_id} flex={1} maxWidth={{ xs: 220, lg: 250, xl: 334 }} width={{ xs: 220, lg: 250, xl: 334 }} display={"flex"} flexDirection={"column"} gap={0.5}>
                            <Box display={"flex"} flexDirection={'row'} alignItems={"center"} p={0.5} gap={"10px"}
                                sx={{
                                    transition: 'all 0.25s ease-in-out',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    'button': {
                                        opacity: 0,
                                        transition: 'all 0.25s ease-in-out',
                                    },
                                    '&:hover': {
                                        background: 'white',
                                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.19)',
                                        'p': {
                                            color: '#047FE0',
                                        },
                                        'button': {
                                            opacity: 1,
                                        },
                                    },
                                }}
                            >
                                <Box width={24} maxWidth={24} minWidth={24} height={24} borderRadius={"5px"} overflow={"hidden"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                    {(file?.name?.toLowerCase()?.includes(".pdf") || file?.doc_name?.toLowerCase()?.includes(".pdf")) ?
                                        <Box onClick={() => openPreview(file)}>
                                            {ICONS.PDFIcon}
                                        </Box> :
                                        (file?.name?.toLowerCase()?.includes(".doc") || file?.name?.toLowerCase()?.includes(".docx") || file?.doc_name?.toLowerCase()?.includes(".doc") || file?.doc_name?.toLowerCase()?.includes(".docx")) ?
                                            <Box onClick={() => openPreview(file)}>
                                                {ICONS.DocIcon}
                                            </Box> :
                                            <img
                                                alt="attachment"
                                                src={file?.base64 ? URL.createObjectURL(base64ToBlob(file?.base64, file?.type)) : file?.preview}
                                                style={{
                                                    ...img, cursor: readOnly ? "pointer" : null, height: 'auto', objectFit: 'cover', objectPosition: "center center", minWidth: "100%", minHeight: "100%",
                                                }}
                                                onClick={() => openPreview(file)}
                                            />}
                                </Box>
                                <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.17px"} flex={1} overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"}>{titleCase(file?.id ? file?.doc_name : file?.name)}</Typography>
                                {file?.id ? <IconButton
                                    size="small"
                                    color="primary"
                                    sx={{ p: "1px", width: 20, minWidth: 20, maxWidth: 20 }}
                                    onClick={() => downloadSingleImage(file?.preview, file?.name)}
                                    disabled={disabled}
                                >
                                    <FileDownloadIcon sx={{ fontSize: 18 }} />
                                </IconButton> : null}
                                {(!file?.id || acceptedFilesList?.length > 1) ? <IconButton
                                    size="small"
                                    color="error"
                                    sx={{ p: "1px", width: 20, minWidth: 20, maxWidth: 20 }}
                                    onClick={() => handleDeleteFile(file)}
                                    disabled={disabled}
                                >
                                    <DeleteIcon sx={{ fontSize: 18 }} />
                                </IconButton> : null}
                            </Box>
                        </Box>
                    ))}
                </Box>
                : null}
        </>
    );
};

export default CustomFileDropZoneDocument;