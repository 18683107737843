import URLS from "../routes/urls";
import ICONS from "../constants/icons";

const RequestSidebarSettings = [
    {
        name: "Request",
        icon: ICONS.Request,
        subMenu: [
            {
                name: "Leave",
                href: URLS.Leaves,
            },
            {
                name: "WFH / Timing Request",
                href: URLS.WorkFromHome,
            },
        ],
    },
];

export default RequestSidebarSettings;
