/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { Dialog, DialogContent, Divider, Drawer, FormControl, FormHelperText, Grid, IconButton, InputLabel, List, ListItem, Menu, MenuItem, Modal, Select, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material';
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ICONS from '../../../constants/icons';
import { makeStyles, styled } from '@mui/styles';
import useAuthentication from '../../../hook/useAuthentication';
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import CircularLoader from '../../../components/CircularLoader';
import { convertDateFromFormat, getFormattedDate, getFullName, getInputId, getReportingAuthority, sortArrayByKey, titleCase } from '../../../utils';
import SearchBoxInput from '../../../components/SearchBoxInput';
import URLS from '../../../routes/urls';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../hook/useTitle';
import BackgroundLetterAvatars from '../../../components/BackgroundLetterAvatars';
import UserRoleChip from '../../../components/UserRoleChip';
import LeaveReportModal from './UserTabPages/leaveReportModal';
import TablePagination from '../../../components/TablePagination';
import { useAlert } from '../../../hook/useAlert';
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import OtherUserSingleDatePicker from '../../../components/OtherUserSingleDatePicker';
import { getDefaultInOutTime, getTime, LEAVE_DURATION_TYPE } from '../../../constants/default-values';
import TimePickerClock from '../../../components/TimePickerClock';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AutocompleteSelect from '../../../components/AutocompleteSelect';
import moment from 'moment';
import Button from '../../../components/Button';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        padding: '16px'
    },
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        overflowX: 'hidden',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));


const Users = () => {
    const navigate = useNavigate();
    const showAlert = useAlert();
    const classes = useStyles();
    const { getCurrentUser } = useAuthentication();
    const { setPageTitle } = usePageTitle();
    const currentUser = getCurrentUser();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('emp_id');
    const [expandedRow, setExpandedRow] = useState([])
    const [userRolesData, setUserRolesData] = useState([]);
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
        userRoleLoading: true,
        userTypeLoading: true,
        years: true,
    });
    const Group_Filter = {
        'none': 'None',
        'status': 'Status',
        'role': 'Role',
        'workType': 'Work Type'
    }
    const [userData, setUserData] = useState({});
    const [userDataCount, setUserDataCount] = useState(0);
    const [openElem, setOpenElem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isMakeAnAdminOpen, setIsMakeAnAdminOpen] = useState(false);
    const [editableUserModalData, setEditableUserModalData] = useState({});
    const [userTypes, setUserTypes] = useState([]);
    const [isReporModalOpen, setIsReporModalOpen] = useState(false);
    const [activeYear, setActiveYear] = useState({});
    const [leaveReportyears, setLeaveReportYears] = useState([]);
    const [leaveData, setLeaveData] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerUserDetails, setDrawerUserDetails] = useState({});
    const [isDisabledClearFilter, setIsDisabledClearFilter] = useState(true);
    let { inTime, outTime } = getDefaultInOutTime();
    const initialLeaveDetails = {
        leave_date: "",
        user_leave_type_setting_id: null,
        leave_duration: Object.keys(LEAVE_DURATION_TYPE)[2],
        in_time: inTime,
        out_time: outTime,
        org_work_type_setting_id: null,
        informed_authorities: {}
    }
    const [generatedLeaveDetails, setGeneratedLeaveDetails] = useState(initialLeaveDetails);
    const initialErrors = {
        leave_date: "",
        user_leave_type_setting_id: "",
        leave_duration: "",
        in_time: "",
        out_time: "",
        informed_authorities: ""
    }
    const [errors, setErrors] = useState(initialErrors);
    const [userLeaveDataLoading, setUserLeaveDataLoading] = useState({
        leaveType: true,
        leaveRequestData: false,
        leaveCreateFormSubmit: false,
        userAuthorityLoading: false
    });
    const [userLeaveTypes, setUserLeaveTypes] = useState([]);
    const [userIgnoreDates, setUserIgnoreDates] = useState([]);
    const [userRequestedLeaves, setUserRequestedLeaves] = useState([]);
    const [organizationUserList, setOrganizationUserList] = useState([]);
    const [halfRequestData, setHalfRequestData] = useState([]);

    setPageTitle("Users");

    const [params, setParams] = useState({
        page: 0,
        limit: 25,
        sortOrder: "asc",
        sortField: "id",
        group: Object.keys(Group_Filter)[0],
        filter: "",
        search: null
    });

    useEffect(() => {
        const getUserRolesData = async () => {
            try {
                setLoading((prev) => ({ ...prev, userRoleLoading: true }));
                const response = await axiosInstance.get(
                    API.organisationUserRoles(currentUser?.organization_id)
                );
                if (response.status === 200) {
                    setUserRolesData([{ id: (response?.data?.data?.length + 1), name: 'All', canDelete: true }, ...response?.data?.data]);
                }
                setLoading((prev) => ({ ...prev, userRoleLoading: false }));
            } catch (error) {
                console.error(error);
                setLoading((prev) => ({ ...prev, userRoleLoading: false }));
            }
        };

        const getUserTypeData = async () => {
            try {
                setLoading((prev) => ({ ...prev, userTypeLoading: true }));
                const response = await axiosInstance.get(API.getUserTypes);
                if (response.status === 200) {
                    setUserTypes(response.data.data);
                }
                setLoading((prev) => ({ ...prev, userTypeLoading: false }));
            } catch (error) {
                console.error(error);
                setLoading((prev) => ({ ...prev, userTypeLoading: false }));
            }
        }
        getUserTypeData();

        if (currentUser?.organization_id) {
            getUserRolesData();
            getAllHolidayYears();
        }
    }, []);

    useEffect(() => {
        setUserDataCount(0);
        getAllUserListByOrgId();
    }, [params]);

    const getAllHolidayYears = async () => {
        try {
            setLoading((prev) => ({ ...prev, years: true }));
            const response = await axiosInstance.get(
                API.getAllHolidaysYear(currentUser.organization_id)
            );
            if (response.status === 200) {
                let sortedYear = response?.data?.result?.sort((a, b) =>
                    a?.year?.toString()?.localeCompare(b?.year?.toString())
                );

                setLeaveReportYears(sortedYear);
                let activeYear = sortedYear?.find(yearData => yearData?.active);
                setActiveYear(activeYear)
            }
            setLoading((prev) => ({ ...prev, years: false }));
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading((prev) => ({ ...prev, years: false }));
        }
    };

    const getAllUserListByOrgId = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            let queryParamsPageIncrease = { ...params, page: params?.page + 1 };
            if (params.group === "none") {
                queryParamsPageIncrease.group = ""
            }
            const response = await axiosInstance.post(API.getAllUsersDataByOrgId(currentUser?.organization_id), queryParamsPageIncrease);
            if (response?.status === 200) {
                if (response?.data?.results) {
                    setDataAccordingToFilters(response?.data?.results)
                    setUserDataCount(response?.data?.totalData)
                } else {
                    setUserData({})
                }
            } else {
                setUserData({})
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const getReportingUserData = async (userId) => {
        try {
            setUserLeaveDataLoading((prev) => ({ ...prev, userAuthorityLoading: true }))
            const response = await axiosInstance.get(API.organisationUserWithInformationAuthorityByUserId(userId));
            if (response.status === 200) {
                let reporting_authorities = response?.data?.data?.reporting_authorities;
                let second_reporting_authorities = response?.data?.data?.second_reporting_authorities;
                let defaultUsers = response?.data?.data?.defaultUsers;
                let users = [];
                if (reporting_authorities && reporting_authorities?.length > 0) {
                    users.push(...reporting_authorities);
                }
                if (second_reporting_authorities && second_reporting_authorities?.length > 0) {
                    users.push(...second_reporting_authorities);
                }
                if (users?.length <= 0) {
                    users.push(...defaultUsers);
                }
                setOrganizationUserList(users?.filter((userData => userData?.id !== userId)) || []);
                setUserLeaveDataLoading((prev) => ({ ...prev, userAuthorityLoading: false }))
            } else {
                setUserLeaveDataLoading((prev) => ({ ...prev, userAuthorityLoading: false }))
            }
        } catch (error) {
            console.error(error);
            setUserLeaveDataLoading((prev) => ({ ...prev, userAuthorityLoading: false }))
        }
    };

    const sortDataForDefaultView = (data) => {
        return data?.sort((a, b) => {
            const roleA = a?.user_role?.toUpperCase();
            const roleB = b?.user_role?.toUpperCase();
            if (roleA < roleB)
                return -1;
            if (roleA > roleB)
                return 1;
            return 0;
        })
    }

    const setDataAccordingToFilters = (data) => {
        let keyToFilter = (params.group === 'role') ? 'user_role' : params.group === 'workType' ? 'work_type' : 'active';
        if (params?.sortField === '') {
            data = sortDataForDefaultView([...data]);
        }
        if (params.group !== "status") {
            data = data?.filter((userData) => userData?.active)
        }
        let filteredData = data?.reduce((result, item) => {
            const _key = keyToFilter === 'active' ? item[keyToFilter] ? titleCase(keyToFilter) : 'Inactive' : titleCase(item[keyToFilter]);
            if (!result[_key]) {
                result[_key] = [];
            }
            result[_key].push(item);
            return result;
        }, {});
        setExpandedRow(Object.keys(filteredData));
        setUserData(filteredData)
    }

    const handleChangePage = (event, newPage) => {
        setParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setParams((prev) => ({
            ...prev,
            limit: event.target.value,
            page: 0,
        }));
    };

    const handleFilterChange = (event) => {
        setParams((prev) => ({
            ...prev,
            [event?.target?.name]: event?.target?.value === "All" ? '' : event?.target?.value
        }))
        setExpandedRow([]);
        setIsDisabledClearFilter(false)
    }

    const handleRowClick = (filterName) => {
        setExpandedRow((prev) => prev.includes(filterName) ? prev?.filter(name => name !== filterName) : [...prev, filterName])
    }

    const handleSearch = (value) => {
        setParams((prev) => ({
            ...prev,
            search: value,
        }));
        if (value !== '') {
            setIsDisabledClearFilter(false)
        }
    };

    const createSortHandler = (property) => {
        const isAsc = (orderBy === property) ? (order === 'asc') ? true : false : true;
        setOrder(isAsc ? 'desc' : 'asc');
        setParams((prev) => ({ ...prev, sortOrder: isAsc ? 'desc' : 'asc', sortField: property }))
        setOrderBy(property);
    }

    const handleLeaveCount = (userDetail) => {
        let total_leave = userDetail?.userLeaveTypeData?.reduce((total, leave) => {
            return total + leave?.leave_used
        }, 0);
        return `${total_leave || 0}/${(userDetail?.total_leave_allocation || 0)}`;
    }

    const handleRemainingLeaveCount = (userLeaveData) => {
        let remainingLeave = userLeaveData?.reduce((result, item) => {
            const _key = item?.leave_type;
            if (!result[_key]) {
                result[_key] = [];
            }
            result[_key] = `${item?.leave_used || 0}/${(item?.total_leave_allocation) || 0} Days`;
            return result;
        }, {});
        return remainingLeave;
    }

    const handleClearFilter = () => {
        setParams(prev => ({
            ...prev,
            page: 0,
            sortOrder: "asc",
            sortField: "id",
            group: Object.keys(Group_Filter)[0],
            filter: "",
            search: ""
        }))
        setIsDisabledClearFilter(true)
    }

    const handleViewUser = (userDetail, tabValue = 3) => {
        handleClose();
        navigate(`${URLS.UserDetails}/${userDetail?.id}?tabValue=${tabValue}`)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    const handleClickEdit = (userDetail) => {
        navigate(`${URLS.UserDetails}/${userDetail?.id}?tabValue=0&isEdit=true`)
    }

    const handleSubmitMakeAdmin = async () => {
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            let adminTypeId = userTypes?.find(item => item?.name?.toLowerCase() === "admin")?.id;
            let administratorRoleId = userRolesData?.find(item => item?.name?.toLowerCase() === "administrator")?.id;
            const body = {
                "name": editableUserModalData?.name,
                "reporting_authorities": editableUserModalData?.reporting_authorities?.length ? editableUserModalData?.reporting_authorities : null,
                "second_reporting_authorities": editableUserModalData?.second_reporting_authorities?.length ? editableUserModalData?.second_reporting_authorities : null,
                "current_user_type_id": editableUserModalData?.is_admin ? editableUserModalData?.current_user_type_id : adminTypeId ?? editableUserModalData?.current_user_type_id,
                "current_user_role_id": editableUserModalData?.is_admin ? editableUserModalData?.current_user_role_id : administratorRoleId ?? editableUserModalData?.current_user_role_id,
                "org_work_type_setting_id": editableUserModalData?.org_work_type_setting_id,
                "is_admin": editableUserModalData?.is_admin ? false : true,
                "is_dedicated_developer": editableUserModalData?.is_dedicated_developer,
                "enable_sys_generated_leave": editableUserModalData?.enable_sys_generated_leave,
                "active": editableUserModalData?.active,
            };
            if (editableUserModalData?.joining_date) {
                body['joining_date'] = editableUserModalData?.joining_date
            }
            if (editableUserModalData?.termination_date) {
                body['termination_date'] = editableUserModalData?.termination_date
            }

            let response = await axiosInstance.put(API.fetchUserDetails(editableUserModalData?.id), body);
            if (response.status === 200) {
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
                showAlert(response.data.message);
                getAllUserListByOrgId();
                handleCloseModal();
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error(error);
            handleCloseModal();
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    }

    const handleModalOpen = (userDetails) => {
        setIsMakeAnAdminOpen(true);
        setEditableUserModalData(userDetails);
    }

    const handleCloseModal = () => {
        setIsMakeAnAdminOpen(false);
        setAnchorEl(null);
        setOpenElem(null);
        setEditableUserModalData({});
    }

    const handleClickOpenModal = (userDetails) => {
        setIsReporModalOpen(true)
        setLeaveData(userDetails)
    }

    const handleReportModalClose = () => {
        setIsReporModalOpen(false)
        setLeaveData({})
    }

    const handleToggleDrawer = (newOpen, userDetails) => {
        setDrawerOpen(newOpen);
        setDrawerUserDetails(userDetails);
        if (newOpen) {
            setGeneratedLeaveDetails(prev => ({ ...prev, org_work_type_setting_id: userDetails?.org_work_type_setting_id }))
            getUserLeaveTypesData(userDetails?.id, userDetails?.org_work_type_setting_id);
            getUserAllRequestData(userDetails?.id);
            getReportingUserData(userDetails?.id);
        } else {
            setUserLeaveTypes([]);
            setUserIgnoreDates([]);
            setUserRequestedLeaves([]);
            setHalfRequestData([]);
            setErrors(initialErrors);
            setGeneratedLeaveDetails(initialLeaveDetails);
        }
        setAnchorEl(null);
        setOpenElem(null);
    }

    const handleClick = (elem) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const getIgnoreDates = () => {
        let updatedList = [getFormattedDate(generatedLeaveDetails?.leave_date)]
        return Array.from(new Set(updatedList));
    }

    const handleChangeSelectedDate = (value) => {
        let convertedDate = getFormattedDate(new Date(value), "YYYY-MM-DD", true);
        let isDateExist = halfRequestData?.find(requestedData => requestedData?.leave_date === convertedDate);
        let timeObj = {};
        if (!!isDateExist) {
            if (isDateExist?.leave_duration === 'first_half') {
                timeObj['out_time'] = getTime(isDateExist?.in_time)
            } else if (isDateExist?.leave_duration === 'second_half') {
                timeObj['in_time'] = getTime(isDateExist?.out_time)
            }
        }
        setGeneratedLeaveDetails((prev) => ({
            ...prev,
            ...timeObj,
            // leave_date: getFormattedDate(new Date(value)),
            leave_date: convertedDate,
            leave_duration: !!isDateExist ? isDateExist?.leave_duration === 'first_half' ? 'second_half' : 'first_half' : prev?.leave_duration,
        }))
        setErrors((prev) => ({
            ...prev,
            leave_date: new Date(value)?.toDateString()?.length ? "" : "Required"
        }))
    }

    const isEnableDuratioOption = (leaveType, date, duration) => {
        let isDateExist = halfRequestData?.find(leaveData => getFormattedDate(leaveData?.leave_date, "DD/MM/YYYY") === date)
        if (!!isDateExist) {
            return leaveType !== duration
        }
        return false
    }

    const getIgnoreDatesArray = (holidaySettingData) => {
        let array = [];
        if (!!holidaySettingData?.leaves?.length) {
            array.push(...holidaySettingData?.leaves);
        }
        if (!!holidaySettingData?.custom_holidays?.length) {
            array.push(...holidaySettingData?.custom_holidays?.map(value => value?.date));
        }
        if (!!holidaySettingData?.festival_holidays?.length) {
            array.push(...holidaySettingData?.festival_holidays?.map(value => value?.date));
        }
        if (!!holidaySettingData?.saturdayHolidays?.length) {
            array.push(...holidaySettingData?.saturdayHolidays?.map(value => value?.date));
        }
        if (!!holidaySettingData?.sundayHolidays?.length) {
            array.push(...holidaySettingData?.sundayHolidays?.map(value => value?.date));
        }
        if (!!holidaySettingData?.inactive_holidays?.length) {
            array.push(...holidaySettingData?.inactive_holidays?.map(value => value?.date));
        }
        if (holidaySettingData?.disabledDates?.length) {
            array.push(...holidaySettingData?.disabledDates?.map(date => convertDateFromFormat(date, 'YYYY-MM-DD', 'DD/MM/YYYY')));
        }
        return Array.from(new Set(array?.map(date => (getFormattedDate(date)))));
    };

    const getUserAllRequestData = async (userId) => {
        try {
            setUserLeaveDataLoading((prev) => ({ ...prev, leaveRequestData: true }));
            const [
                holidayResponse,
                leaveResponse,
                workFromHomeResponse
            ] = await Promise.all([
                axiosInstance.get(
                    `${API.getHolidayDatesBySettings(currentUser?.organization_id, activeYear?.id)}/leave/${userId}`
                ),
                axiosInstance.put(API.getLeaveDaysByOrgYearId(activeYear?.id), {
                    user_id: userId
                }),
                axiosInstance.get(API.getWfhDisabledDatesById(userId))
            ]);
            let object = {};
            if (holidayResponse?.status === 200) {
                let holidaySettingData = holidayResponse?.data?.data;
                object = { ...object, ...holidaySettingData }
            }

            if (leaveResponse?.status === 200) {
                setUserRequestedLeaves([...(leaveResponse?.data?.data?.leaves ?? []), ...(leaveResponse?.data?.data?.wfh ?? [])])
            } else {
                setUserRequestedLeaves([]);
            }

            if (workFromHomeResponse?.status === 200) {
                object = { ...object, disabledDates: workFromHomeResponse?.data?.dates, halfDayDates: workFromHomeResponse?.data?.halfDayDates ?? [] }
            } else {
                object = { ...object, disabledDates: [] }
            }
            setHalfRequestData([...object?.halfDayLeave, ...[...object?.halfDayDates?.map(wfhData => {
                return {
                    ...wfhData,
                    leave_date: wfhData?.date
                }
            })]])
            setUserLeaveDataLoading((prev) => ({ ...prev, leaveRequestData: false }))
            let ignoredDates = getIgnoreDatesArray(object);
            setUserIgnoreDates(ignoredDates);
        } catch (error) {
            console.error(error);
            setUserLeaveDataLoading((prev) => ({ ...prev, leaveRequestData: false }))
        }
    }

    const getUserLeaveTypesData = async (userId, workTypeId) => {
        try {
            setUserLeaveDataLoading((prev) => ({ ...prev, leaveType: true }))
            const response = await axiosInstance.get(
                `${API.getUserLeaveTypeData(userId)}/${workTypeId}`
            );
            if (response.status === 200) {
                setUserLeaveTypes(response?.data.results);
                setUserLeaveDataLoading((prev) => ({ ...prev, leaveType: false }))
            } else {
                setUserLeaveTypes([]);
                setUserLeaveDataLoading((prev) => ({ ...prev, leaveType: false }))
            }
        } catch (error) {
            console.error(error);
            setUserLeaveDataLoading((prev) => ({ ...prev, leaveType: false }))
        }
    };

    const handleChange = (event) => {
        let { name, value } = event.target;
        setGeneratedLeaveDetails((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: !!value ? '' : 'Required'
        }))
    }

    const handleBlur = (event) => {
        let { name, value } = event.target;
        setErrors((prev) => ({
            ...prev,
            [name]: !!value ? '' : 'Required'
        }))
    }

    const isValidDateFormat = (date) => {
        return new Date(date).toString() !== "Invalid date";
    };

    const handelTimeChange = (time) => {
        if (!time || !isValidDateFormat(time)) {
            setErrors(prev => ({ ...prev, time: 'Required' }));

        } else {
            setGeneratedLeaveDetails(prev => ({
                ...prev,
                [prev?.leave_duration ===
                    Object.keys(LEAVE_DURATION_TYPE)[0]
                    ? "in_time"
                    : "out_time"]:
                    !!time && isValidDateFormat(time) ? new Date(time) : null,
            }))
        }
    }

    const LeaveTypePlugin = () => {
        return (
            <Box display={"flex"} sx={{ padding: "8px 20px" }} gap={2}>
                <Box display={"flex"} alignItems={"center"}>
                    <Box mr={1} width={10} height={10} minWidth={10} bgcolor={"#12B495"} borderRadius={20}></Box>
                    <Typography color={"dark.800"} fontSize={13} fontWeight={400} lineHeight={"18px"} letterSpacing={"0.17px"}>Casual Leave</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Box mr={1} width={10} height={10} minWidth={10} bgcolor={"#D32F2F"} borderRadius={20}></Box>
                    <Typography color={"dark.800"} fontSize={13} fontWeight={400} lineHeight={"18px"} letterSpacing={"0.17px"}>Sick Leave</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Box mr={1} width={10} height={10} minWidth={10} bgcolor={"#047FE0"} borderRadius={20}></Box>
                    <Typography color={"dark.800"} fontSize={13} fontWeight={400} lineHeight={"18px"} letterSpacing={"0.17px"}>Work Remotely</Typography>
                </Box>
            </Box>)
    };

    const DrawerList = (
        <Box
            width={420}
            display={"flex"}
            flexDirection={"column"}
            overflow={"hidden"}
            flex={1}
            role="presentation"
        >
            <Grid
                item
                p={2}
                display="flex"
                justifyContent="space-between"
                sx={{ top: "0", zIndex: "1" }}
            >
                <Box>
                    <Typography
                        color="dark"
                        fontSize={16}
                        fontWeight={500}
                        sx={{ lineHeight: "25.6px", letterSpacing: '0.15px' }}
                    >
                        Generate Leave
                    </Typography>
                    <Box display={"flex"} flexDirection={"row"}>
                        <Typography
                            color="dark"
                            fontSize={16}
                            fontWeight={400}
                            sx={{ lineHeight: "25.6px", letterSpacing: '0.15px' }}
                        >
                            for <b>{titleCase(drawerUserDetails?.name)}</b>
                        </Typography>
                    </Box>
                </Box>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className="edit-btn"
                        onClick={() => handleToggleDrawer(false, {})}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            {(userLeaveDataLoading?.leaveType || userLeaveDataLoading?.leaveRequestData || userLeaveDataLoading?.userAuthorityLoading) ?
                <Box
                    display={"flex"}
                    overflow={"hidden"}
                    role="presentation"
                    justifyContent={'center'}
                    alignContent={'center'}
                    height='100%'
                    minHeight={'500px'}
                >
                    <CircularLoader />
                </Box> :
                <Box flex={1} overflow={"auto"}>
                    <Grid p={3} pb={0}>
                        <Grid
                            item
                            flex={1}
                            sx={{
                                input: {
                                    width: "100%",
                                    height: 30,
                                    border: "none !important",
                                    boxShadow: "none !important",
                                    borderRadius: "0px",
                                    paddingBottom: "6px",
                                    paddingLeft: "28px",
                                    borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.42) !important",
                                    margin: "0",
                                    position: "relative",
                                    background: "transparent",
                                    color: "dark.800",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    letterSpacing: "0.15px",
                                },
                                "input:hover": {
                                    borderBottomColor:
                                        "#333333 !important",
                                },
                                "input:focus": {
                                    borderBottomColor:
                                        "#047FE0 !important",
                                },
                                ".rmdp-container": {
                                    minWidth: "100px",
                                },
                            }}
                        >
                            <Typography
                                id="demo-simple-select-standard-label"
                                fontSize={12}
                                fontWeight={400}
                                color="dark.600"
                                sx={{
                                    width: "100%",
                                    letterSpacing: "0.15px",
                                    marginTop: "-3px",
                                    marginBottom: "2px",
                                }}
                            >
                                Leave Date
                            </Typography>
                            <div style={{ height: 1 }}>
                                <CalendarTodayIcon
                                    sx={{
                                        fontSize: 20,
                                        color: "#3F5C76",
                                        marginBottom: "-5px",
                                        marginLeft: "2px",
                                    }}
                                />
                            </div>
                            <OtherUserSingleDatePicker
                                // value={getFormattedDate(new Date(generatedLeaveDetails?.leave_date)) ? generatedLeaveDetails?.leave_date : ""}
                                value={getFormattedDate(new Date(generatedLeaveDetails?.leave_date)) || ""}
                                ignoreDates={getIgnoreDates()}
                                onChange={(value) =>
                                    handleChangeSelectedDate(value)
                                }
                                ignoreLeaveDates={userIgnoreDates}
                                isMultiple={false}
                                requestDays={userRequestedLeaves}
                                userCreatedAt={drawerUserDetails?.createdAt}
                                plugins={[
                                    <LeaveTypePlugin position="bottom" />,
                                ]}
                            />
                            {!!errors?.leave_date?.length ? <Typography sx={{ color: "re.main", fontSize: "12px" }}>{errors?.leave_date}</Typography> : null}
                        </Grid>
                        <Grid item sx={{ marginTop: '24px' }}>
                            <FormControl variant="standard" sx={{ m: 0, width: "100%" }}>
                                <InputLabel htmlFor={getInputId("user_leave_type_setting_id", 0)}>
                                    Leave Type
                                </InputLabel>
                                <Select
                                    id="user_leave_type_setting_id"
                                    inputProps={{ id: getInputId("user_leave_type_setting_id", 0) }}
                                    name="user_leave_type_setting_id"
                                    onChange={handleChange}
                                    value={generatedLeaveDetails?.user_leave_type_setting_id ?? ""}
                                    onBlur={handleBlur}
                                    label="Leave Type"
                                >
                                    {userLeaveTypes?.map(
                                        ({ name, user_leave_type_setting_id }, index) => (
                                            <MenuItem value={user_leave_type_setting_id} key={index}>
                                                {name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                {!!errors?.user_leave_type_setting_id?.length ?
                                    <FormHelperText sx={{ color: "red.main" }}>
                                        {errors?.user_leave_type_setting_id}
                                    </FormHelperText>
                                    : null}
                            </FormControl>
                        </Grid>
                        <Grid item sx={{ marginTop: '24px' }}>
                            <FormControl
                                className={classes.margin}
                                variant="standard"
                                sx={{
                                    margin: "0px !important",
                                    width: "100%",
                                }}
                            >
                                <AutocompleteSelect
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    options={sortArrayByKey(organizationUserList, 'asc', 'name')}
                                    isOptionEqualToValue={(option, value) => getReportingAuthority(organizationUserList, generatedLeaveDetails?.informed_authorities) ?? {}}
                                    value={getReportingAuthority(organizationUserList, generatedLeaveDetails?.informed_authorities)}
                                    label={"Request To"}
                                    name="informed_authorities"
                                    id="informed_authorities"
                                    disableClearable={!getReportingAuthority(organizationUserList, generatedLeaveDetails?.informed_authorities)}
                                />

                                {!!errors?.informed_authorities?.length ?
                                    <FormHelperText sx={{ color: "red.main" }}>
                                        {errors?.informed_authorities}
                                    </FormHelperText>
                                    : null}
                            </FormControl>
                        </Grid>
                        <Grid item width={'100%'} minWidth={'100%'} sx={{ marginTop: '24px' }}>
                            <FormControl
                                size="small"
                                variant="standard"
                                fullWidth
                                sx={{
                                    ".MuiInputBase-root": {
                                        height: "100%",
                                    },
                                }}
                            >
                                <Typography
                                    id="demo-simple-select-standard-label"
                                    fontSize={12}
                                    fontWeight={400}
                                    color="dark.600"
                                    sx={{
                                        width: "100%",
                                        letterSpacing: "0.15px",
                                        marginTop: "-3px",
                                        marginBottom: "2px",
                                    }}
                                >
                                    Type
                                </Typography>
                                <Select
                                    inputProps={{ id: getInputId("leave_duration") }}
                                    id={getInputId("leave_duration", 0)}
                                    name="leave_duration"
                                    value={generatedLeaveDetails?.leave_duration || ""}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    height="44px"
                                // disabled={((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated) || false)}
                                >
                                    {Object.entries(LEAVE_DURATION_TYPE).map(
                                        (leaveType, index) => (
                                            <MenuItem key={index} value={leaveType[0]} disabled={isEnableDuratioOption(leaveType[0], getFormattedDate(generatedLeaveDetails?.leave_date), generatedLeaveDetails?.leave_duration)}>
                                                {leaveType[1]}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {generatedLeaveDetails?.leave_duration !==
                            Object.keys(LEAVE_DURATION_TYPE)[2] ? (
                            <Grid item
                                sx={{
                                    '.rmdp-container': {
                                        minWidth: { xs: '130px !important', xl: '170px !important' },
                                    },
                                    'input': {
                                        width: '100%',
                                        height: 30,
                                        border: 'none !important',
                                        boxShadow: 'none !important',
                                        borderRadius: '0px',
                                        paddingBottom: '6px',
                                        paddingLeft: '28px',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                                        margin: '0',
                                        position: 'relative',
                                        background: 'transparent',
                                        color: 'dark.800',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px'
                                    },
                                    'input:hover': {
                                        borderBottomColor: '#333333 !important',
                                    },
                                    'input:focus': {
                                        borderBottomColor: '#047FE0 !important',
                                    },
                                    marginTop: '35px'
                                }}
                            >
                                <Typography
                                    id="demo-simple-select-standard-label"
                                    fontSize={12}
                                    fontWeight={400}
                                    color="dark.600"
                                    sx={{
                                        width: "100%",
                                        letterSpacing: "0.15px",
                                        marginTop: "-3px",
                                        marginBottom: "2px",
                                    }}
                                >
                                    {generatedLeaveDetails?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0] ? "Time to Come" : "Time to Leave"}
                                </Typography>
                                <div style={{ height: 1 }}>
                                    <ScheduleIcon
                                        sx={{
                                            fontSize: 20,
                                            color: '#3F5C76',
                                            marginBottom: '-9px',
                                            marginLeft: '1px',
                                        }}
                                    />
                                </div>
                                <TimePickerClock
                                    value={(generatedLeaveDetails?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0] ? generatedLeaveDetails?.in_time : generatedLeaveDetails?.out_time) || ""}
                                    placeholder={generatedLeaveDetails?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0] ? "Time to Come" : "Time to Leave"}
                                    onChange={(time) => {
                                        handelTimeChange(time)
                                    }}
                                />
                                {!!errors?.time?.length ?
                                    <div style={{ height: '1px', position: 'relative' }}>
                                        <div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}>
                                            <span className="error-arrow-top"></span>
                                            <Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>
                                                {errors?.time}
                                            </Typography>
                                        </div>
                                    </div>
                                    : null}
                            </Grid>
                        ) : null}
                    </Grid>
                    <Grid item p={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{
                                color: "white",
                                textTransform: "none",
                                marginRight: "20px",
                                boxShadow: "none",
                            }}
                            type="button"
                            onClick={() => handleSubmitLeaveApplication()}
                            isButtonLoading={userLeaveDataLoading?.leaveCreateFormSubmit}
                            disabled={!!Object.values(errors)?.some(data => data?.length) || userLeaveDataLoading?.leaveCreateFormSubmit}
                        >
                            Generate
                        </Button>
                        <Button
                            variant="text"
                            color="secondary"
                            size="large"
                            sx={{ textTransform: "none" }}
                            disabled={userLeaveDataLoading?.leaveCreateFormSubmit}
                            onClick={() => handleToggleDrawer(false, {})}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Box>}
        </Box>
    );

    const validateData = () => {
        let errorObj = { ...errors };
        if (!generatedLeaveDetails?.leave_date) {
            errorObj['leave_date'] = "Required"
        } else {
            errorObj['leave_date'] = ""
        }
        if (!generatedLeaveDetails?.user_leave_type_setting_id) {
            errorObj['user_leave_type_setting_id'] = "Required"
        } else {
            errorObj['user_leave_type_setting_id'] = ""
        }
        if (generatedLeaveDetails?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0] && !generatedLeaveDetails?.in_time) {
            errorObj['time'] = "Required"
        } else {
            errorObj['time'] = ""
        }
        if (generatedLeaveDetails?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[1] && !generatedLeaveDetails?.out_time) {
            errorObj['time'] = "Required"
        } else {
            errorObj['time'] = ""
        }
        if (!Object.keys(generatedLeaveDetails?.informed_authorities)?.length) {
            errorObj['informed_authorities'] = "Required"
        } else {
            errorObj['informed_authorities'] = ""
        }
        setErrors(errorObj);
        return Object.values(errorObj)?.some((data) => data?.length);
    }

    const handleSubmitLeaveApplication = async () => {
        try {
            setUserLeaveDataLoading(prev => ({ ...prev, leaveCreateFormSubmit: true }))
            if (!validateData()) {
                let body = {
                    "user_leave_type_setting_id": generatedLeaveDetails?.user_leave_type_setting_id,
                    "org_work_type_setting_id": generatedLeaveDetails?.org_work_type_setting_id,
                    "informed_authorities": [generatedLeaveDetails?.informed_authorities?.id],
                    "leave_type_name": userLeaveTypes?.find(leaveTypeData => leaveTypeData?.user_leave_type_setting_id === generatedLeaveDetails?.user_leave_type_setting_id)?.name,
                    "leave_days": [
                        {
                            "leave_date": generatedLeaveDetails?.leave_date,
                            "leave_duration": generatedLeaveDetails?.leave_duration,
                            "in_time": generatedLeaveDetails?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0] ? moment(generatedLeaveDetails?.in_time).format("HH:mm") : null,
                            "out_time": generatedLeaveDetails?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[1] ? moment(generatedLeaveDetails?.out_time).format("HH:mm") : null
                        }
                    ]
                }
                let response = await axiosInstance.post(API.generateOtherUserLeaveById(drawerUserDetails?.id), body);
                if (response?.status === 200) {
                    showAlert(response?.data?.message);
                    handleToggleDrawer(false, {});
                    getAllUserListByOrgId();
                }
            }
            setUserLeaveDataLoading(prev => ({ ...prev, leaveCreateFormSubmit: false }))
        } catch (error) {
            console.error(error);
            setUserLeaveDataLoading(prev => ({ ...prev, leaveCreateFormSubmit: false }))
        }
    }

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box px={2} py={1} borderBottom="1px solid #E0E0E0" bgcolor="white" position="sticky" top={0} zIndex={1}>
                <Typography variant='h2' color="secondary" fontSize={18} fontWeight={600} sx={{ lineHeight: "27px", letterSpacing: '0.15px' }}>Users</Typography>
            </Box>
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="auto">
                {(loading?.userRoleLoading || loading?.years) ?
                    <CircularLoader height="500px" /> :
                    <Box borderRadius={0.5} border="1px solid #E0E0E0" overflow="hidden">
                        <Grid container p={{ xs: 2, xl: 3 }} display="flex" justifyContent="space-between" borderBottom="1px solid #E0E0E0">
                            <Grid item display="flex">
                                <Stack direction="row" spacing={2}>
                                    <Grid item width={{ xs: 180, xl: 230 }} sx={{ '.MuiFormControl-root': { width: '100%', } }}>
                                        <SearchBoxInput
                                            label="Search"
                                            variant="standard"
                                            placeholder="Name and Email"
                                            handleSearch={handleSearch}
                                            value={params.search || ''}
                                            isOverwritePlaceholder={true}
                                        />
                                    </Grid>
                                    <Grid item width={{ xs: '150px', xl: '180px' }}>
                                        <FormControl variant="standard" sx={{ width: '100%' }}>
                                            <InputLabel htmlFor={getInputId("group", 0)}>Group By</InputLabel>
                                            <Select
                                                id='group'
                                                value={params.group || ''}
                                                label="Group By"
                                                name='group'
                                                onChange={(e) => handleFilterChange(e)}
                                                inputProps={{ id: getInputId("group", 0) }}
                                            >
                                                {Object.keys(Group_Filter)?.map((key, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={key}
                                                    >
                                                        {Group_Filter[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item width={{ xs: '150px', xl: '180px' }}>
                                        <FormControl variant="standard" sx={{ width: '100%' }}>
                                            <InputLabel htmlFor={getInputId("filter", 0)}>Role</InputLabel>
                                            <Select
                                                label="Role"
                                                name='filter'
                                                value={params.filter || 'All'}
                                                onChange={(e) => handleFilterChange(e)}
                                                inputProps={{ id: getInputId("filter", 0) }}
                                            >
                                                {userRolesData?.map(
                                                    ({ name }, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={name}
                                                        >
                                                            {titleCase(name)}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {!isDisabledClearFilter ?
                                        <Button variant='text' color='primary'
                                            size="small"
                                            sx={{ marginTop: '16px !important', textTransform: 'none' }}
                                            onClick={handleClearFilter}
                                        >
                                            Clear Filters
                                        </Button> : null}
                                </Stack>
                            </Grid>
                            <Grid item display="flex" justifyContent="flex-end" alignItems="center">
                                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="small"
                                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                        onClick={() => navigate(URLS.CreateUser)}
                                    >

                                        Add User
                                    </Button>
                                    {/* <IconButton color='secondary' size="small" borderRadius={0}>
                                        <SettingsRoundedIcon sx={{ fontSize: '24px' }} />
                                    </IconButton> */}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Paper sx={{ width: '100%', borderRadius: '0px', boxShadow: 'none', }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                >
                                    <TableHead>
                                        <TableRow sx={{
                                            whiteSpace: 'nowrap',
                                            'th': {
                                                bgcolor: "bgColor", padding: "8px"
                                            }
                                        }}>
                                            {params?.group !== "none" ?
                                                <TableCell sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}
                                                    sortDirection={orderBy === 'user_role' ? order : false}>
                                                    <TableSortLabel
                                                        active={orderBy === 'user_role'}
                                                        direction={orderBy === 'user_role' ? order : 'asc'}
                                                        onClick={() => !loading.pageLoading && createSortHandler('user_role')}
                                                    >
                                                        {params?.group === "none" ? "" : titleCase(params?.group)}
                                                        {orderBy === 'user_role' ? (
                                                            <Box component="span" sx={visuallyHidden}>
                                                                {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </Box>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                                : null}
                                            <TableCell sx={(theme) => ({
                                                minWidth: '90px',
                                                paddingRight: '0px !important',
                                                [theme.breakpoints.down('xl')]: {
                                                    width: '90px',
                                                    maxWidth: '90px',
                                                },
                                            })}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === 'emp_id'}
                                                    direction={orderBy === 'emp_id' ? order : 'asc'}
                                                    onClick={() => !loading.pageLoading && createSortHandler('emp_id')}
                                                >
                                                    Emp ID
                                                    {orderBy === 'emp_id' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: 0, minWidth: 200 }}>
                                                <TableSortLabel
                                                    active={orderBy === 'name'}
                                                    direction={orderBy === 'name' ? order : 'asc'}
                                                    onClick={() => !loading.pageLoading && createSortHandler('name')}
                                                >
                                                    User
                                                    {orderBy === 'name' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={(theme) => ({
                                                minWidth: '140px',
                                                [theme.breakpoints.down('xl')]: {
                                                    width: '140px',
                                                    maxWidth: '140px',
                                                },
                                            })}>
                                                Leaves Taken
                                            </TableCell>
                                            <TableCell sx={(theme) => ({
                                                minWidth: '130px',
                                                [theme.breakpoints.down('xl')]: {
                                                    width: '130px',
                                                    maxWidth: '130px',
                                                },
                                            })}>
                                                User Type
                                            </TableCell>
                                            {params.group !== "workType" ?
                                                <TableCell sx={(theme) => ({
                                                    minWidth: '120px',
                                                    [theme.breakpoints.down('xl')]: {
                                                        width: '120px',
                                                        maxWidth: '120px',
                                                    },
                                                })}>
                                                    <TableSortLabel
                                                        active={orderBy === 'work_type'}
                                                        direction={orderBy === 'work_type' ? order : 'asc'}
                                                        onClick={() => !loading.pageLoading && createSortHandler('work_type')}
                                                    >
                                                        Work Type
                                                        {orderBy === 'work_type' ? (
                                                            <Box component="span" sx={visuallyHidden}>
                                                                {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </Box>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                                : null}
                                            {params.group !== "role" ? <TableCell sx={(theme) => ({
                                                minWidth: '160px',
                                                [theme.breakpoints.down('xl')]: {
                                                    width: '160px',
                                                    maxWidth: '160px',
                                                },
                                            })}>
                                                User Role
                                            </TableCell> : null}
                                            {/* <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                Is Admin
                                            </TableCell> */}
                                            {/* <TableCell sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                Projects
                                            </TableCell> */}
                                            {/* {groupBy !== "status" ? <TableCell sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}>
                                                Status
                                            </TableCell> : null} */}
                                            <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px' }}>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading.pageLoading ?
                                            <CircularLoader variant="table" rows={10} cols={7} /> :
                                            Object.entries(userData)?.length ? (Object.entries(userData)?.map((user, index) => {
                                                let isOpen = expandedRow?.includes(user?.[0]);
                                                return (
                                                    <React.Fragment key={index}>
                                                        {params.group !== "none" ?
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={() => handleRowClick(user?.[0])}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    align='left'
                                                                    color='secondary'
                                                                    fontSize="14"
                                                                    fontWeight='500'
                                                                    sx={{ padding: '8px 16px' }}
                                                                    colSpan={10}
                                                                >
                                                                    <Grid item display='flex' alignItems='center' color="secondary.main" fontSize={14} fontWeight={500} sx={{ lineHeight: '22px' }}>
                                                                        {expandedRow?.includes(user[0]) ? <ExpandMoreIcon sx={{ fontSize: '18px', mr: 2 }} /> : <ChevronRightIcon sx={{ fontSize: '18px', mr: 2 }} />}
                                                                        {`${user?.[0]} (${user?.[1]?.length})`}
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow> : null}
                                                        {isOpen ? user?.[1]?.map((userDetail, index) => (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                                sx={{ 'td': { cursor: "pointer", color: 'rgba(0, 0, 0, 0.87)', fontSize: '14px', fontWeight: '400', lineHeight: '20px', letterSpacing: '0.17px', padding: '13px 8px' } }}
                                                            // onClick={() => navigate(`${URLS.UserDetails}/${userDetail?.id}`)}
                                                            >
                                                                {params?.group !== "none" ?
                                                                    <TableCell onClick={() => handleViewUser(userDetail)}></TableCell>
                                                                    : null}
                                                                <TableCell onClick={() => handleViewUser(userDetail)} sx={{ paddingRight: '0px !important' }}>#{userDetail?.emp_id}</TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail, 0)} sx={{ maxWidth: 0, minWidth: 200 }}>
                                                                    <Grid item display="flex" alignItems="center">
                                                                        <BackgroundLetterAvatars
                                                                            user={userDetail}
                                                                            sx={{ width: "32px", height: "32px", fontSize: '90%' }}
                                                                        />
                                                                        <Grid item ml={1} overflow={"hidden"}>
                                                                            <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ lineHeight: '24px', letterSpacing: '0.17px' }}> {getFullName(userDetail)}</Typography>
                                                                            <Typography variant='body1' fontSize={12} fontWeight={400} color="secondary.900" whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} sx={{ lineHeight: '15px' }}>{userDetail?.email}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <Typography variant='body1' fontWeight={500} color="white" mb={2}>Remaining Leaves</Typography>
                                                                                <List sx={{
                                                                                    p: 0,
                                                                                    minWidth: '238px',
                                                                                    'li': { p: 0, display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }
                                                                                }}>
                                                                                    {Object.entries(handleRemainingLeaveCount(userDetail?.userLeaveTypeData))?.length ? Object.entries(handleRemainingLeaveCount(userDetail?.userLeaveTypeData))?.map((leaveDetail, index) => (
                                                                                        <ListItem key={index}>
                                                                                            <Typography variant='body2' fontSize={13} fontWeight={500} pr={1} sx={{ lineHeight: '19px' }}>{leaveDetail?.[0]}</Typography>
                                                                                            <Typography variant='body2' fontSize={13} fontWeight={500} sx={{ lineHeight: '19px' }}>{leaveDetail?.[1]}</Typography>
                                                                                        </ListItem>
                                                                                    )) : <ListItem>No leaves Data Found</ListItem>}
                                                                                </List>
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <Typography fontSize={14} fontWeight={400} color="dark.800" display={"inline-block"}
                                                                            sx={{
                                                                                lineHeight: '20px',
                                                                                letterSpacing: '0.17px',
                                                                                "&:hover": {
                                                                                    color: "primary.main",
                                                                                },
                                                                            }}
                                                                            onClick={() => handleClickOpenModal(userDetail)}> {handleLeaveCount(userDetail)}</Typography>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail)} >{titleCase(userDetail?.user_type) || "-"}</TableCell>
                                                                {params.group !== "workType" ? <TableCell onClick={() => handleViewUser(userDetail)} >{titleCase(userDetail?.work_type) || "-"}</TableCell> : null}
                                                                {params.group !== "role" ? <TableCell onClick={() => handleViewUser(userDetail)} sx={{
                                                                    '.MuiChip-filled': {
                                                                        padding: '3px 10px 3px 0px',
                                                                        height: 'auto',
                                                                        '.MuiChip-avatarSmall': {
                                                                            width: '24px',
                                                                            height: '24px'
                                                                        }
                                                                    }
                                                                }}>
                                                                    <UserRoleChip label={titleCase(userDetail?.user_role) || "-"} />
                                                                </TableCell> : null}
                                                                {/* <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                                    <Switch
                                                                        name="is_admin"
                                                                        id="is_admin"
                                                                        size="small"
                                                                        checked={userDetail?.is_admin}
                                                                        disabled={true}
                                                                    />
                                                                </TableCell> */}
                                                                {/* <TableCell onClick={() => handleViewUser(userDetail, 1)} sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                                    <Button variant='text'
                                                                        color='primary'
                                                                        size="small" fontSize={13} fontWeight={500} p={0}
                                                                        sx={{
                                                                            textTransform: 'none',
                                                                        }}>6 Projects</Button>
                                                                </TableCell> */}
                                                                {/* {groupBy !== "status" ? <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}>
                                                                    {userDetail?.active ?
                                                                        <Chip label="Active" color='secondary' size="small" variant='filled'></Chip>
                                                                        :
                                                                        <Chip label="Inactive" size="small" variant='filled'></Chip>}
                                                                </TableCell> : null} */}
                                                                <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px', paddingLeft: '0px !important', paddingRight: '0px !important', }}>
                                                                    <IconButton
                                                                        aria-label="more"
                                                                        aria-haspopup="true"
                                                                        onClick={handleClick(userDetail)}
                                                                        sx={{
                                                                            height: "30px",
                                                                            width: "30px",
                                                                            padding: "0px",
                                                                            borderRadius: "4px",
                                                                        }}
                                                                        id="basic-button"
                                                                        aria-controls={open ? 'basic-menu' : ""}
                                                                        aria-expanded={open ? 'true' : "false"}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                height: "20px",
                                                                                width: "20px",
                                                                                display: "flex",
                                                                            }}
                                                                        >
                                                                            {ICONS.DotsHorizontal}
                                                                        </i>
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="basic-menu"
                                                                        anchorEl={anchorEl}
                                                                        open={open && openElem?.id === userDetail?.id}
                                                                        onClose={handleClose}
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'basic-button',
                                                                        }}
                                                                        slotProps={{
                                                                            paper: {
                                                                                style: {
                                                                                    minWidth: "180px",
                                                                                },
                                                                            },
                                                                        }}
                                                                        sx={{
                                                                            '.MuiMenu-paper': {
                                                                                boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.1)',
                                                                            }
                                                                        }}
                                                                    >
                                                                        <MenuItem onClick={() => handleClickEdit(userDetail)}>Edit</MenuItem>
                                                                        {(!userDetail?.is_admin && userDetail?.active) ? <MenuItem onClick={() => handleModalOpen(userDetail)} disabled={userDetail?.external}>{'Make an admin'}</MenuItem> : null}
                                                                        {(userDetail?.user_type === 'manager' && userDetail?.is_admin && userDetail?.active) ? <MenuItem onClick={() => handleModalOpen(userDetail)}>{'Remove from an admin'}</MenuItem> : null}
                                                                        {(currentUser?.is_admin && currentUser?.active) ? <MenuItem onClick={() => handleToggleDrawer(true, userDetail)}>{'Generate Leave'}</MenuItem> : null}
                                                                    </Menu>
                                                                </TableCell>
                                                            </TableRow>
                                                        )) : null}
                                                    </React.Fragment>
                                                )
                                            })) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={7}
                                                        align="center"
                                                        sx={{ padding: "12px 16px;" }}
                                                    >
                                                        <Typography
                                                            color={"secondary"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            fontSize={13}
                                                            sx={{ opacity: "0.5", height: "236px" }}
                                                        >
                                                            No records found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                                <Drawer open={drawerOpen} anchor="right" onClose={() => handleToggleDrawer(false, {})}>
                                    {DrawerList}
                                </Drawer>
                            </TableContainer>
                            <TablePagination
                                count={userDataCount}
                                rowsPerPage={params.limit}
                                page={params.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                }
            </Box>
            <Modal
                open={isMakeAnAdminOpen}
                onClose={() => setIsMakeAnAdminOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '> div': {
                        overflow: 'hidden',
                        '> div': {
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    }
                }}
            >
                <Box sx={[style, {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto !important",
                }]}>
                    <Box>
                        <Grid item py={2} px={3} borderBottom="1px solid #E2E4EC" sx={{
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            background: "white",
                        }}>
                            <Typography fontSize={20} lineHeight={'32px'} letterSpacing={'0.15px'} fontWeight={500}>{editableUserModalData?.is_admin ? 'Remove from an Admin' : 'Make an Admin'}</Typography>
                        </Grid>
                    </Box>
                    <Grid item py={3} px={3} flex={1} overflow={"auto"} width={576}>
                        <Box>
                            <Typography fontSize={16} lineHeight={'26px'} letterSpacing={'0.15px'} fontWeight={400}>
                                {editableUserModalData?.is_admin ?
                                    <>
                                        Removing <b>{`${editableUserModalData?.name ?? "this user"}`}</b> from an admin?
                                    </> :
                                    <>
                                        Assigning <b>{`${editableUserModalData?.name ?? "this user"}`}</b> as an admin?
                                    </>
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        py={2}
                        px={3}
                        sx={{
                            borderTop: "1px solid #E2E4EC",
                            display: "flex",
                            justifyContent: "space-between",
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            background: "white",
                            flexDirection: 'row !important',
                            overflow: 'visible !important',
                        }}
                    >
                        <Stack spacing={1} direction="row">
                            <Button
                                variant="contained"
                                size="medium"
                                color={editableUserModalData?.is_admin ? "error" : "primary"}
                                fontWeight="500"
                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                onClick={() => handleSubmitMakeAdmin()}
                                isButtonLoading={loading?.formSubmitting}
                                disabled={loading?.formSubmitting}
                            >
                                {"Yes"}
                            </Button>
                            <Button
                                variant="text"
                                color="secondary"
                                sx={{ textTransform: "none" }}
                                size="medium"
                                fontWeight="500"
                                onClick={() => handleCloseModal()}
                                disabled={loading?.formSubmitting}
                            >
                                {"Cancel"}
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </Modal>
            <BootstrapDialog
                open={isReporModalOpen}
                onClose={() => handleReportModalClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    alignItems: 'flex-start',
                    '.MuiDialog-paper': {
                        maxWidth: '100%',
                        width: '1200px',
                        ' > div': {
                            overflow: 'hidden',
                            display: 'flex',
                            '> div': {
                                display: 'flex',
                            }
                        },
                    },
                    '.MuiDialog-container': { alignItems: 'flex-start' }
                }}
            >
                <DialogContent sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%' }}>
                        <LeaveReportModal activeYear={activeYear} handleReportModalClose={handleReportModalClose} userData={leaveData} years={leaveReportyears} currentUser={currentUser} />
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Box>
    );
};

export default Users;