/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, Chip, Dialog, DialogContent, Divider, FormControl, FormHelperText, Grid, IconButton, MenuItem, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Input from '../../../../components/Input'
import { clearAllCookies, durationToWord, generateUniqueId, getApplyDateTime, getDayJsToSecond, getInputId, secondToDayJsHours, sortArrayByKey, timestampToTime, titleCase } from '../../../../utils'
import DeleteIcon from "@mui/icons-material/Delete";
import { ClassNames } from '@emotion/react';
import AutocompleteSelect from '../../../../components/AutocompleteSelect';
import { keysWithActualValues, PROJECT_PHASE_STATUS, PROJECT_TASK_PRIORITY, EOD_TASK_STATUS_LIST, TASK_TYPE_LIST } from '../../../../constants/default-values';
import moment from 'moment';
import Button from '../../../../components/Button';
import useAuthentication from '../../../../hook/useAuthentication';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import CustomTimeInput from '../../../../components/CustomTimeInput';
import { makeStyles, styled } from '@mui/styles';
import TasksModal from './taskModal';
import dayjs from 'dayjs';
import { secondsToTime } from '../../../../utils';
import { toast } from 'react-toastify';
import CircularLoader from '../../../../components/CircularLoader';
import { usePageTitle } from '../../../../hook/useTitle';
import { createDefaultObject, validateLogoutModelData } from '../../../../components/LogoutModal/utils-functions';
import LogoutModal from '../../../../components/LogoutModal';
import { clearStorage } from '../../../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import URLS from '../../../../routes/urls';
import secureLocalStorage from 'react-secure-storage';
import { clearActionData } from '../../../../redux/slices/activityActionDataSlice';
import { useAlert } from '../../../../hook/useAlert';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TextEditor from '../../../../components/TextEditor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as WorkLogIcon } from "../../../../assets/images/worklog-icon.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
    overflowX: 'hidden',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
  },
}));

const initialProjectTaskData = {
  id: null,
  projects: { id: 'nill', title: 'Select', "short_code": "SL", },
  task: "",
  type: "nill",
  phase: "",
  status: "nill",
  duration: dayjs().set("hour", 0).set("minute", 0),
  description: "",
  unique_id: generateUniqueId(),
  isExist: false,
  WorkLogs: [{ duration: dayjs().set("hour", 0).set("minute", 0), unique_id: generateUniqueId(), }],
};

const initialError = {
  projects: "",
  task: "",
  type: "",
  phase: "",
  status: "",
  duration: "",
  description: "",
  unique_id: generateUniqueId(),
  task_id: null,
  WorkLogs: []
}

const ProjectTask = () => {
  const classes = useStyles();
  // const { taskTimerData } = useSelector((state) => state.ongoingTaskWorkLogData);
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropDownRef = useRef()
  const [projectTaskList, setProjectTaskList] = useState([initialProjectTaskData]);
  const [errors, setErrors] = useState([{
    ...initialError,
    unique_id: initialProjectTaskData?.unique_id,
  }])
  const [logoutErrors, setLogoutErrors] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
    allProjectsLoading: true,
    userProjectLoading: false,
    projectTaskLoading: false,
    formSubmitting: false,
  });
  const [modalDetails, setModalDetails] = useState({
    isOpen: false,
    project_id: null,
    search: "",
    user_id: currentUser?.id,
    projectDetail: {},
    unique_id: null
  })
  const [todayTrackerHours, setTodayTrackerHours] = useState({});
  const [selectedProjectTask, setSelectedProjectTask] = useState([]);
  const [totalWorkingSeconds, setTotalWorkingSeconds] = useState(0);
  const [availableWorkingSeconds, setAvailableWorkingSeconds] = useState(0);
  const [totalActivityHours, setTotalActivityHours] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [workingActivity, setWorkingActivity] = useState([]);
  const [optionalWorkingActivity, setOptionalWorkingActivity] = useState([]);
  const [allProjectPhases, setAllProjectPhases] = useState([]);
  let initialModalValues = {
    isShow: false,
    isButtonLoading: false,
    date: null,
    msg: null,
    time: null,
    email: null,
  }
  const [logoutModelDetails, setLogoutModelDetails] = useState(initialModalValues);
  setPageTitle("Project Task");

  let LastDayLogoutData = !!secureLocalStorage.getItem('lastDayLogutData') ? JSON.parse(secureLocalStorage.getItem('lastDayLogutData')) : [];
  let isLastDayTaskRemaining = !!secureLocalStorage.getItem('isLastDayTaskRemain');

  useEffect(() => {
    if (currentUser?.id) {
      getProjectWithPhasedata(currentUser?.id);
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (modalDetails?.isOpen)
      getTaskOfProject(modalDetails?.search)
  }, [modalDetails?.search, modalDetails?.isOpen]);

  const findErrorByUniqueId = (uniqueId) => {
    return errors?.find((data) => data?.unique_id === uniqueId)
  }

  const findWorkLogErrorByUniqueId = (taskUniqueId, workLogUniqueId) => {
    let errorData = errors?.find((data) => data?.unique_id === taskUniqueId)?.WorkLogs ?? [];
    return errorData?.find(workLogData => workLogData?.[workLogUniqueId])?.[workLogUniqueId] ?? null
  }

  const findHourErrorByUniqueId = (taskUniqueId, workLogUniqueId) => {
    let errorData = errors?.find((data) => data?.unique_id === taskUniqueId)?.WorkLogs ?? [];
    let workLogError = errorData?.find(workLogData => workLogData?.[workLogUniqueId])?.hours ?? null;
    return workLogError
  }

  const getProjectWithPhasedata = async (userId) => {
    try {
      setIsLoading(prev => ({ ...prev, pageLoading: true }))
      let response = await axiosInstance.get(API.getProjectsWithPhaseByUserId(userId));
      let privateProjectData = response?.data?.data?.map((projectData => projectData?.projects));
      let publicProjects = response?.data?.public_projects ?? []
      let allSortedProjects = sortArrayByKey([...privateProjectData, ...publicProjects], "desc", "title");
      if (allSortedProjects?.length) setProjectList([{ id: 'nill', title: 'Select', "short_code": "SL", }, ...allSortedProjects]);
      else setProjectList([]);

      let projectWithPhaseData = allSortedProjects?.map((projectData) => {
        return projectData?.ProjectPhases?.filter((data => [PROJECT_PHASE_STATUS["In-Progress"], PROJECT_PHASE_STATUS["Not started"]]?.includes(data?.status)));
      });
      setAllProjectPhases([...projectWithPhaseData?.flat(1)]);
      getTodaysWorkingHours(allSortedProjects);
      setIsLoading(prev => ({ ...prev, pageLoading: false }))
    } catch (error) {
      console.error(error);
      setIsLoading(prev => ({ ...prev, pageLoading: false }))
    }
  }

  const getTodaysWorkingHours = async (allProjectList = projectList, updatedLastDayLogoutData = LastDayLogoutData) => {
    try {
      setIsLoading(prev => ({ ...prev, pageLoading: true }))
      let date = moment(new Date()).format('YYYY-MM-DD');
      if (updatedLastDayLogoutData?.length && updatedLastDayLogoutData?.[0]?.date) {
        date = moment(new Date(LastDayLogoutData?.[0]?.date)).format('YYYY-MM-DD');
      };
      let response = await axiosInstance.get(API.getAllActivityOfEod(currentUser?.id, date));
      if (response?.status === 200) {
        let userTrackingInfo = response?.data?.userTrackingInfo;
        let totalCreatedTaskDuration = 0;
        let remainingWorkingHours = Math.abs((isLastDayTaskRemaining ? (updatedLastDayLogoutData[0]?.duration ?? 0) : (userTrackingInfo?.working_hrs ? userTrackingInfo?.working_hrs : 0)) - (userTrackingInfo?.working_activity_duration ?? 0) ?? 0);
        if (userTrackingInfo?.tasks?.length) {
          let taskError = [];
          let updatedProjectData = userTrackingInfo?.tasks?.map((taskData) => {
            let taskUniqueId = generateUniqueId();
            let workLogError = [];
            let totalOfWorkLogs = taskData?.WorkLogs?.reduce((total, current) => {
              total.second += (Math.abs(current?.duration ?? 0))
              return total
            }, { second: 0 })?.second;

            totalCreatedTaskDuration = totalCreatedTaskDuration + totalOfWorkLogs;
            let projectData = allProjectList?.find((data => data?.id === taskData?.project_id));
            let taskWorkLogs = taskData?.WorkLogs?.map((workLogData => {
              let uniqueId = generateUniqueId()
              workLogError.push({ [uniqueId]: '', hours: '' })
              return {
                ...workLogData,
                "duration": secondToDayJsHours(Math.abs(workLogData?.duration)),
                "actualDuration": Math.abs(workLogData?.duration ?? 0),
                "unique_id": uniqueId,
                "isExistWorkLog": true
              }
            }))
            taskError.push({ ...initialError, WorkLogs: workLogError, unique_id: taskUniqueId })
            return {
              ...taskData,
              "id": taskData?.id || (taskData?.task_id ?? null),
              "projects": projectData,
              "task": `${projectData?.short_code}-${taskData?.task_no} - ${taskData?.title}`,
              "type": taskData?.type,
              "phase": taskData?.project_phase_id,
              "status": taskData?.status,
              "duration": secondToDayJsHours(Math.abs(taskData?.duration ?? 0)),
              "actualDuration": Math.abs(taskData?.duration ?? 0),
              "description": taskData?.description ?? "",
              "unique_id": taskUniqueId,
              "task_id": `${projectData?.short_code}-${taskData?.task_no}`,
              "title": taskData?.title,
              "spent": taskData?.spent ?? 0,
              "isExist": true,
              "workLogId": taskData?.id || null,
              "WorkLogs": taskWorkLogs
            }
          })

          let totalEmptyTaskDuration = (remainingWorkingHours ?? 0) - (totalCreatedTaskDuration ?? 0)
          if (totalEmptyTaskDuration > 59) {
            setProjectTaskList(prev => [...updatedProjectData, ...prev?.map((data, index) => {
              if (index === (projectTaskList?.length - 1)) {
                return {
                  ...data,
                  WorkLogs: [{ ...data?.WorkLogs[0], isNewWorkLog: true, duration: secondToDayJsHours(totalEmptyTaskDuration) }],
                }
              }
              return data;
            })])
          } else {
            setProjectTaskList([...updatedProjectData])
          }
          setErrors([...errors, ...taskError])
        } else {
          if ((remainingWorkingHours ?? 0) > 59) {
            let unique_id = generateUniqueId();
            setErrors([{ ...initialError, unique_id: unique_id }]);
            setProjectTaskList([{
              ...initialProjectTaskData,
              duration: secondToDayJsHours(remainingWorkingHours),
              unique_id: unique_id,
              WorkLogs: [{ ...initialProjectTaskData?.WorkLogs[0], duration: secondToDayJsHours(remainingWorkingHours) }],
            }])
          }
        }
        setTodayTrackerHours(userTrackingInfo);
        setTotalWorkingSeconds(remainingWorkingHours ?? 0);
        setTotalActivityHours(userTrackingInfo?.working_activity_duration ?? 0);
        setWorkingActivity(userTrackingInfo?.user_activities?.filter(activityData => activityData?.Activity?.types === "working"))
        setOptionalWorkingActivity(userTrackingInfo?.user_activities);
      } else {
        setTodayTrackerHours({});
      }
      setIsLoading(prev => ({ ...prev, pageLoading: false }))
    } catch (error) {
      console.error(error);
      setTodayTrackerHours({});
      setIsLoading(prev => ({ ...prev, pageLoading: false }))
    }
  }

  const getTaskOfProject = async (searchValue) => {
    try {
      setIsLoading((prev) => ({ ...prev, projectTaskLoading: true }));
      let body = {
        "project_id": modalDetails?.project_id,
        "search": searchValue,
        "user_id": currentUser?.id
      }
      let response = await axiosInstance.post(API.getAllTaskOfProject, body);
      if (response?.status === 200) {
        setSelectedProjectTask(response?.data?.task)
      } else {
        setSelectedProjectTask([])
      }
      setIsLoading((prev) => ({ ...prev, projectTaskLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, projectTaskLoading: false }));
    }
  }

  const getTaskDate = (isBracket = false, isBold = true) => {
    return <span style={{ fontWeight: isBold ? "bold" : "inherit" }}>{isLastDayTaskRemaining ? "" : "- "}{getApplyDateTime(isLastDayTaskRemaining ? LastDayLogoutData?.[0]?.date : new Date(), isBracket ? "DD/MM/YYYY(dddd)" : "DD/MM/YYYY, dddd")}</span>
  }

  const getCurrentTaskDate = () => {
    return `On ${getApplyDateTime(isLastDayTaskRemaining ? LastDayLogoutData?.[0]?.date : new Date(), "DD/MM/YYYY (dddd)")}`
  }

  const getTotalAutoTrackedHours = () => {
    let seconds = projectTaskList?.reduce((totalTimeData, currentTaskData) => {
      let totatlWorkLogHours = currentTaskData?.WorkLogs?.reduce((total, current) => {
        if (current?.isExistWorkLog && current?.end_time) {
          total.second += (current?.actualDuration ?? 0)
        }
        return total
      }, { second: 0 });

      totalTimeData += totatlWorkLogHours?.second;
      return totalTimeData
    }, 0)
    return seconds > 59 ? seconds : 0
  }

  const getAvailableTaskHours = (todayHoursData) => {
    return <span style={{ fontWeight: "700", fontSize: '25px', color: '#00000 !important' }}> {secondsToTime(LastDayLogoutData?.length ? LastDayLogoutData[0]?.duration : todayHoursData?.working_hrs)} </span>
  }

  const getTotalTaskHours = (todayHoursData) => {
    let totalHours = (LastDayLogoutData?.length ? LastDayLogoutData[0]?.duration : todayHoursData?.working_hrs) - todayHoursData?.working_activity_duration
    return <span style={{ fontWeight: "700", fontSize: '25px' }}> {secondsToTime(totalHours)} </span>
  }

  const getOtherTaskHours = (seconds) => {
    return <span style={{ fontWeight: "600", fontSize: '18px' }}> {secondsToTime(seconds)} </span>
  }

  const handleChangeFunction = (unique_id, event, index) => {
    const { name, value } = event.target;
    let updatedProjectData = projectTaskList?.map((taskDetails, taskIndex) => {
      if (taskDetails?.unique_id === unique_id) {
        if (name === "projects" && value?.id !== "nill") {
          if (value?.id) {
            let currentProjectPhases = getFilteredPhase(value?.id);
            if (currentProjectPhases?.length > 1) {
              let sortArrayByPhaseStartDate = sortArrayByKey(currentProjectPhases, "start_date")
              return {
                ...taskDetails,
                task: value?.id === taskDetails?.projects?.id ? taskDetails?.task : "",
                task_id: value?.id === taskDetails?.projects?.id ? taskDetails?.task_id : null,
                [name]: value,
                'phase': sortArrayByPhaseStartDate[0]?.id,
              };
            } else {
              return {
                ...taskDetails,
                task: value?.id === taskDetails?.projects?.id ? taskDetails?.task : "",
                task_id: value?.id === taskDetails?.projects?.id ? taskDetails?.task_id : null,
                [name]: value,
                'phase': currentProjectPhases[0]?.id
              };
            }
          } else {
            return {
              ...taskDetails,
              [name]: value,
              phase: "",
              task: "",
              task_id: null,
              id: null
            };
          }
        } else {
          return {
            ...taskDetails,
            [name]: value,
            // is_changed: true
          };
        }
      } else {
        return taskDetails;
      }
    });

    setProjectTaskList(updatedProjectData);
    setErrors(prev => prev?.map(data => {
      if (data?.unique_id === unique_id) {
        return {
          ...data,
          [name]: (((name === "task" || name === "status") && value !== "nill") || (name === "projects" && value?.id !== "nill") || (name === "phase" && !!value) || (name === "description" && value?.trim()?.length)) ? "" : data?.name,
          "phase": updatedProjectData?.find((taskData) => taskData?.unique_id === data?.unique_id)?.phase?.toString()?.length ? "" : data?.phase
        }
      }
      return data;
    }))
  }

  const handleChangeDescription = (task_unique_id, workLog_uniqueId, value) => {
    let tempVal = value;

    let isEmpty = !tempVal || tempVal
      ?.replace(/<br\s*\/?>/g, '')
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim().length === 0;

    let descriptionLength = value
      ?.replace(/<br\s*\/?>/g, '')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim().length || 0;

    setProjectTaskList(prev => prev?.map((taskData) => {
      if (taskData?.unique_id === task_unique_id) {
        return {
          ...taskData,
          WorkLogs: taskData?.WorkLogs?.map((workLogData => {
            if (workLogData?.unique_id === workLog_uniqueId) {
              return {
                ...workLogData,
                description: value
              }
            }
            return workLogData
          })),
        }
      }
      return taskData
    }))

    setErrors(prev => prev?.map(data => {
      if (data?.unique_id === task_unique_id) {
        return {
          ...data,
          WorkLogs: data?.WorkLogs?.map((workLogData => {
            if (Object.keys(workLogData)?.[0] === workLog_uniqueId) {
              return {
                ...workLogData,
                [workLog_uniqueId]: !isEmpty ? descriptionLength > 5000 ? "Up to 5000 characters allowed" : "" : data?.description,
              }
            }
            return workLogData
          })),
        }
      }
      return data;
    }))
  }

  const handleBlurFunction = (event, index) => {

  }

  const handleRemoveTask = (taskData, index, uniqueId) => {
    let totatlWorkLogHours = taskData?.WorkLogs?.reduce((total, current) => {
      total.second += getDayJsToSecond(current?.duration)
      return total
    }, { second: 0 })

    setAvailableWorkingSeconds(prev => prev + totatlWorkLogHours?.second)
    setProjectTaskList(prev => prev?.filter(data => data?.unique_id !== uniqueId))
    setErrors(prev => prev?.filter(data => data?.unique_id !== uniqueId))
  }

  const handleClickDeleteWorkLog = (taskUniqueId, workLogUniqueId, workLogDetails) => {
    let workLogHours = getDayJsToSecond(workLogDetails?.duration);
    setAvailableWorkingSeconds(prev => prev + workLogHours)
    setProjectTaskList(prev => prev?.map((taskData) => {
      if (taskData?.unique_id === taskUniqueId) {
        return {
          ...taskData,
          WorkLogs: taskData?.WorkLogs?.filter((workLogData => workLogData?.unique_id !== workLogUniqueId))
        }
      }
      return taskData
    }))
    setErrors(prev => prev?.map((taskError) => {
      if (taskError?.unique_id === taskUniqueId) {
        return {
          ...taskError,
          WorkLogs: taskError?.WorkLogs?.filter((workLogData => Object.keys(workLogData)[0] !== workLogUniqueId))
        }
      }
      return taskError
    }))
  }

  const handleAddTask = () => {
    let uniqueId = generateUniqueId();
    let workLogUniqueId = generateUniqueId();

    setProjectTaskList(prev => ([
      ...prev,
      {
        ...initialProjectTaskData,
        unique_id: uniqueId,
        duration: availableWorkingSeconds > 59 ? secondToDayJsHours(availableWorkingSeconds) : dayjs().set("hour", 0).set("minute", 0),
        WorkLogs: [
          {
            isNewWorkLog: true,
            unique_id: workLogUniqueId,
            description: '',
            duration: availableWorkingSeconds > 59 ? secondToDayJsHours(availableWorkingSeconds) : dayjs().set("hour", 0).set("minute", 0)
          }
        ]
      }
    ]));
    setErrors(prev => (
      [
        ...prev,
        {
          ...initialError,
          unique_id: uniqueId,
          WorkLogs: [
            { [workLogUniqueId]: '' }
          ]
        }
      ]))
    if (availableWorkingSeconds > 59) {
      setAvailableWorkingSeconds(0);
    }
  }

  const handleClickAddWorkLog = (taskUniqueId) => {
    let workLogUniqueId = generateUniqueId();

    setProjectTaskList(prev => {
      return prev?.map((taskData => {
        if (taskData?.unique_id === taskUniqueId) {
          return {
            ...taskData,
            WorkLogs: [
              ...(taskData?.WorkLogs ?? []),
              {
                isNewWorkLog: true,
                unique_id: workLogUniqueId,
                description: '',
                duration: availableWorkingSeconds > 59 ? secondToDayJsHours(availableWorkingSeconds) : dayjs().set("hour", 0).set("minute", 0)
              }
            ]
          }
        }
        return taskData
      }))
    })

    setErrors(prev => {
      return prev?.map((errorData => {
        if (errorData?.unique_id === taskUniqueId) {
          return {
            ...errorData,
            WorkLogs: [
              ...(errorData?.WorkLogs ?? []),
              { [workLogUniqueId]: '' }
            ]
          }
        }
        return errorData
      }))
    })
    if (availableWorkingSeconds > 59) {
      setAvailableWorkingSeconds(0);
    }
  }

  const isShowAddMoreWorkLog = () => {
    return availableWorkingSeconds > 59
  }

  const handleTimeChange = (value, taskUniqueId, workLogUniqueId) => {
    setProjectTaskList(prev => prev?.map((taskDetails) => {
      if (taskDetails?.unique_id === taskUniqueId) {
        return {
          ...taskDetails,
          duration: value,
          idDeleteDisabled: true,
          WorkLogs: taskDetails?.WorkLogs?.map(workLogData => {
            if (workLogData?.unique_id === workLogUniqueId) {
              return {
                ...workLogData,
                duration: value,
                idDeleteDisabled: true
              }
            }
            return workLogData
          })
        }
      }
      return taskDetails
    }))

    setErrors(prev => prev?.map(data => {
      if (data?.unique_id === taskUniqueId) {
        return {
          ...data,
          WorkLogs: data?.WorkLogs?.map((workLogData => {
            if (Object.keys(workLogData)?.[0] === workLogUniqueId) {
              return {
                ...workLogData,
                'hours': ''
              }
            }
            return workLogData
          })),
        }
      }
      return data;
    }))
  };

  const handleTimeBlur = (value, taskUniqueId, workLogUniqueId) => {
    const findHours = projectTaskList?.find((data) => data?.unique_id === taskUniqueId)?.WorkLogs?.find(workLogData => workLogData?.unique_id === workLogUniqueId)?.duration;
    let currentTaskUsedSeconds = getDayJsToSecond(findHours);

    const totalOtherSeconds = projectTaskList?.reduce((total, currentData) => {
      let totalOfWorkLog = currentData?.WorkLogs?.reduce((totalWorkLog, currentWorkLogData) => {
        if (currentWorkLogData?.unique_id !== workLogUniqueId) {
          totalWorkLog.workLogTotal += getDayJsToSecond(currentWorkLogData?.duration);
        }
        return totalWorkLog;
      }, { workLogTotal: 0 })
      total.second += totalOfWorkLog?.workLogTotal;
      return total;
    }, { second: 0 });

    let totalUsedSeconds = totalOtherSeconds?.second + currentTaskUsedSeconds;
    let availableSeconds = totalWorkingSeconds - totalUsedSeconds;

    if (availableSeconds < 0) {
      setIsDisable(true)
      toast.error("You can't add task hours more then work hours");
    } else {
      setIsDisable(false)
    }

    setTimeout(() => {
      setProjectTaskList(prev => prev?.map((taskDetails) => {
        if (taskDetails?.unique_id === taskUniqueId) {
          return {
            ...taskDetails,
            duration: value,
            idDeleteDisabled: false,
            WorkLogs: taskDetails?.WorkLogs?.map(workLogData => {
              if (workLogData?.unique_id === workLogUniqueId) {
                return {
                  ...workLogData,
                  duration: value,
                  idDeleteDisabled: false
                }
              }
              return workLogData
            })
          }
        }
        return taskDetails
      }))
    }, 100);
    setAvailableWorkingSeconds(availableSeconds)
  }

  const handleClearTaskTitle = (uniqueId) => {
    setProjectTaskList(prev => prev?.map((taskDetails) => {
      if (taskDetails?.unique_id === uniqueId) {
        handleOpenPreviousTask({
          ...taskDetails,
          id: null,
          title: '',
          task: '',
          task_id: null,
          task_no: null
        })
        return {
          ...taskDetails,
          id: null,
          title: '',
          task: '',
          task_id: null,
          task_no: null
        }
      }
      return taskDetails
    }))
  }

  const handleOpenPreviousTask = (taskDetails) => {
    setModalDetails({
      isOpen: true,
      project_id: taskDetails?.projects?.id,
      search: taskDetails?.id ? "" : taskDetails?.task,
      user_id: currentUser?.id,
      projectDetail: taskDetails?.projects,
      project_phase_id: taskDetails?.phase,
      unique_id: taskDetails?.unique_id
    })
    setModalDetails((prev) => ({
      ...prev,
      search: taskDetails?.id ? "" : taskDetails?.task,
    }));
    if (dropDownRef?.current) {
      dropDownRef.current.blur()
    }
  }

  const handleTaskModalClose = () => {
    setModalDetails({
      isOpen: false,
      project_id: null,
      search: "",
      user_id: currentUser?.id,
      projectDetail: {},
      unique_id: null
    })
    setSelectedProjectTask([])
  }

  const handleSearch = (value) => {
    setModalDetails((prev) => ({
      ...prev,
      search: value,
    }));
  };

  const handleAddNewTask = async () => {
    try {
      if (modalDetails?.search?.trim()?.length) {
        let taskCreateDate = moment(new Date()).format('YYYY-MM-DD');
        if (LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) {
          taskCreateDate = moment(new Date(LastDayLogoutData?.[0]?.date)).format('YYYY-MM-DD');
        }
        setProjectTaskList(prev => prev?.map((taskDetails) => {
          if (taskDetails?.unique_id === modalDetails?.unique_id) {
            return {
              ...taskDetails,
              // ...response?.data?.task,
              user_id: currentUser?.id,
              task: modalDetails?.search?.trim(),
              task_id: null,
              date: taskCreateDate,
              title: modalDetails?.search?.trim()
            }
          }
          return taskDetails
        }))
        setErrors((prev) => prev?.map((errorData) => {
          if (errorData?.unique_id === modalDetails?.unique_id) {
            return {
              ...errorData,
              task: ""
            }
          }
          return errorData;
        }))
        handleTaskModalClose();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddExistTask = (task) => {
    setProjectTaskList(prev => prev?.map((taskDetails) => {
      if (taskDetails?.unique_id === modalDetails?.unique_id) {
        return {
          ...taskDetails,
          ...task,
          WorkLogs: taskDetails?.WorkLogs,
          id: task?.id,
          spent: task?.spent ?? 0,
          task: `${modalDetails?.projectDetail?.short_code?.toUpperCase()}-${task?.task_no} - ${task?.title}`,
          task_id: `${modalDetails?.projectDetail?.short_code?.toUpperCase()}-${task?.task_no}`,
          title: task?.title,
          phase: task?.ProjectPhase?.id ?? taskDetails?.phase ?? null,
          isNullTaskSelected: (!task?.ProjectPhase?.id && !taskDetails?.isExist),
          description: taskDetails?.description,
          status: task?.status === "To-Do" ? "nill" : task?.status
        }
      }
      return taskDetails
    }))
    setErrors((prev) => prev?.map((errorData) => {
      if (errorData?.unique_id === modalDetails?.unique_id) {
        return {
          ...errorData,
          task: "",
          type: "",
          status: "",
          phase: "",
        }
      }
      return errorData;
    }))
    handleTaskModalClose();
  }

  const isValidateData = () => {
    let validationMessage = "Required";
    let isValid = true;
    let newErrors = [];

    projectTaskList?.forEach((taskData, index, arrayData) => {
      let error = {};
      error['unique_id'] = taskData?.unique_id;
      if (taskData?.projects?.id === "nill") {
        error["projects"] = validationMessage;
        isValid = false;
      } else {
        error["projects"] = "";
      }

      if (taskData?.task === "") {
        error["task"] = validationMessage;
        isValid = false;
      } else {
        error["task"] = "";
      }

      if (taskData?.type === "nill") {
        error["type"] = validationMessage;
        isValid = false;
      } else {
        error["type"] = "";
      }

      if (taskData?.status === "nill") {
        error["status"] = validationMessage;
        isValid = false;
      } else {
        error["status"] = "";
      }

      let workLogError = [];
      taskData?.WorkLogs?.forEach((workLogData => {
        let isEmpty = !workLogData?.description ||
          workLogData?.description
            ?.replace(/<br\s*\/?>/g, '')
            ?.replace(/&nbsp;/g, ' ')
            ?.replace(/<(.|\n)*?>/g, '')
            ?.trim().length === 0;

        let descriptionLength = workLogData?.description
          ?.replace(/<br\s*\/?>/g, '')
          ?.replace(/<(.|\n)*?>/g, '')
          ?.trim().length || 0;

        let duration = getDayJsToSecond(workLogData?.duration);
        let isHoursNotValid = false
        if (duration < 59) {
          isHoursNotValid = true
        }
        if (isEmpty) {
          workLogError.push({ [workLogData?.unique_id]: validationMessage, 'hours': isHoursNotValid ? validationMessage : "" })
          isValid = false;
        } else if (descriptionLength > 5000) {
          workLogError.push({ [workLogData?.unique_id]: 'Up to 5000 characters allowed', 'hours': isHoursNotValid ? validationMessage : "" })
          isValid = false;
        } else {
          if (isHoursNotValid) {
            isValid = false;
          }
          workLogError.push({ [workLogData?.unique_id]: '', 'hours': isHoursNotValid ? validationMessage : "" })
        }
      }))
      error["WorkLogs"] = workLogError;
      newErrors.push(error)
    })
    setErrors(newErrors);
    return isValid;
  }

  const handleOnApprove = async () => {
    try {
      const response = await axiosInstance.post(API.logout(currentUser?.id));
      if (response.status === 200) {
        let ask_login_details = response.data?.ask_login_details;
        if (ask_login_details?.hasOwnProperty("last_day") || ask_login_details?.hasOwnProperty("checkout") || ask_login_details?.hasOwnProperty("current_day")) {
          const defaultObject = {
            ...createDefaultObject(response.data?.ask_login_details, keysWithActualValues),
            date: response.data?.ask_login_details?.date,
            time: moment(response.data?.ask_login_details?.date).format("hh:mm a"),
          };
          setLogoutModelDetails({
            isShow: true,
            ...defaultObject,
            email: currentUser?.email,
            last_activity: defaultObject?.msg?.toString()?.split("from")[1]?.replace("b", "")?.replace("<>", "") || null
          });
        } else {
          showAlert(response?.data?.message);
          dispatch(clearStorage());
          clearAllCookies();
          dispatch(clearActionData())
          navigate(URLS.Login, {
            replace: true,
          });
        }
      } else {
        showAlert(response?.data?.message);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleOnSubmit = async () => {
    try {
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: true }))
      let payload = {
        email: logoutModelDetails?.email,
        ask_login_details: { ...logoutModelDetails },
        // ...(taskTimerData?.id ? { task_id: taskTimerData?.task_id, work_log_id: taskTimerData?.id } : {})
      };
      delete payload.ask_login_details.email;
      delete payload.ask_login_details.msg;
      delete payload.ask_login_details.isShow;
      delete payload.ask_login_details.isButtonLoading;
      delete payload.ask_login_details.time;

      let errorsOfLogout = validateLogoutModelData(payload);
      setLogoutErrors(errorsOfLogout?.ask_login_details);
      if (!Object.values(errorsOfLogout)?.filter((value) => !!value)?.length > 0) {
        const response = await axiosInstance.post(API.addOverTime, payload);
        if (response.status === 200) {
          secureLocalStorage.clear();
          clearAllCookies();
          navigate(URLS.Login, {
            replace: true,
          });
          showAlert(response?.data?.message);
          setLogoutModelDetails(prev => ({
            ...prev,
            isShow: false,
            isButtonLoading: false
          }));
          dispatch(clearActionData())
        } else {
          secureLocalStorage.clear();
          clearAllCookies();
          navigate(URLS.Login, {
            replace: true,
          });
          dispatch(clearActionData())
        }
        setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
      } else {
        setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
      }
    }
    catch (error) {
      console.error(error)
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
    }
  }

  const setClearLastDayTaskData = () => {
    setProjectTaskList([initialProjectTaskData]);
    setErrors([{
      ...initialError,
      unique_id: initialProjectTaskData?.unique_id,
    }])
    setLogoutErrors({})
    setIsLoading(prev => ({
      pageLoading: false,
      allProjectsLoading: false,
      userProjectLoading: false,
      projectTaskLoading: false

    }))
    setModalDetails({
      isOpen: false,
      project_id: null,
      search: "",
      user_id: currentUser?.id,
      projectDetail: {},
      unique_id: null
    })
    setTodayTrackerHours({})
    setSelectedProjectTask([]);
    setTotalWorkingSeconds(0);
    setAvailableWorkingSeconds(0);
    setTotalActivityHours(0);
    setIsDisable(false);
    setWorkingActivity([]);
    setOptionalWorkingActivity([]);
    setLogoutModelDetails(initialModalValues)
  }

  const handleSubmit = async () => {
    try {
      if (isValidateData()) {
        setIsLoading(prev => ({ ...prev, formSubmitting: true }))
        let body = projectTaskList?.map((taskData) => {
          let isChanged = !taskData?.isExist && taskData?.ProjectPhase?.id !== taskData?.phase;
          if (taskData?.isNullTaskSelected && taskData?.phase && taskData?.ProjectPhase?.id !== taskData?.phase) {
            isChanged = true
          }
          return {
            "id": taskData?.id ? taskData?.id : null,
            "project_id": taskData?.projects?.id,
            "title": taskData?.title,
            "date": (LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) ? LastDayLogoutData?.[0]?.date : moment(new Date())?.format('YYYY-MM-DD'),
            "status": taskData?.status,
            "priority": taskData?.id ? taskData?.priority : PROJECT_TASK_PRIORITY["Low"],
            "is_eod": true,
            "project_phase_id": taskData?.phase?.toString()?.length ? taskData?.phase : null,
            "type": taskData?.type,
            "estimation": taskData?.estimation ?? 0,
            "spent": taskData?.spent ?? 0,
            "description": taskData?.description,
            "workLogs": taskData?.WorkLogs?.map((workLogData) => {
              return {
                id: workLogData?.id ?? null,
                duration: getDayJsToSecond(workLogData?.duration),
                description: workLogData?.description
              }
            }),
            "is_phase_changed": isChanged,
            "user_id": currentUser?.id,
            "duration": getDayJsToSecond(taskData?.duration),
            "project_name": taskData?.Project?.title ?? null,
            "short_code": taskData?.Project?.short_code ?? null,
            "phase_name": taskData?.ProjectPhase?.title ? taskData?.ProjectPhase?.title?.split(" ")?.map((word) => word.charAt(0).toUpperCase()).join("") : "-",
            ...(taskData?.id ? { task_code: taskData?.task_id } : {}),
            ...(taskData?.isExist ? { isExist: taskData?.isExist, work_log_id: taskData?.workLogId ?? null } : {}),
          }
        });

        let response = await axiosInstance.post(API.createAllTaskOfEOD, {
          date: (LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) ? LastDayLogoutData?.[0]?.date : moment(new Date())?.format('YYYY-MM-DD'),
          tasks: body,
          unknownSettledTask: (LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) ? true : false
        });
        if (response?.status === 200) {
          showAlert(response?.data?.message);
          if (!isLastDayTaskRemaining) {
            handleOnApprove();
            setIsLoading(prev => ({ ...prev, formSubmitting: false }))
          } else {
            if (LastDayLogoutData?.length > 1) {
              setIsLoading(prev => ({ ...prev, formSubmitting: false }))
              setClearLastDayTaskData()
              let updatedMissingEodData = [...LastDayLogoutData];
              updatedMissingEodData?.splice(0, 1);
              LastDayLogoutData = updatedMissingEodData;
              secureLocalStorage.setItem('lastDayLogutData', JSON.stringify(updatedMissingEodData));
              getTodaysWorkingHours(projectList, updatedMissingEodData);
            } else {
              secureLocalStorage.removeItem('isLastDayTaskRemain');
              secureLocalStorage.removeItem('lastDayLogutData');
              navigate(URLS.Dashboard)
            }
          }
        } else {
          showAlert(response?.data?.message, "error");
        }
        setIsLoading(prev => ({ ...prev, formSubmitting: false }))
      }
    } catch (error) {
      setIsLoading(prev => ({ ...prev, formSubmitting: false }))
      showAlert("Something went wrong!", "error");
      console.error(error);
    }
  }

  const getFilteredPhase = (projectId) => {
    return allProjectPhases?.filter((phasedata) => phasedata?.project_id === projectId);
  }

  const isValidateDataWorkLog = (task_unique_id, workLog_uniqueId, description, duration) => {
    let tempVal = description;
    let isHoursNotValid = false
    if (duration < 59) {
      isHoursNotValid = true
    }

    let isEmpty = !tempVal || tempVal
      ?.replace(/<br\s*\/?>/g, '')
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim().length === 0;

    let descriptionLength = description
      ?.replace(/<br\s*\/?>/g, '')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim().length || 0;

    setErrors(prev => prev?.map(data => {
      if (data?.unique_id === task_unique_id) {
        return {
          ...data,
          WorkLogs: data?.WorkLogs?.map((workLogData => {
            if (Object.keys(workLogData)?.[0] === workLog_uniqueId) {
              return {
                ...workLogData,
                [workLog_uniqueId]: !isEmpty ? descriptionLength > 5000 ? "Up to 5000 characters allowed" : "" : "Required",
                'hours': isHoursNotValid ? "Required" : ""
              }
            }
            return workLogData
          })),
        }
      }
      return data;
    }))
    return (isHoursNotValid || isEmpty || descriptionLength > 5000)
  }

  const handleClickSaveWorklog = async (taskDetails, workLogDetails) => {
    try {
      if (!isValidateDataWorkLog(taskDetails?.unique_id, workLogDetails?.unique_id, workLogDetails?.description, getDayJsToSecond(workLogDetails?.duration))) {
        setIsLoading(prev => ({ ...prev, saveWorkLog: true }))
        let body = workLogDetails?.id ? {
          "description": workLogDetails?.description,
          "id": workLogDetails?.id
        } : {
          "task_id": taskDetails?.id,
          "duration": getDayJsToSecond(workLogDetails?.duration),
          "description": workLogDetails?.description,
          "user_id": taskDetails?.user_id,
          "date": moment(new Date()).format('YYYY-MM-DD'),
          "is_eod": true
        }
        let response = await axiosInstance.post(API.saveWorkLog, body);
        if (response?.status === 200) {
          if (workLogDetails?.id) {
            setProjectTaskList(prev => prev?.map((taskData) => {
              if (taskData?.unique_id === taskDetails?.unique_id) {
                return {
                  ...taskData,
                  WorkLogs: taskData?.WorkLogs?.map((workLogData => {
                    if (workLogData?.unique_id === workLogDetails?.unique_id) {
                      return {
                        ...workLogData,
                        description: response?.data?.workLog?.description
                      }
                    }
                    return workLogData
                  })),
                }
              }
              return taskData
            }))
          } else {
            setProjectTaskList(prev => prev?.map((taskData) => {
              if (taskData?.unique_id === taskDetails?.unique_id) {
                return {
                  ...taskData,
                  WorkLogs: taskData?.WorkLogs?.map((workLogData => {
                    if (workLogData?.unique_id === workLogDetails?.unique_id) {
                      return {
                        ...workLogData,
                        isNewWorkLog: false,
                        ...response?.data?.workLog,
                        isExistWorkLog: true,
                        actualDuration: response?.data?.workLog?.duration,
                        duration: secondToDayJsHours(Math.abs(response?.data?.workLog?.duration ?? 0))
                      }
                    }
                    return workLogData
                  })),
                }
              }
              return taskData
            }))
          }
        }
        setIsLoading(prev => ({ ...prev, saveWorkLog: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading(prev => ({ ...prev, saveWorkLog: false }))
    }
  }

  const inputStyle = {
    caretColor: "transparent",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  return (
    isLoading?.pageLoading ? <CircularLoader /> :
      <Box
        p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}
        sx={{ overflowY: 'auto', minWidth: '100%' }}
      >
        {isLastDayTaskRemaining ? <Box mb={2}>
          <Typography color={"dark.800"} fontSize={{ xs: 14, xl: 16 }} fontWeight={400} lineHeight={"24px"} letterSpacing={'0.15px'}>Please enter {getTaskDate(true)} missed tasks into the system today. Completing this step is mandatory to continue.</Typography>
        </Box> : null}
        <Box mb={2} display={"flex"} justifyContent={"space-between"} gap={1} flexWrap={"wrap"}>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Typography color={"dark.800"} fontSize={{ xs: 19, xl: 22 }} fontWeight={700} lineHeight={"33px"}>{!isLastDayTaskRemaining ? "Today’s Tasks" : ""} {getTaskDate()}</Typography>
            {isLastDayTaskRemaining ? <Chip
              label="Last Day’s Tasks"
              color="error"
              size="small"
              variant="filled"
            /> : null}
          </Box>
          {/* <Box bgcolor={"greentc.light"} px={2} py={"14px"} display={"flex"} alignItems={"center"} gap={1.5} borderRadius={1}>
            <TimerOutlinedIcon sx={{ fontSize: 22, color: "#2E7D32" }} />
            <Typography color={"#1E4620"} fontSize={{ xs: 14, xl: 16 }} fontWeight={"500"} lineHeight={"24px"} letterSpacing={"0.15px"}>{!isLastDayTaskRemaining ? 'Today' : ''}<b>{isLastDayTaskRemaining ? getCurrentTaskDate() : ''}</b>, you have tracked <b>{secondsToTime(LastDayLogoutData?.length ? LastDayLogoutData[0]?.duration : todayTrackerHours?.working_hrs)} hours</b></Typography>
          </Box> */}
          {getTotalAutoTrackedHours() > 0 ? <Box bgcolor={"greentc.light"} px={2} py={"14px"} display={"flex"} alignItems={"center"} gap={1.5} borderRadius={1}>
            <TimerOutlinedIcon sx={{ fontSize: 22, color: "#2E7D32" }} />
            <Typography color={"#1E4620"} fontSize={{ xs: 14, xl: 16 }} fontWeight={"500"} lineHeight={"24px"} letterSpacing={"0.15px"}>{!isLastDayTaskRemaining ? 'Today' : ''}<b>{isLastDayTaskRemaining ? getCurrentTaskDate() : ''}</b>, you have tracked <b>{secondsToTime(LastDayLogoutData?.length ? getTotalAutoTrackedHours() : getTotalAutoTrackedHours())} hours</b></Typography>
          </Box> : null}
        </Box>
        <Box
          display={"flex"}
          width={"100%"}
          pb={2}
        >
          <Stack spacing={2} sx={{ minWidth: '100%' }}>
            {workingActivity?.length ? optionalWorkingActivity?.map((workinActivitydata, index) => {
              if (workinActivitydata?.Activity?.types !== "working") {
                return null;
              }
              const filteredIndex = workingActivity.findIndex((filteredData) => filteredData?.id === workinActivitydata?.id);

              return (
                <Box display={'flex'} key={index} sx={{ minWidth: '100%' }}>
                  <Box width={50} minWidth={50} maxWidth={50}>
                    <Typography
                      textAlign={"right"}
                      fontSize={14}
                      pt={2}
                      width={30}
                      minWidth={30}
                    >
                      {filteredIndex + 1}.
                    </Typography>
                  </Box>
                  <Card key={index} sx={{ boxShadow: "none", display: "flex", width: '100%' }}>
                    <Card
                      sx={{
                        padding: "16px",
                        bgcolor: "offWhite",
                        boxShadow: "none",
                        flex: "1",
                        overflow: 'visible',
                        width: '100%'
                      }}
                    >
                      <Typography color={'dark.800'} fontSize={14} fontWeight={500} lineHeight={'24px'} letterSpacing={'0.15px'}>
                        Activity of <b>{titleCase(workinActivitydata?.msg)} {workinActivitydata?.activity}</b> tracked for <b>{durationToWord(moment.utc(workinActivitydata?.duration * 1000).format("HH:mm:ss"))}</b> {durationToWord(moment.utc(workinActivitydata?.duration * 1000).format("HH:mm:ss"))?.includes('Seconds') ? '' : 'hours'} ({timestampToTime(workinActivitydata?.createdAt)} - {timestampToTime(optionalWorkingActivity[index + 1]?.createdAt)})
                      </Typography>
                    </Card>
                  </Card>
                </Box>
              )
            }
            ) : null}
            {projectTaskList?.map((taskData, index, array) =>
            (
              <Box display={'flex'} key={taskData?.unique_id} sx={{ minWidth: '100%' }}>
                <Box width={50} minWidth={50} maxWidth={50} pt={1}>
                  <Typography
                    textAlign={"right"}
                    fontSize={14}
                    width={30}
                    minWidth={30}
                  >
                    {isNaN(workingActivity?.length + index + 1) ? 1 : workingActivity?.length + index + 1}.
                  </Typography>
                </Box>
                <Box width={"100%"} bgcolor={"offWhite"} p={3} className="worklog-wrapper">
                  <Box width={"100%"} display={"flex"} >
                    <Box pb={3} display={"flex"} width={"100%"} flex={1} mr={1} alignItems={"flex-start"}>
                      <Grid container
                        boxShadow={"none"}
                        flex={1}
                        overflow={"visible"}
                        width={"100%"}
                        rowGap={"24px"}
                      >
                        {/* Project */}
                        <Grid
                          item
                          pr={3}
                          width={200}
                          maxWidth={200}
                          minWidth={200}
                          sx={{
                            '.MuiAutocomplete-popper': {
                              minWidth: '300px'
                            }
                          }}
                        >
                          <FormControl
                            className={classes.margin}
                            variant="standard"
                            sx={{ margin: "0px !important", width: "100%" }}
                          >
                            <AutocompleteSelect
                              label="Project"
                              name="projects"
                              id={getInputId("projects")}
                              onBlur={(e) => handleBlurFunction(e, index)}
                              onChange={(event) =>
                                handleChangeFunction(
                                  taskData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              options={projectList}
                              disabledOptions={projectList?.filter((data) => data?.id === "nill")}
                              isOptionEqualToValue={(option, value) => projectList?.find(user => user.id === value?.id) || {}}
                              value={projectList?.find(project => project?.id === taskData?.projects?.id) || { id: 'nill', title: 'Select', "short_code": "SL", }}
                              isProject={true}
                              disableClearable={taskData?.projects?.id === 'nill' || !taskData?.projects?.id}
                              disabled={!!taskData?.isExist}
                              isError={!!findErrorByUniqueId(taskData?.unique_id)?.projects?.length}
                            />
                            {!!findErrorByUniqueId(taskData?.unique_id)?.projects?.length ? (
                              <FormHelperText sx={{ color: "red.main" }}>
                                {errors?.[index]?.projects}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>

                        <Grid Item display={"flex"}
                          width={"32%"}
                          minWidth={300}
                          flex={1}
                        >
                          {/* Task */}
                          <Grid
                            item
                            pr={3}
                            flex={1}
                          >
                            <FormControl
                              className={ClassNames.margin}
                              variant="standard"
                              sx={{
                                margin: "0px !important", width: "100%",
                                '.MuiInput-root': {
                                  marginTop: '19px !important',
                                },
                                '.MuiInput-root.Mui-disabled': {
                                  marginTop: '19px !important',
                                },
                                '.MuiIconButton-root': {
                                  height: 24,
                                  width: 24,
                                  padding: 0,
                                  'svg': {
                                    height: 24,
                                    width: 24,
                                  },
                                }
                              }}
                            >
                              <Input
                                id={getInputId("task", index)}
                                label="Task"
                                variant="standard"
                                placeholder="Type and search tasks"
                                type="text"
                                name={"task"}
                                size="small"
                                fullWidth
                                required={false}
                                onChange={() => { }}
                                onBlur={() => { }}
                                value={taskData?.task}
                                readOnly={true}
                                error={(!!findErrorByUniqueId(taskData?.unique_id)?.task)}
                                helperText={findErrorByUniqueId(taskData?.unique_id)?.task}
                                // onClick={() => (taskData?.projects?.id === 'nill' || !taskData?.projects?.id) ? {} : handleOpenPreviousTask(taskData)}
                                onClick={() => (taskData?.projects?.id === 'nill' || taskData?.task?.length) ? {} : handleOpenPreviousTask(taskData)}
                                disabled={taskData?.projects?.id === 'nill' || !taskData?.projects?.id || !!taskData?.isExist}
                                InputProps={{
                                  style: inputStyle,
                                  tabIndex: 0,
                                  endAdornment: (
                                    <>
                                      {((taskData?.task?.trim()?.length) && (taskData?.projects?.id !== 'nill' && taskData?.projects?.id && !taskData?.isExist)) ? (
                                        <IconButton onClick={(e) => {
                                          e.stopPropagation();
                                          handleClearTaskTitle(taskData?.unique_id)
                                        }}>
                                          <CloseRoundedIcon sx={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.54)', }} />
                                        </IconButton>
                                      ) : null}
                                      {(taskData?.projects?.id && taskData?.projects?.id !== 'nill' && !taskData?.isExist) ?
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenPreviousTask(taskData);
                                          }}
                                          ref={dropDownRef}
                                        >
                                          <ArrowDropDownIcon sx={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.54)' }} />
                                        </IconButton> : null}
                                    </>
                                  ),
                                }}
                                shrink={false}
                                isEnter={false}
                                sx={{
                                  '& .MuiInputBase-input': {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>

                          {/* Phase */}
                          {(taskData?.projects?.id !== "nill" && taskData?.projects?.id) ?
                            <Grid
                              item
                              pr={3}
                              width={130}
                              maxWidth={130}
                              minWidth={130}
                            >
                              <Input
                                id={getInputId("phase", index)}
                                name={"phase"}
                                select
                                type="select"
                                label="Phase"
                                variant="standard"
                                sx={{ width: "100%" }}
                                onChange={(event) =>
                                  handleChangeFunction(
                                    taskData?.unique_id,
                                    event,
                                    index
                                  )
                                }
                                onBlur={(e) => handleBlurFunction(e, index)}
                                value={taskData?.phase || ""}
                                // error={!!findErrorByUniqueId(taskData?.unique_id)?.phase?.length}
                                // helperText={
                                //   findErrorByUniqueId(taskData?.unique_id)?.phase
                                // }
                                required={false}
                                disabled={!!taskData?.isExist}
                              >
                                {getFilteredPhase(taskData?.projects?.id)?.length < 1 ? <MenuItem value="nill" disabled>
                                  No Options
                                </MenuItem> : null}
                                {(getFilteredPhase(taskData?.projects?.id)?.length > 0 && (!taskData?.phase?.toString()?.length)) ? <MenuItem value="nill" disabled>
                                  Select
                                </MenuItem> : null}
                                {
                                  getFilteredPhase(taskData?.projects?.id)?.map((option, index) => (
                                    <MenuItem key={index} value={option?.id}>
                                      {titleCase(option?.title)}
                                    </MenuItem>
                                  ))
                                }
                              </Input>
                            </Grid> : null}
                        </Grid>

                        {/* <Grid Item display={"flex"}> */}
                        {/* Type */}
                        <Grid
                          pr={3}
                          flex={1}
                          minWidth={174}
                          maxWidth={174}
                        >
                          <Input
                            id={getInputId("type", index)}
                            name={"type"}
                            select
                            type="select"
                            label="Type"
                            defaultValue={"nill"}
                            variant="standard"
                            sx={{ width: "100%" }}
                            onChange={(event) =>
                              handleChangeFunction(
                                taskData?.unique_id,
                                event,
                                index
                              )
                            }
                            onBlur={(e) => handleBlurFunction(e, index)}
                            value={taskData?.type || "nill"}
                            error={!!findErrorByUniqueId(taskData?.unique_id)?.type?.length}
                            helperText={
                              findErrorByUniqueId(taskData?.unique_id)?.type
                            }
                            required={false}
                            disabled={!!taskData?.isExist}
                          >
                            {taskData?.type === "nill" ? <MenuItem value="nill" disabled>
                              Select
                            </MenuItem> : null}
                            {Object.entries(TASK_TYPE_LIST)?.map((option, index) => (
                              <MenuItem key={index} value={option[0]}>
                                {option[1]}
                              </MenuItem>
                            ))}
                          </Input>
                        </Grid>

                        {/* Status */}
                        <Grid
                          pr={3}
                          flex={1}
                          minWidth={174}
                          maxWidth={174}
                        >
                          <Input
                            id={getInputId("status", index)}
                            name={"status"}
                            select
                            type="select"
                            label="Status"
                            defaultValue={"nill"}
                            variant="standard"
                            sx={{ width: "100%" }}
                            onChange={(event) =>
                              handleChangeFunction(
                                taskData?.unique_id,
                                event,
                                index
                              )
                            }
                            onBlur={(e) => handleBlurFunction(e, index)}
                            value={taskData?.status || "nill"}
                            error={!!findErrorByUniqueId(taskData?.unique_id)?.status?.length}
                            helperText={
                              findErrorByUniqueId(taskData?.unique_id)?.status
                            }
                            required={false}
                          // disabled={!!taskData?.isExist}
                          >
                            {taskData?.status === "nill" ? <MenuItem value="nill" disabled>
                              Select
                            </MenuItem> : null}
                            {Object.entries(EOD_TASK_STATUS_LIST)?.map((option, index) => (
                              <MenuItem key={index} value={option[0]}>
                                {option[1]}
                              </MenuItem>
                            ))}
                          </Input>
                        </Grid>
                        {/* </Grid> */}
                      </Grid>
                    </Box>
                    {/* Delete */}
                    <Box width={40} minWidth={40} maxWidth={40} mt={0.5}>
                      {!taskData?.isExist ?
                        <Box width={40} minWidth={40} maxWidth={40}>
                          {index !== 0 ? (
                            <IconButton
                              size="small"
                              sx={{ width: "40px", height: "40px" }}
                              onClick={() =>
                                handleRemoveTask(taskData, index, taskData?.unique_id)
                              }
                              disabled={taskData?.idDeleteDisabled}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          ) : null}
                        </Box> : null}
                    </Box>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={3}>
                    {
                      taskData?.WorkLogs?.map(((workLogData, index, workLogArray) => (
                        <Box display={"flex"} gap={3} flex={1} alignItems={"flex-end"} key={workLogData?.unique_id} pr={8} >
                          <Box item flex={1} className='worklog-editor'>
                            <Typography
                              variant="body2"
                              color={!!findWorkLogErrorByUniqueId(taskData?.unique_id, workLogData?.unique_id)?.length ? "error.main" : "dark.600"}
                              fontSize={12}
                              fontWeight={400}
                              sx={{ lineHeight: "21px", letterSpacing: "0.15" }}
                              mb={1}
                            >
                              {`Worklog ${taskData?.task_id ? `for ${taskData?.task_id}` : ''}`}
                            </Typography>
                            <TextEditor
                              className="textEditor-eod"
                              id={getInputId("description", taskData?.unique_id)}
                              value={workLogData?.description}
                              onChange={(value) =>
                                handleChangeDescription(taskData?.unique_id, workLogData?.unique_id, value)
                              }
                              placeholder={'Add log to explain your efforts/approach for this task. Use dash sign(-) for step-by-step approach.'}
                            />
                            {!!findWorkLogErrorByUniqueId(taskData?.unique_id, workLogData?.unique_id)?.length ?
                              <Typography
                                variant="body2"
                                fontWeight={400}
                                color={'error'}
                                sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
                              >
                                {findWorkLogErrorByUniqueId(taskData?.unique_id, workLogData?.unique_id)}
                              </Typography>
                              : null}
                          </Box>
                          {/* Hours */}
                          <Box
                            flex={1}
                            minWidth={88}
                            maxWidth={88}
                            sx={{
                              ".MuiInputBase-formControl": {
                                padding: '0px',
                                marginTop: "13px",
                              },
                              "input": {
                                padding: '6px 0px',
                              },
                              ".MuiInputLabel-formControl": {
                                transform: 'translate(0px, -3px) scale(0.75)',
                              },
                              "fieldset": {
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                borderRadius: '0px'
                              }
                            }}
                          >
                            <CustomTimeInput
                              label="Hours"
                              time={workLogData?.duration}
                              onTimeChange={(value) => handleTimeChange(value, taskData?.unique_id, workLogData?.unique_id)}
                              handleBlur={(value) => handleTimeBlur(value, taskData?.unique_id, workLogData?.unique_id)}
                              disabled={(!!workLogData?.isExistWorkLog && (workLogData?.end_time || (!workLogData?.start_time && !workLogData?.end_time))) || (!!taskData?.isExist && isLoading?.saveWorkLog)}
                            />
                            {!!findHourErrorByUniqueId(taskData?.unique_id, workLogData?.unique_id)?.length ? (
                              <FormHelperText sx={{ color: "red.main" }}>
                                {findHourErrorByUniqueId(taskData?.unique_id, workLogData?.unique_id)}
                              </FormHelperText>
                            ) : null}
                          </Box>

                          {(taskData?.isExist && !isLastDayTaskRemaining) ? <Box width={40} minWidth={40} maxWidth={40}>
                            <IconButton
                              size="small"
                              sx={{ width: "40px", height: "40px" }}
                              onClick={() => handleClickSaveWorklog(taskData, workLogData)}
                              disabled={!!taskData?.isExist && isLoading?.saveWorkLog}
                            >
                              <CheckCircleIcon fontSize="small" />
                            </IconButton>
                          </Box> : null}

                          {(index !== 0 || workLogArray?.length > 1) ? <Box width={40} minWidth={40} maxWidth={40} mt={0.5}>
                            <Box width={40} minWidth={40} maxWidth={40} className="delete-btn-eod">
                              <IconButton
                                size="small"
                                sx={{ width: "40px", height: "40px" }}
                                disabled={!!workLogData?.isExistWorkLog || (!!taskData?.isExist && isLoading?.saveWorkLog)}
                                onClick={() => handleClickDeleteWorkLog(taskData?.unique_id, workLogData?.unique_id, workLogData)}
                                className="saveWorklog-btn-eod"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          </Box> : null}
                        </Box>
                      )))
                    }
                    {(isShowAddMoreWorkLog()) ? <Box width={152} minWidth={152} maxWidth={152}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        sx={{
                          boxShadow: 'none',
                          textTransform: 'none',
                        }}
                        className="newWorklog-btn-eod"
                        onClick={() => handleClickAddWorkLog(taskData?.unique_id)}
                        startIcon={<WorkLogIcon />}
                      >
                        New Worklog
                      </Button>
                    </Box> : null}

                  </Box>
                </Box>
              </Box>
            )
            )}
            {availableWorkingSeconds > 59 ? <Box sx={{ paddingLeft: '50px', marginBottom: '20px' }}>
              <Button
                color='secondary'
                onClick={() => handleAddTask()}
                sx={{ textTransform: 'none', boxShadow: 'none !important' }}
              >
                Add Task
              </Button>
            </Box> : null}
          </Stack>
        </Box>
        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', alignContent: 'center', marginTop: '20px' }}>
          <Box display={"flex"} pr={"6px"}>
            <Typography display={"flex"} alignItems={"center"} color={"secondary.main"} fontSize={{ xs: 16, xl: 18 }} fontWeight={500} lineHeight={"27px"} letterSpacing={"0.15px"} mr={{ xs: 1.5, xl: 2 }} sx={{ "span": { marginLeft: '4px' }, }}>Availability {isLastDayTaskRemaining ? "for" : ""} {isLastDayTaskRemaining ? getTaskDate(false, false) : 'today'}</Typography>
            <Typography color={"dark.800"} fontSize={{ xs: 22, xl: 25 }} fontWeight={700} lineHeight={"35px"} letterSpacing={"0.15px"} mr={0.4}>
              {getAvailableTaskHours(todayTrackerHours)}
            </Typography>
            <Typography color={"dark.800"} fontSize={{ xs: 13, xl: 14 }} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"} sx={{ opacity: '0.7' }} pt={1.5}>hrs</Typography>
          </Box>
          <Typography color={"dark.800"} fontSize={{ xs: 16, xl: 18 }} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} px={{ xs: 1.5, xl: 2 }}>=</Typography>
          <Box py={"5px"} px={1} bgcolor={"#EEEEEE"} borderRadius={1} display={"inline-flex"} alignItems={"center"} gap={2} mr={1}>
            <Typography color={"secondary.main"} fontSize={{ xs: 16, xl: 18 }} fontWeight={500} lineHeight={"27px"} letterSpacing={"0.15px"}>Total tasks hours </Typography>
            <Typography color={"dark.800"} fontSize={{ xs: 22, xl: 25 }} fontWeight={700} lineHeight={"37px"} letterSpacing={"0.15px"}>{getTotalTaskHours(todayTrackerHours)}</Typography>
          </Box>
          <Box display={"inline-flex"} alignItems={"center"}>
            <Typography color={"dark.main"} fontSize={{ xs: 16, xl: 18 }} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} mr={1}>+</Typography>
            <Typography color={"dark.main"} fontSize={{ xs: 16, xl: 18 }} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} mr={0.5}>{" "}{getOtherTaskHours(totalActivityHours)}</Typography>
            <Typography color={"dark.800"} fontSize={{ xs: 13, xl: 14 }} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"} sx={{ opacity: '0.7' }} pt={"5px"}>other hrs</Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexBasis="100%"
          gap={1}
          marginTop={'20px'}>
          <Button
            variant="contained"
            size="large"
            // type="submit"
            sx={{
              boxShadow: "0",
              fontSize: "15px",
              textTransform: "none",
              color: "white",
              "&:hover": { boxShadow: "0" },
            }}
            disabled={isDisable || availableWorkingSeconds > 59}
            isButtonLoading={isLoading.formSubmitting}
            onClick={handleSubmit}
          >
            {isLastDayTaskRemaining ? "Submit" : 'Submit & Logout'}
          </Button>
          {!isLastDayTaskRemaining ? <Button
            variant="text"
            color="secondary"
            size="large"
            sx={{
              boxShadow: "0",
              fontSize: "15px",
              textTransform: "none",
              "&:hover": { boxShadow: "0" },
            }}
            disabled={isLoading.formSubmitting}
            onClick={() => navigate(URLS.Dashboard)}
          >
            Cancel
          </Button> : null}
        </Box>

        {/* Task popup */}
        <BootstrapDialog
          open={modalDetails?.isOpen}
          onClose={() => handleTaskModalClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            alignItems: 'flex-start',
            '.MuiDialog-paper': {
              maxWidth: '100%',
              width: '900px',
              ' > div': {
                overflow: 'hidden',
                display: 'flex',
                '> div': {
                  display: 'flex',
                }
              },
            },
            '.MuiDialog-container': { alignItems: 'flex-start' }
          }}
        >
          <DialogContent sx={{ width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <TasksModal modalDetails={modalDetails} handleTaskModalClose={handleTaskModalClose} currentUser={currentUser}
                searchString={modalDetails?.search} handleSearch={handleSearch} selectedProjectTask={selectedProjectTask?.filter(taskDetails => !projectTaskList?.find(taskListData => taskListData?.id === taskDetails?.id))}
                handleAddNewTask={handleAddNewTask} isLoading={isLoading} handleAddExistTask={(task) => handleAddExistTask(task)}
                allProjectPhases={allProjectPhases} LastDayLogoutData={LastDayLogoutData} isLastDayTaskRemaining={isLastDayTaskRemaining}
                getTaskDate={getTaskDate}
              />
            </Box>
          </DialogContent>
        </BootstrapDialog>

        <LogoutModal
          actionType={"ask_login_details"}
          onApprove={handleOnSubmit}
          isShow={logoutModelDetails.isShow}
          isButtonLoading={logoutModelDetails.isButtonLoading}
          title={`Logout time for ${moment(logoutModelDetails?.date).format("DD/MM/YYYY (dddd)")}`}
          isShowCancelButton={false}
          errors={logoutErrors}
          setErrors={setLogoutErrors}
          setLogoutModelDetails={setLogoutModelDetails}
          logoutModelDetails={logoutModelDetails}
        />
      </Box>
  )
}

export default ProjectTask