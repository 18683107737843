import { Box, Typography, IconButton, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import React from 'react'
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import { titleCase } from '../../../../utils';

const MembersList = ({ setOpenMembersListDrawer, teamMembersData, projectData }) => {
    return (
        <>
            <Box p={2} display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <Typography color={"dark.800"} fontSize={16} fontWeight={400} lineHeight={"25px"} letterSpacing={"0.15px"} mr={0.5} display={"inline-block"}>Team of </Typography>
                    <Typography color={"dark.800"} fontSize={16} fontWeight={600} lineHeight={"25px"} letterSpacing={"0.15px"} display={"inline-block"}>{titleCase(projectData?.title)}</Typography>
                </Box>
                <IconButton
                    aria-label="Close"
                    size="small"
                    // onClose={() => toggleDrawer(false)}
                    sx={{ width: 24, minWidth: 24, height: 24, padding: '2px' }}
                    onClick={() => setOpenMembersListDrawer(false)}
                >
                    <CloseIcon fontSize="medium" />
                </IconButton>
            </Box>
            <Divider />
            <Box flex={1} p={3} overflow={"auto"}>
                <TableContainer sx={{ overflow: "visible" }}>
                    <Table>
                        <TableHead
                            sx={{
                                'th': {
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: 13,
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    letterSpacing: "0.17px",
                                    background: 'transparent',
                                    padding: "8px 8px 5px 8px",
                                    borderBottom: "2px solid rgba(0, 0, 0, 0.12)"
                                }
                            }}
                        >
                            <TableRow>
                                <TableCell>{teamMembersData?.filter(memberData => memberData?.is_active)?.length ?? 0} Members</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                'td': {
                                    borderBottom: "none",
                                    padding: '8px',
                                }
                            }}
                        >
                            {
                                teamMembersData?.length ? teamMembersData?.map((memberData, index, array) => (
                                    <React.Fragment key={index}>
                                        {
                                            memberData?.is_active ?
                                                <TableRow hover
                                                    sx={{
                                                        '&:hover': {
                                                            '.delete-btn': {
                                                                opacity: '1',
                                                            },
                                                        },
                                                        "td": {
                                                            verticalAlign: "top",
                                                        }
                                                    }}
                                                >
                                                    <TableCell>
                                                        <Box display={"flex"} alignItems={"center"} gap={1}>
                                                            <Box minWidth={32} maxWidth={32} width={32}>
                                                                <BackgroundLetterAvatars
                                                                    sx={{
                                                                        width: "32px",
                                                                        height: "32px",
                                                                        fontSize: "90%",
                                                                    }}
                                                                    user={memberData?.User}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Typography color={"dark.800"} fontSize={16} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"}>{titleCase(memberData?.User?.name)}</Typography>
                                                                <Typography variant="body1" color={"rgba(63, 92, 118, 0.9)"} fontSize={12} fontWeight={400} lineHeight={"14px"}>{memberData?.User?.email}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                                : null}
                                    </React.Fragment>
                                )) : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default MembersList