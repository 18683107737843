import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Grid, Typography, Box } from "@mui/material";
import { convertToBase64, generateUniqueId } from "../../utils";
import { useAlert } from "../../hook/useAlert";

const focusedStyle = {
    borderColor: " #808080"
};

const acceptStyle = {
    borderColor: "#517c58"
};

const rejectStyle = {
    borderColor: '#ff1744'
};
const CustomFileDropZone = ({
    onDrop,
    initialFiles = [],
    fileURLs = [],
    readOnly = false,
    maxFiles = 5,
    maxTotalSize = 10 * 1000000,
    onDeleteImage,
    isConvertToBase64 = false,
    isDownloadShow = false,
    disabled = false,
    ...rest
}) => {
    const showAlert = useAlert();
    const maxFileSize = 5 * 1000000;
    const [acceptedFilesList, setAcceptedFilesList] = useState(initialFiles || []);

    useEffect(() => {
        if (!!fileURLs?.length) {
            const convertToFile = fileURLs?.filter(value => !!value)?.map((url) => ({
                name: url?.substring(url.lastIndexOf("/") + 1),
                preview: url,
                unique_id: generateUniqueId(),
            }));

            setAcceptedFilesList((prevFiles) => [...prevFiles, ...convertToFile]);
        }
    }, [fileURLs]);

    useEffect(() => {
        return () => acceptedFilesList?.forEach((file) => URL.revokeObjectURL(file?.preview));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fileSizeValidator = (file, type = "file") => {
        const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
        if (type === "validator") {
            if (!allowedFormats.includes(file.type)) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
        } else {
            const allowedFormats = [".jpeg", ".jpg", ".png", ".pdf"];
            const isValidFormat = allowedFormats.some(format => file.name?.toLowerCase()?.endsWith(format));
            if (!isValidFormat) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
            if (file?.size > maxFileSize) {
                showAlert("File size exceeds 5MB limit.", "error");
                return {
                    code: "size-too-large",
                    message: "File size exceeds 5MB limit.",
                };
            }
        }
        return null;
    };

    const totalSizeValidator = (newFiles) => {
        const totalSize = acceptedFilesList?.reduce((acc, file) => acc + file.size, 0);
        const newTotalSize = newFiles?.reduce((acc, file) => acc + file.size, 0);
        return totalSize + newTotalSize > maxTotalSize;
    };

    const { getRootProps, getInputProps, isDragAccept, isDragReject, isFocused } = useDropzone({
        accept: {
            "image/*": [".jpeg", ".jpg", ".png", ".pdf"],
        },
        onDrop: async (newlyAddedFiles) => {
            const invalidFiles = newlyAddedFiles
                .map(file => fileSizeValidator(file))
                .filter(error => error !== null);

            if (invalidFiles.length > 0) return;

            if (totalSizeValidator(newlyAddedFiles)) {
                showAlert("Total size exceeds the limit. Max 10MB allowed.", "error");
                return;
            }

            const updatedFiles = [];

            for (const file of newlyAddedFiles) {
                if (isConvertToBase64) {
                    let base64 = null;
                    if (file instanceof Blob || file instanceof File) {
                        base64 = await convertToBase64(file);
                    } else {
                        base64 = file?.base64;
                    }
                    let fileObject = {
                        name: file?.name,
                        size: file?.size,
                        type: file?.type,
                        preview: URL.createObjectURL(file),
                        unique_id: generateUniqueId(),
                        base64,
                    };
                    updatedFiles.push(fileObject);
                } else {
                    updatedFiles.push(Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        unique_id: generateUniqueId(),
                    }));
                }
            }
            const limitedFiles = [...acceptedFilesList, ...updatedFiles]?.filter((item) => item && (item !== null || item !== "null"));
            setAcceptedFilesList(limitedFiles);
            onDrop(limitedFiles);
        },
        validator: (file) => fileSizeValidator(file, "validator"),
    });

    const style = useMemo(() => {
        const borderColor = isFocused ? focusedStyle.borderColor : isDragAccept ? acceptStyle.borderColor : isDragReject ? rejectStyle.borderColor : "rgba(81, 95, 124, 0.25)";

        return {
            border: `1px dashed ${borderColor}`,
            ...(isFocused || isDragAccept || isDragReject ? {} : {
                borderColor: "rgba(81, 95, 124, 0.25)"
            }),
            cursor: "pointer",
            padding: "10px 8px",
            borderRadius: "4px",
            "&:hover": {
                borderColor: "primary.main",
            },
        };
    }, [isFocused, isDragAccept, isDragReject]);

    return (
        <Box>
            {(!readOnly) && (
                <Grid
                    item
                    {...getRootProps({ className: "dropzone" })}
                    style={style}
                    display="flex"
                    alignItems="center"
                >
                    <input {...getInputProps()} disabled={disabled} />
                    <i
                        style={{
                            height: "40px",
                            maxWidth: "40px",
                            flex: "0 0 40px",
                            padding: "10px",
                        }}
                    >
                        <UploadFileOutlinedIcon color="primary" sx={{ fontSize: "20px" }} />
                    </i>
                    <Box ml={1}>
                        <Typography
                            color="primary"
                            fontSize={14}
                            fontWeight={400}
                            mr={0.5}
                            className="upload-text"
                            sx={{
                                display: "inline-block",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                                textDecoration: "underline",
                            }}
                        >
                            Click to upload
                        </Typography>
                        <Typography
                            color="dark.800"
                            fontSize={14}
                            fontWeight={400}
                            className="drag-text"
                            sx={{
                                display: "inline-block",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                            }}
                        >
                            or drag and drop
                        </Typography>
                        <Typography
                            color="dark.800"
                            fontSize={11}
                            fontWeight={400}
                            mt={0.5}
                            className="size-text"
                            sx={{
                                display: "block",
                                lineHeight: "15px",
                                letterSpacing: "0.17px",
                            }}
                        >
                            PDF, PNG, JPG (max. 10MB per file)
                        </Typography>
                    </Box>
                </Grid>
            )}
        </Box>
    );
}

export default CustomFileDropZone