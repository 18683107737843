import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Switch from "../../../../components/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import SingleDatePicker from "../../../../components/SingleDatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { makeStyles } from "@mui/styles";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import {
  generateUniqueId,
  getFormattedDate,
  getInputId,
  isProjectEditAccess,
  sortArrayByKey,
  titleCase,
} from "../../../../utils";
import Button from "../../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../../hook/useAlert";
import TableRowSkeleton from "../../../../components/TableRowSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const TeamMembers = ({ projectDetail, setProjectDetails }) => {
  const classes = useStyles();
  const { getCurrentUser } = useAuthentication();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const showAlert = useAlert();
  const params = useParams();
  const [userRolesData, setUserRolesData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState({
    projectUserLoading: true,
    userLoading: true,
    projectRoleLoading: true,
  });
  let initialMemeberData = {
    id: null,
    User: null,
    user_id: null,
    project_role_id: null,
    joining_date: "",
    is_active: true,
    unique_id: null,
  };
  const [teamMembersData, setTeamMembersData] = useState([initialMemeberData]);
  const [actualTeamMembersData, setActualTeamMembersData] = useState([]);
  const [inActiveMembersData, setInActiveMembersData] = useState([]);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (currentUser) {
      getProjectRolesData();
    }
    if (params?.projectId) {
      getAllUserListByOrgId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userList?.length) {
      if (params?.projectId) {
        getCurrentProjectUsers(Number(params?.projectId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  const getCurrentProjectUsers = async (projectId) => {
    try {
      setIsLoading((prev) => ({ ...prev, projectUserLoading: true }));
      const response = await axiosInstance.get(
        API.getAllProjectUsersList(projectId)
      );
      if (response?.status === 200) {
        if (response?.data?.data?.length) {
          let membersData = [...response?.data?.data]?.map((memberData) => {
            return { ...memberData, unique_id: generateUniqueId() };
          });
          setInActiveMembersData(
            response?.data?.data?.filter((list) => !list?.is_active)
          );
          if (response?.data?.data?.length < userList?.length) {
            membersData.push({
              ...initialMemeberData,
              unique_id: generateUniqueId(),
            });
          }
          setTeamMembersData(membersData);
          setActualTeamMembersData(membersData);
          setErrors(
            response?.data?.data?.map((member) => {
              return {
                User: "",
                project_role_id: "",
                joining_date: "",
              };
            })
          );
        } else {
          setTeamMembersData([
            {
              ...initialMemeberData,
              unique_id: generateUniqueId(),
            },
          ]);
        }
      } else {
        setTeamMembersData([
          {
            ...initialMemeberData,
            unique_id: generateUniqueId(),
          },
        ]);
      }
      setIsLoading((prev) => ({ ...prev, projectUserLoading: false }));
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, projectUserLoading: false }));
      console.error(error);
    }
  };

  const getProjectRolesData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, projectRoleLoading: true }));
      const response = await axiosInstance.get(
        API.organisationProjectRoles(currentUser?.organization_id)
      );
      if (response.status === 200) {
        let sortedData = sortArrayByKey(response?.data?.data, "asc", "title");
        setUserRolesData(sortedData);
      }
      setIsLoading((prev) => ({ ...prev, projectRoleLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, projectRoleLoading: false }));
    }
  };

  const getAllUserListByOrgId = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, userLoading: true }));
      const response = await axiosInstance.get(
        API.organisationUserSetting(currentUser?.organization_id)
      );

      if (response.status === 200) {
        if (response.data?.data) {
          let sortedData = sortArrayByKey(response?.data?.data, "asc", "name");
          setUserList(sortedData);
        } else {
          setUserList([]);
        }
      }
      setIsLoading((prev) => ({ ...prev, userLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, userLoading: false }));
    }
  };

  const validateMemberData = (event, memberIndex, array) => {
    const { name, value } = event.target;
    let updatedErrors = [...errors];

    setErrors(
      array?.map((memberData, index) => {
        if (index === memberIndex) {
          let isLastData =
            name === "User" &&
            !memberData?.User &&
            !memberData?.project_role_id &&
            !memberData?.joining_date &&
            index === teamMembersData?.length - 1;
          return {
            ...updatedErrors?.[index],
            [name]: value ? "" : isLastData ? "" : "Required",
          };
        } else {
          return {
            ...updatedErrors?.[index],
            [name]: updatedErrors?.[index]?.[name]
              ? updatedErrors?.[index]?.[name]
              : "",
          };
        }
      })
    );
  };

  const handleChange = (event, memberIndex, array, uniqueId) => {
    let indexOfMember = memberIndex;
    let membersDataList = [...array];
    let inActiveMembersList = array?.filter((list) => !list?.is_active);
    const { name, value } = event.target;

    let leaveMemberDatalist = [];
    if (name === "User") {
      if (inActiveMembersList?.find((list) => list?.user_id === value?.id)) {
        let existRecord = inActiveMembersList?.find(
          (list) => list?.user_id === value?.id
        );

        if (existRecord) {
          leaveMemberDatalist = membersDataList?.map((memberData, index) => {
            if (uniqueId === memberData?.unique_id) {
              return {
                ...memberData,
                project_role_id: existRecord?.project_role_id,
                joining_date: existRecord?.joining_date,
                [name]: value,
                is_active: true,
                id: existRecord?.id,
                unique_id: generateUniqueId(),
              };
            }
            return memberData;
          });
          setTimeout(() => {
            leaveMemberDatalist = leaveMemberDatalist?.filter(
              (list) => list?.user_id !== existRecord?.user_id
            );
          });
        }
      } else {
        leaveMemberDatalist = membersDataList?.map((memberData, index) => {
          if (uniqueId === memberData?.unique_id) {
            return { ...memberData, [name]: value, is_active: true };
          }
          return memberData;
        });
      }
      validateMemberData(event, indexOfMember, membersDataList);
    } else {
      leaveMemberDatalist = membersDataList?.map((memberData, index) => {
        if (uniqueId === memberData?.unique_id) {
          return { ...memberData, [name]: value, is_active: true };
        }
        return memberData;
      });

      let updatedErrors = [...errors];

      setErrors(
        array?.map((memberData, index) => {
          if (index === memberIndex) {
            return {
              ...updatedErrors?.[index],
              [name]: value ? "" : "Required",
            };
          } else {
            return {
              ...updatedErrors?.[index],
              [name]: updatedErrors?.[index]?.[name]
                ? updatedErrors?.[index]?.[name]
                : "",
            };
          }
        })
      );
    }

    setTimeout(() => {
      let lastMember = leaveMemberDatalist[leaveMemberDatalist?.length - 1];
      if (
        !!value &&
        name === "User" &&
        (lastMember?.User) &&
        !array[indexOfMember]?.[name] &&
        leaveMemberDatalist?.filter((list) => list?.is_active)?.length <
        userList?.filter(
          (data) =>
            !inActiveMembersData?.find(
              (member) => member?.User?.id === data?.id
            )
        )?.length
      ) {
        leaveMemberDatalist.push({
          ...initialMemeberData,
          unique_id: generateUniqueId(),
        });
      }

      setTeamMembersData(leaveMemberDatalist);
    });
  };

  const handleDeleteMemberList = (memberData, memberIndex, uniqueId) => {
    let updatedTeamMembersData = [];
    if (memberData?.id) {
      updatedTeamMembersData = [
        ...teamMembersData?.map((member, index) =>
          member?.unique_id === uniqueId
            ? {
              ...member,
              is_active: false,
              removed_date: getFormattedDate(new Date(), "YYYY-MM-DD"),
            }
            : { ...member }
        ),
      ];
    } else {
      updatedTeamMembersData = [
        ...teamMembersData?.filter((member, index) => member?.unique_id !== uniqueId),
      ];
    }
    if (
      updatedTeamMembersData[updatedTeamMembersData?.length - 1]?.User !== null
    ) {
      updatedTeamMembersData.push({
        ...initialMemeberData,
        unique_id: generateUniqueId(),
      });
    }
    setTeamMembersData(updatedTeamMembersData);
    setErrors((prev) => prev?.filter((member, index) => index !== memberIndex));
  };

  const handleChangeDate = (value, memberIndex) => {
    let err = [...errors];
    if (!err[memberIndex]) {
      err[memberIndex] = {};
    }
    err[memberIndex].joining_date = "";
    setTeamMembersData((prev) =>
      prev?.map((memberData, index) => {
        if (memberIndex === index) {
          err[index].joining_date = "";
          return { ...memberData, joining_date: new Date(value) };
        }
        return memberData;
      })
    );
  };

  const validateTeamMemberData = () => {
    let error = teamMembersData?.map((memberData, index) => {
      if (
        index === 0 ||
        index !== teamMembersData?.length - 1 ||
        ((memberData?.User ||
          memberData?.project_role_id ||
          memberData?.joining_date) &&
          index === teamMembersData?.length - 1)
      ) {
        return {
          User: memberData?.User || !memberData?.is_active ? "" : "Required",
          project_role_id: memberData?.project_role_id || !memberData?.is_active ? "" : "Required",
          joining_date: memberData?.joining_date || !memberData?.is_active ? "" : "Required",
        };
      }
      return { User: "", project_role_id: "", joining_date: "" };
    });
    setErrors(error);
    return error?.some(
      (errorData) =>
        errorData?.User?.length ||
        errorData?.project_role_id?.length ||
        errorData?.joining_date?.length
    );
  };

  const updateMembersList = async () => {
    try {
      let payload = [
        ...teamMembersData
          ?.filter((memberData) => !!memberData?.User || memberData?.id)
          ?.map((memberData) => {
            let updatedMemberData = {
              id: memberData?.id ?? null,
              project_id: Number(params?.projectId),
              user_id: memberData?.User?.id ?? memberData?.user_id,
              project_role_id: memberData?.project_role_id,
              joining_date: getFormattedDate(
                memberData?.joining_date,
                "YYYY-MM-DD"
              ),
              is_active: memberData?.is_active ?? true,
              removed_date: !memberData?.is_active
                ? memberData?.removed_date ?? null
                : null,
            }
            let actualMemberData = actualTeamMembersData?.find(
              (data) => data?.unique_id === memberData?.unique_id
            );
            if (updatedMemberData?.user_id !== actualMemberData?.user_id ||
              updatedMemberData?.joining_date !== actualMemberData?.joining_date ||
              updatedMemberData?.project_role_id !== actualMemberData?.project_role_id ||
              updatedMemberData?.is_active !== actualMemberData?.is_active
            ) {
              updatedMemberData['is_changed'] = true
            }
            return updatedMemberData;
          }),
      ];

      const response = await axiosInstance.put(
        API.updateProjectMembersData(Number(params?.projectId)),
        { project_members: payload }
      );
      if (response?.status === 200) {
        showAlert(response.data?.message);
        getCurrentProjectUsers(Number(params?.projectId));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!validateTeamMemberData()) {
        updateMembersList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeActiveUser = async (userRolesData, event) => {
    try {
      setInActiveMembersData((prev) =>
        prev?.map((list) =>
          list?.user_id === userRolesData?.user_id
            ? { ...list, is_active: true }
            : { ...list }
        )
      );
      const response = await axiosInstance.put(
        API.activateProjectMember(userRolesData?.id)
      );
      if (response?.status === 200) {
        showAlert(response.data?.message);
        getCurrentProjectUsers(Number(params?.projectId));
      } else {
        setInActiveMembersData((prev) =>
          prev?.map((list) =>
            list?.user_id === userRolesData?.user_id
              ? { ...list, is_active: false }
              : { ...list }
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  // projectDetail?.start_date ? new Date(projectDetail?.start_date) : currentUser?.organization?.createdAt

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {(isLoading.projectUserLoading || isLoading?.projectRoleLoading || isLoading?.userLoading) ?
          <Box mb={1}>
            <Skeleton animation="wave" variant="rounded" width={"100%"} height={200} />
          </Box> :
          <React.Fragment>
            <Box p={3} bgcolor={"#F8F8F8"} mb={2}>
              <Stack spacing={4} width={820} maxWidth={"100%"}>
                {teamMembersData?.map((memberData, index, array) => (
                  <React.Fragment key={index}>
                    {memberData?.is_active ||
                      (!memberData?.User && !memberData?.project_role_id && !memberData?.joining_date) ? (
                      <Grid container>
                        <>
                          <Grid
                            item
                            display="flex"
                            flexWrap={{ xs: "wrap", md: "nowrap" }}
                            width={780}
                          >
                            <Grid
                              item
                              pr={3}
                              mb={{ xs: 3, md: 0 }}
                              flex={{ xs: "0 0 50%", md: "0 0 37%" }}
                            >
                              <FormControl
                                className={classes.margin}
                                variant="standard"
                                sx={{ margin: "0px !important", width: "100%" }}
                              >
                                <AutocompleteSelect
                                  label="Select Member"
                                  name="User"
                                  id={getInputId("User")}
                                  onBlur={(event) =>
                                    validateMemberData(event, index, array, memberData?.unique_id)
                                  }
                                  onChange={(event) =>
                                    handleChange(event, index, array, memberData?.unique_id)
                                  }
                                  options={userList?.filter(
                                    (data) =>
                                      !teamMembersData?.find(
                                        (member) =>
                                          member?.User?.id === data?.id && member?.is_active
                                      ) &&
                                      !inActiveMembersData?.find(
                                        (member) => member?.User?.id === data?.id
                                      )
                                  )}
                                  disabledOptions={userList?.filter((data) =>
                                    teamMembersData?.find(
                                      (member) => member?.User?.id === data?.id && member?.is_active
                                    )
                                  )}
                                  isOptionEqualToValue={(option, value) => userList?.find(user => user.id === value?.id) ?? {}}
                                  value={userList?.find(user => user.id === memberData?.User?.id) || {}}
                                  disableClearable={(!memberData?.User && !memberData?.project_role_id && !memberData?.joining_date)}
                                  disabled={!isProjectEditAccess(currentUser)}
                                />
                                {!!errors?.[index]?.User ? (
                                  <FormHelperText sx={{ color: "red.main" }}>
                                    {errors?.[index]?.User}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                            {!memberData?.User &&
                              !memberData?.project_role_id &&
                              !memberData?.joining_date &&
                              index !== 0 &&
                              teamMembersData?.length - 1 === index ? null : (
                              <>
                                <Grid
                                  item
                                  pr={3}
                                  mb={{ xs: 3, md: 0 }}
                                  flex={{ xs: "0 0 50%", md: "0 0 32%" }}
                                  sx={{
                                    ".MuiTypography-body1": {
                                      fontSize: "12px",
                                      lineHeight: "19px",
                                      marginTop: "3px",
                                      color: "dark.800",
                                    },
                                  }}
                                >
                                  <FormControl
                                    variant="standard"
                                    sx={{ m: 0, width: "100%" }}
                                  >
                                    <InputLabel
                                      htmlFor={getInputId("role", index)}
                                      shrink={Boolean(memberData?.project_role_id)}
                                    >
                                      Role
                                    </InputLabel>
                                    <Select
                                      name="project_role_id"
                                      label="Role"
                                      sx={{ width: "100%" }}
                                      value={memberData?.project_role_id ?? ""}
                                      onChange={(event) =>
                                        handleChange(event, index, array, memberData?.unique_id)
                                      }
                                      onBlur={(event) =>
                                        validateMemberData(event, index, array, memberData?.unique_id)
                                      }
                                      inputProps={{
                                        id: getInputId("role", index),
                                      }}
                                      disabled={!isProjectEditAccess(currentUser)}
                                    >
                                      {userRolesData?.map(({ id, title }, index) => (
                                        <MenuItem key={index} value={id} disabled={false}>
                                          {titleCase(title)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {!!errors?.[index]?.project_role_id ? (
                                      <FormHelperText sx={{ color: "red.main" }}>
                                        {errors?.[index]?.project_role_id}
                                      </FormHelperText>
                                    ) : null}
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  pr={3}
                                  mb={{ xs: 3, md: 0 }}
                                  flex={{ xs: "0 0 50%", md: "0 0 31%" }}
                                  sx={{
                                    input: {
                                      width: "100%",
                                      height: 30,
                                      border: "none !important",
                                      boxShadow: "none !important",
                                      borderRadius: "0px",
                                      paddingBottom: "6px",
                                      paddingLeft: "28px",
                                      borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
                                      margin: "0",
                                      position: "relative",
                                      background: "transparent",
                                      color: "dark.800",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      lineHeight: "24px",
                                      letterSpacing: "0.15px",
                                    },
                                    "input:hover": {
                                      borderBottomColor: "#333333 !important",
                                    },
                                    "input:focus": {
                                      borderBottomColor: "#047FE0 !important",
                                    },
                                    ".rmdp-container": {
                                      minWidth: "100px",
                                    },
                                  }}
                                >
                                  <Typography
                                    id={getInputId("joining_date")}
                                    fontSize={12}
                                    fontWeight={400}
                                    color="dark.600"
                                    sx={{
                                      width: "100%",
                                      letterSpacing: "0.15px",
                                      marginTop: "-3px",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    Joining Date
                                  </Typography>
                                  <div style={{ height: 1 }}>
                                    <CalendarTodayIcon
                                      sx={{
                                        fontSize: 20,
                                        color: "#3F5C76",
                                        marginBottom: "-5px",
                                        marginLeft: "2px",
                                      }}
                                    />
                                  </div>
                                  <SingleDatePicker
                                    id={getInputId("joining_date")}
                                    value={new Date(memberData?.joining_date) || ""}
                                    name="joining_date"
                                    onChange={(event) =>
                                      handleChangeDate(event, index)
                                    }
                                    isMultiple={false}
                                    onOpen={() => { }}
                                    disabled={!isProjectEditAccess(currentUser)}
                                    isDisableRequest={true}
                                    minDate={projectDetail?.start_date ? new Date(projectDetail?.start_date) : new Date(currentUser?.organization?.createdAt)}
                                  />
                                  {!!errors?.[index]?.joining_date ? (
                                    <FormHelperText sx={{ color: "red.main" }}>
                                      {errors?.[index]?.joining_date}
                                    </FormHelperText>
                                  ) : null}
                                </Grid>
                              </>
                            )}
                          </Grid>
                          {!isProjectEditAccess(currentUser) || (!memberData?.User &&
                            !memberData?.project_role_id &&
                            !memberData?.joining_date &&
                            teamMembersData?.length - 1 === index) ? null : (
                            <Grid item mt={1} minWidth={40}>
                              <Box sx={{ minWidth: "40px" }}>
                                <IconButton
                                  size="small"
                                  sx={{ width: "40px", height: "40px" }}
                                  onClick={() =>
                                    handleDeleteMemberList(
                                      memberData,
                                      index,
                                      memberData?.unique_id
                                    )
                                  }
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </Grid>
                          )}
                        </>
                      </Grid>
                    ) : null}
                  </React.Fragment>
                ))}
              </Stack>
            </Box>
            {isProjectEditAccess(currentUser) ? <Box mb={6}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  boxShadow: "0",
                  color: "white",
                  fontSize: "14px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  textTransform: "none",
                  "&:hover": { boxShadow: "0" },
                }}
                type="submit"
                // isButtonLoading={loading?.formSubmitting}
                disabled={isLoading?.projectUserLoading || isLoading.projectRoleLoading || isLoading.userLoading}
              >
                Save
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ textTransform: "none" }}
                size="medium"
                fontWeight="500"
                onClick={() => navigate(-1)}
                disabled={isLoading?.projectUserLoading || isLoading.projectRoleLoading || isLoading.userLoading}
              >
                Cancel
              </Button>
            </Box> : null}
          </React.Fragment>
        }
      </form>
      <Typography
        fontSize={20}
        fontWeight={400}
        lineHeight={"30px"}
        mb={2}
        letterSpacing={"0.15px"}
      >
        Inactive Users
      </Typography>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          boxShadow: "none",
          border: "1px solid #E0E0E0",
          borderRadius: "3px",
        }}
      >
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    color: "dark.800",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.17px",
                    padding: "6px 16px",
                    background: "#F5F8FA",
                  },
                }}
              >
                <TableCell>User</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Joining Date</TableCell>
                <TableCell>Remove Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(isLoading.projectUserLoading || isLoading?.projectRoleLoading || isLoading?.userLoading)
                ? <TableRowSkeleton cols={7} rows={5} /> :
                inActiveMembersData?.length ? (
                  inActiveMembersData.map((userData, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        td: {
                          color: "rgba(0, 0, 0, 0.87)",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          letterSpacing: "0.17px",
                          padding: "6px 16px",
                        },
                      }}
                    >
                      <TableCell>{titleCase(userData?.User?.name)}</TableCell>
                      <TableCell>
                        {titleCase(
                          userRolesData?.find(
                            (roleData) =>
                              roleData?.id === userData?.project_role_id
                          )?.title
                        )}
                      </TableCell>
                      <TableCell>
                        {getFormattedDate(userData?.joining_date)}
                      </TableCell>
                      <TableCell>
                        {userData?.removed_date
                          ? getFormattedDate(userData?.removed_date)
                          : "-"}
                      </TableCell>
                      <TableCell>
                        <Switch
                          name="is_received_leave_mail"
                          id="is_received_leave_mail"
                          size="small"
                          checked={userData?.is_active}
                          onChange={(e) =>
                            handleChangeActiveUser(userData, e, true)
                          }
                          disabled={!isProjectEditAccess(currentUser)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      sx={{ padding: "12px 16px;" }}
                    >
                      <Typography
                        color={"secondary"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontSize={13}
                        sx={{ opacity: "0.5", height: "200px" }}
                      >
                        No inactive users found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default TeamMembers;