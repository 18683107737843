/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Modal, Select, Stack, Typography } from '@mui/material'
import API from "../../../../axios/api";
import axiosInstance from "../../../../axios";
import CircularLoader from '../../../../components/CircularLoader';
import { getInputId } from '../../../../utils';
import SearchBoxInput from '../../../../components/SearchBoxInput';
import { usePageTitle } from '../../../../hook/useTitle';
import TablePagination from '../../../../components/TablePagination';
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from '../../../../components/Input';
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useDropzone } from 'react-dropzone';
import { useAlert } from '../../../../hook/useAlert';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import Button from '../../../../components/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ICONS from '../../../../constants/icons';
import AutocompleteSuggetionWithAddOption from '../../../../components/AutoCompleteSuggestion';
import { CRM_SOURCE } from '../../../../constants/default-values';
import AutoCompleteTagSelection from '../../../../components/AutoCompleteTagSelection';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
};

const CampaignsContactList = () => {
    const showAlert = useAlert();
    const { setPageTitle } = usePageTitle();
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const open = Boolean(anchorEl);
    const [contactsData, setContactsData] = useState([]);
    const [userDataCount, setUserDataCount] = useState(0);
    const [editableUserModalData, setEditableUserModalData] = useState(null);
    const [optionalEditableData, setoptionalEditableData] = useState({});
    const [showModalForImportContacts, setShowModalForImportContacts] = useState(false);
    const [showIsAddEditUserModal, setShowIsAddEditUserModal] = useState(false);
    const [isDeleteIsOpen, setIsDeleteIsOpen] = useState(false);
    const [deletedContactData, setDeletedContactData] = useState(null);
    const [suggestionTitles, setSuggestionTitles] = useState([]);
    const [tagOptions, setTagOptions] = useState([])
    const [selectedTags, setSelectedTags] = useState([])

    setPageTitle("CRM - Contacts");

    const [params, setParams] = useState({
        page: 0,
        limit: 100,
        search: null
    });

    useEffect(() => {
        getAllContactsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
        const fetchTagData = async () => {
            try {
                const body = {
                    "title": "",
                    "isForTag": true
                };
                const response = await axiosInstance.post(API.findJobProfileOrTag, body);
                if (response?.status === 200) {
                    setTagOptions(response?.data?.data ?? []);
                } else {
                    setTagOptions([]);
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        const fetchProfileData = async () => {
            try {
                const body = { "title": "", "isForTag": false };
                const response = await axiosInstance.post(API.findJobProfileOrTag, body);
                if (response.status === 200) {
                    setSuggestionTitles(response?.data?.data || []);
                } else {
                    setSuggestionTitles([]);
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchProfileData();
        fetchTagData();
    }, []);

    const getAllContactsList = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.post(
                API.getAllContactsListPaginate,
                params
            );

            if (response?.status === 200) {
                setContactsData(response?.data?.data?.rows);
                setUserDataCount(response?.data?.data?.count);
            } else {
                setContactsData([]);
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const formikForAddEditContacts = useFormik({
        initialValues: editableUserModalData ? editableUserModalData :
            {
                name: "",
                email: "",
                contact_no: "",
                job_profile: {},
                source: "",
                tags: []
            },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            email: Yup.string().email("Invalid email address").required("Required"),
            contact_no: Yup.string().nullable(),
        }),
        onSubmit: async (values) => {
            try {
                let body = {
                    ...values,
                    email: values?.email?.trim() ?? "",
                    "tags": selectedTags?.map(tagData => {
                        return {
                            "id": tagData?.id,
                            "title": tagData?.title
                        }
                    })
                }
                if (values?.source?.length) {
                    body['source'] = values?.source;
                    body["sourceTitle"] = CRM_SOURCE[values?.source];
                } else {
                    body['source'] = null;
                }
                if (values?.job_profile?.id) {
                    body['job_profile'] = values?.job_profile?.id;
                    body["jobProfileObj"] = {
                        "id": values?.job_profile?.id,
                        "title": values?.job_profile?.title
                    }
                } else {
                    body['job_profile'] = null
                }
                setLoading((prev) => ({ ...prev, formSubmitting: true }));
                if (!editableUserModalData) {
                    const response = await axiosInstance.post(API.createContact, body);
                    if (response?.status === 200) {
                        if (!response?.data?.error) {
                            showAlert("Recipient Created Successfully!")
                            setContactsData(prev => [{ ...response?.data?.data, CrmTags: selectedTags, CrmJobProfile: values?.job_profile }, ...prev]);
                            setUserDataCount(prev => prev + 1)
                            setShowIsAddEditUserModal(false);
                            setSelectedTags([]);
                            formikForAddEditContacts.resetForm();
                            getAllContactsList();
                        } else {
                            showAlert(response?.data?.error, "error");
                        }
                    }
                } else {
                    if (values?.job_profile?.id || optionalEditableData?.CrmJobProfile?.id || optionalEditableData?.jobProfileObj?.id) {
                        body['job_profile'] = values?.job_profile?.id ?? null;
                        body["jobProfileObj"] = {
                            ...{ ...optionalEditableData?.CrmJobProfile ?? optionalEditableData?.jobProfileObj },
                            id: optionalEditableData?.CrmJobProfile?.id ?? optionalEditableData?.jobProfileObj?.id,
                            title: optionalEditableData?.CrmJobProfile?.title ?? optionalEditableData?.jobProfileObj?.title
                        }
                        if (values?.job_profile?.id !== optionalEditableData?.CrmJobProfile?.id) {
                            body['is_job_profile_changed'] = true;
                            if (!values?.job_profile?.id) {
                                body["jobProfileObj"] = {
                                    ...{ ...optionalEditableData?.CrmJobProfile ?? optionalEditableData?.jobProfileObj },
                                    id: optionalEditableData?.CrmJobProfile?.id ?? optionalEditableData?.jobProfileObj?.id,
                                    title: optionalEditableData?.title ?? optionalEditableData?.jobProfileObj?.title,
                                    is_deleted: true
                                }
                            } else {
                                body["jobProfileObj"] = {
                                    id: values?.job_profile?.id,
                                    title: values?.job_profile?.title,

                                }
                            }
                        }
                    }

                    if (selectedTags?.length || optionalEditableData?.CrmTags?.length) {
                        let changedItems = [];

                        selectedTags?.forEach(item => {
                            const matchingItem = [
                                ...(optionalEditableData?.CrmTags ?? [])
                            ].find(optItem => optItem.id === item.id);
                            if (!matchingItem) {
                                item.is_newly_added = true;
                                changedItems.push(item);
                            } else if (item.is_deleted) {
                                changedItems.push(item);
                            }
                        });

                        [...(optionalEditableData?.CrmTags ?? [])]?.forEach(item => {
                            const matchingItem = selectedTags?.find(selItem => selItem.id === item.id);

                            if (!matchingItem) {
                                item.is_deleted = true;
                                changedItems.push(item);
                            }
                        });

                        body['tags'] = changedItems;
                    }

                    if (values?.source?.length) {
                        body['source'] = values?.source;
                        body["sourceTitle"] = CRM_SOURCE[values?.source];
                        if (values?.source !== optionalEditableData?.source) {
                            body['is_source_changed'] = true
                        }
                    }

                    const response = await axiosInstance.put(API.updateContact(editableUserModalData?.id), body);
                    if (response?.status === 200) {
                        showAlert(response?.data.message);
                        setContactsData(prev => prev.map((contact) => contact?.id === editableUserModalData?.id ? body : contact));
                        setEditableUserModalData(null);
                        setoptionalEditableData({})
                        setShowIsAddEditUserModal(false);
                        setSelectedTags([]);
                        formikForAddEditContacts.resetForm();
                        getAllContactsList();
                    }
                }
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
            } catch (error) {
                console.error("Error:", error);
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
            }
        },
    });

    const formikForImportContacts = useFormik({
        initialValues: {
            file: null
        },
        validationSchema: Yup.object().shape({
            file: Yup.mixed().required('File is required'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading((prev) => ({ ...prev, formSubmitting: true }));
                const config = {
                    headers: { "content-type": "multipart/form-data-1" },
                };
                const formData = new FormData();
                formData.append("file", values.file);
                const response = await axiosInstance.post(API.importContactsData, formData, config);
                if (response.status === 200) {
                    getAllContactsList();
                    formikForImportContacts.resetForm();
                    setShowModalForImportContacts(false);
                    showAlert(response?.data.message);
                    setLoading((prev) => ({ ...prev, formSubmitting: false }));
                }
            } catch (error) {
                console.error("Error:", error);
                showAlert("Failed to upload file. Please try again.", "error");
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
            }
        },
    });

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept: {
            "text/csv": [".csv"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        },
        maxFiles: 1,
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length === 0) return;

            const file = acceptedFiles[0];
            if (file) {
                formikForImportContacts.setFieldValue('file', file);
            }
        },
    });

    const handleChangePage = (event, newPage) => {
        setParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setParams((prev) => ({
            ...prev,
            limit: event.target.value,
            page: 0,
        }));
    };

    const handleSearch = (value) => {
        setParams((prev) => ({
            ...prev,
            search: value,
        }));
    };

    const handleClearFilter = () => {
        setParams((prev) => ({
            ...prev,
            search: "",
        }));
    }

    const handleEditContact = (user) => {
        user.email = user?.email?.trim() ?? "";
        handleClose();
        setEditableUserModalData({ ...user, job_profile: suggestionTitles?.find(jobData => jobData?.id === user?.job_profile) });
        setoptionalEditableData({ ...user, job_profile: suggestionTitles?.find(jobData => jobData?.id === user?.job_profile) });
        setSelectedTags(user?.tags ?? user?.CrmTags ?? [])
        setShowIsAddEditUserModal(true);
    };

    const handleSubmitDelete = async () => {
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            const response = await axiosInstance.delete(API.deleteContact(deletedContactData?.id));
            if (response?.status === 200) {
                setContactsData(prev => prev.filter((contact) => contact?.id !== deletedContactData?.id));
                setDeletedContactData(null);
                setIsDeleteIsOpen(false);
                setUserDataCount(prev => prev - 1)
                showAlert(response?.data?.message)
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error("Error:", error);
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    };

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null);
    };

    const handleChangeSuggetion = (e, name, value) => {
        formikForAddEditContacts.setFieldValue(name, value);
    }

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="auto">
                {(loading?.userRoleLoading || loading?.years) ?
                    <CircularLoader height="500px" /> :
                    (userDataCount || params?.search?.length || loading?.pageLoading || contactsData?.length) ? <Box>
                        <Grid container pb={2} display="flex" justifyContent="space-between">
                            <Grid item display="flex">
                                <Grid item width={{ xs: 180, xl: 230 }}>
                                    <SearchBoxInput
                                        label="Search"
                                        variant="standard"
                                        placeholder="Name and Email"
                                        handleSearch={handleSearch}
                                        value={params?.search}
                                        isOverwritePlaceholder={true}
                                    />
                                </Grid>
                                <Grid item ml={1}>
                                    {params?.search &&
                                        <Button variant='text' color='primary'
                                            size="small"
                                            sx={{ marginTop: '16px !important', textTransform: 'none' }}
                                            onClick={handleClearFilter}
                                        >
                                            Clear
                                        </Button>}
                                </Grid>
                            </Grid>
                            <Grid item display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
                                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="medium"
                                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                        onClick={() => { setShowIsAddEditUserModal(true); }}
                                    >
                                        Create Contact
                                    </Button>
                                </Stack>
                                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="medium"
                                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                        onClick={() => setShowModalForImportContacts(true)}
                                    >
                                        Import Contacts
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Paper sx={{ width: '100%', borderRadius: '0px', boxShadow: 'none', border: "1px solid #E0E0E0" }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                >
                                    <TableHead>
                                        <TableRow sx={{
                                            whiteSpace: 'nowrap',
                                            'th': {
                                                color: "dark.800",
                                                fontWeight: 500,
                                                fontSize: 14,
                                                lineHeight: "24px",
                                                letterSpacing: "0.17px",
                                                bgcolor: "bgColor",
                                                padding: "5px 16px",
                                                textAlign: "left",
                                                background: "rgba(245, 248, 250, 1)",
                                            }
                                        }}>
                                            <TableCell sx={{ width: '70px', maxWidth: '70px', minWidth: '70px', paddingRight: '0px !important' }}>#</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Contact No.</TableCell>
                                            <TableCell sx={{ textAlign: 'center !important', width: '48px', maxWidth: '48px', minWidth: '48px' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            'td': {
                                                color: "dark.800",
                                                fontWeight: 400,
                                                fontSize: 14,
                                                lineHeight: "20px",
                                                letterSpacing: "0.17px",
                                                padding: "7px 16px",
                                                textAlign: "left",
                                            }
                                        }}
                                    >
                                        {loading.pageLoading ?
                                            <CircularLoader variant="table" rows={10} cols={5} /> :
                                            contactsData?.length ? contactsData?.map((user, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {user?.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {user?.email}
                                                            </TableCell>
                                                            <TableCell>{user?.contact_no ? user?.contact_no : "-"}</TableCell>
                                                            <TableCell sx={{ textAlign: 'center', width: '100px', maxWidth: '100px', minWidth: '100px', paddingLeft: '0px !important', paddingRight: '0px !important' }}>
                                                                <div
                                                                    style={{
                                                                        width: "98px",
                                                                        textAlign: "center",
                                                                        display: "inline-block",
                                                                    }}
                                                                >
                                                                    <IconButton
                                                                        sx={{
                                                                            height: "30px",
                                                                            width: "30px",
                                                                            padding: "0px",
                                                                            borderRadius: "4px",
                                                                            color: "secondary.main",
                                                                        }}
                                                                        id={`basic-button-${index}`}
                                                                        aria-controls={
                                                                            open ? "basic-menu" : undefined
                                                                        }
                                                                        aria-haspopup="true"
                                                                        aria-expanded={open ? "true" : undefined}
                                                                        onClick={(e) => handleClick(e, user?.id)}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                height: "20px",
                                                                                width: "20px",
                                                                                display: "flex",
                                                                            }}
                                                                        >
                                                                            {ICONS.DotsHorizontal}
                                                                        </i>
                                                                    </IconButton>
                                                                    <Menu
                                                                        id={`basic-menu-${index}`}
                                                                        anchorEl={anchorEl}
                                                                        open={open && openMenuIndex === user?.id}
                                                                        onClose={() => handleClose()}
                                                                        MenuListProps={{
                                                                            "aria-labelledby": "basic-button",
                                                                        }}
                                                                        sx={{
                                                                            ".MuiPaper-root": {
                                                                                boxShadow:
                                                                                    "0px 2px 4px -1px rgba(0,0,0,0.2)",
                                                                                width: "180px",
                                                                            },
                                                                            ".MuiMenuItem-root": {
                                                                                color: "dark.800",
                                                                                fontSize: "16px",
                                                                                fontWeight: "400",
                                                                                lineHeight: "24px",
                                                                                letterSpacing: "0.15px",
                                                                            },
                                                                            ".MuiMenuItem-root:hover": {
                                                                                background: "rgba(33, 150, 243, 0.04)",
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem
                                                                            onClick={() => handleEditContact(user)}
                                                                        >
                                                                            Edit
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            onClick={() => { handleClose(); setIsDeleteIsOpen(true); setDeletedContactData(user); }}
                                                                        >
                                                                            <Typography fontSize={16} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.15px"} sx={{ color: '#D32F2F' }}>Delete</Typography>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>)
                                            }) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        align="center"
                                                        sx={{ padding: "12px 16px;" }}
                                                    >
                                                        <Typography
                                                            color={"secondary"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            fontSize={13}
                                                            sx={{ opacity: "0.5", height: "236px" }}
                                                        >
                                                            No records found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                count={userDataCount}
                                rowsPerPageOptions={[100, 200, 500]}
                                rowsPerPage={params.limit}
                                page={params.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box> : <Box bgcolor={"#FAFBFF"} height={151} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                        <Typography color={"#262626"} mb={3} fontSize={16} fontWeight={400}>No contacts created yet</Typography>
                        <Grid item display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
                            <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size="medium"
                                    sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                    onClick={() => { setShowIsAddEditUserModal(true); }}
                                >
                                    Add Contact
                                </Button>
                            </Stack>
                            <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size="medium"
                                    sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                    onClick={() => setShowModalForImportContacts(true)}
                                >

                                    Import Contacts
                                </Button>
                            </Stack>
                        </Grid>
                    </Box>
                }
            </Box>

            {/* Modal for Import contacts */}
            <Modal
                open={showModalForImportContacts}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '> div': {
                        overflow: 'hidden',
                        '> div': {
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    }
                }}
            >
                <Box sx={[style, {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto !important",
                }]}>
                    <form onSubmit={formikForImportContacts.handleSubmit} noValidate style={{ width: 600 }}>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} py={2} px={3} borderBottom="1px solid #E2E4EC">
                            <Typography fontSize={20} lineHeight={'32px'} letterSpacing={'0.15px'} fontWeight={500}>Import Contacts</Typography>
                            <IconButton
                                aria-label="Close"
                                size="small"
                                color="secondary"
                                onClick={() => { setShowModalForImportContacts(false); }}
                                height={24}
                                width={24}
                                sx={{ 'svg': { fontSize: 22, } }}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{ p: 3, width: "100%" }}
                            boxShadow={1}
                        >
                            <Stack spacing={1} alignItems="flex-start">
                                <Box display={"flex"} gap={1} alignItems={"baseline"}>
                                    <Typography color={"dark.800"} fontSize={15} fontWeight={400} lineHeight={"22px"} letterSpacing={"0.15px"}>Upload file to import contact</Typography>
                                    <Typography color={"dark.600"} fontSize={11} fontWeight={400} lineHeight={"25px"} letterSpacing={"0.17px"}>Upto 25MB</Typography>
                                </Box>
                                <Box
                                    {...getRootProps({ className: "dropzone" })}
                                    display="flex"
                                    alignItems="center"
                                    width={"100%"}
                                    borderRadius={"5px"}
                                    p={1}
                                    sx={{ border: "1px dashed rgba(81, 95, 124, 0.25)", cursor: "pointer", }}
                                >
                                    <input {...getInputProps()} />
                                    <i
                                        style={{
                                            height: "40px",
                                            maxWidth: "40px",
                                            flex: "0 0 40px",
                                            padding: "10px",
                                        }}
                                    >
                                        <UploadFileOutlinedIcon color="primary" sx={{ fontSize: "20px" }} />
                                    </i>
                                    {formikForImportContacts.values.file ?
                                        <Box ml={1}>
                                            <Typography
                                                color="primary"
                                                fontSize={14}
                                                fontWeight={400}
                                                mr={0.5}
                                                sx={{
                                                    display: "inline-block",
                                                    lineHeight: "24px",
                                                    letterSpacing: "0.15px",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                {formikForImportContacts.values.file?.name}
                                            </Typography>
                                        </Box> :
                                        <Box ml={1}>
                                            <Typography
                                                color="primary"
                                                fontSize={14}
                                                fontWeight={400}
                                                lineHeight={"24px"}
                                                letterSpacing={"0.15px"}
                                                mr={0.5}
                                                sx={{
                                                    display: "inline-block",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                Click to upload
                                            </Typography>
                                            <Typography
                                                color="dark.800"
                                                fontSize={14}
                                                fontWeight={400}
                                                lineHeight={"24px"}
                                                letterSpacing={"0.15px"}
                                                sx={{
                                                    display: "inline-block",
                                                }}
                                            >
                                                or drag and drop
                                            </Typography>
                                            <Typography
                                                color="dark.600"
                                                fontSize={11}
                                                fontWeight={400}
                                                lineHeight={"15px"}
                                                letterSpacing={"0.17px"}
                                                mt={0.5}
                                                sx={{
                                                    display: "block",
                                                }}
                                            >
                                                XLSX or CSV
                                            </Typography>
                                        </Box>}
                                </Box>
                                {formikForImportContacts.errors.file ? <Typography sx={{ color: "red.main", fontSize: "12px" }}>{formikForImportContacts.errors.file}</Typography> : null}
                            </Stack>
                        </Box>
                        <Grid item py={2} px={3} borderTop="1px solid #E2E4EC">
                            <Stack spacing={1} direction="row">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    fontWeight="500"
                                    sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                    type='submit'
                                    isButtonLoading={loading.formSubmitting}

                                >
                                    Upload
                                </Button>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    sx={{ textTransform: "none" }}
                                    size="medium"
                                    fontWeight="500"
                                    onClick={() => { setShowModalForImportContacts(false); }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </form>
                </Box>
            </Modal>

            {/* Modal for Add/edit contacts */}
            <Modal
                open={showIsAddEditUserModal}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '> div': {
                        overflow: 'hidden',
                        '> div': {
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    }
                }}
            >
                <Box sx={[style, {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto !important",
                }]}>
                    <form onSubmit={formikForAddEditContacts.handleSubmit} noValidate style={{ width: 600 }}>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} py={2} px={3} borderBottom="1px solid #E2E4EC">
                            <Typography variant='h4' fontSize={20} lineHeight={'32px'} letterSpacing={'0.15px'} fontWeight={500}>{editableUserModalData ? "Edit Contact" : "Add Contact"}</Typography>
                            <IconButton
                                aria-label="Close"
                                size="small"
                                color="secondary"
                                onClick={() => {
                                    setShowIsAddEditUserModal(false);
                                    setEditableUserModalData(null);
                                    setoptionalEditableData({});
                                    setSelectedTags([])
                                    formikForAddEditContacts.resetForm();
                                }}
                                height={24}
                                width={24}
                                sx={{ 'svg': { fontSize: 22, } }}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{ p: 3, pb: 4, width: "100%" }}
                            boxShadow={1}
                        >
                            <Stack spacing={4} alignItems="flex-start">
                                <Stack spacing={3} alignItems="flex-start" direction={"row"} width={"100%"}>
                                    <Input
                                        id={getInputId("name")}
                                        name="name"
                                        label="Name"
                                        variant="standard"
                                        type="text"
                                        sx={{ width: "100%" }}
                                        placeholder={"name"}
                                        isCapitalizeFirstLatter={true}
                                        value={formikForAddEditContacts.values.name}
                                        error={
                                            formikForAddEditContacts.touched.name &&
                                            Boolean(formikForAddEditContacts.errors.name)
                                        }
                                        helperText={
                                            formikForAddEditContacts.touched.name && formikForAddEditContacts.errors.name
                                        }
                                        {...formikForAddEditContacts.getFieldProps("name")}
                                    />
                                    <Input
                                        id={getInputId("email")}
                                        name="email"
                                        label="Email address"
                                        variant="standard"
                                        type="email"
                                        sx={{ width: "100%" }}
                                        value={formikForAddEditContacts.values.email}
                                        placeholder={"email address"}
                                        disabled={editableUserModalData}
                                        error={
                                            formikForAddEditContacts.touched.email && Boolean(formikForAddEditContacts.errors.email)
                                        }
                                        helperText={formikForAddEditContacts.touched.email && formikForAddEditContacts.errors.email}
                                        {...formikForAddEditContacts.getFieldProps("email")}
                                    />
                                </Stack>
                                <Stack spacing={3} alignItems="flex-start" direction={"row"} width={"100%"}>
                                    <Input name="contact_no"
                                        label="Contact No."
                                        variant="standard"
                                        inputmode="numeric"
                                        required={false}
                                        type="text"
                                        sx={{ width: "100%" }}
                                        placeholder={"Contact No."}
                                        value={formikForAddEditContacts.values.contact_no}
                                        {...formikForAddEditContacts.getFieldProps("contact_no")}
                                    />
                                    <Box width={"100%"} sx={{ '> div': { width: '100%' } }}>
                                        <AutocompleteSuggetionWithAddOption
                                            label={"Job Profile"}
                                            variant="standard"
                                            name="job_profile"
                                            id="job_profile"
                                            sx={{
                                                width: "100%",
                                                minWidth: "250px",
                                            }}
                                            disabledItems={[]}
                                            value={formikForAddEditContacts.values.job_profile || { title: '' }} // Object value
                                            onChange={(event, newValue) => {
                                                handleChangeSuggetion(event, 'job_profile', newValue); // Pass the object to the handler
                                            }}
                                            setSuggestionTitles={setSuggestionTitles}
                                            suggestionTitles={suggestionTitles}
                                        />
                                    </Box>
                                </Stack>
                                <Stack spacing={3} alignItems="flex-start" direction={"row"} width={"100%"}>
                                    <Box flex={1}>
                                        <FormControl size="medium" variant="standard" fullWidth>
                                            <InputLabel id="source-label">Source</InputLabel>
                                            <Select
                                                labelId="source-label"
                                                id="source-demo"
                                                name="source"
                                                label="Source"
                                                fullWidth
                                                value={formikForAddEditContacts.values.source || ""}
                                                {...formikForAddEditContacts.getFieldProps("source")}
                                            >
                                                {
                                                    Object.entries(CRM_SOURCE)?.map((sourceData, index) => (
                                                        <MenuItem value={sourceData[0]} key={index}>{sourceData[1]}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box flex={1} overflow={"hidden"}
                                        sx={{
                                            '.MuiChip-root': {
                                                margin: '3px 5px 3px 0px !important',
                                                // height: 24,
                                            },
                                            '.MuiChip-deleteIcon': {
                                                fontSize: '16px !important',
                                                marginRight: '3px !important'
                                            },
                                            '.MuiChip-label': {
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                fontSize: 13,
                                                lineHeight: '18px',
                                                fontWeight: 400,
                                                paddingLeft: '10px',
                                                paddingRight: '8px',
                                            },
                                            '.MuiInputBase-root': {
                                                flexWrap: 'wrap !important',
                                            }
                                        }}
                                    >
                                        <AutoCompleteTagSelection tagOptions={tagOptions} setTagOptions={setTagOptions} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                        <Grid item py={2} px={3} borderTop="1px solid #E2E4EC">
                            <Stack spacing={1} direction="row">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    fontWeight="500"
                                    sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                    type='submit'
                                    isButtonLoading={loading.formSubmitting}
                                >
                                    {editableUserModalData ? "Update Contact" : "Create"}
                                </Button>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    sx={{ textTransform: "none" }}
                                    size="medium"
                                    fontWeight="500"
                                    onClick={() => {
                                        setShowIsAddEditUserModal(false);
                                        setEditableUserModalData(null);
                                        setoptionalEditableData({});
                                        setSelectedTags([]);
                                        formikForAddEditContacts.resetForm();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </form>
                </Box>
            </Modal>
            <ConfirmationModal
                isOpen={isDeleteIsOpen}
                title={'Contact'}
                dataContentName={'Contact'}
                handleClose={() => setIsDeleteIsOpen(false)}
                onConfirm={handleSubmitDelete}
                onCancel={() => setIsDeleteIsOpen(false)}
                isButtonLoading={loading.formSubmitting}
            >
                <Stack spacing={3} p={3}>
                    <Typography color={"dark.800"} fontWeight={400} fontSize={16} lineHeight={"26px"} letterSpacing={"0.15px"}>Are you sure, you want to <b><span style={{ color: "#D32F2F" }}>delete</span> {deletedContactData?.name}</b> from Contacts?</Typography>
                    <Box bgcolor={"rgba(0, 0, 0, 0.04)"} p={"10px"} display={"flex"} flexDirection={"column"} gap={0.5} borderRadius={"10px"}>
                        <Typography variant="h3" color={"color.800"} fontSize={18} fontWeight={600} lineHeight={"25px"}>{deletedContactData?.name}</Typography>
                        <Typography variant="body1" color={"color.800"} fontSize={13} fontWeight={400} lineHeight={"19px"} letterSpacing={"0.17px"}>{deletedContactData?.email}</Typography>
                        <Typography variant="body1" color={"color.800"} fontSize={13} fontWeight={400} lineHeight={"19px"} letterSpacing={"0.17px"}>{deletedContactData?.contact_no}</Typography>
                    </Box>
                </Stack>
            </ConfirmationModal>
        </Box>
    );
};

export default CampaignsContactList;