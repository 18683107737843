import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { generateUniqueId, getInputId, titleCase } from "../../../../utils";
import Button from "../../../../components/Button";
import { Document_List } from "../../../../constants/default-values";
import CustomFileDropZoneDocument from "../../../../components/customFileDropZoneDocument";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import { useAlert } from "../../../../hook/useAlert";
import { useParams } from "react-router-dom";
import CircularLoader from "../../../../components/CircularLoader";

const Documents = ({ isCurrentUser = false }) => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const { userId } = useParams();

    const showAlert = useAlert();
    const initialDocumentdata = {
        type: '',
        document: [],
        doc_data: [],
        uniqueId: ''
    }

    const initialError = {
        type: "",
        document: "",
        uniqueId: '',
    }
    const [isLoading, setIsLoading] = useState({
        pageLoading: false,
        documentSubmit: false,
    });
    const [isMoreDisable, setIsMoreDisable] = useState(false);
    let unique_id = generateUniqueId();
    const [documentDataList, setDocumentDataList] = useState([{ ...initialDocumentdata, uniqueId: unique_id }]);
    const [optionalDocumentList, setOptionalDocumentList] = useState([{ ...initialDocumentdata, uniqueId: unique_id }]);
    const [errors, setErrors] = useState([{ ...initialError, uniqueId: unique_id }]);

    useEffect(() => {
        if (isCurrentUser) {
            if (currentUser?.id) {
                getUsersAllDocumentsData();
            }
        } else {
            if (userId?.length) {
                getUsersAllDocumentsData();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeDocumentName = (e, unique_id) => {
        let docs = [];
        let isDocumentExist = false;
        setDocumentDataList(prev => prev?.map((documentData) => {
            if (documentData?.uniqueId === unique_id) {
                docs.push({
                    type: e.target.value,
                    document: documentData?.document ?? [],
                    is_added: true
                })
                if (documentData?.document?.length) isDocumentExist = true
                return {
                    ...documentData,
                    type: e.target.value
                }
            }
            docs.push({
                type: documentData?.type,
                document: [...documentData?.doc_data?.map(fileData => {
                    if (fileData?.id) {
                        return {
                            "id": fileData?.id,
                            "doc_name": fileData?.doc_name,
                            "doc_url": fileData?.doc_url,
                            "is_updated": true
                        }
                    }
                    return fileData
                })],
            })
            return documentData
        }))
        setErrors(prev => prev?.map((errorData) => {
            if (errorData?.uniqueId === unique_id) {
                return {
                    ...errorData,
                    type: ""
                }
            }
            return errorData
        }))
        if (isDocumentExist) {
            handleSubmitDocuments(docs, unique_id)
        }
    }

    const handleAddNewDocument = () => {
        let unique_Id = generateUniqueId();
        setDocumentDataList(prev => [...prev, { ...initialDocumentdata, uniqueId: unique_Id }])
        setErrors(prev => [...prev, { ...initialError, uniqueId: unique_Id }])

        let isMoreExist = Object.keys(Document_List)?.length < (documentDataList?.length + 2);
        setIsMoreDisable(isMoreExist)
    }

    const handleFileUpload = (files, unique_id) => {
        let docs = [];
        setDocumentDataList(prev => prev?.map((documentData) => {
            if (documentData?.uniqueId === unique_id) {
                docs.push({
                    type: documentData?.type,
                    document: [...files?.map(fileData => {
                        if (fileData?.id) {
                            return {
                                "id": fileData?.id,
                                "doc_name": fileData?.doc_name,
                                "doc_url": fileData?.preview,
                            }
                        } else {
                            return {
                                ...fileData,
                                ...((optionalDocumentList?.find(docDetails => docDetails?.type === documentData?.type)) ? { "is_updated": true } : {})
                            }
                        }
                    })],
                    ...((optionalDocumentList?.find(docDetails => docDetails?.type === documentData?.type)) ? {} : { is_added: true })
                })
                return {
                    ...documentData,
                    document: files
                }
            }
            return documentData
        }))
        setErrors(prev => prev?.map((errorData) => {
            if (errorData?.uniqueId === unique_id) {
                return {
                    ...errorData,
                    document: ""
                }
            }
            return errorData
        }));
        let type = documentDataList?.find(data => data?.uniqueId === unique_id)?.type;
        if (type && type?.length) {
            handleSubmitDocuments(docs, unique_id);
        }
    }

    const handleDeleteImage = async (file) => {
        if (file?.id) {
            let deletableData = documentDataList?.find(docData => docData?.uniqueId === file?.parentUniqueId);

            let updateData = deletableData?.doc_data?.map(imageData => {
                if (file?.id === imageData?.id) {
                    return {
                        "id": imageData?.id,
                        "doc_name": imageData?.doc_name,
                        "doc_url": imageData?.doc_url,
                        is_deleted: true
                    }
                }
                return {
                    "id": imageData?.id,
                    "doc_name": imageData?.doc_name,
                    "doc_url": imageData?.doc_url,
                }
            })
            handleDeleteDocuments([{ type: deletableData?.type, document: updateData }], file?.id, file?.parentUniqueId)
        }
    }

    const getUsersAllDocumentsData = async () => {
        try {
            setIsLoading(prev => ({ ...prev, pageLoading: true }))
            let response = await axiosInstance.get(API.getUserDocuments(isCurrentUser ? currentUser?.id : userId));
            if (response?.status === 200) {
                let uploadedDocumentData = response?.data?.data;
                if (uploadedDocumentData?.length) {
                    const groupedDocuments = uploadedDocumentData?.reduce((previousValue, currentValue) => {
                        const existingGroup = previousValue?.find(group => group?.type === currentValue?.type);

                        if (existingGroup) {
                            existingGroup.attachment_references.push(currentValue?.doc_url);
                            existingGroup.doc_data.push({
                                id: currentValue?.id,
                                doc_name: currentValue?.doc_name,
                                doc_url: currentValue?.doc_url
                            })
                        } else {
                            previousValue.push({
                                user_id: currentValue?.user_id,
                                type: currentValue?.type,
                                attachment_references: [currentValue?.doc_url],
                                doc_data: [{
                                    id: currentValue?.id,
                                    doc_name: currentValue?.doc_name,
                                    doc_url: currentValue?.doc_url
                                }]
                            });
                        }

                        return previousValue;
                    }, []);
                    let errorsData = [];
                    setOptionalDocumentList(groupedDocuments?.slice())
                    setDocumentDataList(groupedDocuments?.map((documentData) => {
                        let unique_id = generateUniqueId();
                        errorsData.push({
                            type: "",
                            document: "",
                            uniqueId: unique_id
                        })
                        return { ...documentData, uniqueId: unique_id }
                    }));
                    setErrors(errorsData);
                    let isMoreExist = Object.keys(Document_List)?.length <= (groupedDocuments?.length);
                    setIsMoreDisable(isMoreExist)
                }
            } else {
                setOptionalDocumentList([]);
                setDocumentDataList([]);
                showAlert("Something went wrong", "error");
            }
            setIsLoading(prev => ({ ...prev, pageLoading: false }))
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, pageLoading: false }));
            setOptionalDocumentList([]);
            setDocumentDataList([]);
        }
    }

    // const isValidate = () => {
    //     let error = [];
    //     if (documentDataList?.length === 1 && deletedDocuments?.length && !documentDataList[0]?.doc_data?.length && !documentDataList[0]?.type?.length && !documentDataList[0]?.document?.length) {
    //         error = []
    //     } else {
    //         error = documentDataList?.map((documentData) => {
    //             return {
    //                 type: documentData?.type?.length ? "" : "Required",
    //                 document: (documentData?.document?.length || (documentData?.doc_data?.length && !documentData?.doc_data?.every(imageData => !!deletedImages?.find(deletedImgData => deletedImgData?.id === imageData?.id)))) ? "" : "Required",
    //                 uniqueId: documentData?.uniqueId
    //             }
    //         })
    //     }
    //     setErrors(error);
    //     return error?.some(
    //         (errorData) =>
    //             errorData?.type?.length ||
    //             errorData?.document?.length
    //     );
    // }

    const handleDeleteDocuments = async (documentDataList, fileId, uniqueId) => {
        try {
            setIsLoading(prev => ({ ...prev, documentSubmit: true }))

            let body = {
                doc: documentDataList
            }
            let response = await axiosInstance.put(API.addDocument(isCurrentUser ? currentUser?.id : userId), body);
            if (response?.status === 200) {
                showAlert(response.data?.message);
                setDocumentDataList(prev => prev?.map(docData => {
                    if (docData?.uniqueId === uniqueId) {
                        return {
                            ...docData,
                            doc_data: docData?.doc_data?.filter(imgaeData => imgaeData?.id !== fileId)
                        }
                    }
                    return docData
                }))
            }
            setIsLoading(prev => ({ ...prev, documentSubmit: false }))
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, documentSubmit: false }))
        }
    }

    const handleSubmitDocuments = async (documentList = [], uniqueId) => {
        try {
            // if (!isValidate()) {
            setIsLoading(prev => ({ ...prev, documentSubmit: true }))
            let body = {
                doc: documentList
            }
            let response = await axiosInstance.put(API.addDocument(isCurrentUser ? currentUser?.id : userId), body);
            if (response?.status === 200) {
                showAlert(response.data?.message);
                setDocumentDataList(prev => prev?.map(docData => {
                    if (docData?.uniqueId === uniqueId) {
                        return {
                            ...docData,
                            doc_data: [...docData?.doc_data, ...response.data?.data?.map(data => {
                                return {
                                    doc_name: data?.doc_name,
                                    doc_url: data?.doc_url,
                                    id: data?.id
                                }
                            })],
                            attachment_references: [...(docData?.attachment_references || []), ...response.data?.data?.map(data => data?.doc_url)],
                            document: []
                        }
                    }
                    return docData
                }))
                setOptionalDocumentList(prev => prev?.map(docData => {
                    if (docData?.uniqueId === uniqueId) {
                        return {
                            ...docData,
                            doc_data: [...docData?.doc_data, ...response.data?.data?.map(data => {
                                return {
                                    doc_name: data?.doc_name,
                                    doc_url: data?.doc_url,
                                    id: data?.id
                                }
                            })],
                        }
                    }
                    return docData
                }))
            }
            setIsLoading(prev => ({ ...prev, documentSubmit: false }))
            // }
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, documentSubmit: false }))
        }
    }

    const getErrorDataByUniqeId = (unique_Id) => {
        return errors?.find(errorData => errorData?.uniqueId === unique_Id) ?? null
    }

    return (
        <>
            {
                isLoading?.pageLoading ? <CircularLoader height="500px" /> :
                    <Box>
                        <Stack spacing={2}>
                            {documentDataList?.length ? documentDataList?.map((documentData, index) => (
                                <Box display={'flex'} key={documentData?.uniqueId}>
                                    <Box width={50} minWidth={50} maxWidth={50} pt={2.4}>
                                        <Typography minWidth={30} width={30} maxWidth={30} color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} textAlign={"right"}>{index + 1}.</Typography>
                                    </Box>
                                    <Box flex={1} bgcolor={"#F8F8F8"} p={3} pr={2} gap={3} display={"flex"} alignItems={"flex-start"}>
                                        <Box width={{ xs: 200, xl: 260 }} minWidth={{ xs: 200, xl: 260 }} maxWidth={{ xs: 200, xl: 260 }}>
                                            <FormControl size="medium" variant="standard" fullWidth>
                                                <InputLabel id={getInputId(`document-${documentData?.uniqueId}`)}>Document</InputLabel>
                                                <Select
                                                    labelId="select-document"
                                                    id={getInputId(`type-${index}`)}
                                                    label="Document"
                                                    name="type"
                                                    value={documentData?.type || ""}
                                                    onChange={(e) => handleChangeDocumentName(e, documentData?.uniqueId)}
                                                    disabled={documentDataList?.find(documentListDetails => documentListDetails?.type === documentData?.type && documentListDetails?.user_id) || isLoading?.documentSubmit}
                                                >
                                                    {Object.entries(Document_List)?.map((documentData, menuIndex) => (
                                                        <MenuItem
                                                            key={menuIndex}
                                                            value={documentData[0]}
                                                            disabled={documentDataList?.some(document => document?.type === documentData[0])}
                                                        >{titleCase(documentData[1])}</MenuItem>
                                                    ))}
                                                </Select>
                                                {!!getErrorDataByUniqeId(documentData?.uniqueId)?.type ? (
                                                    <FormHelperText sx={{ color: "red.main" }}>
                                                        {getErrorDataByUniqeId(documentData?.uniqueId)?.type}
                                                    </FormHelperText>
                                                ) : null}
                                            </FormControl>
                                        </Box>
                                        <Box flex={1} display={"flex"} gap={3} alignItems={"flex-start"}
                                            sx={{
                                                '.attached-box > div': {
                                                    '@media (max-width: 1330px)': {
                                                        width: 240,
                                                        minWidth: 240,
                                                        maxWidth: 240,
                                                    },
                                                    'div': {
                                                        display: "flex",
                                                        flexWrap: 'wrap',
                                                        margin: '0',
                                                        justifyContent: "space-between",
                                                        background: 'white',
                                                        flex: 1,
                                                        '@media (max-width: 1330px)': {
                                                            justifyContent: "flex-start",
                                                        },
                                                        '.drag-text': {
                                                            order: 3,
                                                            fontSize: 11,
                                                            lineHeight: '20px',
                                                            '@media (max-width: 1330px)': {
                                                                order: 0,
                                                            }
                                                        },
                                                        '.upload-text': {
                                                            fontSize: 12,
                                                            letterSpacing: "0.15px",
                                                        },
                                                        '.size-text': {
                                                            fontSize: 10,
                                                            lineHeight: '15px',
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                            margin: 0,
                                                            position: 'relative',
                                                            top: 15,
                                                            '@media (max-width: 1330px)': {
                                                                top: 0,
                                                            }
                                                        },
                                                    },
                                                },
                                            }}
                                        >
                                            <CustomFileDropZoneDocument
                                                onDrop={(files) => handleFileUpload(files, documentData?.uniqueId)}
                                                onDeleteImage={handleDeleteImage}
                                                initialFiles={documentData?.document?.length ? [...documentData?.document] : []}
                                                fileURLs={documentData?.doc_data ?? []}
                                                isConvertToBase64={true}
                                                disabled={isLoading?.documentSubmit}
                                                uniqueId={documentData?.uniqueId}
                                            />
                                            {!!getErrorDataByUniqeId(documentData?.uniqueId)?.document ? (
                                                <FormHelperText sx={{ color: "red.main" }}>
                                                    {getErrorDataByUniqeId(documentData?.uniqueId)?.document}
                                                </FormHelperText>
                                            ) : null}
                                        </Box>
                                    </Box>
                                </Box>
                            )) : null}
                            <Box my={2} pl={"50px"}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="medium"
                                    sx={{ textTransform: "none", boxShadow: 'none' }}
                                    onClick={() => handleAddNewDocument()}
                                    disabled={isMoreDisable || isLoading?.documentSubmit}
                                >
                                    Add Document
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
            }
        </>
    )
}

export default Documents;