const adminRoutes = {
    AdminDashboard: "/admin/dashboard/organisation",
    AdminProfile: "/admin/profile",
    AddOrganisation: "/admin/organisation/add-organisation",
    EditOrganisation: "/admin/organisation/edit-organisation",
    AdminDashboardModule: "/admin/dashboard/module",
    AddModule: "/admin/module/add-module",
    EditModule: "/admin/module/edit-module",
    onBoarding: "/on-borading",
};

export default adminRoutes;
