import React from 'react'
import { Chip } from '@mui/material'
import { titleCase } from '../../utils'
import Avatar from '@mui/material/Avatar';

const ProjectNameChip = ({ label, code = "", size = "small", ...rest }) => {
    return (
        <Chip
            avatar={<Avatar>{code}</Avatar>}
            label={titleCase(label) || "-"}
            size={size}
            {...rest}
            sx={{
                bgcolor: "#0288D1",
                fontSize: '13px',
                fontWeight: '400',
                lineHeight: '18px',
                letterSpacing: '0.16px',
                '.MuiChip-avatar': {
                    minWidth: 24,
                    height: 24,
                    width: 'auto',
                    bgcolor: '#01579B',
                    color: '#ffffff',
                    borderRadius: "50px",
                    padding: "0px 6px",
                },
                '.MuiChip-label': {
                    color: '#ffffff',
                },
                'svg': {
                    color: 'white'
                }
            }}
        />
    )
}

export default ProjectNameChip