import ICONS from "../constants/icons";
import URLS from "../routes/urls";

const DashboardSidebarSettings = [
    {
        name: "Dashboard",
        icon: ICONS.Dashboard,
        href: URLS.Dashboard,
    }
];

export default DashboardSidebarSettings;
