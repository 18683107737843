import { Chip, Typography } from "@mui/material";
import {
  LEAVE_STATUS,
  LEAVE_TYPES,
  ON_BOARDING_STEPS,
  PROJECT_PHASE_STATUS,
  PROJECT_TASK_PRIORITY,
  TASK_STATUS_LIST,
  TASK_TYPE_LIST,
  USER_TYPES,
} from "../constants/default-values";
import ICONS from "../constants/icons";
import moment from "moment";
import { DateObject } from "react-multi-date-picker";
import { encryptData } from "../crypto";
import secureLocalStorage from "react-secure-storage";
import dayjs from "dayjs";

export const titleCase = (str, key = null) => {
  try {
    if (!str)
      return "";
    const text = str
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return key === "And" ? text.replace("And", "&") : text;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const capitalOnlyFirstLatter = (str) => {
  return str?.length > 0 ? str[0].toUpperCase() + str.slice(1) : "";
};

export const generateUniqueId = () => {
  return Math.random().toString(16).slice(2);
};

export const getFullName = (user) => {
  if (user && (!!user?.first_name || !!user?.last_name)) {
    return titleCase(`${user?.first_name} ${user?.last_name}`);
  } else if (user && !!user?.name) {
    return titleCase(`${user?.name}`);
  } else if (user && !!user?.userName) {
    return titleCase(`${user?.userName}`);
  } else {
    return "";
  }
};

export const replaceDashedFormat = (status) => {
  return status.replace(/_/g, "-");
};

export const UrlSplitter = (inputUrl) => {
  const match = inputUrl?.match(/(https?:\/\/)?([^/]+)(.*)/);

  if (match) {
    const protocol = match[1];
    const subDomain = match[2];
    return { protocol, subDomain };
  } else {
    return null;
  }
};

export const calculateLeavePerMonth = (annualLeaveDays = 20) => {
  const monthsInYear = 12;
  const leavePerMonth = annualLeaveDays / monthsInYear;
  let value = parseFloat(leavePerMonth.toFixed(2));
  return isNaN(value) ? 0 : value;
};

export const trimValue = (value) => {
  return value?.replace(/^\s+|\s+$/g, " ");
};

export const getDaysInYear = () => {
  var year = new Date().getFullYear();
  var isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  var daysInNonLeapYear = 365;
  var daysInLeapYear = 366;
  return isLeapYear ? daysInLeapYear : daysInNonLeapYear;
};

export const getMissingKeys = (obj, leaveFrequency = "monthly") => {
  const missingKeys = {};
  for (const key in obj) {
    if (
      ![
        "id",
        "unique_id",
        "is_visible",
        "is_deleted",
        "active",
        "is_initial",
        "organization_id",
      ].includes(key)
    ) {
      if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
        missingKeys[key] = "Required";
      } else if (key === "monthly_days") {
        if (!isValidDays(obj[key]) && leaveFrequency === "monthly")
          missingKeys[key] =
            "Please enter valid days which is divisible by 12 or 6";
        else missingKeys[key] = "";
      } else if (key === "max_leave_encashment") {
        if (obj?.max_leave_encashment > obj?.monthly_days)
          missingKeys[key] =
            "Please enter valid encash days which is less than or equal to yearly leave";
        else missingKeys[key] = "";
      } else {
        missingKeys[key] = "";
      }
    }
  }
  return missingKeys;
};

export const isValidDays = (value) =>
  value === 0 ? false : value % 12 === 0 || value % 6 === 0;

export const isValidOpeningBalance = (number) => {
  const decimalPart = number - Math.floor(number);
  const percentage = Math.round(decimalPart * 100);
  if (percentage === 0 || percentage === 50) return true;
  else return false;
};

export const isValidEncashDays = (
  formData,
  findIndexWorkType,
  maxEncash,
  unique_id
) => {
  let changedSetting = formData["work_type_settings"][findIndexWorkType][
    "leave_type_settings"
  ]?.find((data) => {
    return data?.unique_id === unique_id;
  });
  if (maxEncash > changedSetting?.monthly_days) {
    return false;
  }
  return true;
};

export const validateObject = (obj) => {
  let keysToValidate = Object.keys(obj);
  if (!!keysToValidate?.length)
    for (const key of keysToValidate) {
      if (!(key in obj) || obj[key] === undefined || obj[key] === null) {
        return false;
      }
    }
  else return false;
  return true;
};

export const getLeaveIcon = (statusCode) => {
  switch (statusCode) {
    case LEAVE_TYPES.SickLeave:
      return ICONS.SickLeave;
    case LEAVE_TYPES.SystemGenerated:
      return ICONS.SystemGenerated;
    case LEAVE_TYPES.CasualLeave:
      return ICONS.Other;
    case LEAVE_TYPES.ClubLeave:
      return ICONS.Other;
    default:
      return ICONS.Other;
  }
};

export const getLeaveStatus = (statusCode, variant = "text", chipSize = "small") => {
  const getBorderStyle = () => ({
    border: "1px solid",
    display: "inline",
    borderRadius: "50px",
    padding: "0px 6px 0px 6px",
  });

  const getFillStyle = () => ({
    ...getBorderStyle(),
    color: "white",
    padding: "2px 6px 2px 6px",
  });

  const getTextStyle = () => ({
    display: "inline",
  });

  let style;
  switch (statusCode) {
    case LEAVE_STATUS.Approved:
      style = { color: "greentc", backgroundColor: "greentc.800" };
      break;
    case LEAVE_STATUS.Apply:
      style = { color: "secondary", backgroundColor: "secondary.800" };
      break;
    case LEAVE_STATUS.Cancelled:
      // style = { color: "error.main", backgroundColor: "error.800" };
      style = { color: "secondary", backgroundColor: "error.800" };
      break;
    case LEAVE_STATUS.Rejected:
      style = { color: "error", backgroundColor: "error.800" };
      break;
    case LEAVE_STATUS.Pending:
      style = { color: "orangetc", backgroundColor: "orangetc" };
      break;
    default:
      style = { color: "secondary", backgroundColor: "secondary.800" };
  }

  const mergedStyle =
    variant === "fill"
      ? { ...style, ...getFillStyle() }
      : variant === "outline"
        ? getBorderStyle()
        : getTextStyle();

  return variant === "chip" ? (
    <Chip
      label={titleCase(statusCode)}
      color={style.color}
      size={chipSize}
      variant="outlined"
      sx={{ display: "inline-flex", marginRight: "10px" }}
    />
  ) : (
    <Typography color={style.color} sx={mergedStyle}>
      {titleCase(statusCode)}
    </Typography>
  );
};

export const getLeaveObject = (statusCode) => {
  switch (statusCode) {
    case LEAVE_TYPES.SickLeave:
      return {
        icon: ICONS.SickLeave,
        text: LEAVE_TYPES.SickLeave,
        color: "primary.main",
        backgroundColor: "primary.main",
      };
    case LEAVE_TYPES.SystemGenerated:
      return {
        icon: ICONS.SystemGenerated,
        text: LEAVE_TYPES.SystemGenerated,
        color: "primary.main",
        backgroundColor: "primary.main",
      };
    case LEAVE_TYPES.CasualLeave:
      return {
        icon: ICONS.Other,
        text: LEAVE_TYPES.CasualLeave,
        color: "secondary.main",
        backgroundColor: "secondary.main",
      };
    case LEAVE_TYPES.EmergencyLeaves:
      return {
        icon: ICONS.Other,
        text: LEAVE_TYPES.EmergencyLeaves,
        color: "error.main",
        backgroundColor: "error.main",
      };
    case LEAVE_TYPES.ClubLeave:
      return {
        icon: ICONS.Other,
        text: LEAVE_TYPES.ClubLeave,
        color: "#2196F3",
        backgroundColor: "#2196F3",
      };
    default:
      return {
        icon: ICONS.SystemGenerated,
        text: LEAVE_TYPES.SystemGenerated,
        color: "primary.main",
        backgroundColor: "primary.main",
      };
  }
};

export function formatLeaveDates(dates) {
  if (dates.length === 1) {
    return moment(dates[0].date).format("DD/MM/YYYY");
  } else if (dates.length >= 2) {
    const sortedArray = dates?.sort((a, b) => {
      return new Date(a?.date) - new Date(b?.date);
    });
    const startDate = moment(sortedArray[0].date).format("DD/MM/YYYY");
    const endDate = moment(sortedArray[sortedArray.length - 1].date).format(
      "DD/MM/YYYY"
    );
    return `${startDate} - ${endDate}`;
  } else {
    return "Invalid array length";
  }
}

export const removeDuplicates = (array, key) => {
  if (!key) return array;
  return array?.filter(
    (item, index, self) =>
      index === self.findIndex((obj) => obj[key] === item[key])
  );
};

export const areDatesEqual = (date1, date2) => {
  const momentDate1 = moment(new Date(date1)).startOf("day");
  const momentDate2 = moment(new Date(date2)).startOf("day");

  return momentDate1.isSame(momentDate2);
};

export const getFormattedDate = (date, inputFormat = "DD/MM/YYYY") => {
  // If date is already a valid ISO format, parse it directly
  if (moment(date, moment.ISO_8601, true).isValid()) {
    return moment(date).format(inputFormat);
  }
  // Otherwise, assume it’s in the specified input format
  return moment(date, inputFormat, true).format(inputFormat);
};


export const isValidTime = (time, format = "h:mm A") => {
  let date = moment(time, 'HH:mm:ss').format(format);
  if (date === "Invalid date") {
    return false;
  } else {
    return true;
  }
}

export const convertDateFromFormat = (
  date,
  format = "YYYY-MM-DD",
  inputFormat = "DD/MM/YYYY"
) => {
  return moment(date, inputFormat).format(format);
};

export const getDayName = (date) => {
  return new Date(moment(date, "DD/MM/YYYY").format()).toLocaleString("en-us", {
    weekday: "long",
  });
};

export const getYearObject = (year) => {
  if (!year) return null;
  let yearObject = {
    year: year?.year,
    setting_end_date: year?.setting_end_date,
    setting_start_date: year?.setting_start_date,
    yearDashedFormat: null,
  };
  let startYear = year?.setting_start_date?.slice(0, 4);
  let endYear = year?.setting_end_date?.slice(0, 4);
  if (startYear !== endYear) {
    yearObject.yearDashedFormat = `${startYear}-${endYear
      ?.toString()
      .slice(-2)}`;
  } else {
    yearObject.yearDashedFormat = startYear;
  }
  return yearObject;
};

export const isDashedYearBasedOnMonth = (month, year, format = null) => {
  if (month && year) {
    let yearDate;
    let yearDashedFormat;
    let setting_start_date = moment()
      .set("year", year)
      .set("month", month)
      .startOf("month");
    let setting_end_date = setting_start_date
      .clone()
      .add(1, "year")
      .subtract(1, "day");

    yearDate = moment();
    yearDate.set("year", year);
    yearDate.set("month", month);
    if (month === "January") {
      yearDashedFormat = year;
    } else {
      yearDashedFormat = `${year}-${parseInt(year?.toString()?.slice(-2)) + 1}`;
    }
    let yearObject = {
      yearDate: yearDate.toLocaleString(),
      yearDashedFormat,
      setting_start_date:
        format != null
          ? moment(setting_start_date).format(format)
          : setting_start_date,
      setting_end_date:
        format != null
          ? moment(setting_end_date).format(format)
          : setting_end_date,
    };
    return yearObject;
  }
  return;
};

export const handleSortOfficeHoliday = (response, array = null) => {
  let concatHolidayData = [];
  if (response !== null) {
    concatHolidayData = [
      ...response?.custom_holidays?.map(data => { return { ...data, is_custom: true } }),
      ...response?.saturdayHolidays,
      ...response?.sundayHolidays,
    ];
  } else {
    concatHolidayData = array;
  }
  const sortedArray = concatHolidayData?.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
  return sortedArray;
};

export const handleSortFestivalHolidays = (response) => {
  let sortedArray = response?.slice();
  return sortedArray?.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
};

export function formatFileSize(bytes) {
  if (bytes === 0) return "0 bytes";
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + units[i];
}

export const removeTimeFromDate = (dateString, format = "YYYY-MM-DD") => {
  const date = moment(dateString);
  // Set the time part to 0
  date.startOf("day");
  return date.format(format);
};

export const sortArrayBaseOnRole = (
  array,
  key = "admin_status",
  value = "primary_admin"
) =>
  array.sort((a, b) => {
    if (Object.keys(a)?.includes(key)) {
      if (a[key] === value) {
        return -1;
      }
      if (b[key] === value) {
        return 1;
      }
      return a[key].localeCompare(b[key]);
    }
    return 1;
  });

export function getValueByKey(obj, key) {
  if (obj && typeof obj === "object" && key in obj) {
    if (["date", "leave_date", "createdAt", "updatedAt"].includes(key)) {
      // let values = obj[key]?.split("/");
      let values = obj[key]?.includes('/') ? obj[key]?.split("/") : obj[key];
      if (values[0]?.length === 2) {
        const time = new Date(values[2], values[1], values[0], 0, 0, 0, 0);
        return time;
      } else if (values[0]?.length === 4) {
        const time = new Date(values[0], values[1], values[2], 0, 0, 0, 0);
        return time;
      } else {
        return obj[key];
      }
    } else {
      return obj[key];
    }
  } else if (obj && typeof obj === "object" && key?.includes("?.")) {
    return key.split("?.").reduce((acc, part) => acc?.[part], obj);
  }
  return null;
}

export const sortArrayByKey = (array, direction = "asc", key = "name") => {
  if (!array?.length) {
    return [];
  } else {
    let Array = [...array];
    return Array.sort((a, b) => {
      const valueA = getValueByKey(a, key);
      const valueB = getValueByKey(b, key);
      if (typeof valueA === "string" && typeof valueB === "string") {
        return direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      } else if (valueA instanceof Date && valueB instanceof Date) {
        return direction === "asc"
          ? valueA.getTime() - valueB.getTime()
          : valueB.getTime() - valueA.getTime();
      } else {
        return 0;
      }
    });
  }
};

export const sortRequestArrayByDate = (dataArray, arrayKey, dateKey, direction = "asc") => {
  if (!dataArray?.length) {
    return [];
  } else {
    let Array = [...dataArray];
    return Array?.sort((a, b) => {
      const getMinDate = (reqData) => {
        return reqData?.reduce((minDate, currentDay) => {
          const currentDate = new Date(
            currentDay?.[dateKey]?.split("/").reverse().join("-")
          );
          return currentDate < minDate ? currentDate : minDate;
        }, new Date(reqData[0]?.[dateKey]?.split("/")?.reverse()?.join("-")));
      };

      const minDateA = getMinDate(a?.[arrayKey]);
      const minDateB = getMinDate(b?.[arrayKey]);
      return direction === "asc" ? minDateA - minDateB : minDateB - minDateA;
    });
  }
};

export function groupDataByKey(data, key = "role") {
  if (!key) {
    throw new Error("Please pass key to group data");
  }
  if (data?.length > 0) return [];
  else {
    const groupedData = {};
    data?.forEach((item) => {
      const keyValue = item[key];
      if (!groupedData[keyValue]) {
        groupedData[keyValue] = [];
      }
      groupedData[keyValue].push(item);
    });
    return groupedData;
  }
}

export function unixToTime(timestamp) {
  return moment.unix(timestamp).format("HH:mm:ss");
}

export function timeToUnix(time) {
  return moment(time, "HH:mm:ss").unix();
}

export function filterUniqueLeaveData(leaveData) {
  const uniqueLeaveDatesMap = new Map();

  leaveData?.forEach((leave) => {
    if (!uniqueLeaveDatesMap.has(leave.date)) {
      uniqueLeaveDatesMap.set(leave.date, leave);
    } else {
      if (leave.id) {
        uniqueLeaveDatesMap.set(leave.date, leave);
      }
    }
  });

  const uniqueLeaveData = Array.from(uniqueLeaveDatesMap.values());

  return uniqueLeaveData;
}

export const getDate = (date) => {
  return new Date(date).toLocaleDateString();
};

export function getLeaveDates(leavesArray) {
  if (!leavesArray?.length) return "-";

  if (leavesArray?.length === 1) {
    return getFormattedDate(leavesArray[0]);
  } else if (leavesArray?.length === 2) {
    return leavesArray?.map((date) => getFormattedDate(date))?.join(", ");
  } else {
    return `${leavesArray
      ?.map((date) => getFormattedDate(date))
      ?.slice(0, 2)
      ?.join(", ")}...`;
  }
}

export const getLeaveDateArray = (array, key) => {
  return sortArrayByKey(array, "asc", key)?.map((leave) => leave[key]);
};

export function findDeletedDataByDate(array1, array2, key = "date") {
  const datesInArray2 = {};
  array2.forEach((item) => {
    datesInArray2[item?.[key]] = true;
  });

  const deletedItems = array1.filter((item) => !datesInArray2[item?.[key]]);

  return deletedItems;
}

export const getAllLeaveDates = (leaveData, isHalfInclude = false, key = 'date') => {
  if (isHalfInclude) {
    return sortArrayByKey(leaveData, 'asc', key)?.map((leave) => {
      if (leave.leave_duration.includes("first_half")) {
        return `${getFormattedDate(leave?.date || leave?.leave_date)} (1st Half)`;
      } else if (leave.leave_duration.includes("second_half")) {
        return `${getFormattedDate(leave?.date || leave?.leave_date)} (2nd Half)`;
      }
      return getFormattedDate(leave?.date || leave?.leave_date);
    });
  } else {
    let endDates = sortArrayByKey(leaveData, "asc", "date")?.map(
      (leave) => leave?.date || leave?.leave_date
    );
    return leaveData?.length > 1
      ? `${endDates[0]} - ${endDates[endDates?.length - 1]}`
      : endDates[0];
  }
};

export function findKeyByValue(obj, value) {
  if (!!obj && !!value) {
    for (let key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }
    return null;
  }
  return null;
}

export const isWithinNext7Days = (dateString) => {
  // var parts = dateString.split('/');
  // var day = parseInt(parts[0], 10);
  // var month = parseInt(parts[1], 10) - 1;
  // var year = parseInt(parts[2], 10);
  let day = new Date(dateString)?.getDate();
  let month = new Date(dateString)?.getMonth();
  let year = new Date(dateString)?.getFullYear();

  var inputDate = new Date(year, month, day);

  let todayDate = new Date()?.getDate();
  let todayMonth = new Date()?.getMonth();
  let todayYear = new Date()?.getFullYear();

  var currentDate = new Date(todayYear, todayMonth, todayDate);

  var difference = inputDate.getTime() - currentDate.getTime();

  var daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));

  return daysDifference <= 7 && daysDifference >= 0;
};

export const isInPastDays = (dateString) => {
  // var parts = dateString.split('/');
  // var day = parseInt(parts[0], 10);
  // var month = parseInt(parts[1], 10) - 1;
  // var year = parseInt(parts[2], 10);
  let day = new Date(dateString)?.getDate();
  let month = new Date(dateString)?.getMonth();
  let year = new Date(dateString)?.getFullYear();

  var inputDate = new Date(year, month, day);

  var currentDate = new Date();

  return inputDate < currentDate;
};

export const isWithinNext30Days = (dateString) => {
  // var parts = dateString.split('/');
  // var day = parseInt(parts[0], 10);
  // var month = parseInt(parts[1], 10) - 1;
  // var year = parseInt(parts[2], 10);
  // let day = new Date(dateString)?.getDate();
  // let month = new Date(dateString)?.getMonth();
  // let year = new Date(dateString)?.getFullYear();

  // var inputDate = new Date(year, month, day);
  var inputDate = new Date(dateString);

  var currentDate = new Date();

  var difference = inputDate.getTime() - currentDate.getTime();
  if (difference < 0) {
    return false;
  }

  var daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

  return daysDifference <= 37 && daysDifference > 7;
};

export const isMoreThan30Days = (dateString) => {
  // var parts = dateString.split('/');
  // var day = parseInt(parts[0], 10);
  // var month = parseInt(parts[1], 10) - 1;
  // var year = parseInt(parts[2], 10);
  let day = new Date(dateString)?.getDate();
  let month = new Date(dateString)?.getMonth();
  let year = new Date(dateString)?.getFullYear();

  var inputDate = new Date(year, month, day);

  var currentDate = new Date();

  var difference = inputDate.getTime() - currentDate.getTime();
  if (difference < 0) {
    return false;
  }

  var daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

  return daysDifference > 37;
};

export function getPreviousOnBoardingStep(currentStep) {
  const steps = Object.values(ON_BOARDING_STEPS);
  const currentIndex = steps.indexOf(currentStep);

  if (currentIndex === -1 || currentIndex === 0) {
    return null;
  }

  return steps[currentIndex - 1];
}

export const specialRequestList = [
  { id: "1", name: "work from home" },
  { id: "2", name: "work from other place" },
  { id: "3", name: "availability change" },
  { id: "4", name: "leave early" },
  { id: "5", name: "Come late" },
  // { id: "6", name: "emergency" },
  // { id: "7", name: "max club leave" },
  // { id: "8", name: "Unpaid leaves" },
  // { id: "9", name: "Leave on same day" },
];

export const getDayAndMonth = (date, name, isOnlyDay = false) => {
  if (isOnlyDay === true) {
    return moment(date).format("dddd");
  }
  return `${name?.length > 0 ? titleCase(name) : moment(date).format("dddd")
    } off for the month ${moment(date).format("MMMM")}`;
};

export function convertTime(timeStr, totalStr = null) {
  const toSeconds = (time) => {
    if (!time) {
      return;
    }
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const formatTime = (seconds, format = null) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    const time = `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
    if (!!format) {
      return moment(time, "hh:mm:ss").format(format);
    } else {
      return durationToWord(time);
    }
  };

  const timeInSeconds = toSeconds(timeStr);

  if (!totalStr) {
    return {
      time: formatTime(timeInSeconds, "hh:mm"),
    };
  } else {
    const totalInSeconds = toSeconds(totalStr);
    const percentage = ((timeInSeconds / totalInSeconds) * 100).toFixed(0);

    return {
      time: formatTime(timeInSeconds),
      percentage: `${isNaN(percentage) ? 0 : percentage}%`,
    };
  }
}

export const formatDuration = (duration) => {
  const parsedDuration = moment.duration(duration, 'seconds');

  // const hours = parsedDuration.hours();
  const hours = Math.floor(parsedDuration?.asHours());
  const minutes = parsedDuration.minutes();
  const seconds = parsedDuration.seconds();

  let result = "";
  if (hours) result += `${hours}h `;
  if (minutes) result += `${minutes}m `;
  if (!hours && !minutes && seconds) result += `${seconds}s`;

  return result.trim(); // Remove any trailing space
};

export const timestampToTime = (timestamp) => {
  const date = new Date(timestamp || new Date().toISOString());
  const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  const timeString = date.toLocaleTimeString("en-US", options);
  return timeString;
};

export const getLastDuration = (timestamp) => {
  const startTime = moment(timestamp);
  const endTime = moment();
  const duration = moment.duration(endTime.diff(startTime));
  const hours = String(duration.hours()).padStart(2, "0");
  const minutes = String(duration.minutes()).padStart(2, "0");
  const seconds = String(duration.seconds()).padStart(2, "0");
  const formattedDuration = `${hours}:${minutes}:${seconds}`;
  return formattedDuration;
};

export const durationToWord = (duration) => {
  if (
    !duration ||
    duration === "-" ||
    duration === "00:00:00" ||
    duration === null
  ) {
    return "00:00";
  }

  const [hours, minutes, seconds] = duration.split(":").map(Number);

  const formatValue = (value) => {
    return value === 0 ? "00" : value.toString().padStart(2, "0");
  };

  const formatUnit = (value, unit) => {
    if (value === 0) return "";
    return value === 1
      ? `${value} ${titleCase(unit)}`
      : `${value} ${titleCase(unit)}s`;
  };

  if (hours > 0 && minutes > 0) {
    return `${formatValue(hours)}:${formatValue(minutes)}`;
  }

  if (hours === 0 && minutes > 0 && seconds > 0) {
    return `${formatValue(hours)}:${formatValue(minutes)}`;
  }

  if (hours > 0 && minutes === 0 && seconds > 0) {
    return `${formatValue(hours)}:${formatValue(minutes)}`;
  }

  if (hours > 0 && minutes === 0 && seconds === 0) {
    return `${formatValue(hours)}:${formatValue(minutes)}`;
  }

  if (hours === 0 && minutes > 0 && seconds === 0) {
    return `${formatValue(hours)}:${formatValue(minutes)}`;
  }

  if (hours === 0 && minutes === 0 && seconds > 0) {
    return formatUnit(seconds, "Second");
  }

  return "-";
};

export function convert12to24(time12) {
  if (!time12) return null;
  const [timePart, period] = time12.split(" ");
  let [hours, minutes] = timePart.split(":");

  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:00`;
}

export function getFirstLetterOfString(string) {
  return string?.length
    ? titleCase(string)
      ?.split(" ")
      ?.map((word) => word[0])
      ?.join("")
    : "ST";
}

export const getPaginatedArray = (array, page = 0, limit = 10) => {
  if (!array && array?.length === 0) {
    return [];
  } else {
    return array?.slice(page * limit, page * limit + limit);
  }
};

export function getDatesArray(startDate, endDate) {
  let dates = [];
  let currentDate = moment(startDate);

  while (currentDate <= moment(endDate)) {
    dates.push(currentDate.format("YYYY-MM-DD")); // Format the date as desired
    currentDate = currentDate.add(1, "days");
  }

  return dates;
}

export function isFutureYearDate(renewalSettingData, requestDate) {
  let formatedDate = convertDateFromFormat(
    requestDate,
    "YYYY-MM-DD",
    "DD/MM/YYYY"
  );
  return formatedDate > renewalSettingData?.setting_end_date;
}

export const getApplyDateTime = (createAt, format = "dddd, hh:mm A, DD/MM/YYYY") => {
  let date = moment(createAt).format(format);
  if (date !== "Invalid date") {
    return date;
  } else {
    return moment(createAt, "DD/MM/YYYY").format(format);
  }
};

export const getApplicationNumber = (
  applicationNumber,
  TotalLength = 4,
  includeHash = true
) => {
  if (!applicationNumber) {
    let str = Array.from({ length: TotalLength }, (_, index) => "0").join("");
    return includeHash ? `#${str}` : str;
  } else if (applicationNumber?.toString().length === TotalLength) {
    return includeHash ? `#${applicationNumber}` : applicationNumber;
  } else {
    return includeHash
      ? `#${applicationNumber?.toString()?.padStart(TotalLength, 0)}` ?? "#0000"
      : applicationNumber?.toString()?.padStart(TotalLength, 0) ?? "0000";
  }
};

export const getMaxSize = (size) => {
  if (!size) return 0;
  else return Math.round(size / 1024 / 1024);
};

export function convertTimeToDate(timeStr) {
  if (!!timeStr) {
    const [hours, minutes, seconds] = timeStr?.split(":")?.map(Number);

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    date.setMilliseconds(0);

    return date;
  } else {
    return "";
  }
}

export const getDurationFromDate = (startTime, endTime, type = "duration") => {
  if (!startTime || !endTime) {
    return "-";
  }
  let start_time = null;
  let end_time = null;
  if (startTime.length === 8 || endTime.length === 8) {
    start_time = moment(startTime, "HH:mm:ss").format("HH:mm");
    end_time = moment(endTime, "HH:mm:ss").format("HH:mm");
  } else {
    start_time = moment(startTime).format("hh:mm A");
    end_time = moment(endTime).format("hh:mm A");
  }
  if (type === "workingHours") {
    return `${moment(start_time, "HH:mm").format("hh:mm A")} - ${moment(
      end_time,
      "HH:mm"
    ).format("hh:mm A")}`;
  } else if (type === "hours") {
    const start = moment(convertTimeToDate(startTime));
    const end = moment(convertTimeToDate(endTime));
    const duration = moment.duration(end.diff(start));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const formattedHours =
      hours < 0
        ? `-${String(Math.abs(hours)).padStart(2, "0")}`
        : String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} Hours`;
  } else {
    // const duration = moment.duration(moment(end_time, "HH:mm").diff(moment(start_time, "HH:mm")));
    // const formattedDuration = moment.utc(duration.as('milliseconds')).format('hh:mm');
    // return `${formattedDuration} Hours`;
    const start = moment(startTime);
    const end = moment(endTime);
    const duration = moment.duration(end.diff(start));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const formattedHours =
      hours < 0
        ? `-${String(Math.abs(hours)).padStart(2, "0")}`
        : String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} Hours`;
  }
};

export const getDisplayValue = (value) => {
  return value === null || value === undefined || value === "-" ? "-" : value;
};

export function getStartDateBaseOnOnboarding(currentDate, onboardingDate) {
  const onboarding = onboardingDate;
  const current = currentDate;

  const onboardingUTC = Date.UTC(
    onboarding.getUTCFullYear(),
    onboarding.getUTCMonth(),
    onboarding.getUTCDate(),
    onboarding.getUTCHours(),
    onboarding.getUTCMinutes(),
    onboarding.getUTCSeconds()
  );

  const currentUTC = Date.UTC(
    current.getUTCFullYear(),
    current.getUTCMonth(),
    current.getUTCDate(),
    current.getUTCHours(),
    current.getUTCMinutes(),
    current.getUTCSeconds()
  );
  if (onboardingUTC < currentUTC) {
    return new DateObject(currentUTC);
  } else {
    return new DateObject(onboardingDate);
  }
}

export const setIsReportee = (currentUser) => {
  if (currentUser?.is_admin || currentUser?.user_type === USER_TYPES.admin) {
    secureLocalStorage.setItem("isShowLeaveReport", true);
  } else {
    secureLocalStorage.setItem("isShowLeaveReport", false);
  }
}

export const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/; secure; SameSite=Strict;`;
};

export const getCookie = (name) => {
  const cookieArray = document.cookie.split('; ');
  for (let cookie of cookieArray) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};

export const clearAllCookies = () => {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

export function formDataToObject(formData) {
  const obj = {};

  formData.forEach((value, key) => {
    // eslint-disable-next-line no-useless-escape
    const keys = key.split(/[\[\]]+/).filter(Boolean); // Split by brackets and remove empty strings
    let current = obj;

    keys.forEach((k, index) => {
      if (index === keys.length - 1) {
        // Handle attachment objects properly

        // Handle regular fields
        if (current[k]) {
          if (!Array.isArray(current[k])) {
            current[k] = [current[k]];
          }
          current[k].push(value);
        } else {
          current[k] = value === "null" ? null : value;
        }
      } else {
        if (!current[k]) {
          current[k] = isNaN(keys[index + 1]) ? {} : [];
        }
        current = current[k];
      }
    });
  });

  return obj;
}

export const convertToBase64 = async (file) => {
  if (!(file instanceof File || file instanceof Blob)) {
    throw new Error("Input must be a File object");
  }

  const arrayBuffer = await file.arrayBuffer();
  const byteArray = new Uint8Array(arrayBuffer);

  // Convert to a binary string
  const binaryString = byteArray.reduce((data, byte) => {
    return data + String.fromCharCode(byte);
  }, "");

  // Encode as base64
  const base64String = btoa(binaryString);

  return base64String;
};

export const getReportingAuthority = (allUsers, user) => {
  if (!user) {
    return null;
  }
  if (user?.name && user?.id) {
    return allUsers?.find((u) => u?.name === user?.name);
  } else if (user?.length > 0) {
    return allUsers?.find((u) => u?.name === user);
  } else {
    return null;
  }
};

export function base64ToBlob(base64Data, contentType = "image/png") {
  const byteString = atob(base64Data);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
}

export const getEncryptedData = (data) => {
  const _jsonFormData = formDataToObject(data);
  const formData = encryptData(_jsonFormData);
  return formData;
}

export const calculateLeaveCount = (leaveData) => {
  if (!leaveData?.length)
    return 0;
  let total_leave = leaveData?.reduce((total, leave) => {
    if (leave?.leave_duration === "full") {
      return total + 1;
    } else if (leave?.leave_duration?.toLowerCase().includes("half")) {
      return total + 0.5;
    } else {
      return total;
    }
  }, 0);
  return total_leave;
};
export const showInformationAuthority = (informed_authorities) => {
  if (!informed_authorities) {
    return "-";
  }
  else {
    if (informed_authorities?.length > 0 && !informed_authorities[0]?.name) {
      return informed_authorities?.join(", ");
    } else {
      return informed_authorities?.map(user => user?.name)?.join(", ");
    }
  }
}

export const updateSystemGeneratedLeaveStatus = (leaves) => {
  return leaves.map((leave) => {
    return {
      ...leave,
      status: leave?.status?.toLocaleLowerCase() === "sys-generated" ? "pending" : leave?.status,
      isSystemGeneratedLeave: leave?.status?.toLocaleLowerCase() === "sys-generated"
    }
  });
}

export const updateSystemGeneratedLeaveApplicationStatus = (leave) => {
  return {
    ...leave,
    status: leave?.status?.toLocaleLowerCase() === "sys-generated" ? "pending" : leave?.status,
    isSystemGeneratedLeave: leave?.status?.toLocaleLowerCase() === "sys-generated"
  }
}

export const getInputId = (id, index) => {
  if (index?.toString().length) {
    return `input-${id}-${index}`
  } else {
    return `input-${id}-${generateUniqueId()}`
  }
}

export function convertDecimalToSeconds(decimalHours) {
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);
  const totalSeconds = (hours * 3600) + (minutes * 60);

  return totalSeconds;
}

export function convertSecondsToDecimal(seconds) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const decimalHours = hours + (minutes / 60);

  return decimalHours;
}
export function timeToSeconds(time) {
  if (!time) {
    return 0;
  }

  const parts = time?.split(':').map(Number);

  if (parts?.length === 2) {
    let hours = parseInt(parts[0], 10);
    let minutes = parseInt(parts[1], 10);
    return (hours * 3600) + (minutes * 60);
  }
  return 0;
}

export function secondsToTime(seconds) {
  const hours = Math.floor((Math.abs(seconds)) / 3600);
  const minutes = Math.floor(((Math.abs(seconds)) % 3600) / 60);
  if (isNaN(hours) && isNaN(minutes)) return "00:00"
  return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');
}

export function secondsToTimeForTimer(seconds) {
  const hours = Math.floor((Math.abs(seconds)) / 3600);
  const minutes = Math.floor(((Math.abs(seconds)) % 3600) / 60);
  const second = Math.floor((Math.abs(seconds)) % 60);
  if (isNaN(hours) && isNaN(minutes) && isNaN(seconds)) return "00:00:00"
  return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0') + ':' + String(second).padStart(2, '0');
}

export const timeToHours = (time) => {
  if (time !== "00:00" || !time) {
    const [hours, minutes] = time?.split(':').map(Number);
    const totalHours = hours + minutes / 60;
    return totalHours;
  }
}

export const secondToDayJsHours = (seconds = 0) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const newTime = dayjs().startOf('day').set("hour", hours).set("minute", minutes).set("second", 0);
  return newTime;
}

export const getDayJsToSecond = (dayJsObject) => {
  const yourDate = dayjs(dayJsObject).set("second", 0);
  const startOfDay = dayjs(yourDate).startOf('day');

  return yourDate.diff(startOfDay, 'second');
}

export const isProvisionLeave = (leaveDetails, leaveDay) => {
  if (leaveDetails?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name !== LEAVE_TYPES?.CasualLeave) {
    return false
  } else {
    const isDateBeforeCurrentMonth = (date) => {
      const currentDate = new Date();
      const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      return new Date(date) < currentMonthStart;
    }

    if (new Date(leaveDay?.leave_date)?.getMonth() === new Date()?.getMonth()) {
      if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
        return true
      } else {
        return false
      }
    } else {
      if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
        if (isDateBeforeCurrentMonth(leaveDay?.leave_date)) {
          return false
        }
        return true
      } else {
        if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
          if (isDateBeforeCurrentMonth(leaveDay?.leave_date)) {
            return false
          }
          if (!leaveDay?.is_paid > 0) {
            return true
          } else {
            if (leaveDay?.leave_duration === "full" && leaveDay?.is_paid === 0.5) {
              return true
            }
            return false
          }
        }
      }
    }
  }
}

export const getProvisionTakenLeave = (leaveDetails, onlyCount = false) => {
  let totalProvisionLeaveCountOfApplication = leaveDetails?.leave_days ? leaveDetails?.leave_days?.reduce((currentLeavePrevCountData, currentLeaveData) => {
    if (currentLeaveData?.leave_duration === "full") {
      if (new Date(currentLeaveData?.leave_date)?.getMonth() === new Date()?.getMonth()) {
        if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
          currentLeavePrevCountData.pendingProvisionCount = 1 + currentLeavePrevCountData?.pendingProvisionCount;
        } else {
          if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
            currentLeavePrevCountData.approvedCount = 1 + currentLeavePrevCountData?.approvedCount;
          }
        }
      } else {
        if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
          currentLeavePrevCountData.pendingProvisionCount = 1 + currentLeavePrevCountData?.pendingProvisionCount;
        } else {
          if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
            if (!currentLeaveData?.is_paid > 0) {
              currentLeavePrevCountData.approvedProvisionCount = 1 + currentLeavePrevCountData?.approvedProvisionCount;
            } else if (currentLeaveData?.is_paid === 0.5) {
              currentLeavePrevCountData.approvedProvisionCount = 0.5 + currentLeavePrevCountData?.approvedProvisionCount;
              currentLeavePrevCountData.approvedCount = 0.5 + currentLeavePrevCountData?.approvedCount;
            } else {
              currentLeavePrevCountData.approvedCount = 1 + currentLeavePrevCountData?.approvedCount;
            }
          }
        }
      }
    } else {
      if (new Date(currentLeaveData?.leave_date)?.getMonth() === new Date()?.getMonth()) {
        if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
          currentLeavePrevCountData.pendingProvisionCount = 0.5 + currentLeavePrevCountData?.pendingProvisionCount;
        } else {
          if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
            currentLeavePrevCountData.approvedCount = 0.5 + currentLeavePrevCountData?.approvedCount;
          }
        }
      } else {
        if (leaveDetails?.status === LEAVE_STATUS?.Pending) {
          currentLeavePrevCountData.pendingProvisionCount = 0.5 + currentLeavePrevCountData?.pendingProvisionCount;
        } else {
          if (leaveDetails?.status === LEAVE_STATUS?.Approved) {
            if (!currentLeaveData?.is_paid > 0) {
              currentLeavePrevCountData.approvedProvisionCount = 0.5 + currentLeavePrevCountData?.approvedProvisionCount;
            } else {
              currentLeavePrevCountData.approvedCount = 0.5 + currentLeavePrevCountData?.approvedCount;
            }
          }
        }
      }
    }
    return currentLeavePrevCountData;
  }, { pendingProvisionCount: 0, approvedProvisionCount: 0, approvedCount: 0 }) : { pendingProvisionCount: 0, approvedProvisionCount: 0, approvedCount: 0 };
  return onlyCount ? (totalProvisionLeaveCountOfApplication?.pendingProvisionCount + totalProvisionLeaveCountOfApplication?.approvedProvisionCount + totalProvisionLeaveCountOfApplication?.approvedCount) : totalProvisionLeaveCountOfApplication;
}

export const getDefaultTime = (date = null) => {
  if (!date) {
    const dateObj = new Date();
    dateObj.setHours(10, 0, 0, 0);
    return dateObj;
  } else {
    return date
  }
};


export const getMonthOfLatestLeaveDate = (leaveDays) => {
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  let sortedArray = sortArrayByKey(leaveDays, 'asc', 'leave_date');
  let biggestDate = sortedArray[sortedArray?.length - 1];

  let currentMonth = months[new Date()?.getMonth()];
  let currentYear = new Date()?.getFullYear();
  let month = months[new Date(biggestDate?.leave_date)?.getMonth()];
  let fullDateString = new Date(biggestDate?.leave_date)?.getFullYear();

  return {
    month: month,
    fullDateMonth: `${month} ${fullDateString}`,
    isCurrentMonth: (currentMonth === month && currentYear === fullDateString),
  }
}

export const getTaskStatus = (statusCode, variant = "text", chipSize = "small", className = "status-chip") => {
  const getBorderStyle = () => ({
    border: "1px solid",
    display: "inline",
    borderRadius: "50px",
    padding: "0px 6px 0px 6px",
  });

  const getFillStyle = () => ({
    ...getBorderStyle(),
    color: "white",
    padding: "2px 6px 2px 6px",
  });

  const getTextStyle = () => ({
    display: "inline",
  });

  let style;
  switch (statusCode) {
    case 'To-Do':
      style = { color: "graytc", backgroundColor: "orangetc.primary" };
      break;
    case TASK_STATUS_LIST.Working:
      style = { color: "greentcChip", backgroundColor: "greentcChip.main" };
      break;
    case TASK_STATUS_LIST.Review:
      style = { color: "primary", backgroundColor: "primary.main" };
      break;
    case TASK_STATUS_LIST.Pause:
      style = { color: "orangetc", backgroundColor: "orangetc.main" };
      break;
    case TASK_STATUS_LIST.Close:
      style = { color: "lightGraytc", backgroundColor: "orangetc.main" };
      break;
    case TASK_STATUS_LIST.Deferred:
      style = { color: "graytc", backgroundColor: "orangetc.primary" };
      break;
    case TASK_STATUS_LIST['Re-Open']:
      style = { color: "red", backgroundColor: "red.main" };
      break;
    case TASK_STATUS_LIST.Block:
      style = { color: "primary", backgroundColor: "primary" };
      break;

    default:
      style = { color: "greentcChip", backgroundColor: "greentcChip.main" };
  }

  const mergedStyle =
    variant === "fill"
      ? { ...style, ...getFillStyle() }
      : variant === "outline"
        ? getBorderStyle()
        : getTextStyle();

  return variant === "chip" ? (
    <Chip
      label={statusCode === 'To-Do' ? 'To Do' : titleCase(statusCode)}
      color={style.color}
      size={chipSize}
      variant="outlined"
      sx={{ display: "inline-flex", marginRight: "10px" }}
      className={className}
    />
  ) : (
    <Typography color={style.color} sx={mergedStyle}>
      {titleCase(statusCode)}
    </Typography>
  );
};

export const getPhaseStatus = (statusCode, variant = "text", chipSize = "small") => {
  const getBorderStyle = () => ({
    border: "1px solid",
    display: "inline",
    borderRadius: "50px",
    padding: "0px 6px 0px 6px",
  });

  const getFillStyle = () => ({
    ...getBorderStyle(),
    color: "white",
    padding: "2px 6px 2px 6px",
  });

  const getTextStyle = () => ({
    display: "inline",
  });

  let style;
  switch (statusCode) {
    case PROJECT_PHASE_STATUS['In-Progress']:
      style = { color: "primary", backgroundColor: "primary.main" };
      break;
    case PROJECT_PHASE_STATUS['Not started']:
      style = { color: "secondary", backgroundColor: "secondary.main" };
      break;
    case PROJECT_PHASE_STATUS.Done:
      style = { color: "lightGraytc", backgroundColor: "lightGraytc.main" };
      break;

    default:
      style = { color: "primary", backgroundColor: "primary.main" };
  }

  const mergedStyle =
    variant === "fill"
      ? { ...style, ...getFillStyle() }
      : variant === "outline"
        ? getBorderStyle()
        : getTextStyle();

  return variant === "chip" ? (
    <Chip
      label={titleCase(statusCode)}
      color={style.color}
      size={chipSize}
      variant="outlined"
      sx={{ display: "inline-flex", marginRight: "10px" }}
    />
  ) : (
    <Typography color={style.color} sx={mergedStyle}>
      {titleCase(statusCode)}
    </Typography>
  );
};

export const getPriorotyIcon = (priority) => {
  switch (priority) {
    case PROJECT_TASK_PRIORITY.Highest:
      return ICONS.HighestPriority;
    case PROJECT_TASK_PRIORITY.High:
      return ICONS.HighPriority;
    case PROJECT_TASK_PRIORITY.Medium:
      return ICONS.MediumPriority;
    case PROJECT_TASK_PRIORITY.Low:
      return ICONS.LowPriority;
    case PROJECT_TASK_PRIORITY.Lowest:
      return ICONS.LowestPriority;
    default:
      return ICONS.HighPriority;
  }
};

export const getTaskTypeIcon = (type) => {
  switch (type) {
    case TASK_TYPE_LIST.Task:
      return ICONS.TaskIcon;
    case TASK_TYPE_LIST.Improvement:
      return ICONS.ImprovementIcon;
    case TASK_TYPE_LIST.Bug:
      return ICONS.BugIcon;
    default:
      return ICONS.TaskIcon;
  }
};

export const getProjectPhaseShortCode = (phaseData) => {
  if (phaseData?.title?.length) return phaseData?.title?.split(" ")?.map((word) => word.charAt(0).toUpperCase()).join("");
  else return "P1"
}

export const formatTimeAgo = (date, isDateWithTime = false) => {
  const now = moment();
  const inputDate = moment(date);

  if (now.diff(inputDate, "days") < 1) {
    if (inputDate.fromNow() === 'an hour ago') return "1 hour ago"
    return inputDate.fromNow();
  } else if (now.diff(inputDate, "days") === 1) {
    return "Yesterday";
  } else {
    return inputDate.format(isDateWithTime ? "hh:mm A, DD MMMM YYYY" : "MMM D, YYYY");
  }
};

export const remainingHoursColor = (usedHours = 0, totalHours = 0) => {
  let percentageOfUsedHours = Math.ceil((usedHours * 100) / totalHours);
  if (usedHours === 0 && totalHours === 0) {
    return "text.greentc"
  }
  if (percentageOfUsedHours <= 50) {
    return "text.greentc"
  } else if (percentageOfUsedHours >= 51 && percentageOfUsedHours <= 89) {
    return "orangetc.main"
  } else {
    return "red.main"
  }
}

export function roundToTwoDecimals(value) {
  if (!value) {
    return 0;
  }
  return +value?.toFixed(2);
}

export const getPhaseRemainingHours = (remainingHours) => {
  let updatedHours = roundToTwoDecimals(remainingHours);
  return Math.abs(updatedHours);
}

export const convertManualTimeToSeconds = (timeString) => {
  const timePattern = /(?:(\d+)h)?\s*(?:(\d+)m)?/i;
  const match = timePattern.exec(timeString);

  if (!match) return 0;

  const hours = match[1] ? parseInt(match[1], 10) : 0;
  const minutes = match[2] ? parseInt(match[2], 10) : 0;

  const totalSeconds = hours * 3600 + minutes * 60;
  return totalSeconds;
};

export const isProjectEditAccess = (user) => {
  return ['admin']?.includes(user?.user_type?.toLowerCase())
}

export const isProjectPhaseAccess = (user) => {
  return ['admin', 'manager']?.includes(user?.user_type?.toLowerCase()) || user?.user_type?.toLowerCase()?.includes('manager');
}

export const generateShortCode = (title) => {
  const words = title?.trim()?.split(/\s+/);

  if (words?.length > 1) {
    return words?.slice(0, 6)?.map(word => word[0]?.toUpperCase())?.join("");
  } else {
    return words[0]?.length >= 3 ? words[0]?.substring(0, 3)?.toUpperCase() : words[0]?.toUpperCase();
  }
};


export const getTimeDifferenceInSeconds = (timeString = "00:00:00") => {
  const givenTime = moment(timeString, "HH:mm:ss");

  const currentTime = moment();

  const differenceInSeconds = Math.abs(givenTime?.diff(currentTime, 'seconds'));
  return differenceInSeconds;
}

export const generateFileName = (fileName) => {
  let dateTime = moment().format("DD-MM-YYYY HH:mm:ss");
  return `${fileName}-${dateTime}.xlsx`
}