import { Box, Button, ButtonGroup, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TableRowSkeleton from '../../../components/TableRowSkeleton';
import axiosInstance from '../../../axios';
import API from '../../../axios/api';
import moment from 'moment';
import { getFullName, sortArrayByKey, titleCase } from '../../../utils';
import { STATUS_ACTIVITY_REPORT } from '../../../constants/default-values';
import BackgroundLetterAvatars from '../../../components/BackgroundLetterAvatars';
import socket from '../../../socket';
import useAuthentication from '../../../hook/useAuthentication';

const CurrentStatusReport = () => {
  const { isAuthenticated } = useAuthentication();
  const [isLoading, setIsLoading] = React.useState({
    pageLoading: true,
  });
  const [allStatusList, setAllStatusList] = useState([]);
  const [activeStatus, setActiveStatus] = useState("All");
  const currentUser = useAuthentication().getCurrentUser();
  const [activitySocket, setActivitySocket] = useState(null);
  const [sortType, setSortType] = useState("asc")
  const [orderBy, setOrderBy] = useState("status");

  const updateStatusOfAllStatusList = (status) => {
    return status?.map((value) => {
      let updatesStatus = "";
      let isWfh = false;
      let isHoliday = false;
      let isHalfDay = false;
      let isFullDay = false;
      if (value?.status?.includes("-")) {
        updatesStatus = value?.status?.split("-")[0]?.trim();
        const otherStatusArray = value?.status?.split("-")?.map((value) => value?.toLowerCase()?.trim());
        if (otherStatusArray?.includes("wfh")) {
          isWfh = true;
        }
        if (otherStatusArray?.includes("holiday")) {
          isHoliday = true;
        }
        if (otherStatusArray?.includes("full day")) {
          isFullDay = true;
        }
        if (otherStatusArray?.includes("half day")) {
          isHalfDay = true;
        }
      } else {
        updatesStatus = value?.status?.trim();
      }
      return {
        ...value,
        status: updatesStatus,
        actualStatus: value?.status,
        isHalfDay: isHalfDay,
        isFullDay: isFullDay,
        isHoliday: isHoliday,
        isWfh: isWfh
      }
    })
  };

  const getAllStatus = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, pageLoading: true }));
      let payload = {
        orgYearId: currentUser?.organization?.id,
      }
      const response = await axiosInstance.post(API.getCurrentStatusReport, payload);
      if (response.status === 200) {
        setAllStatusList(updateStatusOfAllStatusList(response.data?.data));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const updateUserStatus = (activityData) => {
    setAllStatusList(prev => {
      let updateStatus = prev?.map((user) => {
        if (user?.id === activityData?.id) {
          return updateStatusOfAllStatusList([activityData])[0];
        }
        return user;
      })
      return updateStatus;
    });
  };

  const initializeSocket = () => {
    setActivitySocket(socket);

    socket.emit('subscribeToUserActivity', currentUser);

    socket.on("userLiveStatus", (activityData) => {
      if (typeof activityData === "object" && activityData?.length) {
        updateUserStatus(activityData[0]);
      } else if (typeof activityData?.response === "string") {
        console.info(activityData?.response);
      }
    });

    return socket;
  };

  useEffect(() => {
    if (isAuthenticated() && currentUser?.user_type !== "super_admin") {
      getAllStatus();
      initializeSocket();
    }

    return () => {
      if (activitySocket) {
        activitySocket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusColor = (user) => {
    let status = user?.status || "";
    switch (status) {
      case "Working":
        return "primary.main";
      case "Away":
        return "orangetc.main";
      case "Leave":
        return "error.main";
      default:
        return "text.primary";
    }
  };

  const getActivityColor = (user) => {
    let status = user?.status || "";
    if (status === "Leave") {
      return "error.main";
    } else if (status === "Away" && user?.activity === "Logout" && user?.leaveInfo?.length === 1 && user?.leaveInfo[0]?.leave_duration === "full") {
      return "error.main";
    } else if (status === "Away" && user?.activity === "Logout" && user?.leaveInfo?.length && user?.leaveInfo?.length === 2) {
      return "error.main";
    }
    else if (status === "Away" && user?.activity === "Logout" && user?.leaveInfo?.length && user?.leaveInfo?.length === 1 && user?.leaveInfo[0]?.leave_duration === "second_half") {
      return "error.main";
    }
  }

  const getFutureActivityColor = (user) => {
    let status = user?.status || "";
    if (status === "Leave") {
      if (user?.leaveInfo[0]?.leave_duration === "first_half") {
        return "text.primary";
      } else if (user?.leaveInfo?.length === 2) {
        return "error.main";
      }
    } if (user?.leaveInfo?.length === 2) {
      return "error.main"
    } else if (user?.leaveInfo?.length === 1 && user?.leaveInfo[0]?.leave_duration === "second_half" && user?.activity === "Not Started" && user?.status === "Away") {
      return "error.main"
    } else if (status === "Away" && user?.activity === "Logout" && user?.leaveInfo?.length && user?.leaveInfo[0]?.leave_duration === "second_half") {
      return "error.main";
    } else if (status === "Working" && user?.WFHInfo?.length && user?.WFHInfo[0]?.leave_duration === "first_half") {
      return "error.main";
    } else if (status === "Working" && user?.leaveInfo?.length && user?.leaveInfo[0]?.leave_duration === "second_half") {
      return "error.main";
    }
  }

  const calculateDuration = (since) => {
    if (!since) {
      return "-";
    }
    const start = new Date(since);
    const now = new Date();
    const diff = Math.abs(now - start);

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    if (hours === 0 && minutes === 0) {
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      return `${seconds}s`;
    }

    return `${hours}h ${minutes}m`;
  };

  const getForDuration = (user) => {
    if (user?.isFullDay) {
      return "- Full Day";
    } else if (user?.isHalfDay) {
      return "- Half Day";
    } else if (user?.isHoliday) {
      return "";
    } else if (user?.isWfh && user?.activity !== "Not Started") {
      return `- ${calculateDuration(user?.since)}`;
    } else if ((user?.activity === "Not Started" || user?.activity === "Logout") && user?.status === "Away") {
      return "";
    } else if (user?.status === "Leave" && user?.isHalfDay && user?.isWfh) {
      return "";
    } else {
      return `- ${calculateDuration(user?.since)}`;
    }
  }

  const getActivity = (user) => {
    let activity = user?.activity || "";
    let status = user?.status || "";
    let leaveInfo = user?.leaveInfo?.[0] || [];
    if (leaveInfo?.leave_duration === "full" && status === "Leave") {
      return titleCase(leaveInfo?.type);
    } else if (["first_half"].includes(leaveInfo?.leave_duration) && activity !== "Work Log" && status === "Leave") {
      return `Half ${titleCase(leaveInfo?.type)}`;
    } else if (["second_half"].includes(leaveInfo?.leave_duration) && activity === "Not Started" && status === "Leave") {
      return "Not Started";
    } else if (leaveInfo?.leave_duration === "second_half" && ['Leave'].includes(status) && user?.leaveInfo?.length === 1 && user?.activity === "Logout") {
      return `Half ${titleCase(leaveInfo?.type)}`;
    } else if (activity === "Logout" && ['Away'].includes(status) && user?.leaveInfo?.length === 2) {
      return `Half ${titleCase(user?.leaveInfo?.find((item) => item?.leave_duration === "first_half")?.type)}`;
    } else if (activity === "Logout" && ['Away'].includes(status) && user?.leaveInfo?.length === 1 && user?.leaveInfo[0]?.leave_duration === "full") {
      return titleCase(leaveInfo?.type);
    } else {
      return titleCase(activity);
    }
  }
  const getSinceTime = (user) => {
    if (user?.status === "Away" && user?.activity === "Not Started") {
      return "";
    } else if (user?.status === "Leave" && user?.leaveInfo?.length && ['full', 'first_half', 'second_half'].includes(user?.leaveInfo[0]?.leave_duration)) {
      return "";
    } else if (user?.status === "Away" && user?.activity === "Logout" && user?.leaveInfo?.length === 1 && user?.leaveInfo[0]?.leave_duration === "first_half") {
      return `(${moment(user?.login).format("hh:mm A") + " to " + moment(user?.logout).format("hh:mm A")})`
    } else if (user?.status === "Away" && user?.activity === "Logout" && !user?.leaveInfo?.length) {
      return `(${moment(user?.login).format("hh:mm A") + " to " + moment(user?.logout).format("hh:mm A")})`
    } else if (user?.status === "Away" && user?.activity === "Logout" && user?.leaveInfo?.length === 2) {
      return ""
    } else if (user?.status === "Away" && user?.activity === "Logout" && user?.leaveInfo?.length === 1 && user?.leaveInfo[0]?.leave_duration === "second_half") {
      return `(from ${moment(user?.logout).format("hh:mm A")})`
    } else {
      return `(since ${moment(user?.since).format("hh:mm A")})`
    }
  }

  const isShowFutureEvent = (user) => {
    let leaveInfo = user?.leaveInfo?.[0] || [];
    let WFHInfo = user?.WFHInfo?.[0] || [];
    if (leaveInfo?.leave_duration === "full" && user?.status === "Leave") {
      return false;
    } else if (leaveInfo?.leave_duration === "first_half" && user?.status === "Leave") {
      return true;
    } else if ((user?.status === "Away" || user?.status === "Leave") && (user?.activity === "Logout" || user?.activity === "Not Stared") && user?.leaveInfo?.length === 2) {
      return true;
    } else if (WFHInfo?.leave_duration === "first_half" && user?.status === "Working" && user?.activity === "Work Log" && user?.leaveInfo?.length) {
      return true;
    } else if (WFHInfo?.leave_duration === "second_half" && user?.status === "Away" && (user?.activity !== "Work Log" && user?.activity !== "Logout")) {
      return true;
    } else if (leaveInfo?.leave_duration === "second_half" && user?.status === "Away" && (user?.activity === "Not Started") && user?.leaveInfo?.length === 1) {
      return true;
    } else if (leaveInfo?.leave_duration === "second_half" && user?.status === "Working" && user?.activity === "Work Log") {
      return true;
    }
    return false;
  }

  const showStatus = (userStatus) => {
    if (userStatus?.isWfh) {
      return <Stack direction={"row"} spacing={"6px"}>
        <Typography color={getStatusColor(userStatus)} fontSize={14} fontWeight={600} lineHeight={"20px"} letterSpacing={"0.17px"}>{titleCase(userStatus?.status)}</Typography>
        <Typography color={"#00000099"} fontSize={13} fontWeight={400} lineHeight={"18.59px"} letterSpacing={"0.17px"}>(WFH)</Typography>
      </Stack>
    }
    return <Typography color={getStatusColor(userStatus)} fontSize={14} fontWeight={600} lineHeight={"20px"} letterSpacing={"0.17px"}>{titleCase(userStatus?.status)}</Typography>
  }

  const getFutureActivity = (user) => {
    let leaveInfo = user?.leaveInfo?.[0] || [];
    if (leaveInfo?.leave_duration === "full" && user?.status === "Leave") {
      return "";
    }
    else if (leaveInfo?.leave_duration === "first_half" && user?.status === "Leave" && !user?.WFHInfo?.length && user?.leaveInfo?.length === 1) {
      return "Working";
    } else if (user?.leaveInfo?.length === 2) {
      return `Half ${titleCase(user?.leaveInfo?.find((item) => item?.leave_duration === "second_half")?.type)}`;
    } else if (leaveInfo?.leave_duration === "first_half" && user?.status === "Leave" && user?.WFHInfo?.length) {
      return "WFH";
    }
    else if (leaveInfo?.leave_duration === "second_half" && user?.status === "Leave" && user?.activity === "Not Started") {
      return "";
    } else if (leaveInfo?.leave_duration === "second_half" && user?.status === "Away" && user?.activity === "Logout") {
      return `Half ${titleCase(user?.leaveInfo?.find((item) => item?.leave_duration === "second_half")?.type)}`;
    } else if (leaveInfo?.leave_duration === "second_half" && user?.status === "Working" && user?.activity === "Work Log" && user?.WFHInfo?.length && user?.WFHInfo[0]?.leave_duration === "first_half") {
      return `Half ${titleCase(leaveInfo?.type)}`;
    } else if (!user?.leaveInfo?.length && user?.status === "Working" && user?.activity === "Work Log" && user?.WFHInfo?.length && user?.WFHInfo[0]?.leave_duration === "second_half") {
      return "WFH";
    } else if (user?.leaveInfo?.length && user?.status === "Working" && user?.activity === "Work Log") {
      return `Half ${titleCase(leaveInfo?.type)}`;
    } else if (user?.WFHInfo?.length && user?.WFHInfo[0]?.leave_duration === "second_half") {
      return "WFH";
    } else if (user?.leaveInfo?.length && user?.leaveInfo[0]?.leave_duration === "second_half" && user?.activity === "Not Started" && user?.status === "Away") {
      return `Half ${titleCase(user?.leaveInfo?.find((item) => item?.leave_duration === "second_half")?.type)}`
    }
    else {
      return titleCase(user?.activity);
    }
  }

  const getFutureSinceTime = (user) => {
    let leaveInfo = user?.leaveInfo?.[0] || [];
    let WFHInfo = user?.WFHInfo?.[0] || [];
    if (leaveInfo?.leave_duration === "full" && user?.status === "Leave") {
      return "";
    }
    else if (leaveInfo?.leave_duration === "first_half" && user?.status === "Leave" && user?.leaveInfo?.length === 1) {
      return `(from ${moment(leaveInfo?.in_time, 'HH:mm:ss').format("hh:mm A")})`;
    }
    else if (leaveInfo?.leave_duration === "second_half" && user?.status === "Leave" && user?.activity === "Not Started" && user?.leaveInfo?.length === 1) {
      return "";
    } else if (leaveInfo?.leave_duration === "second_half" && user?.status === "Away" && user?.activity === "Logout" && user?.leaveInfo?.length === 1) {
      return `(from ${moment(leaveInfo?.out_time, 'HH:mm:ss').format("hh:mm A")})`;
    } else if (leaveInfo?.leave_duration === "second_half" && user?.status === "Working" && user?.activity === "Work Log" && user?.WFHInfo?.length && user?.WFHInfo[0]?.leave_duration === "first_half") {
      return `(from ${moment(leaveInfo?.out_time, 'HH:mm:ss').format("hh:mm A")})`;
    } else if (!user?.leaveInfo?.length && user?.status === "Working" && user?.activity === "Work Log" && user?.WFHInfo?.length && user?.WFHInfo[0]?.leave_duration === "second_half") {
      return `(from ${moment(WFHInfo?.in_time, 'HH:mm:ss').format("hh:mm A")})`;
    } else if (user?.leaveInfo?.length && leaveInfo?.leave_duration === "second_half" && user?.status === "Working") {
      return `(from ${moment(leaveInfo?.out_time, 'HH:mm:ss').format("hh:mm A")})`;
    } else if (user?.leaveInfo?.length === 2) {
      return ""
    } else if (user?.WFHInfo?.length && user?.WFHInfo[0]?.leave_duration === "second_half") {
      return `(from ${moment(WFHInfo?.in_time, 'HH:mm:ss').format("hh:mm A")})`;
    } else if (user?.leaveInfo?.length && user?.leaveInfo[0]?.leave_duration === "second_half" && user?.activity === "Not Started" && user?.status === "Away") {
      return `(from ${moment(leaveInfo?.out_time, 'HH:mm:ss').format("hh:mm A")})`;
    } else {
      return `(since ${moment(user?.since).format("hh:mm A")})`
    }
  }

  const handleSortClick = (sort_by) => {
    setOrderBy(sort_by);
    setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const handleFilterChange = (e) => {
    let activeFilter = e.target.name;
    setActiveStatus(activeFilter);
  };

  let filteredStatus = allStatusList?.filter((value) => {
    if (activeStatus === "All") {
      return true;
    } else if (activeStatus === "Leave") {
      if (value?.status === "Leave") {
        if (value?.activity === "Work Log") {
          return false;
        } else {
          return true;
        }
      } else if (value?.status === "Away" && value?.activity === "Logout" && value?.leaveInfo?.length) {
        return true;
      } else {
        return false;
      }
    } else if (activeStatus === "Working") {
      if (value?.status === "Leave" && value?.activity === "Work Log") {
        return true;
      } else if (value?.status === "Working") {
        return true;
      } else {
        return false;
      }
    } else if (activeStatus === "Away") {
      if (value?.status === "Away" && !value?.leaveInfo?.length) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  });

  const sortedData = sortArrayByKey(filteredStatus, sortType, orderBy);

  return (
    <Box p={3} bgcolor="white" borderRadius={"4px"} sx={{ boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)' }}>
      <Box display={"flex"} justifyContent={"space-between"} mb={2}>
        <Box gap={1} display={"flex"} alignItems={"center"}>
          <Typography color={"secondary.main"} fontSize={18} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"}>Current Status</Typography>
          <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>({moment(new Date()).format("dddd, DD/MM/YYYY")})</Typography>
        </Box>
        <Grid>
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            color="secondary"
            fontSize={14}
          >
            {Object.entries(STATUS_ACTIVITY_REPORT)?.map((filter, index) => (
              <Button
                key={index}
                variant="outlined"
                size='medium'
                color={activeStatus === filter[0] ? "primary" : "secondary"}
                name={filter[0]}
                sx={{
                  textTransform: "none",
                  background: `${activeStatus === filter[0] ? "rgba(4, 127, 224, 0.1)" : "none"
                    }`,
                  borderColor: `${activeStatus === filter[0] ? "#047FE0 !important" : "none"
                    }`,
                  position: "relative",
                  zIndex: `${activeStatus === filter[0] ? "1" : "none"}`,
                  paddingLeft: { xs: '10px', md: '15px' },
                  paddingRight: { xs: '10px', md: '15px' }
                }}
                onClick={(e) => handleFilterChange(e)}
              >
                {titleCase(filter[1])}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Box>
      <Paper
        sx={{
          border: "1px solid #E0E0E0",
          borderRadius: "3px",
          boxShadow: 'none',
        }}>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                'th': {
                  color: "dark.800",
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: "24px",
                  letterSpacing: "0.17px",
                  padding: "5px 16px",
                  textAlign: "left",
                  background: "rgba(245, 248, 250, 1)",
                },
              }}
            >
              <TableRow>
                <TableCell sx={{ minWidth: 240 }}>
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? sortType : "asc"}
                    onClick={() => handleSortClick("name")}
                  >
                    Users
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ minWidth: 170 }}>
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? sortType : "asc"}
                    onClick={() => handleSortClick("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ minWidth: 300 }}>Activity</TableCell>
                <TableCell sx={{ minWidth: 275 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                'td': {
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: '20px',
                  letterSpacing: '0.17px',
                  padding: '10px 16px'
                },
                'tr:hover': {
                  background: '#F7F7F7',
                }
              }}
            >
              {isLoading?.pageLoading ?
                <TableRowSkeleton cols={4} rows={5} /> :
                sortedData?.length === 0 ?
                  <TableRow
                    style={{
                      height: 50,
                    }}
                  >
                    <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '0px !important', height: 300 }}>
                      <Typography fontSize={'15px'} fontWeight={500} sx={{ textAlign: 'center' }}>No Current Status Found of type <b>{titleCase(activeStatus)}</b></Typography>
                    </TableCell>
                  </TableRow> :
                  sortedData?.map((userStatus, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"} gap={"6px"} overflow={"hidden"}>
                          <BackgroundLetterAvatars
                            sx={{
                              height: 24,
                              width: 24,
                              maxWidth: 24,
                              fontSize: "85%"
                            }}
                            user={userStatus}
                          />
                          <Typography color={'dark.main'} fontSize={14} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.17px"} maxWidth={200} overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"}>
                            {getFullName(userStatus)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ borderLeft: '1px solid #F2F2F2' }}>
                        <Box display={"flex"} alignItems={"baseline"} gap={"6px"} justifyContent={"flex-start"}>
                          {showStatus(userStatus)}
                          <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>{getForDuration(userStatus)}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ borderLeft: '1px solid #F2F2F2' }} colSpan={isShowFutureEvent(userStatus) ? 1 : 2}>
                        <Box display={"flex"} flexWrap={"wrap"} gap={"6px"} alignItems={"baseline"}>
                          <Typography color={getActivityColor(userStatus)} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>{getActivity(userStatus)}</Typography>
                          <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"18px"} letterSpacing={"0.17px"} component={"div"}>{getSinceTime(userStatus)}</Typography>
                        </Box>
                      </TableCell>
                      {isShowFutureEvent(userStatus) ?
                        <TableCell sx={{ borderLeft: "2px solid #E0E0E0" }}>
                          <Box display={"flex"} flexWrap={"wrap"} gap={"6px"} alignItems={"baseline"}>
                            <Typography color={getFutureActivityColor(userStatus)} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>{getFutureActivity(userStatus)}</Typography>
                            <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"18px"} letterSpacing={"0.17px"}>{getFutureSinceTime(userStatus)}</Typography>
                          </Box>
                        </TableCell>
                        : null}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default CurrentStatusReport;
