import ICONS from "../constants/icons";
import URLS from "../routes/urls";

const ProjectsSidebarSettings = [
    {
        name: "Projects",
        icon: ICONS.Projects,
        href: URLS.Projects,
    }
];

export default ProjectsSidebarSettings;
