/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, tooltipClasses, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { useNavigate } from 'react-router-dom'
import { convertDateFromFormat, convertTime, formatDuration, getApplyDateTime, getDatesArray, getDisplayValue, getFormattedDate, getLeaveStatus, getStartDateBaseOnOnboarding, sortArrayByKey, specialRequestList, titleCase } from '../../../utils'
import axiosInstance from '../../../axios'
import API from '../../../axios/api'
import TableRowSkeleton from '../../../components/TableRowSkeleton'
import useAuthentication from '../../../hook/useAuthentication'
import Button from '../../../components/Button'
import { DateObject, getAllDatesInRange } from 'react-multi-date-picker'
import CircularLoader from '../../../components/CircularLoader'
import moment from 'moment'
import { useAlert } from '../../../hook/useAlert'
import { styled } from '@mui/styles'
import ReactApexChart from 'react-apexcharts'
import EmptyTable from '../../../components/EmptyTable'
import { CHART_COLOR, CHART_DOT_COLOR } from '../../../constants/default-values'
import MenuDateRangePicker from '../../../components/DateRangePicker/MenuDateRangePicker';
import CloseIcon from "@mui/icons-material/Close";
import emptySidebarImg from '../../../assets/images/empty-sidebar-img.svg'
import ICONS from '../../../constants/icons';
import * as MuiIcons from "@mui/icons-material";
import LaunchIcon from '@mui/icons-material/Launch';
import URLS from '../../../routes/urls';
import { motion } from "framer-motion";
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CelebrationIcon from '@mui/icons-material/Celebration';

const ProgressContainer = styled(Box)({
    display: 'flex',
    borderRadius: 10,
    overflow: 'hidden',
    marginTop: 8,
    gap: 6,
    width: '100%'
});

const ProgressSection = styled(Box)(({ theme, isWorking }) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 6,
    padding: 11,
    backgroundColor: isWorking
        ? 'rgba(4, 127, 224, 0.06)'
        : 'rgba(107, 107, 107, 0.08)'
}));

const DurationDot = styled(Box)(({ theme, isWorking }) => ({
    width: 13,
    height: 13,
    margin: 'auto',
    borderRadius: '50%',
    backgroundColor: isWorking
        ? theme.palette.primary.main
        : 'rgba(107, 107, 107, 1)'
}));

const TimeBox = styled(Box)(({ theme, type }) => ({
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'right',
    letterSpacing: '0.17px',
    width: 137,
    height: '100%',
}));

const DurationLabel = styled(Box)(({ theme, type, isLeave }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: type === "Work Log"
        ? 'rgba(217, 238, 255, 1)'
        : isLeave ? "#FFF4F4" : 'rgba(235, 235, 235, 1)',
    color: type === "Work Log"
        ? theme.palette.primary.main
        : isLeave ? "#000000DE" : 'rgba(107, 107, 107, 1)',
    fontSize: 11,
    fontWeight: 700,
    lineHeight: '16px',
    padding: '6px 2px',
    textAlign: "center",
    width: 21,
    minHeight: type === "Work Log" ? 36 : 36
}));

const TimeLabel = styled(Box)(({ theme, type, isLeave }) => ({
    flex: 1,
    borderRadius: '0 3px 3px 0',
    backgroundColor: type === "Work Log"
        ? 'rgba(4, 127, 224, 0.1)'
        : isLeave ? "#FFF4F4" : 'rgba(246, 246, 246, 1)',
    lineHeight: 1,
    padding: 4,
    height: '100%'
}));

const ActivityBox = styled(Box)(({ theme, type, isLeave, isHoliday = false, isWFH = false }) => ({
    borderRadius: 4,
    backgroundColor: type === "Work Log"
        ? 'rgba(245, 248, 250, 1)'
        : isLeave ? "#FFF4F4" : isHoliday ? 'rgba(183, 83, 255, 0.1)' : isWFH ? '#047FE01A' : 'rgba(246, 246, 246, 1)',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    minWidth: 240
}));

const IconBox = styled(Box)(({ theme, type }) => ({
    borderRadius: 4,
    backgroundColor: type === "Work Log"
        ? theme.palette.primary.main
        : 'rgba(107, 107, 107, 1)',
    width: 26,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
}));

const getIcon = (iconType) => {
    const IconComponent = MuiIcons[iconType];
    return IconComponent ? <IconComponent /> : <MuiIcons.Logout />;
};

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "16px",
        borderRadius: "5px",
        background: "#444444",
    },
}));


const frameVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            staggerChildren: 0.5,
            delayChildren: 0.25,
            ease: "linear",
        }
    }
};

const itemVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5,
            ease: "linear",
        }
    }
};


const InOutReport = () => {
    let navigate = useNavigate();
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const [isLoading, setIsLoading] = useState({
        users: true,
        report: false,
        summary: true,
    });
    let initialSummaryData = {
        startDate: null,
        endDate: null,
        results: [],
        user: null,
        working_days: 0,
        non_working_days: 0,
        cl_count: 0,
        sl_count: 0,
    };
    let onboardingDate = new Date(currentUser?.organization?.createdAt);
    let initialStartDate = getStartDateBaseOnOnboarding(new Date(new DateObject().toFirstOfMonth()), onboardingDate);
    const [summary, setSummary] = useState(initialSummaryData);
    const [sortType, setSortType] = useState("asc");
    const [orderBy, setOrderBy] = useState("date");
    const [dateRange, setDateRange] = useState([
        initialStartDate,
        new DateObject()
    ]);
    const [dateLimits, setDateLimits] = useState({
        minDate: new DateObject().toFirstOfYear(),
        maxDate: new DateObject()
    });
    const [queryParams, setQueryParams] = useState({
        startDate: initialStartDate.format("YYYY-MM-DD"),
        endDate: new DateObject().format("YYYY-MM-DD"),
        user_id: "",
        page: 0,
        perPage: 25,
    });
    const [errors, setErrors] = useState({});
    const [organizationUserList, setOrganizationUserList] = useState([]);
    const [isShowSidebar, setIsShowSidebar] = useState(false);
    const [activitySidebarData, setActivitySidebarData] = useState({});

    const compareDates = (date1Str, date2Str) => {
        function parseDate(dateStr) {
            const [day, month] = dateStr.split('/').map(Number);
            return new Date(new Date().getFullYear(), month - 1, day);
        }

        const date1 = parseDate(date1Str);
        const date2 = parseDate(date2Str);

        return date1 < date2;
    }

    const handleChartXAxisLabel = (...rest) => {
        const seriesElements = document.querySelectorAll('.apexcharts-xaxis-label');
        if (seriesElements?.length > 0) {
            let allLabels = [];
            seriesElements.forEach((element) => {
                allLabels.push({
                    id: element?.id,
                    text: element?.textContent?.slice(0, 5)
                });
            })
            let dateStrings = rest?.[0]?.config?.xaxis?.categories;
            if (dateStrings?.length > 0) {
                const dates = dateStrings?.map(dateStr => new Date(dateStr));
                const maxDate = new Date(Math.max(...dates));
                allLabels?.forEach(label => {
                    const result = compareDates(moment(maxDate).format("DD/MM"), label?.text);
                    if (result) {
                        let _label = document.querySelector(`#${label?.id}`);
                        _label.setAttribute("style", "display: none");
                    }
                })
            }
        }
    }

    const handleChartMounted = () => {
        const bars = document.querySelectorAll('.apexcharts-bar-area');
        bars.forEach(bar => {
            bar.style.borderRadius = '0';
            bar.style.clipPath = 'none';
        });
    }

    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            id: "my-stack-chart",
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
            },
            animations: {
                enabled: true,
                easing: 'linear',
                speed: 300,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
            },
            events: {
                updated: (chartContext, ...config) => {
                    handleChartXAxisLabel(...config);
                },
                mounted: () => { handleChartMounted() }
            },
        },
        toolbar: {
            autoSelected: 'zoom',
            export: {
                csv: {
                    filename: "in-out-report",
                    columnDelimiter: ',',
                    headerCategory: 'category',
                    headerValue: 'value',
                    categoryFormatter(x) {
                        return new Date(x).toDateString()
                    },
                    valueFormatter(y) {
                        return decimalToTime(y)
                    }
                },
                svg: {
                    filename: "in-out-report",
                },
                png: {
                    filename: "in-out-report",
                }
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        colors: CHART_COLOR,
        grid: {
            padding: {
                left: 15,
                right: 15
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                hideZeroBarsWhenGrouped: true,
                borderRadius: 4,
            },
        },
        xaxis: {
            type: 'datetime',
            categories: [],
            tickPlacement: 'on',
            labels: {
                format: 'dd/MM',
                showDuplicates: false,
                trim: true,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                formatter: function (val, index) {
                    return val > 0 ? `${val?.toString()?.padStart(2, "0")} h` : 0;
                }
            },
        },
        fill: {
            opacity: 1
        },
        noData: {
            text: "No data found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 10,
            offsetY: 125,
            style: {
                color: CHART_COLOR[1],
                fontSize: '22px',
            }
        },
        tooltip: {
            inverseOrder: true,
            followCursor: true,
            shared: false,
            x: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return moment(value)?.format("DD/MM")
                }
            },
            y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return decimalToTime(value);
                }
            }
        },
    })

    useEffect(() => {
        getUserSettingData();
    }, []);

    useEffect(() => {
        handleChartXAxisLabel();
    }, [options])

    const getUserSettingData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, users: true }))
            const response = await axiosInstance.get(
                `${API.organisationUserSetting(currentUser?.organization_id)}`
            );
            if (response.status === 200) {
                let sortedNames = sortArrayByKey(response?.data?.data)
                setOrganizationUserList(sortedNames);
                setQueryParams((prev) => ({
                    ...prev,
                    user_id: currentUser?.id,
                }));
                getSummary(currentUser?.id)
                setIsLoading((prev) => ({ ...prev, users: false }))
            } else {
                setIsLoading((prev) => ({ ...prev, users: false }))
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, users: false }))
        }
    };

    let getUser = (id) => {
        return organizationUserList?.find((value) => value?.id === id)
    };

    let getDates = () => {
        if (summary?.startDate === summary?.endDate)
            return <span style={{ fontWeight: "bold" }}>{getFormattedDate(summary?.startDate)}</span>
        else
            return <span style={{ fontWeight: "bold" }}>{getFormattedDate(summary?.startDate)} - {getFormattedDate(summary?.endDate)}</span>
    }

    const isShowDot = (value) => {
        if (value?.work_mode_label) {
            if (['casual leave', 'sick leave'].includes(value?.work_mode_label?.toLowerCase())) {
                return {
                    isShow: [true, true],
                    isMultiple: true
                }
            } else {
                return {
                    isShow: [true, false],
                    isMultiple: false
                }
            }
        } else {
            if (value?.is_holiday) {
                return {
                    isShow: [false, true],
                    isMultiple: false
                }
            }
            else if (value?.is_leave) {
                return {
                    isShow: value?.leave_duration === "full" ? [false, true] : [true, true],
                    isMultiple: value?.leave_duration !== "full"
                }
            }
        }
    }

    const convertLatestUserData = async (latestUserDailyTrackerData) => {
        try {
            let updatedTrackingData = [...latestUserDailyTrackerData];
            const convertSecondsToTime = async (given_seconds) => {
                let hours = Math.floor(given_seconds / 3600);
                let minutes = Math.floor((given_seconds - hours * 3600) / 60);
                let seconds = given_seconds - hours * 3600 - minutes * 60;

                let timeString =
                    hours.toString().padStart(2, "0") +
                    ":" +
                    minutes.toString().padStart(2, "0") +
                    ":" +
                    seconds.toString().padStart(2, "0");

                return timeString;
            };

            for (const item of updatedTrackingData) {
                if (typeof item.total === "number") {
                    item.total = await convertSecondsToTime(item.total);
                    item.working_hrs = await convertSecondsToTime(item.working_hrs);
                    item.non_working_hrs = await convertSecondsToTime(item.non_working_hrs);

                    const loginTime = item.user_activities?.filter(
                        (key) => key?.log_type === "login"
                    );
                    const logoutTime = item.user_activities?.filter(
                        (key) => key?.log_type === "logout"
                    );

                    if (loginTime.length > 0) {
                        item.loginInDate = moment(loginTime[0]?.createdAt).format("DD/MM/YYYY");
                        item.loginInTime = moment(loginTime[0]?.createdAt).format("hh:mm A");
                    }

                    if (logoutTime.length > 0) {
                        item.loginOutDate = moment(logoutTime[logoutTime.length - 1]?.createdAt).format("DD/MM/YYYY");
                        item.logoutTime = moment(logoutTime[logoutTime.length - 1]?.createdAt).format("hh:mm A");
                    } else {
                        item.loginOutDate = moment(item.date).format("DD/MM/YYYY");
                    }
                    item.date = moment(item.date).format("DD/MM/YYYY");
                } else {
                    item.date = moment(item.date).format("DD/MM/YYYY");
                    item.loginInDate = item.date;
                    item.loginOutDate = item.date;
                }
            }

            return updatedTrackingData;
        } catch (error) {
            console.error("Error converting latestUserDailyTrackerData", error);
        }
    };

    const findIsLeaveDayExist = (leaveApp, date) => {
        let isLeaveExist = false;
        leaveApp?.map((leaveDetails) => {
            leaveDetails?.leave_days?.map(leaveData => {
                if (leaveData?.leave_date === date) {
                    isLeaveExist = true;
                    return true;
                }
                return leaveData;
            })
            return leaveDetails
        })
        return isLeaveExist
    }

    const findIsWFHDayExist = (wfhApp, date) => {
        let isWfhExistName = null;
        wfhApp?.map((wfhDetails) => {
            wfhDetails?.WfhDays?.map(wfhData => {
                if (wfhData?.date === date) {
                    isWfhExistName = specialRequestList?.find(({ id }) => id === wfhDetails?.static_id)?.name;
                    return isWfhExistName;
                }
                return wfhData;
            })
            return wfhDetails
        })
        return isWfhExistName
    }

    const findLeaveDataByForActivitySidebar = (leaveData, date) => {
        const result = leaveData?.reduce((acc, leave) => {
            const matchedDay = leave.leave_days.find((day) => day.leave_date === date);
            if (matchedDay) {
                return {
                    ...matchedDay,
                    user_id: leave?.user_id,
                    leaveType: leave?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name,
                    status: leave?.status
                }
            }
            return acc;
        }, null);

        return result;
    };

    const findHolidayDataByForActivitySidebar = (holidayData, date) => {
        const result = holidayData?.find((data, index) => data.date === date);
        return result ?? null;
    };
    const findWfhDateByForActivitySidebar = (wfhData, date) => {
        const result = wfhData?.reduce((acc, wfh) => {
            const matchedDay = wfh.WfhDays.find((day) => day?.date === date);
            if (matchedDay) {
                return {
                    ...wfh,
                    ...matchedDay,
                    user_id: wfh?.user_id,
                    leaveType: wfh?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name,
                    status: wfh?.status
                }
            }
            return acc;
        }, null);
        return result;
    }

    const handleAddFromNowTimeOnToday = (dailyActivityParam) => {
        const dailyActivityData = JSON.parse(JSON.stringify(dailyActivityParam));
        const today = new Date();
        const formattedToday = today.toLocaleDateString("en-GB");

        const dailyActivityIndex = dailyActivityData.findIndex(item => item.date === formattedToday);

        if (dailyActivityIndex !== -1) {
            const todayData = dailyActivityData[dailyActivityIndex];

            if (todayData && todayData.user_activities?.length > 0) {
                const lastActivity = todayData.user_activities[todayData.user_activities.length - 1];

                if (["checkin", "login"].includes(lastActivity.log_type)) {
                    const lastActivityTime = new Date(lastActivity.createdAt);
                    const currentTime = new Date();

                    const durationDifference = currentTime - lastActivityTime;

                    const existingTotalMs = parseTimeToMs(todayData.total);
                    const existingWorkingHrsMs = parseTimeToMs(todayData.working_hrs);

                    const updatedTotal = formatMsToTime(existingTotalMs + durationDifference);
                    const updatedWorkingHrs = formatMsToTime(existingWorkingHrsMs + durationDifference);

                    todayData.total = updatedTotal;
                    todayData.working_hrs = updatedWorkingHrs;
                }
            }

            dailyActivityData.splice(dailyActivityIndex, 1, todayData);
        }

        return dailyActivityData;
    };

    const parseTimeToMs = (time) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return (hours * 3600 + minutes * 60 + seconds) * 1000;
    };

    const formatMsToTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0");
        const seconds = String(totalSeconds % 60).padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
    };


    const getSummary = async (userId = null) => {
        try {
            setIsLoading((prev) => ({ ...prev, report: true }));
            let body = {
                startDate: queryParams?.startDate,
                endDate: queryParams?.endDate,
                user_id: userId ?? queryParams?.user_id,
            }
            const response = await axiosInstance.post(API.getInOutReport, body);
            if (response.status === 200) {
                let nonWorking = response.data?.data?.nonWorking;
                let leaveInfo = response.data?.data?.leaveInfo;
                let wfhDaysInfo = response.data?.data?.userWFHData;
                let holidaysData = response?.data?.data?.customData;

                let userDailyTrackerData = await convertLatestUserData(response.data?.data?.userDailyTrackerData);
                let dates = getDatesArray(queryParams.startDate, queryParams.endDate);
                let workingDays = dates?.filter((value) => !nonWorking.includes(value));
                let sortedData = sortArrayByKey([...userDailyTrackerData] || [], "asc", "date");
                const addRealTimeInToday = handleAddFromNowTimeOnToday(sortedData);
                let updatedSortedDate = addRealTimeInToday?.map((userTrackerData) => {
                    return {
                        ...userTrackerData,
                        is_leave: findIsLeaveDayExist(leaveInfo, convertDateFromFormat(userTrackerData?.date, "YYYY-MM-DD", "DD/MM/YYYY")),
                        is_holiday: nonWorking?.includes(convertDateFromFormat(userTrackerData?.date, "YYYY-MM-DD", "DD/MM/YYYY")),
                        is_wfh: findIsWFHDayExist(wfhDaysInfo, convertDateFromFormat(userTrackerData?.date, "YYYY-MM-DD", "DD/MM/YYYY")),
                        leaveData: findLeaveDataByForActivitySidebar(leaveInfo, convertDateFromFormat(userTrackerData?.date, "YYYY-MM-DD", "DD/MM/YYYY")),
                        holidayData: findHolidayDataByForActivitySidebar(holidaysData, convertDateFromFormat(userTrackerData?.date, "YYYY-MM-DD", "DD/MM/YYYY")),
                        wfhData: findWfhDateByForActivitySidebar(wfhDaysInfo, convertDateFromFormat(userTrackerData?.date, "YYYY-MM-DD", "DD/MM/YYYY")),
                    }
                })

                const getLeaveCount = (type = "Casual Leave") => {
                    let totalCount = leaveInfo?.filter((value) => value?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.toLowerCase() === type?.toLowerCase())?.reduce((previousValue, currentValue) => {
                        return previousValue + currentValue?.days
                    }, 0);
                    return totalCount;
                };

                setSummary({
                    results: updatedSortedDate,
                    user: getUser(queryParams?.user_id),
                    startDate: queryParams.startDate,
                    endDate: queryParams.endDate,
                    working_days: workingDays?.length || 0,
                    non_working_days: nonWorking?.length || 0,
                    cl_count: getLeaveCount("Casual Leave"),
                    sl_count: getLeaveCount("Sick Leave"),
                });

                const transformedData = updatedSortedDate?.map(item => ({
                    date: item.date,
                    working_hrs: timeToDecimal(item?.working_hrs),
                    non_working_hrs: timeToDecimal(item?.non_working_hrs),
                    wfh: !!item?.is_wfh,
                    is_leave: item?.is_leave,
                    is_holiday: item?.is_holiday
                }));

                const xAxisData = transformedData?.map(item => {
                    const [day, month, year] = item?.date?.split('/').map(Number);
                    const date = new Date(Date.UTC(year, month - 1, day));
                    const formattedDate = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()} GMT`;
                    return formattedDate;
                });
                const workingHoursData = transformedData?.map(item => item?.working_hrs);
                const nonWorkingHoursData = transformedData?.map(item => item?.non_working_hrs);

                setSeries([
                    {
                        name: 'Working hours',
                        data: workingHoursData,
                    }, {
                        name: 'Non-working',
                        data: nonWorkingHoursData,
                    }
                ]);
                let generatePoints = (daysDataArray) => {
                    return daysDataArray?.map(({ date, wfh, is_leave, is_holiday }) => {
                        let [DD, MM, YYYY] = date?.split("/");
                        const _date = new Date(Date.UTC(YYYY, MM - 1, DD));

                        // Build the text and styles dynamically for multiple conditions
                        const annotations = [];
                        if (wfh) annotations.push({ text: "R", color: "#047FE0" });
                        if (is_leave) annotations.push({ text: "L", color: "#E75252" });
                        if (is_holiday) annotations.push({ text: "H", color: "#B753FF" });
                        const spacing = 25;
                        return annotations.map((annotation, i) => ({
                            x: _date.getTime(),
                            marker: {
                                size: 0,
                                fillColor: "#fff",
                                strokeColor: "#fff",
                                strokeWidth: 0,
                            },
                            label: {
                                borderColor: annotation.color,
                                text: annotation.text,
                                borderRadius: "34px",
                                offsetX: 0,
                                offsetY: 10 + i * spacing,
                                textAnchor: 'middle',
                                style: {
                                    background: annotation.color,
                                    color: "#FFFFFF",
                                    fontSize: "12px",
                                    fontWeight: 800,
                                    fontFamily: "Inter",
                                    cssClass: 'report-badges',
                                    padding: {
                                        left: 5,
                                        right: 5,
                                        top: 0,
                                        bottom: 2,
                                    }
                                },
                            },
                        }));
                    }).flat(); // Flatten the array to combine annotations for the same date
                };

                setOptions((prev) => ({
                    ...prev,
                    xaxis: { ...prev.xaxis, categories: xAxisData },
                    annotations: {
                        points: generatePoints(transformedData)
                    }
                }))
                setQueryParams((prev) => ({
                    ...prev,
                    page: 0,
                    perPage: 25,
                }))
                setIsLoading((prev) => ({ ...prev, report: false }));
            } else {
                setSummary(initialSummaryData)
                setIsLoading((prev) => ({ ...prev, report: false }));
                showAlert(response.data.message, "error");
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, report: false }));
            // showAlert(error.response.data.message, "error");
            setSummary(initialSummaryData);
        }
    };

    const handleSortClick = (sort_by) => {
        setOrderBy(sort_by);
        setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
    };

    // const handleChangePage = (event, newPage) => {
    //     setQueryParams((prev) => ({
    //         ...prev,
    //         page: newPage,
    //     }));
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setQueryParams((prev) => ({
    //         ...prev,
    //         perPage: event.target.value,
    //         page: 0,
    //     }));
    // };

    const handleChangeDatePicker = (dateObjects) => {
        let allDates = getAllDatesInRange(dateObjects);
        let start_date = convertDateFromFormat(new Date(allDates[0]));
        let end_date = convertDateFromFormat(new Date(allDates[allDates?.length - 1]));
        setQueryParams((prev) => ({
            ...prev,
            startDate: start_date,
            endDate: end_date,
        }));
        setDateRange(dateObjects);

        let startDate = new Date(dateObjects[0]);
        let endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 89);
        if (endDate >= new Date()) {
            endDate = new Date();
            endDate.setDate(new Date().getDate());
        }
        setDateLimits({
            minDate: startDate,
            maxDate: endDate,
        });
    }

    const validateDate = (object) => {
        let errors = []
        if (!object?.user_id) {
            errors['user_id'] = "Required";
        } else {
            errors['user_id'] = "";
        }
        if (!object?.startDate) {
            errors['startDate'] = "Date is required";
        } else {
            errors['startDate'] = "";
        }

        setErrors((prev) => ({ ...prev, ...errors }));
        return Object.values(errors)?.filter((value) => !!value)?.length > 0;
    };

    const handleBlur = (name, value) => {
        if (!value) {
            setErrors((prev) => ({ ...prev, [name]: "Required" }));
        } else {
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }
    }

    const handleSearch = () => {
        setIsLoading((prev) => ({ ...prev, report: true }));
        if (!validateDate(queryParams)) {
            getSummary();
        } else {
            setIsLoading((prev) => ({ ...prev, report: false }));
        }
    };

    function timeToDecimal(timeString) {
        let [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
        let decimalHours = hours + minutes / 60 + seconds / 3600;
        return decimalHours > 0 ? decimalHours.toFixed(2) : null;
    }

    function decimalToTime(decimalHours) {
        let hours = Math.floor(decimalHours);
        let minutes = Math.round((decimalHours - hours) * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    const visibleRows = sortArrayByKey(summary?.results || [], sortType, orderBy);

    useEffect(() => {
        const seriesElements = document.querySelectorAll('.apexcharts-series');
        const seriesDataLength = seriesElements[0]?.querySelectorAll('.apexcharts-bar-area')?.length;

        for (let i = 0; i < seriesDataLength; i++) {
            const lastElements = [];

            seriesElements.forEach((series) => {
                const bar = series.querySelectorAll('.apexcharts-bar-area')[i];
                if (bar && bar.getAttribute('barHeight') !== '0') {
                    lastElements.push(bar);
                }
            })

            const lastElement = lastElements[lastElements.length - 1];

            if (lastElement) {
                lastElement.setAttribute('clip-path', 'inset(0% 0% -100% 0% round 10px)');
            }
        }
    }, [series]);

    const handleSelectActivityDay = (reportData) => {
        setIsShowSidebar(true);
        handleReformedActivityDataforSidebar(reportData);
    };

    const handleReformedActivityDataforSidebar = (reportData) => {
        if (reportData?.user_activities) {
            const leaveData = reportData?.leaveData;
            const userActivities = JSON.parse(JSON.stringify(reportData?.user_activities)) || [];
            const is_wfh = !!reportData?.is_wfh?.length;

            const handleCalculateDurationForTillWokring = (activityStartTime) => {
                const startTime = moment(activityStartTime);
                const currentTime = moment();
                return currentTime.diff(startTime, "seconds");
            }

            const handleCalculateHeightBasedOnDuration = (durationOfSeconds) => {
                const durationInMinutes = Math.ceil(durationOfSeconds / 60);
                const defaultHeight = 36;

                if (durationInMinutes <= 30) {
                    return `${defaultHeight}px`;
                } else {
                    const extraHeight = durationInMinutes - 30;
                    return `${defaultHeight + extraHeight}px`;
                }
            };

            const userActivityData = [];
            if (leaveData?.leave_duration === "first_half") {
                const leaveInfo = {
                    ...leaveData,
                    is_wfh,
                    isLeave: true,
                };
                userActivityData.push(leaveInfo);
            }
            userActivities.forEach((activity, index) => {
                const isLogin = activity?.log_type === "login";
                const isLogout = activity?.log_type === "logout";
                const isCheckout = activity?.log_type === "checkout";
                const isCheckin = activity?.log_type === "checkin";

                const activityStartTime = activity?.createdAt;
                const activityEndTime = userActivities[index + 1]?.createdAt || null;
                const isTillNow = !activityEndTime;
                const activityDuration = isTillNow ? handleCalculateDurationForTillWokring(activityStartTime) : activity?.duration;
                const hieghtForDuration = handleCalculateHeightBasedOnDuration(activityDuration);
                const duration = formatDuration(activityDuration);

                if (isLogout && index !== 0) {
                    const prevActivityIndex = userActivityData?.findIndex((userActivity) => userActivity?.id === userActivities[index - 1]?.id);
                    let prevActivityData = userActivityData[prevActivityIndex];

                    prevActivityData = {
                        ...prevActivityData,
                        isLogout: true,
                        is_wfh,
                        logoutTime: activity?.createdAt,
                    };
                    userActivityData.splice(prevActivityIndex, 1, prevActivityData);
                } else if (isLogin) {
                    const loginActivity = {
                        ...activity,
                        isLogin,
                        isLogout: false,
                        duration,
                        hieghtForDuration,
                        activityStartTime,
                        activityEndTime,
                        isTillNow,
                        is_wfh,
                    };
                    userActivityData.push(loginActivity);
                } else if (isCheckout) {
                    const checkOutActivity = {
                        ...activity,
                        isLogin: false,
                        isLogout: false,
                        duration,
                        hieghtForDuration,
                        activityStartTime,
                        activityEndTime,
                        isTillNow,
                        is_wfh,
                    };
                    userActivityData.push(checkOutActivity);
                } else if (isCheckin) {
                    const checkInActivity = {
                        ...activity,
                        isLogin: false,
                        isLogout: false,
                        duration,
                        hieghtForDuration,
                        activityStartTime,
                        activityEndTime,
                        isTillNow,
                        is_wfh,
                    };
                    userActivityData.push(checkInActivity);
                }
            });
            if (leaveData?.leave_duration === "second_half") {
                const leaveInfo = {
                    ...leaveData,
                    isLeave: true,
                    is_wfh,
                };
                userActivityData.push(leaveInfo);
            }
            setActivitySidebarData({ ...reportData, user_activities: userActivityData });
            setIsLoading((prev) => ({ ...prev, summary: false }));
        } else {
            setActivitySidebarData(reportData);
            setIsLoading((prev) => ({ ...prev, summary: false }));
        }
    };

    const handleLeaveClick = (user_id, leave_application_id) => {
        navigate(`${currentUser?.id === user_id ? URLS.ViewMyLeaveApplication : URLS.OtherLeaveDetails}/${user_id}/${leave_application_id}`);
    };

    const handleWFHClick = (user_id, wfh_application_id) => {
        navigate(`${currentUser?.id === user_id ? URLS.ViewMyWfhApplication : URLS.OtherWfhApplicationDetails}/${user_id}/${wfh_application_id}`);
    };

    const labelForLeaveDuration = (leave_duration) => {
        switch (leave_duration) {
            case "full":
                return <Typography
                    color={"#000000DE"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"14px"}
                    letterSpacing={"0.15px"}
                    className='time'
                    sx={{ transition: 'all 0.25s ease-in-out' }}
                >
                    Full Day Leave
                </Typography>;
            case "first_half":
                return <Typography
                    color={"#000000DE"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"14px"}
                    letterSpacing={"0.15px"}
                    className='time'
                    sx={{ transition: 'all 0.25s ease-in-out' }}
                >
                    1<sup>st</sup> Half Leave
                </Typography>;
            case "second_half":
                return <Typography
                    color={"#000000DE"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"14px"}
                    letterSpacing={"0.15px"}
                    className='time'
                    sx={{ transition: 'all 0.25s ease-in-out' }}
                >
                    2<sup>nd</sup> Half Leave
                </Typography>;
            default:
                return <Typography
                    color={"#000000DE"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"14px"}
                    letterSpacing={"0.15px"}
                    className='time'
                    sx={{ transition: 'all 0.25s ease-in-out' }}
                >
                    Full Day Leave
                </Typography>;
        }
    }

    const labelForWfhDuration = (wfh_duration) => {
        switch (wfh_duration) {
            case "full":
                return <Typography
                    variant="body2"
                    fontWeight={500}
                    fontSize={13}
                    color={'dark.600'}
                    lineHeight={'20.8px'}
                    letterSpacing={'0.15px'}
                >
                    - Full Day
                </Typography>;
            case "first_half":
                return <Typography
                    variant="body2"
                    fontWeight={500}
                    fontSize={13}
                    color={'dark.600'}
                    lineHeight={'20.8px'}
                    letterSpacing={'0.15px'}
                >
                    1<sup>st</sup> Half
                </Typography>;
            case "second_half":
                return <Typography
                    variant="body2"
                    fontWeight={500}
                    fontSize={13}
                    color={'dark.600'}
                    lineHeight={'20.8px'}
                    letterSpacing={'0.15px'}
                >
                    2<sup>nd</sup> Half
                </Typography>;
            default:
                return <Typography
                    variant="body2"
                    fontWeight={500}
                    fontSize={13}
                    color={'dark.600'}
                    lineHeight={'20.8px'}
                    letterSpacing={'0.15px'}
                >
                    Full Day
                </Typography>;
        }
    }

    const DrawerActivityData = (
        <Box
            sx={{ width: 520 }}
            display={"flex"}
            flexDirection={"column"}
            overflow={"hidden"}
            role="presentation"
            flex={1}
        >
            <Grid
                item
                py={1.5}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ top: "0", zIndex: "1" }}
            >
                <Box>
                    <Typography
                        display={"inline"}
                        fontSize={16}
                        fontWeight={500}
                        sx={{ lineHeight: "22px", mr: "5px" }}
                    >
                        <b>{organizationUserList.find((user) => user?.id === queryParams?.user_id)?.name}‘s</b> Activities for
                    </Typography>
                    <Typography
                        display={"inline"}
                        fontSize={16}
                        fontWeight={700}
                        mr={1}
                        sx={{ lineHeight: "22px" }}
                    >{activitySidebarData?.loginInDate}</Typography>
                    {activitySidebarData?.loginInDate === moment().format("DD/MM/YYYY") ?
                        <Typography
                            display={"inline"}
                            fontSize={14}
                            fontWeight={600}
                            sx={{ lineHeight: "22px", fontStyle: "italic" }}
                            color={"#05B01B"}
                        >Today</Typography> : null}
                </Box>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className="edit-btn"
                        onClick={() => setIsShowSidebar(false)}
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            {isLoading.summary ? <CircularLoader height="500px" /> : null}
            {(((!activitySidebarData?.user_activities?.length &&
                activitySidebarData?.leaveData) ||
                (activitySidebarData?.user_activities?.length &&
                    activitySidebarData?.leaveData &&
                    activitySidebarData?.leaveData?.leave_duration === "full"
                )) && (activitySidebarData?.total && activitySidebarData?.total === "00:00:00")) ?
                <Box width={"100%"} p={'24px'}>
                    <Box
                        display="flex"
                        gap={1.5}
                        mt={"5px"}
                    >
                        <TimeBox>
                            <DurationLabel isLeave={true} className='duration-box' sx={{ transition: 'all 0.25s ease-in-out', minHeight: 49, background: '#FAE8E8 !important' }}>
                                <Typography
                                    fontSize={11}
                                    fontWeight={700}
                                    sx={{ lineHeight: "15px", transform: 'rotate(180deg)', writingMode: "vertical-lr", display: "flex", textWrap: "nowrap", transition: 'all 0.25s ease-in-out' }}
                                    className='duration'
                                >
                                    Leave
                                </Typography>
                            </DurationLabel>
                            <Box flex={1}>
                                <TimeLabel isLeave={true} className='time-box' sx={{ transition: 'all 0.25s ease-in-out' }} display={"flex"} flexDirection={"column"}>
                                    {labelForLeaveDuration(activitySidebarData?.leaveData?.leave_duration)}
                                </TimeLabel>
                            </Box>
                        </TimeBox>
                        <Box display={"flex"} flex={1} flexDirection={"column"}>
                            <ActivityBox isLeave={true} sx={{ minHeight: 49, py: "11px", borderRadius: '0px' }}>
                                <Box display={"flex"} gap={1} px={"11px"} alignItems={"center"} width="100%" className="worktype-box" sx={{ transition: 'all 0.25s ease-in-out' }}>
                                    <Typography height={24} width={24} color={"white"} maxWidth={24} minWidth={24} borderRadius={"50%"} display={"flex"} alignItems={"center"} justifyContent={"center"}
                                        sx={{
                                            fontSize: 13,
                                            fontWeight: 800,
                                            lineHeight: '15px',
                                            background: `${activitySidebarData?.leaveData ? "#E75252" : "#B753FF"}`
                                        }}
                                    >
                                        {activitySidebarData?.leaveData ? "L" : "H"}
                                    </Typography>
                                    <Box
                                        sx={{
                                            'div': {
                                                background: '#ffffff',
                                                margin: 0,
                                            },
                                            'span': {
                                                padding: '3px 14px',
                                            },
                                        }}
                                    >
                                        {getLeaveStatus(activitySidebarData?.leaveData?.status, "chip")}
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        fontWeight={700}
                                        fontSize={17}
                                        lineHeight={"27px"}
                                        letterSpacing={"0.15px"}
                                        color={"dark.800"}
                                    >
                                        {activitySidebarData?.leaveData?.leaveType}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => handleLeaveClick(activitySidebarData?.leaveData?.user_id, activitySidebarData?.leaveData?.leave_application_id)}
                                    >
                                        <LaunchIcon
                                            className="launchIcon"
                                            sx={{
                                                fontSize: 16,
                                                marginLeft: '4px',
                                                color: '#047FE0',
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </ActivityBox>
                        </Box>
                    </Box>
                </Box> : null}
            {activitySidebarData?.user_activities?.length ?
                <Box
                    sx={{ height: "100%", visibility: "visible", padding: "24px" }}
                    flex={1}
                    overflow={"auto"}
                >
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle2" color="dark.800" fontSize={16} fontWeight={600} lineHeight={"22px"} letterSpacing={"0.15px"}>
                                Total Duration
                            </Typography>
                            <Typography variant="subtitle1" color="dark.800" fontSize={16} fontWeight={700} lineHeight={"26px"} letterSpacing={"0.15px"}>
                                {moment(activitySidebarData?.total, "HH:mm:ss").format("HH:mm")} Hours
                            </Typography>
                        </Box>
                        {
                            ((activitySidebarData?.total && activitySidebarData?.total !== "00:00:00") && ((activitySidebarData?.leaveData && activitySidebarData?.leaveData?.leave_duration === "full") ? activitySidebarData?.leaveData?.leave_duration === "full" : activitySidebarData?.is_holiday)) ?
                                (
                                    <ActivityBox isHoliday={!!!activitySidebarData?.leaveData} isLeave={!!activitySidebarData?.leaveData} sx={{ minHeight: 49, p: "11px", flexDirection: "column", marginTop: '8px', borderRadius: '6px' }}>
                                        <Box display="flex" alignItems={"center"} width="100%" className="worktype-box" borderRadius={"4px"} sx={{ transition: 'all 0.25s ease-in-out' }}>
                                            <Typography height={24} width={24} color={"white"} maxWidth={24} minWidth={24} mr={1} borderRadius={"50%"} display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                sx={{
                                                    fontSize: 13,
                                                    fontWeight: 800,
                                                    lineHeight: '15px',
                                                    background: `${!activitySidebarData?.is_holiday ? "#E75252" : "#B753FF"}`
                                                }}
                                            >{!activitySidebarData?.is_holiday ? "L" : "H"}</Typography>
                                            <Box
                                                sx={{
                                                    'div': {
                                                        background: '#ffffff',
                                                    },
                                                    'span': {
                                                        padding: '3px 14px',
                                                    },
                                                }}
                                            >
                                                {!activitySidebarData?.is_holiday ? getLeaveStatus(activitySidebarData?.leaveData?.status, "chip") : null}
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                className="work-type-info"
                                                sx={{ transition: 'all 0.25s ease-in-out' }}
                                            >
                                                {<Typography
                                                    variant="body2"
                                                    fontWeight={700}
                                                    fontSize={17}
                                                    color={'dark.800'}
                                                    lineHeight={"27px"}
                                                    letterSpacing={"0.15px"}
                                                    mr={0.5}
                                                >
                                                    {!activitySidebarData?.is_holiday ? activitySidebarData?.leaveData?.leaveType : `Its Holiday ${activitySidebarData?.holidayData?.id ? ` / ${titleCase(activitySidebarData?.holidayData?.name)}` : ''}`}
                                                </Typography>}
                                                {!activitySidebarData?.is_holiday ? <Typography
                                                    variant="body2"
                                                    fontWeight={500}
                                                    fontSize={13}
                                                    color={'dark.600'}
                                                    lineHeight={'20.8px'}
                                                    letterSpacing={'0.15px'}
                                                >
                                                    - Full Day
                                                </Typography> : null}
                                                {!activitySidebarData?.is_holiday ? <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                    onClick={() => handleLeaveClick(activitySidebarData?.leaveData?.user_id, activitySidebarData?.leaveData?.leave_application_id)}
                                                >
                                                    <LaunchIcon
                                                        className="launchIcon"
                                                        sx={{
                                                            fontSize: 16,
                                                            marginLeft: '4px',
                                                            color: '#047FE0',
                                                        }}
                                                    />
                                                </Box> : null}
                                            </Box>
                                        </Box>
                                        <ProgressContainer>
                                            <ProgressSection isWorking={true}>
                                                <Box display="flex" alignItems="center" width={27} height={46}>
                                                    <DurationDot isWorking={true} />
                                                </Box>
                                                <Box>
                                                    <Box display="flex" alignItems="baseline" gap={0.5}>
                                                        <Typography variant="h5" color="primary" fontSize={28} fontWeight={500} lineHeight={"44px"} letterSpacing={"0.15px"}>
                                                            {moment(activitySidebarData?.working_hrs, "HH:mm:ss").format("HH:mm")}
                                                        </Typography>
                                                        <Typography variant="body2" color="dark.600" fontSize={16} fontWeight={500} lineHeight={"30px"} letterSpacing={"0.17px"}>
                                                            ({convertTime(activitySidebarData?.working_hrs, activitySidebarData?.total)?.percentage})
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="caption" color="text.secondary" fontSize={11} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>
                                                        Working Hours
                                                    </Typography>
                                                </Box>
                                            </ProgressSection>
                                            <ProgressSection>
                                                <Box display="flex" alignItems="center" width={27} height={46}>
                                                    <DurationDot />
                                                </Box>
                                                <Box>
                                                    <Box display="flex" alignItems="baseline" gap={0.5}>
                                                        <Typography variant="h5" color="text.primary" fontSize={28} fontWeight={500} lineHeight={"44px"} letterSpacing={"0.15px"}>
                                                            {moment(activitySidebarData?.non_working_hrs, "HH:mm:ss").format("HH:mm")}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight={500} lineHeight={"30px"} letterSpacing={"0.17px"}>
                                                            ({convertTime(activitySidebarData?.non_working_hrs, activitySidebarData?.total)?.percentage})
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="caption" color="text.secondary" fontSize={11} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>
                                                        Non-working Hours
                                                    </Typography>
                                                </Box>
                                            </ProgressSection>
                                        </ProgressContainer>
                                    </ActivityBox>
                                ) :
                                (
                                    (activitySidebarData?.is_wfh?.length && ["1", "2"]?.includes(activitySidebarData?.wfhData?.static_id)) ?
                                        <ActivityBox isHoliday={false} isLeave={false} isWFH={true} sx={{ minHeight: 49, p: "11px", flexDirection: "column", marginTop: '8px', borderRadius: '6px' }}>
                                            <Box display="flex" alignItems={"center"} width="100%" className="worktype-box" borderRadius={"4px"} sx={{ transition: 'all 0.25s ease-in-out' }}>
                                                <Typography height={24} width={24} color={"white"} maxWidth={24} minWidth={24} mr={1} borderRadius={"50%"} display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                    sx={{
                                                        fontSize: 13,
                                                        fontWeight: 800,
                                                        lineHeight: '15px',
                                                        background: "#047FE0"
                                                    }}
                                                >{"R"}</Typography>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    className="work-type-info"
                                                    sx={{ transition: 'all 0.25s ease-in-out' }}
                                                >
                                                    {<Typography
                                                        variant="body2"
                                                        fontWeight={700}
                                                        fontSize={17}
                                                        color={'dark.800'}
                                                        lineHeight={"27px"}
                                                        letterSpacing={"0.15px"}
                                                        mr={0.5}
                                                    >
                                                        {"Remote Work"}
                                                    </Typography>}
                                                    {!!activitySidebarData?.wfhData ? labelForWfhDuration(activitySidebarData?.wfhData?.leave_duration) : null}
                                                    {!!activitySidebarData?.wfhData ? <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                            textDecoration: 'underline',
                                                        }}
                                                        onClick={() => handleWFHClick(queryParams?.user_id, activitySidebarData?.wfhData?.id)}
                                                    >
                                                        <LaunchIcon
                                                            className="launchIcon"
                                                            sx={{
                                                                fontSize: 16,
                                                                marginLeft: '4px',
                                                                color: '#047FE0',
                                                            }}
                                                        />
                                                    </Box> : null}
                                                </Box>
                                            </Box>
                                            <ProgressContainer>
                                                <ProgressSection isWorking={true}>
                                                    <Box display="flex" alignItems="center" width={27} height={46}>
                                                        <DurationDot isWorking={true} />
                                                    </Box>
                                                    <Box>
                                                        <Box display="flex" alignItems="baseline" gap={0.5}>
                                                            <Typography variant="h5" color="primary" fontSize={28} fontWeight={500} lineHeight={"44px"} letterSpacing={"0.15px"}>
                                                                {moment(activitySidebarData?.working_hrs, "HH:mm:ss").format("HH:mm")}
                                                            </Typography>
                                                            <Typography variant="body2" color="dark.600" fontSize={16} fontWeight={500} lineHeight={"30px"} letterSpacing={"0.17px"}>
                                                                ({convertTime(activitySidebarData?.working_hrs, activitySidebarData?.total)?.percentage})
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant="caption" color="text.secondary" fontSize={11} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>
                                                            Working Hours
                                                        </Typography>
                                                    </Box>
                                                </ProgressSection>
                                                <ProgressSection>
                                                    <Box display="flex" alignItems="center" width={27} height={46}>
                                                        <DurationDot />
                                                    </Box>
                                                    <Box>
                                                        <Box display="flex" alignItems="baseline" gap={0.5}>
                                                            <Typography variant="h5" color="text.primary" fontSize={28} fontWeight={500} lineHeight={"44px"} letterSpacing={"0.15px"}>
                                                                {moment(activitySidebarData?.non_working_hrs, "HH:mm:ss").format("HH:mm")}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight={500} lineHeight={"30px"} letterSpacing={"0.17px"}>
                                                                ({convertTime(activitySidebarData?.non_working_hrs, activitySidebarData?.total)?.percentage})
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant="caption" color="text.secondary" fontSize={11} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>
                                                            Non-working Hours
                                                        </Typography>
                                                    </Box>
                                                </ProgressSection>
                                            </ProgressContainer>
                                        </ActivityBox> :
                                        <ProgressContainer>
                                            <ProgressSection isWorking={true}>
                                                <Box display="flex" alignItems="center" width={27} height={46}>
                                                    <DurationDot isWorking={true} />
                                                </Box>
                                                <Box>
                                                    <Box display="flex" alignItems="baseline" gap={0.5}>
                                                        <Typography variant="h5" color="primary" fontSize={28} fontWeight={500} lineHeight={"44px"} letterSpacing={"0.15px"}>
                                                            {moment(activitySidebarData?.working_hrs, "HH:mm:ss").format("HH:mm")}
                                                        </Typography>
                                                        <Typography variant="body2" color="dark.600" fontSize={16} fontWeight={500} lineHeight={"30px"} letterSpacing={"0.17px"}>
                                                            ({convertTime(activitySidebarData?.working_hrs, activitySidebarData?.total)?.percentage})
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="caption" color="text.secondary" fontSize={11} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>
                                                        Working Hours
                                                    </Typography>
                                                </Box>
                                            </ProgressSection>
                                            <ProgressSection>
                                                <Box display="flex" alignItems="center" width={27} height={46}>
                                                    <DurationDot />
                                                </Box>
                                                <Box>
                                                    <Box display="flex" alignItems="baseline" gap={0.5}>
                                                        <Typography variant="h5" color="text.primary" fontSize={28} fontWeight={500} lineHeight={"44px"} letterSpacing={"0.15px"}>
                                                            {moment(activitySidebarData?.non_working_hrs, "HH:mm:ss").format("HH:mm")}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight={500} lineHeight={"30px"} letterSpacing={"0.17px"}>
                                                            ({convertTime(activitySidebarData?.non_working_hrs, activitySidebarData?.total)?.percentage})
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="caption" color="text.secondary" fontSize={11} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>
                                                        Non-working Hours
                                                    </Typography>
                                                </Box>
                                            </ProgressSection>
                                        </ProgressContainer>
                                )
                        }
                    </Box>
                    <motion.div mt={3} variants={frameVariants} initial="hidden" animate="visible">
                        {activitySidebarData?.user_activities?.map((activityData, index) => (
                            activityData?.isLeave ?
                                <motion.div
                                    key={index}
                                    display="flex"
                                    gap={1.5}
                                    mt={0.5}
                                    // initial={{ opacity: 0, y: 200 }}
                                    // animate={{ opacity: 1, y: 0, stagger: 0.5  }}
                                    // transition={{
                                    //     duration: 0.4,
                                    //     delay: 0.4,
                                    //     stagger: 0.5
                                    // }}
                                    variants={itemVariants}
                                >
                                    <TimeBox>
                                        <DurationLabel isLeave={true} className='duration-box' sx={{ transition: 'all 0.25s ease-in-out', minHeight: 50, background: '#FAE8E8 !important' }}>
                                            <Typography
                                                fontSize={11}
                                                fontWeight={700}
                                                sx={{ lineHeight: "15px", transform: 'rotate(180deg)', writingMode: "vertical-lr", display: "flex", textWrap: "nowrap", transition: 'all 0.25s ease-in-out' }}
                                                className='duration'
                                            >
                                                Leave
                                            </Typography>
                                        </DurationLabel>
                                        <Box flex={1}>
                                            <TimeLabel isLeave={true} className='time-box' sx={{ transition: 'all 0.25s ease-in-out' }} display={"flex"} flexDirection={"column"}>
                                                {labelForLeaveDuration(activityData?.leave_duration)}
                                            </TimeLabel>
                                        </Box>
                                    </TimeBox>
                                    <Box display={"flex"} flex={1} flexDirection={"column"}>
                                        <ActivityBox isLeave={true} sx={{ borderRadius: '0px' }}>
                                            <Box display={"flex"} gap={1} px={"11px"} alignItems={"center"} width="100%" className="worktype-box" sx={{ transition: 'all 0.25s ease-in-out' }}>
                                                <Typography bgcolor="#E75252" height={24} width={24} color={"white"} maxWidth={24} minWidth={24} borderRadius={"50%"} display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                    sx={{
                                                        fontSize: 13,
                                                        fontWeight: 800,
                                                        lineHeight: '15px',
                                                        background: `${activitySidebarData?.leaveData ? "#E75252" : "#B753FF"}`
                                                    }}
                                                >
                                                    {activitySidebarData?.leaveData ? "L" : "H"}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        'div': {
                                                            background: '#ffffff',
                                                            margin: 0,
                                                        },
                                                        'span': {
                                                            padding: '3px 14px',
                                                        },
                                                    }}
                                                >
                                                    {getLeaveStatus(activityData?.status, "chip")}
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    fontWeight={700}
                                                    fontSize={17}
                                                    lineHeight={"27px"}
                                                    letterSpacing={"0.15px"}
                                                    color={"dark.800"}
                                                >
                                                    {activityData?.leaveType}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                    onClick={() => handleLeaveClick(activityData?.user_id, activityData?.leave_application_id)}
                                                >
                                                    <LaunchIcon
                                                        className="launchIcon"
                                                        sx={{
                                                            fontSize: 16,
                                                            marginLeft: '4px',
                                                            color: '#047FE0',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </ActivityBox>
                                    </Box>
                                </motion.div>
                                : <motion.div width={"100%"}
                                    key={index}
                                    // initial={{ opacity: 0, y: 200 }}
                                    // animate={{ opacity: 1, y: 0, stagger: 0.5 }}
                                    // transition={{
                                    //     duration: 0.4,
                                    //     delay: 0.4,
                                    //     stagger: 0.5
                                    // }}
                                    variants={itemVariants}
                                >
                                    <Box
                                        key={index}
                                        display="flex"
                                        alignItems={"flex-start"}
                                        gap={1.5}
                                        mt={0.5}
                                    >
                                        <TimeBox>
                                            <DurationLabel type={activityData?.activity} className='duration-box' sx={{ transition: 'all 0.25s ease-in-out', minHeight: `${activityData?.hieghtForDuration} !important` }}>
                                                <Typography
                                                    fontSize={11}
                                                    fontWeight={700}
                                                    sx={{ lineHeight: "15px", transform: 'rotate(180deg)', writingMode: "vertical-lr", display: "flex", textWrap: "nowrap", transition: 'all 0.25s ease-in-out' }}
                                                    className='duration'
                                                >
                                                    {activityData?.duration}
                                                </Typography>
                                            </DurationLabel>
                                            <Box flex={1}>
                                                <TimeLabel type={activityData?.activity} className='time-box' sx={{ transition: 'all 0.25s ease-in-out' }} display={"flex"} flexDirection={"column"}>
                                                    {activityData?.isLogin ?
                                                        <Box
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            color="#069A30"
                                                            width={'100%'}
                                                            flex={1}
                                                            className={"login-info"}
                                                            sx={{ transition: 'all 0.25s ease-in-out' }}
                                                        >
                                                            <Typography variant="caption" fontSize={11} fontWeight={700} lineHeight={"15px"} letterSpacing={"0.17px"}>
                                                                Login
                                                            </Typography>
                                                            <Typography variant="caption" fontSize={12} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.17px"}>
                                                                {moment(activityData?.createdAt).format("h:mm A")}
                                                            </Typography>
                                                        </Box>
                                                        :
                                                        <Typography color={"dark.600"} fontSize={12} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.17px"} className='time' sx={{ transition: 'all 0.25s ease-in-out' }}>
                                                            {moment(activityData?.createdAt).format("h:mm A")}
                                                        </Typography>}
                                                    {activityData?.isLogout ? (
                                                        <Box
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            color="error.main"
                                                            width={'100%'}
                                                            flex={1}
                                                            alignItems={"flex-end"}
                                                            className={"logout-info"}
                                                            sx={{ transition: 'all 0.25s ease-in-out' }}
                                                        >
                                                            <Typography variant="caption" fontSize={11} fontWeight={700} lineHeight={"15px"} letterSpacing={"0.17px"}>
                                                                Logout
                                                            </Typography>
                                                            <Typography variant="caption" fontSize={12} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.17px"}>
                                                                {moment(activityData?.logoutTime).format("h:mm A")}
                                                            </Typography>
                                                        </Box>
                                                    ) : null}
                                                    {((index + 1) === activitySidebarData?.user_activities?.length && !activityData?.isLogout && activityData?.isTillNow) ? (
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            color="#069A30"
                                                            width={'100%'}
                                                            flex={1}
                                                            alignItems={"flex-end"}
                                                            className={"logout-info"}
                                                            sx={{ transition: 'all 0.25s ease-in-out' }}
                                                        >
                                                            <Typography variant="caption" fontSize={10} fontWeight={600} lineHeight={"14px"} letterSpacing={"0.17px"}>
                                                                Till Now
                                                            </Typography>
                                                            {/* <Typography variant="caption" fontSize={10} fontWeight={500} lineHeight={"12px"} letterSpacing={"0.17px"}>
                                                                {moment().format("h:mm A")}
                                                            </Typography> */}
                                                        </Box>
                                                    ) : null}
                                                </TimeLabel>
                                            </Box>
                                        </TimeBox>
                                        <Box display={"flex"} flex={1} flexDirection={"column"}>
                                            <ActivityBox type={activityData?.activity} >
                                                <Box display="flex" width="100%" className="worktype-box" borderRadius={"4px"} sx={{ transition: 'all 0.25s ease-in-out' }}>
                                                    <IconBox type={activityData?.activity} sx={{ 'svg': { width: 16, height: 16 }, transition: 'all 0.25s ease-in-out' }} className='type-icon'>
                                                        {getIcon(activityData?.Activity?.activity_logo)}
                                                    </IconBox>
                                                    <Box flex={1}
                                                        py={0.5}
                                                        px={1}>
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            className="work-type-info"
                                                            sx={{ transition: 'all 0.25s ease-in-out' }}
                                                        >
                                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={0.5}>
                                                                <Typography
                                                                    variant="body2"
                                                                    fontWeight={600}
                                                                    fontSize={13}
                                                                    lineHeight={"18px"}
                                                                    letterSpacing={"0.17px"}
                                                                    color={activityData?.Activity?.types === "non-working" ? 'text.secondary' : 'primary'}
                                                                >
                                                                    {activityData?.activity}
                                                                </Typography>
                                                                {/* {activityData?.is_wfh ?
                                                                    <Typography
                                                                        variant="body2"
                                                                        fontWeight={600}
                                                                        fontSize={13}
                                                                        lineHeight={"18px"}
                                                                        letterSpacing={"0.17px"}
                                                                        color={'#6B6B6B'}
                                                                        pl={"4px"}
                                                                    >
                                                                        (WFH)
                                                                    </Typography> : null} */}
                                                                {(activityData?.is_remote) ?
                                                                    <Typography height={18} width={18} color={"white"} maxWidth={18} minWidth={18} borderRadius={"50%"} display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                        sx={{
                                                                            fontSize: 10,
                                                                            fontWeight: 800,
                                                                            lineHeight: '12px',
                                                                            background: "#047FE0"
                                                                        }}
                                                                    >{"R"}</Typography> : null}
                                                            </Box>
                                                            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                                                                <Typography display={"flex"} variant='body2' color={"dark.600"} fontSize={11} fontWeight={500} lineHeight={"16px"} letterSpacing={"0.17px"}>{moment(activityData?.activityStartTime).format("h:mm A")} - {activityData?.activityEndTime ? moment(activityData?.activityEndTime).format("h:mm A") : <Typography color={"#05B01B"} fontSize={11} fontWeight={500}>Till Now</Typography>}</Typography>
                                                                <Box
                                                                    width={8}
                                                                    height={8}
                                                                    borderRadius="50%"
                                                                    bgcolor={activityData?.Activity?.types === "non-working" ? 'transparent' : 'primary.main'}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </ActivityBox>
                                            {((index + 1) === activitySidebarData?.user_activities?.length && !activityData?.isLogout && activityData?.isTillNow) ?
                                                <Box display={"flex"} gap={"11px"} py={"14px"} justifyContent={"center"} alignItems={"center"}>
                                                    <i
                                                        style={{
                                                            width: "25px",
                                                            height: "25px",
                                                            display: "inline-block",
                                                            verticalAlign: "middle",
                                                            color: "#B4B4B4",
                                                        }}
                                                    >
                                                        {ICONS.TillNowClockIcon}
                                                    </i>
                                                    <Typography
                                                        fontSize={15}
                                                        color={"#B4B4B4"}
                                                        fontWeight={600}
                                                        sx={{ lineHeight: "24px", letterSpacing: "0.15px" }}
                                                    >
                                                        Still Working...
                                                    </Typography>
                                                </Box>
                                                : null}
                                        </Box>
                                    </Box>
                                </motion.div>
                        ))}
                    </motion.div>
                </Box> : null}
            {(!activitySidebarData?.leaveData && !activitySidebarData?.user_activities?.length) ?
                activitySidebarData?.is_holiday ? <Box p={3}>
                    <Box
                        p={"42px"}
                        sx={{ textAlign: "center", bgcolor: "rgba(183, 83, 255, 0.1)", borderRadius: '6px', }}
                        overflow={"auto"}
                    >
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={122} maxWidth={122} minWidth={122} height={122} borderRadius={100} border={"1px solid rgba(183, 83, 255, 0.1)"} m={"0px auto 24px"}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={96} maxWidth={96} minWidth={96} height={96} borderRadius={100} bgcolor={"rgba(183, 83, 255, 0.1)"}>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={70} maxWidth={70} minWidth={70} height={70} borderRadius={100} bgcolor={"#B753FF"}>
                                    {activitySidebarData?.holidayData?.id ? <CelebrationIcon sx={{ fontSize: '30px', color: '#ffffff', transform: 'scaleX(-1)', }} /> : <BeachAccessIcon sx={{ fontSize: '30px', color: '#ffffff' }} />}
                                </Box>
                            </Box>
                        </Box>
                        <Typography color="dark.800" fontSize={22} fontWeight={500} lineHeight={"25px"} letterSpacing={"0.15px"} mb={1}>
                            {activitySidebarData?.holidayData?.id ? `It's ${titleCase(activitySidebarData?.holidayData?.name)}!` : "It's Holiday!"}
                        </Typography>
                        <Typography color="dark.800" fontSize={14} fontWeight={500} lineHeight={"22px"} letterSpacing={"0.15px"}>
                            {getApplyDateTime(convertDateFromFormat(activitySidebarData?.date, "YYYY-MM-DD", "DD/MM/YYYY"), 'dddd, Do [of] MMMM YYYY')}
                        </Typography>
                    </Box>
                </Box> :
                    <Box
                        py={"74px"}
                        sx={{ height: "100%", visibility: "visible", textAlign: "center" }}
                        flex={1}
                        overflow={"auto"}
                    >
                        <Box pb={"30px"}>
                            <img alt='No activities' src={emptySidebarImg} style={{ maxWidth: '100%', width: '287px' }} />
                        </Box>
                        <Typography color="dark.600" fontSize={18} fontWeight={500} lineHeight={"28px"} letterSpacing={"0.15px"}>
                            No activities
                        </Typography>
                    </Box> : null}
        </Box>
    );

    return (
        <React.Fragment>
            <Box width="100%" bgcolor="white" display="flex" flexDirection="column"
                sx={{
                    '> div:first-of-type': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: 'white',
                    }
                }}
            >
                <Breadcrumb isBack={true} pageTitle={"In-Out Working Report"} title={"reports"} onBackClick={() => navigate(-1)} />
                <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto" flex={1}>
                    {isLoading?.users ?
                        <CircularLoader height='500px' /> :
                        <React.Fragment>
                            <Box display="flex" flexWrap={"wrap"} justifyContent="space-between" alignItems="center" columnGap={1.5} mb={1.5}>
                                <Grid mb={1.5} sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "flex-start",
                                    gap: 2,
                                }}>
                                    <Grid item sx={{ width: { xs: 180, lg: 264 } }}>
                                        <FormControl variant="standard" sx={{ width: "100%" }}>
                                            <InputLabel htmlFor="range_label">Select User</InputLabel>
                                            <Select
                                                inputProps={{ id: "range_label" }}
                                                id="user_id"
                                                value={queryParams?.user_id || ""}
                                                onChange={(event, ...rest) => {
                                                    setQueryParams((prev) => ({ ...prev, user_id: event?.target?.value }))
                                                }}
                                                name="user_id"
                                                label="Select User"
                                                sx={{
                                                    ".MuiSelect-select": {
                                                        fontSize: 14,
                                                        fontWeight: 400,
                                                        color: "dark.800",
                                                    },
                                                }}
                                                onBlur={(e) => handleBlur("user_id", e.target.value)}
                                            >
                                                {organizationUserList?.map((item, index) => (
                                                    <MenuItem key={item?.id} value={item?.id}>
                                                        {titleCase(item?.name)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {!!errors?.user_id ?
                                                <Typography sx={{
                                                    color: "red.main",
                                                    fontSize: "12px"
                                                }}>
                                                    {errors?.user_id}
                                                </Typography>
                                                : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid className='date-picker-with-filter' item sx={{
                                        width: { xs: 220, lg: 264 },
                                    }}>
                                        <MenuDateRangePicker
                                            dateRange={dateRange}
                                            onChange={(dateRange) => handleChangeDatePicker(dateRange)}
                                            error={errors.startDate}
                                            maxDate={dateLimits?.maxDate}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            isButtonLoading={isLoading?.report}
                                            sx={{ color: "white", boxShadow: 'none' }}
                                            onClick={handleSearch}
                                            disabled={Object.values(errors)?.filter((value) => !!value)?.length > 0}
                                        >
                                            Apply
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid mb={1.5} item>
                                    {!isLoading?.report ?
                                        <Box sx={{ gap: 1, textAlign: "right" }}>
                                            <Typography color="dark.800" display="inline-flex" fontSize={13} fontWeight={400} lineHeight="18px" letterSpacing="0.17px">
                                                Working<span style={{ fontWeight: 'bold', paddingLeft: '4px' }}>{summary?.working_days} Days</span>, Non-working <span style={{ fontWeight: 'bold', padding: '0px 4px' }}>{summary?.non_working_days} Days</span>
                                            </Typography>
                                            <Grid display="inline-flex" color="dark.800" fontSize={13} fontWeight={400} lineHeight="18px" letterSpacing="0.17px">
                                                ( Including
                                                <HtmlTooltip
                                                    title={
                                                        <Typography fontSize={{ xs: 12, lg: 14 }}>Casual Leave</Typography>
                                                    }>
                                                    <Typography variant='span' borderBottomColor="dark.800" fontSize={14} fontWeight={500} sx={{ borderBottom: '1px dashed', display: 'inline-block', ml: 0.5, '&:hover': { color: '#047FE0' } }}>{summary?.cl_count} CL</Typography>
                                                </HtmlTooltip>
                                                <Typography variant='span' fontSize={14} fontWeight={500} sx={{ display: 'inline-block', ml: 0.5, '&:hover': { color: '#047FE0' } }}>,</Typography>
                                                <HtmlTooltip
                                                    title={
                                                        <Typography fontSize={{ xs: 12, lg: 14 }}>Sick Leave</Typography>
                                                    }>
                                                    <Typography variant='span' borderBottomColor="dark.800" fontSize={14} fontWeight={500} sx={{ borderBottom: '1px dashed', display: 'inline-block', mx: 0.5, '&:hover': { color: '#047FE0' } }}>{summary?.sl_count} SL </Typography>
                                                </HtmlTooltip>
                                                )
                                            </Grid>
                                        </Box> : null}
                                </Grid>
                            </Box>
                            <Box pb={{ xs: 3, lg: 3, xl: 4 }} display={'flex'} gap={3}>
                                <Box gap={1} sx={{ display: "flex", alignItems: 'center' }}>
                                    <Box sx={{
                                        width: "18px",
                                        height: "18px",
                                        maxWidth: "18px",
                                        flex: "0 0 18px"
                                    }}>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                bgcolor: CHART_DOT_COLOR[2],
                                                fontSize: 10,
                                                lineHeight: '12px',
                                                color: 'white',
                                                fontWeight: 800,
                                            }}>H</Box>
                                    </Box>
                                    <Typography color="dark.main" fontSize={14} fontWeight={400} lineHeight="20px" letterSpacing={"0.17px"} variant="span">Holiday</Typography>
                                </Box>
                                <Box gap={1} sx={{ display: "flex", alignItems: 'center' }}>
                                    <Box sx={{
                                        width: "18px",
                                        height: "18px",
                                        maxWidth: "18px",
                                        flex: "0 0 18px"
                                    }}>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                bgcolor: CHART_DOT_COLOR[1],
                                                fontSize: 10,
                                                lineHeight: '12px',
                                                color: 'white',
                                                fontWeight: 800,
                                            }}>L</Box>
                                    </Box>
                                    <Typography color="dark.main" fontSize={14} fontWeight={400} lineHeight="20px" letterSpacing={"0.17px"} variant="span">Leave</Typography>
                                </Box>
                                <Box gap={1} sx={{ display: "flex", alignItems: 'center' }}>
                                    <Box sx={{
                                        width: "18px",
                                        height: "18px",
                                        maxWidth: "18px",
                                        flex: "0 0 18px"
                                    }}>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                bgcolor: CHART_DOT_COLOR[0],
                                                fontSize: 10,
                                                lineHeight: '12px',
                                                color: 'white',
                                                fontWeight: 800,
                                            }}>R</Box>
                                    </Box>
                                    <Typography color="dark.main" fontSize={14} fontWeight={400} lineHeight="20px" letterSpacing={"0.17px"} variant="span">Remote Work</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Paper sx={{ width: '100%', boxShadow: 'none', }}>
                                    <Box>
                                        <Paper
                                            sx={{
                                                border: "1px solid #E0E0E0",
                                                borderRadius: "3px",
                                                boxShadow: 'none',
                                            }}>
                                            <TableContainer>
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    size={'medium'}
                                                >
                                                    <TableHead>
                                                        <TableRow sx={{
                                                            whiteSpace: 'nowrap',
                                                            'th': {
                                                                bgcolor: "bgColor",
                                                                color: "dark.800",
                                                                fontWeight: 500,
                                                                fontSize: 14,
                                                                lineHeight: "24px",
                                                                letterSpacing: "0.17px",
                                                                padding: "5px 16px",
                                                                textAlign: "left",
                                                                background: "rgba(245, 248, 250, 1)",
                                                            },
                                                        }}>
                                                            <TableCell sx={{ width: 250, maxWidth: 250, minWidth: 250 }}>
                                                                <TableSortLabel
                                                                    active={orderBy === "date"}
                                                                    direction={orderBy === "date" ? sortType : "asc"}
                                                                    onClick={() => handleSortClick("date")}
                                                                >
                                                                    Date
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell sx={{ width: '170px', maxWidth: '170px', minWidth: '170px' }}>
                                                                <TableSortLabel
                                                                    active={orderBy === "loginInTime"}
                                                                    direction={orderBy === "loginInTime" ? sortType : "asc"}
                                                                    onClick={() => handleSortClick("loginInTime")}
                                                                >
                                                                    Login Time
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell sx={{ width: '170px', maxWidth: '170px', minWidth: '170px' }}>
                                                                <TableSortLabel
                                                                    active={orderBy === "logoutTime"}
                                                                    direction={orderBy === "logoutTime" ? sortType : "asc"}
                                                                    onClick={() => handleSortClick("logoutTime")}
                                                                >
                                                                    Logout Time
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell>
                                                                <TableSortLabel
                                                                    active={orderBy === "working_hrs"}
                                                                    direction={orderBy === "working_hrs" ? sortType : "asc"}
                                                                    onClick={() => handleSortClick("working_hrs")}
                                                                >
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <Typography fontSize={{ xs: 12, lg: 14 }}>Total Working Hours</Typography>
                                                                        }>
                                                                        <Typography variant='span' sx={{ textDecoration: 'underline', textDecorationStyle: 'dashed' }}>Total Working Hours</Typography>
                                                                    </HtmlTooltip>
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell>
                                                                <TableSortLabel
                                                                    active={orderBy === "non_working_hrs"}
                                                                    direction={orderBy === "non_working_hrs" ? sortType : "asc"}
                                                                    onClick={() => handleSortClick("non_working_hrs")}
                                                                >
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <Typography fontSize={{ xs: 12, lg: 14 }}>Total Non-working Hours</Typography>
                                                                        }>
                                                                        <Typography variant='span' sx={{ textDecoration: 'underline', textDecorationStyle: 'dashed' }}>Total Non-working Hours</Typography>
                                                                    </HtmlTooltip>
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell>
                                                                <TableSortLabel
                                                                    active={orderBy === "total"}
                                                                    direction={orderBy === "total" ? sortType : "asc"}
                                                                    onClick={() => handleSortClick("total")}
                                                                >
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <Typography fontSize={{ xs: 12, lg: 14 }}>Total Duration</Typography>
                                                                        }>
                                                                        <Typography variant='span' sx={{ textDecoration: 'underline', textDecorationStyle: 'dashed' }}>Total Duration</Typography>
                                                                    </HtmlTooltip>
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody
                                                        sx={{
                                                            "tr:hover": {
                                                                background: "rgba(247, 247, 247, 1)",
                                                            },
                                                            "tr:hover .date-col .report-date": {
                                                                color: '#047FE0 !important',
                                                                textDecoration: 'underline',
                                                            },
                                                            td: {
                                                                color: "dark.800",
                                                                fontWeight: 400,
                                                                fontSize: 14,
                                                                lineHeight: "20px",
                                                                letterSpacing: "0.17px",
                                                                padding: "7px 16px",
                                                                textAlign: "left",
                                                                height: "45px",
                                                            },
                                                        }}
                                                    >
                                                        {isLoading?.report ?
                                                            <TableRowSkeleton cols={7} rows={5} /> :
                                                            visibleRows?.length ? visibleRows?.map((report, index) => {
                                                                let dot = isShowDot(report);
                                                                return (<TableRow hover key={index} sx={{ background: report?.date === moment().format("DD/MM/YYYY") ? "rgba(18, 180, 149, 0.05) !important" : "" }}>
                                                                    <TableCell className='date-col' onClick={() => handleSelectActivityDay(report)} sx={{ cursor: "pointer" }}>
                                                                        <Stack sx={{ width: 266 }} direction="row" alignItems="center" gap={1}>
                                                                            <Typography className='report-date' width={'80px'} minWidth={'80px'} color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                {report?.date}
                                                                            </Typography>
                                                                            <Typography className='report-date' color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"19px"} letterSpacing={'0.17px'}>
                                                                                ({moment(report?.date, "DD/MM/YYYY").format("dddd")})
                                                                            </Typography>
                                                                            {(!!report?.is_wfh || report?.is_leave || report?.is_holiday) ? <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                                                                                {!!report?.is_wfh ? <Box sx={{
                                                                                    width: "18px",
                                                                                    flex: "0 0 18px",
                                                                                    maxWidth: "18px",
                                                                                    height: "18px"
                                                                                }}>
                                                                                    <HtmlTooltip
                                                                                        title={
                                                                                            <React.Fragment>
                                                                                                <Typography color="white" fontSize={14} fontWeight={500} lineHeight={"21px"}>{!!report?.is_wfh ? "Remote Work" : ""}</Typography>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    >
                                                                                        <Box
                                                                                            display={"flex"}
                                                                                            alignItems={"center"}
                                                                                            justifyContent={"center"}
                                                                                            sx={{
                                                                                                width: "100%",
                                                                                                height: "100%",
                                                                                                borderRadius: "50%",
                                                                                                bgcolor: !!report?.is_wfh ? CHART_DOT_COLOR[0] : "transparent",
                                                                                                fontSize: 10,
                                                                                                lineHeight: '12px',
                                                                                                color: 'white',
                                                                                                fontWeight: 800,
                                                                                            }}>R</Box>
                                                                                    </HtmlTooltip>
                                                                                </Box> : null}
                                                                                {report?.is_leave ? <Box sx={{
                                                                                    width: "18px",
                                                                                    flex: "0 0 18px",
                                                                                    maxWidth: "18px",
                                                                                    height: "18px"
                                                                                }}>
                                                                                    <HtmlTooltip
                                                                                        title={
                                                                                            <React.Fragment>
                                                                                                <Typography color="white" fontSize={14} fontWeight={500} lineHeight={"21px"}>{dot?.isShow[1] ? "Leave" : ""}</Typography>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    >
                                                                                        <Box
                                                                                            display={"flex"}
                                                                                            alignItems={"center"}
                                                                                            justifyContent={"center"}
                                                                                            sx={{
                                                                                                width: "100%",
                                                                                                height: "100%",
                                                                                                borderRadius: "50%",
                                                                                                bgcolor: report?.is_leave ? CHART_DOT_COLOR[1] : "transparent",
                                                                                                fontSize: 10,
                                                                                                lineHeight: '12px',
                                                                                                color: 'white',
                                                                                                fontWeight: 800,
                                                                                            }}>L</Box>
                                                                                    </HtmlTooltip>
                                                                                </Box> : null}
                                                                                {report?.is_holiday ? <Box sx={{
                                                                                    width: "18px",
                                                                                    flex: "0 0 18px",
                                                                                    maxWidth: "18px",
                                                                                    height: "18px"
                                                                                }}>
                                                                                    <HtmlTooltip
                                                                                        title={
                                                                                            <React.Fragment>
                                                                                                <Typography color="white" fontSize={14} fontWeight={500} lineHeight={"21px"}>{report?.is_holiday ? "Holiday" : ""}</Typography>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    >
                                                                                        <Box
                                                                                            display={"flex"}
                                                                                            alignItems={"center"}
                                                                                            justifyContent={"center"}
                                                                                            sx={{
                                                                                                width: "100%",
                                                                                                height: "100%",
                                                                                                borderRadius: "50%",
                                                                                                bgcolor: report?.is_holiday ? CHART_DOT_COLOR[2] : "transparent",
                                                                                                fontSize: 10,
                                                                                                lineHeight: '12px',
                                                                                                color: 'white',
                                                                                                fontWeight: 800,
                                                                                            }}>H</Box>
                                                                                    </HtmlTooltip>
                                                                                </Box> : null}
                                                                            </Box> : null}
                                                                            {report?.date === moment().format("DD/MM/YYYY") ?
                                                                                <Typography
                                                                                    fontSize={13}
                                                                                    fontWeight={500}
                                                                                    sx={{ lineHeight: "18px", fontStyle: "italic" }}
                                                                                    color={"#05B01B"}
                                                                                >Today</Typography> : null}
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}>
                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                {getDisplayValue(report?.loginInTime)}
                                                                            </Typography>
                                                                            <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"19px"} letterSpacing={'0.17px'}>
                                                                                {report?.loginInDate !== report?.date ? `(${moment(report?.loginInDate, "DD/MM/YYYY").format("DD/MM")})` : null}
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}>
                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                {getDisplayValue(report?.logoutTime)}
                                                                            </Typography>
                                                                            <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"19px"} letterSpacing={'0.17px'}>
                                                                                {report?.loginOutDate !== report?.date ? `(${moment(report?.loginOutDate, "DD/MM/YYYY").format("DD/MM")})` : null}
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}>
                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                {convertTime(report?.working_hrs, report?.total)?.time}
                                                                            </Typography>
                                                                            <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"19px"} letterSpacing={'0.17px'}>
                                                                                ({convertTime(report?.working_hrs, report?.total)?.percentage})
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}>
                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                                {convertTime(report?.non_working_hrs, report?.total)?.time}
                                                                            </Typography>
                                                                            <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"19px"} letterSpacing={'0.17px'}>
                                                                                ({convertTime(report?.non_working_hrs, report?.total)?.percentage})
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {convertTime(report?.total, report?.total)?.time}
                                                                    </TableCell>
                                                                </TableRow>)
                                                            }) : <EmptyTable colSpan={7} />}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </Paper>
                            </Box>
                            {!isLoading?.report && series?.length > 0 && series?.[0]?.data?.length > 1 ?
                                <Box mt={3} width={"100%"} border={"1px solid #E0E0E0"} p={3} borderRadius={"4px"}>
                                    <Box mb={5}>
                                        <Grid sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}>
                                            <Grid item>
                                                <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"} >{titleCase(summary?.user?.name)} activity for {getDates()}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}>
                                                    <Grid item>
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "8px",
                                                        }}>
                                                            <Box sx={{
                                                                backgroundColor: "#047FE0",
                                                                width: "16px",
                                                                height: "16px",
                                                                borderRadius: "4px",
                                                            }}></Box>
                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Working hours</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "8px",
                                                        }}>
                                                            <Box sx={{
                                                                backgroundColor: "#3F5C76",
                                                                width: "16px",
                                                                height: "16px",
                                                                borderRadius: "4px",
                                                            }}></Box>
                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Non-working hours</Typography>
                                                        </Box>
                                                    </Grid>
                                                    {/* <Grid item>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                }}>
                                                    <Box sx={{
                                                        backgroundColor: "#047FE0",
                                                        width: "10px",
                                                        height: "10px",
                                                        borderRadius: "50%",
                                                    }}></Box>
                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Work From Home</Typography>
                                                </Box>
                                            </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <ReactApexChart type='bar' height={375} options={options} series={series} />
                                    </Box>
                                </Box> : null}
                        </React.Fragment>}
                </Box>
            </Box>
            <Drawer open={isShowSidebar} anchor="right" onClose={() => setIsShowSidebar(false)}>
                {DrawerActivityData}
            </Drawer>
        </React.Fragment>
    )
};

export default InOutReport;