/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { TextField } from "@mui/material";
import { debounce } from "lodash";

const SearchBoxInput = ({
  label = "Search",
  variant = "standard",
  placeholder = "Search...",
  handleSearch,
  isUseDebounce = true,
  isOverwritePlaceholder = false,
  isKeyDownChanges = false,
  value: controlledValue,
  disabled = false,
  ...rest
}) => {
  const [internalSearchTerm, setInternalSearchTerm] = useState(controlledValue ?? "");

  useEffect(() => {
    setInternalSearchTerm(controlledValue ?? "");
  }, [controlledValue])

  const debouncedSearch = useCallback(
    debounce((query) => {
      handleSearch(query);
    }, 500),
    []
  );


  const immediateSearch = useCallback((query) => {
    handleSearch(query);
  }, []);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInternalSearchTerm(newValue);
    if (handleSearch) {
      if (isUseDebounce) {
        debouncedSearch(newValue);
      } else {
        immediateSearch(newValue);
      }
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = ['ArrowUp', 'ArrowDown'];
    if (allowedKeys.includes(e?.key)) {
      e.preventDefault();
    }
  };


  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <TextField
      label={label}
      variant={variant}
      placeholder={isOverwritePlaceholder ? placeholder : `Type ${placeholder}`}
      onKeyDown={isKeyDownChanges ? handleKeyDown : undefined}
      type="search"
      value={internalSearchTerm || ''}
      onChange={handleChange}
      disabled={disabled}
      InputProps={{
        autoComplete: 'off',
        ...rest.InputProps
      }}
      {...rest}
    />
  );
};

export default SearchBoxInput;