/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { HOLIDAYS } from "../../../../constants/default-values";
import useAuthentication from "../../../../hook/useAuthentication";
import API from "../../../../axios/api";
import axiosInstance from "../../../../axios";
import CircularLoader from "../../../../components/CircularLoader";
import { Navigate } from "react-big-calendar";
import URLS from "../../../../routes/urls";
import { useNavigate } from "react-router-dom";
import {
  areDatesEqual,
  convertDateFromFormat,
  generateUniqueId,
  getFormattedDate,
  getYearObject,
  handleSortFestivalHolidays,
  handleSortOfficeHoliday,
  titleCase,
} from "../../../../utils";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SingleDatePicker from "../../../../components/SingleDatePicker";
import moment from "moment";
import Button from "../../../../components/Button";
import { useAlert } from "../../../../hook/useAlert";
import Input from "../../../../components/Input";
import AutocompleteWithAddOption from "../../../../components/AutocompleteWithAddOption";
import useQuery from "../../../../hook/useQuery";
import { usePageTitle } from "../../../../hook/useTitle";
import Breadcrumb from "../../../../components/Breadcrumb";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const initialFormData = {
  date: null,
  name: "",
  is_deleted: false,
  id: null,
};

const initialHolidayFormData = {
  year: "",
  saturday_setting: "false",
  sunday_setting: "false",
  saturday_setting_status: false,
  sunday_setting_status: false,
  custom_setting_status: false,
  festival_holidays: [],
  custom_holidays: [],
};

const CreateHoliday = () => {
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const { getQueryParams } = useQuery();
  const currentUser = getCurrentUser();
  let selectedYearId = getQueryParams("selectedYearId");
  const navigate = useNavigate();
  const showAlert = useAlert();
  const [tabValue, setTabValue] = useState(Object.keys(HOLIDAYS)[0]);
  const [holidayYears, setHolidayYears] = useState([]);
  const [holidayFormData, setHolidayFormData] = useState(
    initialHolidayFormData
  );
  const [holidayData, setHolidayData] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState({
    dateLoading: true,
    holidayDataLoading: true,
    tableDataLoading: false,
    formSubmitting: false,
    holidayCountLoading: true,
  });
  const searchParams = new URLSearchParams('')
  const [holidaySettingData, setHolidaySettingData] = useState({
    holidaySundayDates: [],
    holidaySaturdayDates: [],
  });
  const [holidayTitles, setHolidayTitles] = useState([]);

  setPageTitle("Create Holidays");

  useEffect(() => {
    if (currentUser?.organization_id) {
      getAllHolidayYears();
    }
  }, []);

  const getAllHolidayYears = async () => {
    try {
      setLoading((prev) => ({ ...prev, dateLoading: true }));
      const response = await axiosInstance.get(
        API.getAllHolidaysYear(currentUser.organization_id)
      );
      if (response.status === 200) {
        let sortedYear = response?.data?.result?.sort((a, b) =>
          a?.year?.toString()?.localeCompare(b?.year?.toString())
        );
        let selectedYear = sortedYear?.find(yearData => yearData?.id === Number(selectedYearId));
        let activeYear = sortedYear?.find(yearData => yearData?.active);
        activeYear = activeYear?.length ? activeYear : sortedYear[0];
        setHolidayFormData({ ...holidayFormData, year: selectedYear ? selectedYear : activeYear });
        setHolidayYears(sortedYear?.filter((yearData) => !yearData?.isDisabled));
        getHolidayData(selectedYear ? Number(selectedYearId) : activeYear?.id);
        getHolidayList(selectedYear ? selectedYear : activeYear);
      }
      setLoading((prev) => ({ ...prev, dateLoading: false }));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading((prev) => ({ ...prev, dateLoading: false }));
    }
  };

  function getDayOccurrence(date) {
    const givenDate = new Date(date);
    const dayOfWeek = givenDate.getDay();

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      return false
    }

    const day = givenDate.getDate();
    const occurrence = Math.ceil(day / 7);

    let startOfYear;
    let daysSinceStartOfYear;
    let weeksSinceStartOfYear;
    let isEvenWeek;

    if (holidayFormData?.sunday_setting === 'alternate') {
      startOfYear = new Date(givenDate.getFullYear(), 0, 1);
      daysSinceStartOfYear = Math.floor((givenDate - startOfYear) / (1000 * 60 * 60 * 24));
      weeksSinceStartOfYear = Math.floor(daysSinceStartOfYear / 7);
      isEvenWeek = weeksSinceStartOfYear % 2 === 0;
    }

    return {
      day: givenDate.getDay(),
      frequency: occurrence,
      isHoliday: isEvenWeek ?? false
    }
  }

  const handleAddClick = () => {
    let currentDayData = getDayOccurrence(formData?.date)
    let isDateEnable = true;
    if (currentDayData && (holidayFormData.sunday_setting_status || holidayFormData.saturday_setting_status)) {
      if (currentDayData?.day === 6 && holidayFormData.saturday_setting_status) {
        if (holidayFormData?.saturday_setting === 'first_third') {
          if ([1, 3, 5]?.includes(currentDayData?.frequency)) {
            isDateEnable = false;
          }
        } else if (holidayFormData?.saturday_setting === 'second_fourth') {
          if ([2, 4]?.includes(currentDayData?.frequency)) {
            isDateEnable = false;
          }
        } else if (holidayFormData?.saturday_setting === 'alternate') {
          if (currentDayData?.isHoliday) {
            isDateEnable = false;
          }
        } else {
          isDateEnable = false;
        }
      }
      if (currentDayData?.day === 0 && holidayFormData.sunday_setting_status) {
        if (holidayFormData?.sunday_setting === 'first_third') {
          if ([1, 3, 5]?.includes(currentDayData?.frequency)) {
            isDateEnable = false;
          }
        } else if (holidayFormData?.sunday_setting === 'second_fourth') {
          if ([2, 4]?.includes(currentDayData?.frequency)) {
            isDateEnable = false;
          }
        } else if (holidayFormData?.sunday_setting === 'alternate') {
          if (currentDayData?.isHoliday) {
            isDateEnable = false;
          }
        } else {
          isDateEnable = false;
        }
      }
    }
    if (isDateEnable) {
      if (tabValue === Object.keys(HOLIDAYS)[0]) {
        const allDatesData = holidayFormData?.custom_holidays?.filter((value) => !value?.is_deleted);
        const isAlreadyInclude = [...allDatesData, ...holidayFormData?.festival_holidays]?.some((value) => areDatesEqual(value?.date, formData?.date))
        const isAlreadyIncludeFestival = [...allDatesData, ...holidayFormData?.festival_holidays]?.some((value) => value?.name?.toLowerCase() === formData?.name?.toLowerCase());
        if (isAlreadyInclude) {
          showAlert("Holiday date already exist", "error")
        }
        else if (isAlreadyIncludeFestival) {
          showAlert("Holiday title already exist", "error")
        } else {
          setHolidayFormData({
            ...holidayFormData,
            custom_holidays: [
              ...holidayFormData?.custom_holidays,
              { ...formData, date: getFormattedDate(formData?.date, "YYYY-MM-DD"), unique_id: generateUniqueId() },
            ],
          });
          setFormData(initialFormData);
        }
      } else {
        const allDatesData = holidayFormData?.festival_holidays?.filter((value) => !value?.is_deleted);
        const isAlreadyInclude = [...allDatesData, ...holidayFormData?.custom_holidays]?.some((value) => areDatesEqual(value?.date, formData?.date));
        const isAlreadyIncludeFestival = [...allDatesData, ...holidayFormData?.custom_holidays]?.some((value) => value?.name?.toLowerCase() === formData?.name?.toLowerCase());
        if (isAlreadyInclude) {
          showAlert("Holiday date already exist", "error")
        }
        else if (isAlreadyIncludeFestival) {
          showAlert("Holiday title already exist", "error")
        } else {
          setHolidayFormData({
            ...holidayFormData,
            festival_holidays: [
              ...holidayFormData?.festival_holidays,
              { ...formData, date: getFormattedDate(formData?.date, "YYYY-MM-DD"), unique_id: generateUniqueId() },
            ],
          });
          setFormData(initialFormData);
        }
      }
    } else {
      showAlert("Holiday date already exist", "error")
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setFormData(initialFormData);
  };

  const getHolidayData = async (selected_year_id) => {
    try {
      setLoading((prev) => ({ ...prev, holidayDataLoading: true }));
      const response = await axiosInstance.get(
        API.getAllUserHolidays(currentUser?.organization_id, selected_year_id)
      );
      if (response.status === 200) {
        let responseData = JSON.parse(JSON.stringify(response?.data?.data));
        setHolidayData(responseData);
        setHolidayFormData((prev) => ({
          ...prev,
          sunday_setting: responseData?.sunday_setting,
          sunday_setting_status: [
            "all",
            "first_third",
            "second_fourth",
            "alternate"
          ].includes(responseData?.sunday_setting),
          saturday_setting_status: [
            "all",
            "first_third",
            "second_fourth",
            "alternate"
          ].includes(responseData?.saturday_setting),
          saturday_setting: responseData?.saturday_setting,
          custom_setting_status: !!responseData?.custom_holidays?.length,
          custom_holidays: responseData?.custom_holidays?.map(day => ({ ...day, unique_id: generateUniqueId(), is_deleted: false })),
          festival_holidays: responseData?.festival_holidays?.map(day => ({ ...day, unique_id: generateUniqueId(), is_deleted: false }))
        }));
      }
      setLoading((prev) => ({ ...prev, holidayDataLoading: false }));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading((prev) => ({ ...prev, holidayDataLoading: false }));
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDeleteHoliday = (holiday) => {
    if (tabValue === Object.keys(HOLIDAYS)[0]) {
      let updatedValue = holidayFormData?.custom_holidays?.map((value) => {
        if (value?.unique_id === holiday?.unique_id) {
          if (!!value?.id)
            return { ...value, is_deleted: true }
          else
            return null;
        }
        else {
          return value;
        }
      })?.filter(value => !!value);
      setHolidayFormData((prev) => ({
        ...prev,
        custom_holidays: updatedValue,
      }));
    } else {
      let updatedValue = holidayFormData?.festival_holidays?.map((value) => {
        if (value?.unique_id === holiday?.unique_id) {
          if (!!value?.id)
            return { ...value, is_deleted: true }
          else
            return null;
        }
        else {
          return value;
        }
      })?.filter(value => !!value);
      setHolidayFormData((prev) => ({
        ...prev,
        festival_holidays: updatedValue,
      }));
    }
  };

  const handleChange = (e, name, value) => {
    let _name = e?.target?.name || name;
    let _value = e?.target?.value || value;
    setFormData((prev) => ({ ...prev, [_name]: _value }));
  };

  const getIgnoreDates = () => {
    let array = [
      ...holidayFormData?.festival_holidays?.map((value) => value?.date),
      ...holidayFormData?.custom_holidays?.map((value) => value?.date),
    ];
    array.push(...holidaySettingData?.holidaySundayDates);
    array.push(...holidaySettingData?.holidaySaturdayDates);
    return Array.from(new Set(array?.map((date) => getFormattedDate(date))));
  };

  const handleChangeHolidaySetting = (data) => {
    let ignoreDates = [
      ...data?.holidaySundayDates,
      ...data?.holidaySaturdayDates,
    ]?.map((value) => getFormattedDate(value));
    const filteredArray = holidayFormData?.festival_holidays.map((obj) => {
      const objDate = obj.id
        ? moment(obj.date, "YYYY-MM-DD").format("DD/MM/YYYY")
        : moment(obj.date).format("DD/MM/YYYY");
      let isMatch = ignoreDates.some((value) => value === objDate);
      if (isMatch) {
        if (obj?.id) return { ...obj, canDelete: true };
        else return null;
      } else {
        return obj;
      }
    });
    setHolidayFormData({
      ...holidayFormData,
      festival_holidays: filteredArray?.filter((value) => !!value),
    });
  };

  const getHolidayList = async (year) => {
    try {
      setLoading((prev) => ({ ...prev, holidayCountLoading: true }));
      let body = {
        saturday_setting: "all",
        sunday_setting: "all",
        setting_start_date: year?.setting_start_date,
        setting_end_date: year?.setting_end_date,
      };
      let response = await axiosInstance.post(
        API.getHolidayDatesBySettings(currentUser?.organization_id, year?.id),
        body
      );
      if (response.status === 200) {
        let { holidaySaturdayDates, holidaySundayDates } = response.data;
        setHolidaySettingData({ holidaySaturdayDates, holidaySundayDates });
        setLoading((prev) => ({ ...prev, holidayCountLoading: false }));
      } else {
        setLoading((prev) => ({ ...prev, holidayCountLoading: false }));
      }
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, holidayCountLoading: false }));
    }
  };

  const getHolidayListBySettings = useMemo(async () => {
    try {
      let yearId = holidayFormData?.year?.id;
      if (
        !loading.dateLoading &&
        holidayFormData?.saturday_setting !== "false" &&
        holidayFormData?.sunday_setting !== "false"
      ) {
        setLoading((prev) => ({ ...prev, holidayCountLoading: true }));
        let body = {
          saturday_setting: holidayFormData?.saturday_setting,
          sunday_setting: holidayFormData?.sunday_setting,
          setting_start_date: holidayFormData?.year?.setting_start_date,
          setting_end_date: holidayFormData?.year?.setting_end_date,
        };
        let response = await axiosInstance.post(
          API.getHolidayDatesBySettings(currentUser?.organization_id, yearId),
          body
        );
        if (response.status === 200) {
          let { holidaySaturdayDates, holidaySundayDates } = response.data;
          handleChangeHolidaySetting(response.data);
          setHolidaySettingData({ holidaySaturdayDates, holidaySundayDates });
          setLoading((prev) => ({ ...prev, holidayCountLoading: false }));
        } else {
          setLoading((prev) => ({ ...prev, holidayCountLoading: false }));
        }
      }
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, holidayCountLoading: false }));
    }
  }, [
    currentUser?.organization_id,
    holidayFormData?.saturday_setting,
    holidayFormData?.sunday_setting,
    holidayFormData?.year,
  ]);

  const handleChangeHolidayYear = async (event) => {
    let selectedData = holidayYears?.find(
      (holiday) => holiday?.id === event.target.value
    );
    setHolidayFormData({
      ...initialHolidayFormData,
      year: selectedData,
      setting_start_date: selectedData?.year?.setting_start_date,
      setting_end_date: selectedData?.year?.setting_end_date,
    });
    setFormData(initialFormData);
    await getHolidayList(selectedData);
    await getHolidayData(selectedData?.id);
  };

  if (["super_admin"].includes(currentUser.user_type)) {
    return <Navigate to={URLS.AdminDashboard} />;
  }

  const handleChangeFunction = (name, value) => {
    setHolidayFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitHoliday = async (event) => {
    event?.preventDefault();
    try {
      setLoading((prev) => ({ ...prev, formSubmitting: true }));
      let payload = {
        year: holidayFormData?.year?.year,
        saturday_setting: holidayFormData.saturday_setting,
        sunday_setting: holidayFormData.sunday_setting,
        setting_start_date: holidayFormData?.year?.setting_start_date,
        setting_end_date: holidayFormData?.year?.setting_end_date,
        festival_holiday: !!holidayData?.festival_holidays?.length
          ? [
            // ...holidayData?.festival_holidays,
            ...holidayFormData.festival_holidays?.map((value) => {
              let obj = {
                date: value?.date,
                name: value?.name,
              };
              if (!!value?.id) {
                obj['id'] = value?.id;
              }
              if (!!value?.is_deleted) {
                obj['is_deleted'] = value?.is_deleted;
              }
              return obj;
            }),
          ]
          : holidayFormData.festival_holidays?.map((value) => {
            let obj = {
              date: value?.date,
              name: value?.name,
            };
            if (!!value?.id) {
              obj['id'] = value?.id;
            }
            if (!!value?.is_deleted) {
              obj['is_deleted'] = value?.is_deleted;
            }
            return obj;
          }),
        custom_holiday: !!holidayData?.custom_holidays?.length && !holidayFormData?.custom_setting_status
          ? [
            ...holidayData?.custom_holidays?.map((value) => {
              return { ...value, is_deleted: true }
            }),
          ]
          : holidayFormData?.custom_holidays?.map((value) => {
            let obj = {
              date: value?.date,
              name: value?.name,
            };
            if (!!value?.id) {
              obj['id'] = value?.id;
            }
            if (!!value?.is_deleted) {
              obj['is_deleted'] = value?.is_deleted;
            }
            return obj;
          }),
      };
      let response = await axiosInstance.put(
        API.updateHolidaySetting(
          currentUser?.organization_id,
          holidayFormData?.year?.id
        ),
        payload
      );
      if (response.status === 200) {
        showAlert(response.data.message);
        navigate(URLS.AdministratorHolidays);
      }
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    }
  };

  const onOpenDatePicker = () => {
    let isObject = formData?.date === null ? false : typeof formData?.date === 'object' ? true : false;
    if (!formData?.date?.length && !isObject && holidayFormData?.year?.year) {
      let monthNumber = moment().month(holidayData?.renewal_month).format("M");
      let date = new Date();
      if (monthNumber > (new Date().getMonth() + 1)) {
        date.setDate(1);
        date.setMonth((monthNumber - 1));
      }
      date.setFullYear(holidayFormData?.year?.year);

      setFormData((prev) => ({
        ...prev,
        date: date
      }));
    }
  }

  const isDisableDelete = (holidayDate) => {
    if (holidayDate) {
      let date = new Date(moment(new Date()).format('YYYY/MM/DD'));

      return new Date(convertDateFromFormat(
        holidayDate, 'YYYY/MM/DD', 'YYYY-MM-DD'
      )).toString() === date.toString() ? false : new Date(convertDateFromFormat(
        holidayDate, 'YYYY/MM/DD', 'YYYY-MM-DD'
      )) < date;
    }
    return false;
  }

  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <Breadcrumb isBack={true} title={"Holidays Settings"}
        pageTitle={'Edit Holidays'}
        isPathShow={false}
        onBackClick={() => handleBackClick()} />
      {loading.dateLoading || loading.holidayDataLoading ? (
        <CircularLoader height="500px" />
      ) : (
        <Box sx={{ flex: "1", overflowY: "auto" }}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ width: "100%" }}
            p={4}
          >
            <form onSubmit={handleSubmitHoliday}>
              <Stack spacing={3}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item width={265} maxWidth={265}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel htmlFor="year_label">Select Year</InputLabel>
                      <Select
                        inputProps={{ id: "year_label" }}
                        value={holidayFormData?.year?.id || ""}
                        onChange={(event) => {
                          handleChangeHolidayYear(event);
                          searchParams.set('selectedYearId', event.target.value);
                          navigate({
                            pathname: URLS.AdministratorHolidaysCreate,
                            search: searchParams.toString(),
                          }, {
                            replace: true
                          });
                        }}
                        disabled={true}
                        name="year"
                        id="year"
                        label="Select Year"
                        sx={{
                          ".MuiSelect-select": {
                            fontSize: 14,
                            fontWeight: 400,
                            color: "dark.800",
                          },
                        }}
                      >
                        {holidayYears?.map((yearData, index) => (
                          <MenuItem key={index} value={yearData?.id}>
                            {getYearObject(yearData)?.yearDashedFormat}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        mb: 2,
                      }}
                    >
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        {Object.entries(HOLIDAYS)?.map((holiday, index) => (
                          <Tab key={index} label={holiday[1]} value={holiday[0]} />
                        ))}
                      </Tabs>
                    </Box>
                    <Box
                      bgcolor="secondary"
                      sx={{
                        ".tabs-detail > .MuiBox-root": {
                          padding: "0px",
                        },
                      }}
                    >
                      {Object.keys(HOLIDAYS)?.map((holidayType, index) => (
                        <CustomTabPanel
                          key={index}
                          value={tabValue}
                          index={holidayType}
                          className="tabs-detail"
                        >
                          <Box>
                            <Stack spacing="1">
                              {tabValue === Object.keys(HOLIDAYS)[0] ? (
                                <>
                                  <Grid container>
                                    <Grid
                                      item
                                      minWidth={120}
                                      maxWidth={120}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            size="small"
                                            name="saturday_setting_status"
                                            checked={
                                              holidayFormData.saturday_setting_status
                                            }
                                            value={
                                              holidayFormData.saturday_setting_status
                                            }
                                            onChange={(e) => {
                                              setHolidayFormData(
                                                (prev) => ({
                                                  ...prev,
                                                  saturday_setting_status:
                                                    e.target.checked,
                                                  saturday_setting: !e
                                                    .target.checked
                                                    ? "false"
                                                    : "all",
                                                })
                                              );
                                            }}
                                            disabled={
                                              holidayData?.saturday_setting ===
                                              "all"
                                            }
                                          />
                                        }
                                        label="Saturday"
                                      />
                                    </Grid>
                                    {Boolean(
                                      holidayFormData.saturday_setting_status
                                    ) ? (
                                      <Grid item flex={1}>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          sx={{
                                            ".MuiFormControlLabel-label": {
                                              fontSize: 14,
                                              color:
                                                "rgba(38, 38, 38, 0.87)",
                                              fontWeight: 400,
                                            },
                                            ".MuiFormControlLabel-root:not(:last-child)":
                                            {
                                              marginRight: 3,
                                            },
                                          }}
                                          name="saturday_setting"
                                          value={
                                            holidayFormData.saturday_setting
                                          }
                                          onChange={(event) =>
                                            handleChangeFunction(
                                              event.target.name,
                                              event.target.value
                                            )
                                          }
                                        >
                                          <FormControlLabel
                                            value="all"
                                            control={<Radio size="small" />}
                                            label="All Saturday"
                                          />
                                          <FormControlLabel
                                            value="first_third"
                                            control={<Radio size="small" />}
                                            label="1st, 3rd and 5th Saturday"
                                            disabled={[
                                              "all",
                                              "second_fourth",
                                              "alternate"
                                            ].includes(
                                              holidayData?.saturday_setting
                                            )}
                                          />
                                          <FormControlLabel
                                            value="second_fourth"
                                            control={<Radio size="small" />}
                                            label="2nd and 4rd Saturday"
                                            disabled={[
                                              "all",
                                              "first_third",
                                              "alternate"
                                            ].includes(
                                              holidayData?.saturday_setting
                                            )}
                                          />
                                          <FormControlLabel
                                            value="alternate"
                                            control={<Radio size="small" />}
                                            label="Alternate"
                                            disabled={[
                                              "all",
                                              "first_third",
                                              "second_fourth",
                                            ].includes(
                                              holidayData?.saturday_setting
                                            )}
                                          />
                                        </RadioGroup>
                                      </Grid>
                                    ) : null}
                                    {Boolean(
                                      holidayFormData.saturday_setting
                                    ) &&
                                      [
                                        "all",
                                        "first_third",
                                        "second_fourth",
                                        "alternate"
                                      ].includes(
                                        holidayFormData.saturday_setting
                                      ) ? (
                                      <Grid item>
                                        <Typography
                                          color="primary"
                                          fontSize={12}
                                          fontWeight={500}
                                          mt={1.5}
                                          sx={{
                                            lineHeight: "18px",
                                            letterSpacing: "0.15px",
                                          }}
                                        >
                                          {holidaySettingData
                                            ?.holidaySaturdayDates
                                            ?.length || 0}{" "}
                                          Saturday
                                        </Typography>
                                      </Grid>
                                    ) : null}
                                  </Grid>
                                  <Grid container>
                                    <Grid
                                      item
                                      minWidth={120}
                                      maxWidth={120}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            size="small"
                                            name="sunday_setting_status"
                                            checked={
                                              holidayFormData.sunday_setting_status
                                            }
                                            value={
                                              holidayFormData.sunday_setting_status
                                            }
                                            onChange={(e) => {
                                              setHolidayFormData(
                                                (prev) => ({
                                                  ...prev,
                                                  sunday_setting_status:
                                                    e.target.checked,
                                                  sunday_setting: !e.target
                                                    .checked
                                                    ? "false"
                                                    : "all",
                                                })
                                              );
                                            }}
                                            disabled={
                                              holidayData?.sunday_setting ===
                                              "all"
                                            }
                                          />
                                        }
                                        label="Sunday"
                                      />
                                    </Grid>
                                    {Boolean(
                                      holidayFormData.sunday_setting_status
                                    ) ? (
                                      <Grid item flex={1}>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          sx={{
                                            ".MuiFormControlLabel-label": {
                                              fontSize: 14,
                                              color:
                                                "rgba(38, 38, 38, 0.87)",
                                              fontWeight: 400,
                                            },
                                            ".MuiFormControlLabel-root:not(:last-child)":
                                            {
                                              marginRight: 3,
                                            },
                                          }}
                                          name="sunday_setting"
                                          value={
                                            holidayFormData.sunday_setting
                                          }
                                          onChange={(event) =>
                                            handleChangeFunction(
                                              event.target.name,
                                              event.target.value
                                            )
                                          }
                                        >
                                          <FormControlLabel
                                            value="all"
                                            control={<Radio size="small" />}
                                            label="All Sunday"
                                          />
                                          <FormControlLabel
                                            value="first_third"
                                            control={<Radio size="small" />}
                                            label="1st, 3rd and 5th Sunday"
                                            disabled={[
                                              "all",
                                              "second_fourth",
                                              "alternate"
                                            ].includes(
                                              holidayData?.sunday_setting
                                            )}
                                          />
                                          <FormControlLabel
                                            value="second_fourth"
                                            control={<Radio size="small" />}
                                            label="2nd and 4rd Sunday"
                                            disabled={[
                                              "all",
                                              "first_third",
                                              "alternate"
                                            ].includes(
                                              holidayData?.sunday_setting
                                            )}
                                          />
                                          <FormControlLabel
                                            value="alternate"
                                            control={<Radio size="small" />}
                                            label="Alternate"
                                            disabled={[
                                              "all",
                                              "first_third",
                                              "second_fourth",
                                            ].includes(
                                              holidayData?.sunday_setting
                                            )}
                                          />
                                        </RadioGroup>
                                      </Grid>
                                    ) : null}
                                    {Boolean(
                                      holidayFormData.sunday_setting
                                    ) &&
                                      [
                                        "all",
                                        "first_third",
                                        "second_fourth",
                                        "alternate"
                                      ].includes(
                                        holidayFormData.sunday_setting
                                      ) ? (
                                      <Grid item>
                                        <Typography
                                          color="primary"
                                          fontSize={12}
                                          fontWeight={500}
                                          mt={1.5}
                                          sx={{
                                            lineHeight: "18px",
                                            letterSpacing: "0.15px",
                                          }}
                                        >
                                          {holidaySettingData
                                            ?.holidaySundayDates?.length ||
                                            0}{" "}
                                          Sunday
                                        </Typography>
                                      </Grid>
                                    ) : null}
                                  </Grid>
                                </>
                              ) : null}
                              <Grid container flexDirection="column">
                                {tabValue === Object.keys(HOLIDAYS)[0] ? (
                                  <Grid item>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          size="small"
                                          name="custom_setting_status"
                                          checked={
                                            holidayFormData.custom_setting_status
                                          }
                                          value={
                                            holidayFormData.custom_setting_status
                                          }
                                          onChange={(event) =>
                                            handleChangeFunction(
                                              event.target.name,
                                              event.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Custom"
                                    />
                                  </Grid>
                                ) : null}
                                <Stack spacing={4} pl={1} mt={2}>
                                  {(tabValue === Object.keys(HOLIDAYS)[0] && holidayFormData.custom_setting_status) || (tabValue === Object.keys(HOLIDAYS)[1]) ? <Grid
                                    width={650}
                                    sx={{
                                      maxWidth: "100%",
                                      marginBottom: "16px !important",
                                    }}
                                  >
                                    <TableContainer>
                                      <Table>
                                        <TableBody
                                          sx={{
                                            td: {
                                              padding: "5px 4px",
                                              color: "dark.800",
                                              fontWeight: 500,
                                              fontSize: 14,
                                              lineHeight: "24px",
                                              letterSpacing: "0.15px",
                                              border: "none",
                                            },
                                            "td:first-of-type": {
                                              paddingLeft: 0,
                                            },
                                            "td:last-child": {
                                              padding: 0,
                                            },
                                          }}
                                        >
                                          {!!holidayFormData.custom_setting_status ||
                                            tabValue === Object.keys(HOLIDAYS)[1] ? (
                                            <TableRow>
                                              <TableCell
                                                sx={{
                                                  width: "276px",
                                                  minWidth: "276px",
                                                  maxWidth: "276px",
                                                  paddingRight: '16px !important',
                                                  paddingBottom: '20px !important',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  flex={1}
                                                  sx={{
                                                    input: {
                                                      width: "100%",
                                                      height: 30,
                                                      border: "none !important",
                                                      boxShadow: "none !important",
                                                      borderRadius: "0px",
                                                      paddingBottom: "6px",
                                                      paddingLeft: "28px",
                                                      borderBottom:
                                                        "1px solid rgba(0, 0, 0, 0.42) !important",
                                                      margin: "0",
                                                      position: "relative",
                                                      background: "transparent",
                                                      color: "dark.800",
                                                      fontSize: 14,
                                                      fontWeight: 400,
                                                      lineHeight: "24px",
                                                      letterSpacing: "0.15px",
                                                    },
                                                    "input:hover": {
                                                      borderBottomColor:
                                                        "#333333 !important",
                                                    },
                                                    "input:focus": {
                                                      borderBottomColor:
                                                        "#047FE0 !important",
                                                    },
                                                    ".rmdp-container": {
                                                      minWidth: "100px",
                                                    },
                                                  }}
                                                >
                                                  <Typography
                                                    id="demo-simple-select-standard-label"
                                                    fontSize={12}
                                                    fontWeight={400}
                                                    color="dark.600"
                                                    sx={{
                                                      width: "100%",
                                                      letterSpacing: "0.15px",
                                                      marginTop: "-3px",
                                                      marginBottom: "2px",
                                                    }}
                                                  >
                                                    Holiday Date
                                                  </Typography>
                                                  <div style={{ height: 1 }}>
                                                    <CalendarTodayIcon
                                                      sx={{
                                                        fontSize: 20,
                                                        color: "#3F5C76",
                                                        marginBottom: "-5px",
                                                        marginLeft: "2px",
                                                      }}
                                                    />
                                                  </div>
                                                  <SingleDatePicker
                                                    value={formData?.date || ''}
                                                    ignoreDates={!loading?.holidayCountLoading ? getIgnoreDates() : []}
                                                    onChange={(value) =>
                                                      setFormData((prev) => ({
                                                        ...prev,
                                                        date: new Date(value),
                                                      }))
                                                    }
                                                    isMultiple={false}
                                                    onOpen={onOpenDatePicker}
                                                    minDate={new Date(holidayFormData?.year?.setting_start_date)}
                                                    maxDate={new Date(holidayFormData?.year?.setting_end_date)}
                                                    isDisableRequest={true}
                                                  />
                                                </Grid>
                                              </TableCell>
                                              <TableCell sx={{
                                                minWidth: "261px !important",
                                                maxWidth: "261px !important",
                                                padding: "0px 16px 16px 16px !important"
                                              }}>
                                                {tabValue === Object.keys(HOLIDAYS)[0] ?
                                                  <Input
                                                    label={"Festival / Occasion"}
                                                    variant="standard"
                                                    sx={{ width: "100%" }}
                                                    name="name"
                                                    id="name"
                                                    value={formData?.name ? titleCase(formData?.name) : ""}
                                                    onChange={handleChange}
                                                    required={false}
                                                  // isOnlyCapitalFirstLatter={true}
                                                  /> :
                                                  <AutocompleteWithAddOption
                                                    label={"Topic"}
                                                    variant="standard"
                                                    name="name"
                                                    id="name"
                                                    sx={{
                                                      width: "100%",
                                                      // "input": {
                                                      //   textTransform: 'capitalize',
                                                      // }
                                                    }}
                                                    disabledItems={holidayFormData?.festival_holidays?.filter((value) => !value?.is_deleted)}
                                                    value={titleCase(formData?.name)}
                                                    onChange={(event, newValue) => handleChange(event, 'name', titleCase(newValue))}
                                                    setHolidayTitles={setHolidayTitles}
                                                    holidayTitles={holidayTitles}
                                                  />
                                                }
                                              </TableCell>
                                              <TableCell sx={{
                                                padding: "0px 0px 10px 16px !important",
                                                width: '80px',
                                                maxWidth: '80px',
                                                minWidth: '80px',
                                                textAlign: 'center',
                                              }} >
                                                <Button
                                                  variant="contained"
                                                  color="secondary"
                                                  size="medium"
                                                  sx={{
                                                    boxShadow: "none !important",
                                                    marginTop: "6px",
                                                    textTransform: "none",
                                                  }}
                                                  disabled={
                                                    !formData?.date ||
                                                    !formData?.name
                                                  }
                                                  onClick={handleAddClick}
                                                >
                                                  Add
                                                </Button>
                                              </TableCell>
                                            </TableRow>
                                          ) : null}
                                          {(tabValue ===
                                            Object.keys(HOLIDAYS)[0]
                                            ? handleSortOfficeHoliday(null, holidayFormData?.custom_holidays)
                                            : handleSortFestivalHolidays(holidayFormData?.festival_holidays)
                                          )?.filter(value => value?.is_deleted === false)?.map((holiday, index) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                sx={{
                                                  padding: '0px 16px 0px 0px !important'
                                                }}
                                              >
                                                <Box sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "flex-start",
                                                  gap: 2,
                                                }}>
                                                  <Typography color="dark.800" fontSize={14} fontWeight={500} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>
                                                    {index + 1}. {"  "}
                                                    {getFormattedDate(
                                                      holiday?.date
                                                    )}
                                                  </Typography>
                                                  <Typography color="dark.800" fontSize={14} fontWeight={500} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>
                                                    {moment(
                                                      holiday?.date
                                                    ).format("dddd")}
                                                  </Typography>
                                                </Box>
                                              </TableCell>
                                              <TableCell sx={{
                                                padding: "0px 16px !important"
                                              }}>
                                                - {titleCase(holiday?.name)}
                                              </TableCell>
                                              <TableCell sx={{
                                                padding: "0px 0px 0px 16px !important",
                                                width: '80px',
                                                maxWidth: '80px',
                                                minWidth: '80px',
                                                textAlign: 'center',
                                              }}>
                                                <IconButton
                                                  aria-label="delete"
                                                  size="small"
                                                  disabled={isDisableDelete(holiday?.date)}
                                                  onClick={() =>
                                                    handleDeleteHoliday(
                                                      holiday
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon
                                                    fontSize="small"
                                                  />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid> : null}
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      flexBasis="100%"
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{
                                          boxShadow: "0",
                                          color: "white",
                                          fontSize: "14px",
                                          paddingTop: "6px",
                                          paddingBottom: "6px",
                                          textTransform: "none",
                                          "&:hover": { boxShadow: "0" },
                                        }}
                                        type="submit"
                                        isButtonLoading={
                                          loading.formSubmitting
                                        }
                                        disabled={loading.formSubmitting}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="lightGray"
                                        size="medium"
                                        sx={{
                                          boxShadow: "0",
                                          color: "#3F5C76",
                                          fontSize: "14px",
                                          textTransform: "none",
                                          marginLeft: "20px",
                                          padding: "6px",
                                          "&:hover": { boxShadow: "0" },
                                        }}
                                        type="button"
                                        onClick={handleBackClick}
                                      >
                                        Cancel
                                      </Button>
                                    </Box>
                                  </Box>
                                </Stack>
                              </Grid>
                            </Stack>
                          </Box>
                        </CustomTabPanel>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </Stack>
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreateHoliday;