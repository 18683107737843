import { Box, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TableSortLabel, Tooltip, tooltipClasses, Typography, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { getDayName, getFormattedDate, getTaskTypeIcon, secondsToTime, sortArrayByKey, titleCase } from '../../../../utils';
import moment from 'moment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import ICONS from '../../../../constants/icons';
import { styled } from '@mui/styles';
import { TASK_TYPE_LIST } from '../../../../constants/default-values';
import CircularLoader from '../../../../components/CircularLoader';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        padding: "8px 12px",
    },
}));


const MemberTimeTableData = ({ duration, modalDetails, handleModalClose, selectedProjectData }) => {
    const [sortType, setSortType] = useState("desc");
    let [taskData, setTaskData] = useState([...modalDetails?.taskDetails])
    let [taskDataByDate, setTaskDataByDate] = useState({});
    const [expandedRow, setExpandedRow] = useState([]);
    const [isLoading, setIsLoading] = useState({
        taskDataLoading: false,
    });

    const handleSortClick = (sortTypeString) => {
        setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
    }

    useEffect(() => {
        if (modalDetails?.isShow) {
            getUserTaskData(modalDetails?.userId, modalDetails?.actualDate, selectedProjectData?.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalDetails])

    const getEndDate = (date) => {
        if (duration === 'daily') {
            return date;
        } else if (duration === 'weekly') {
            return moment(date).endOf('week').format('YYYY-MM-DD')
        } else {
            return moment(date).endOf('month').format('YYYY-MM-DD')
        }
    }

    const getUserTaskData = async (userId, date, projectId) => {
        try {
            setIsLoading(prev => ({ ...prev, taskDataLoading: true }))
            let body = {
                projectId: projectId,
                startDate: date,
                endDate: getEndDate(date),
            }
            let response = await axiosInstance.post(API.getTaskDataByUserAndProjectId(userId), body);
            if (response?.status === 200) {
                let workLogData = sortArrayByKey(response?.data?.workLogData, "asc", 'date');
                setTaskData(workLogData);
                if (duration !== 'daily') {
                    let groupedDateData = sortArrayByKey(workLogData, "asc", 'id')?.reduce((allAddedTaskData, currentData) => {
                        let date = currentData?.date;
                        if (!allAddedTaskData[date]) {
                            allAddedTaskData[date] = [];
                        }
                        allAddedTaskData[date].push(currentData)
                        return allAddedTaskData
                    }, {});

                    setTaskDataByDate(groupedDateData)
                }
            }
            setIsLoading(prev => ({ ...prev, taskDataLoading: false }))
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, taskDataLoading: false }))
        }
    }

    const handleRowClick = (date) => {
        setExpandedRow((prev) => prev.includes(date) ? prev?.filter(name => name !== date) : [...prev, date])
    }

    const totalHoursOfDay = (taskList) => {
        return taskList?.reduce((total, currentData) => {
            total += currentData?.duration;
            return total
        }, 0)
    }

    const totalDurationOfAllTask = () => {
        return modalDetails?.taskDetails?.reduce((total, currentData) => {
            total += currentData?.duration;
            return total
        }, 0)
    }

    const sortedKeys = Object.keys(taskDataByDate)?.sort((a, b) => sortType === 'asc' ? new Date(a) - new Date(b) : new Date(b) - new Date(a));

    const sortedData = {};
    sortedKeys?.forEach(key => {
        sortedData[key] = taskDataByDate[key];
    });

    return (
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }} overflow="auto">
            <Box py={2.5} px={3} borderBottom={"1px solid #E2E4EC"} bgcolor={"white"} position={"sticky"} top={0} zIndex={10} display={'flex'} justifyContent={'space-between'}>
                {/* <Typography variant='h4' color={"dark.800"} fontSize={20} fontWeight={500} lineHeight={"32px"} letterSpacing={"0.15px"} display={"block"}>{titleCase(selectedProjectData?.title)}</Typography> */}
                <Box display={"flex"} alignItems={"center"} gap={"10px"} flex={1}>
                    <Box bgcolor={"rgba(0, 99, 191, 0.1)"} border={"1px solid rgba(4, 127, 224, 0.2)"} minWidth={35} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ borderRadius: "3px", padding: "1px 5px" }}>
                        <Typography color={"primary"} fontSize={13} fontWeight={600} lineHeight={"20px"}> {selectedProjectData?.short_code}</Typography>
                    </Box>
                    <Typography color={"dark.800"} fontSize={24} fontWeight={500} lineHeight={"34.32px"} letterSpacing={"0.17px"}>{titleCase(selectedProjectData?.title)}</Typography>
                </Box>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className="edit-btn"
                        onClick={() => handleModalClose()}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Box>
            {isLoading?.taskDataLoading ?
                <CircularLoader height="500px" /> :
                <Stack p={3} gap={2}>
                    <Box display={'flex'} alignItems={"center"} gap={"6px"}>
                        <Box width={14} height={14} sx={{ background: modalDetails?.color ?? '#000' }}>
                        </Box>
                        <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                            {titleCase(modalDetails?.userName)} ({secondsToTime(totalDurationOfAllTask())})
                        </Typography>
                    </Box>
                    {duration === 'daily' ?
                        <Box display={'flex'}>
                            <Typography color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={"24px"} letterSpacing={"0.17px"}>
                                For {modalDetails?.dateLabel}
                            </Typography>
                        </Box>
                        : duration === 'weekly' ?
                            <Box>
                                <Typography color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={"24px"} letterSpacing={"0.17px"}>
                                    {modalDetails?.dateLabel}
                                </Typography>
                            </Box>
                            :
                            <Box display={'flex'} gap={1}>
                                <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"}>
                                    {/* {modalDetails?.dateLabel} <b>({modalDetails?.date} - {moment(modalDetails?.date)?.endOf('month')?.format('YYYY-MM-DD')})</b> */}
                                    {modalDetails?.dateLabel} <b>({moment(modalDetails?.date)?.startOf('month')?.format('YYYY-MM-DD')} - {moment(modalDetails?.date)?.endOf('month')?.format('YYYY-MM-DD')})</b>
                                </Typography>
                            </Box>}
                    <Stack>
                        <Paper sx={{
                            border: "1px solid #E0E0E0",
                            borderRadius: "3px",
                            boxShadow: 'none',
                        }}
                        >
                            <TableContainer>
                                <Table>
                                    <TableHead
                                        sx={{
                                            th: {
                                                color: "dark.800",
                                                fontWeight: 500,
                                                fontSize: 14,
                                                lineHeight: "24px",
                                                letterSpacing: "0.17px",
                                                padding: "5px 16px",
                                                textAlign: "left",
                                                background: "rgba(245, 248, 250, 1)",
                                            },
                                        }}
                                    >
                                        <TableRow>
                                            {duration !== 'daily' ?
                                                <TableCell sx={{ width: 200, minWidth: 200, maxWidth: 200 }}>
                                                    <TableSortLabel
                                                        active={true}
                                                        direction={sortType}
                                                        onClick={() => handleSortClick(sortType)}
                                                    >
                                                        Date
                                                    </TableSortLabel>
                                                </TableCell> : null}
                                            <TableCell maxWidth={0}>
                                                Tasks
                                            </TableCell>
                                            <TableCell sx={{ width: 100, minWidth: 100, maxWidth: 100 }}>Duration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            "tr:hover": {
                                                background: "rgba(247, 247, 247, 1)",
                                                '.task-name': {
                                                    color: "#047FE0",
                                                    textDecoration: "underline",
                                                },
                                                '.external-link-icon': {
                                                    opacity: 1,
                                                    visibility: "visible",
                                                }
                                            },
                                            td: {
                                                color: "dark.800",
                                                fontWeight: 400,
                                                fontSize: 14,
                                                lineHeight: "20px",
                                                letterSpacing: "0.17px",
                                                padding: "7px 16px",
                                                textAlign: "left",
                                            },
                                        }}
                                    >
                                        {
                                            taskData?.length ?
                                                duration !== 'daily' ?
                                                    Object.entries(sortedData)?.map((taskObj, index) => {
                                                        let isOpen = expandedRow?.includes(taskObj?.[0]);
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <TableRow hover sx={{ cursor: 'pointer' }} onClick={() => handleRowClick(taskObj?.[0])}>
                                                                    <TableCell colSpan={2}>
                                                                        <Box display='flex' alignItems='center' gap={0.5}>
                                                                            {expandedRow?.includes(taskObj[0]) ? <ExpandMoreIcon sx={{ fontSize: '18px', mr: 0.5 }} /> : <ChevronRightIcon sx={{ fontSize: '18px', mr: 0.5 }} />}
                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>{taskObj?.[0]}</Typography>
                                                                            <Typography color={"dark.600"} fontSize={13} fontWeight={400} lineHeight={"19px"} letterSpacing={"0.17px"}>({getDayName(getFormattedDate(taskObj?.[0]))})</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: '700 !important' }}>
                                                                        {secondsToTime(totalHoursOfDay(taskObj[1]))}
                                                                    </TableCell>
                                                                </TableRow>
                                                                {isOpen ?
                                                                    <>
                                                                        {taskObj[1]?.map((taskDetails, subIndex) => (
                                                                            <TableRow hover key={subIndex}>
                                                                                <TableCell>
                                                                                </TableCell>
                                                                                <TableCell sx={{ maxWidth: 0, cursor: "pointer" }}>
                                                                                    <Box display={'flex'} alignItems={"center"} gap={1}>
                                                                                        <HtmlTooltip arrow
                                                                                            title={
                                                                                                <React.Fragment>
                                                                                                    <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"19px"}>{TASK_TYPE_LIST[taskDetails?.Task?.type]}</Typography>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        >
                                                                                            <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                                                {getTaskTypeIcon(taskDetails?.Task?.type)}
                                                                                            </Box>
                                                                                        </HtmlTooltip>
                                                                                        <Box overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"}>
                                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"} overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"} className='task-name'>
                                                                                                {selectedProjectData?.short_code}-{taskDetails?.Task?.task_no} - {taskDetails?.Task?.title}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box width={14} minWidth={14} height={14} color={"primary.800"} className="external-link-icon" visibility={"hidden"} sx={{ opacity: 0, }}>
                                                                                            {ICONS.ExternalLinkIcon}
                                                                                        </Box>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {secondsToTime(taskDetails?.duration)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>
                                                                    : null}
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    :
                                                    taskData?.map((taskDetails, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell sx={{ cursor: "pointer" }}>
                                                                <Box display={'flex'} alignItems={"center"} gap={1}>
                                                                    <HtmlTooltip arrow
                                                                        title={
                                                                            <React.Fragment>
                                                                                <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"19px"}>{TASK_TYPE_LIST[taskDetails?.Task?.type]}</Typography>
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                            {getTaskTypeIcon(taskDetails?.Task?.type)}
                                                                        </Box>
                                                                    </HtmlTooltip>
                                                                    <Box overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"}>
                                                                        <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"} overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"} className='task-name'>
                                                                            {selectedProjectData?.short_code}-{taskDetails?.Task?.task_no} - {taskDetails?.Task?.title}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box width={14} minWidth={14} height={14} color={"primary.800"} className="external-link-icon" visibility={"hidden"} sx={{ opacity: 0, }}>
                                                                        {ICONS.ExternalLinkIcon}
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography color={"dark"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>
                                                                    {secondsToTime(taskDetails?.duration)}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                :
                                                null
                                        }
                                    </TableBody>
                                    {duration === 'daily' ? <TableFooter>
                                        <TableRow
                                            sx={{
                                                'td': {
                                                    color: "secondary.main",
                                                    fontWeight: 700,
                                                    fontSize: 14,
                                                    lineHeight: "20px",
                                                    letterSpacing: "0.17px",
                                                    padding: "11px 16px",
                                                    textAlign: "left",
                                                }
                                            }}
                                        >
                                            <TableCell>
                                                Total Working Hours
                                            </TableCell>
                                            <TableCell sx={{ color: "rgba(0, 0, 0, 0.87) !important" }}>
                                                {secondsToTime(totalHoursOfDay(taskData))}
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter> : null}
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Stack>
                </Stack>}
        </Box>
    )
}

export default MemberTimeTableData