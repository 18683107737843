import React, { useEffect, useRef, useState } from 'react'
import { PROJECT_PHASE_STATUS, PROJECT_TASK_PRIORITY, TASK_STATUS_LIST, TASK_TYPE_LIST } from '../../../../constants/default-values'
import { convertManualTimeToSeconds, convertToBase64, generateUniqueId, getFormattedDate, getPriorotyIcon, getTaskStatus, getTaskTypeIcon, sortArrayByKey } from '../../../../utils'
import Button from '../../../../components/Button'

import {
    Box,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import { getInputId, titleCase } from "../../../../utils";
import { useAlert } from "../../../../hook/useAlert";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import CloseIcon from '@mui/icons-material/Close';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ModeStandbyOutlinedIcon from '@mui/icons-material/ModeStandbyOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import TextEditor from "../../../../components/TextEditor";
import SubjectIcon from '@mui/icons-material/Subject';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import Input from '../../../../components/Input';
import TimeInput from '../../../../components/CustomManualTimePicker';
import CustomFileDropZone from '../../../../components/CustomFileDropZone';
import AttachmentLists from './attachmentLists';
import ICONS from '../../../../constants/icons';
import API from '../../../../axios/api';
import axiosInstance from '../../../../axios';
import { useParams } from 'react-router-dom';
import useAuthentication from '../../../../hook/useAuthentication';

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const CreateTask = ({
    handleCloseTaskDrawer,
    projectPhases,
    handleSubmitTaskData,
    activeMembersData,
}) => {
    const currentUser = useAuthentication()?.getCurrentUser();
    const initialTaskData = {
        title: "",
        status: Object.keys(TASK_STATUS_LIST)[0],
        priority: PROJECT_TASK_PRIORITY['Medium'],
        project_phase_id: "",
        type: Object.keys(TASK_TYPE_LIST)[0],
        description: "",
        attachments: [],
        assignee: currentUser?.id,
        estimation: "",
    }

    const initialTaskError = {
        title: null,
        status: null,
        priority: null,
        project_phase_id: null,
        type: null,
        description: null,
        attachments: null,
        assignee: null,
        estimation: null
    }
    const params = useParams();
    const [taskData, setTaskData] = useState({ ...initialTaskData });
    const [taskErrors, setTaskErrors] = useState({ ...initialTaskError, estimation: false });
    const [isLoading, setIsLoading] = useState({
        taskSubmitLoading: false,
    });

    const showAlert = useAlert();
    const topDivRef = useRef(null);

    const maxFileSize = 5 * 1000000;
    const maxTotalSize = 10 * 1000000;

    const getFilterdProjectPhase = () => {
        return projectPhases?.filter(phaseData => [PROJECT_PHASE_STATUS['In-Progress'], PROJECT_PHASE_STATUS['Not started']]?.includes(phaseData?.status))
    }

    useEffect(() => {
        if (getFilterdProjectPhase()?.length) {
            let phaseList = sortArrayByKey(getFilterdProjectPhase(), "asc", "number");
            let inProgressPhase = phaseList?.find(phase => phase?.status === "in-progress")
            let notStartedPhase = phaseList?.find(phase => phase?.status === "not-started")

            if (inProgressPhase) {
                setTaskData(prev => ({ ...prev, project_phase_id: inProgressPhase?.id }))
            } else {
                setTaskData(prev => ({ ...prev, project_phase_id: notStartedPhase?.id }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeTaskData = (e) => {
        let { name, value } = e.target;
        setTaskData(prev => ({
            ...prev,
            [name]: value
        }))
        if (name !== "assignee" || name !== "project_phase_id") {
            if (name === "title") {
                setTaskErrors((prev) => ({
                    ...prev,
                    [name]: value?.toString()?.length ? null : "Required"
                }))
            } else {
                setTaskErrors((prev) => ({
                    ...prev,
                    [name]: value?.toString()?.length ? null : "Required"
                }))
            }
        }
    }

    const handleBlurTitleData = (e) => {
        let { name, value } = e.target;
        setTaskErrors((prev) => ({
            ...prev,
            [name]: value?.toString()?.length ?
                value?.trim()?.length < 20 ? "Minimum 20 character required" : value?.trim()?.length > 255 ? "Maxmimum 255 characters allowed" : null
                : "Required"
        }))
    }

    const handleChangeDescription = (value) => {
        setTaskData(prev => ({
            ...prev,
            description: value,
        }))
    }

    const handleTimeChange = (newTime, isError) => {
        setTaskData(prev => ({
            ...prev,
            estimation: newTime
        }))
        setTaskErrors(prev => ({ ...prev, estimation: isError }))
    }

    const handleTimeBlur = (newTime, isError) => {
        setTaskErrors(prev => ({ ...prev, estimation: isError }))
    }

    const fileSizeValidator = (file, type = "file") => {
        const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
        if (type === "validator") {
            if (!allowedFormats.includes(file.type)) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
        } else {
            const allowedFormats = [".jpeg", ".jpg", ".png", ".pdf"];
            const isValidFormat = allowedFormats.some(format => file.name?.toLowerCase()?.endsWith(format));
            if (!isValidFormat) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
            if (file?.size > maxFileSize) {
                showAlert("File size exceeds 5MB limit.", "error");
                return {
                    code: "size-too-large",
                    message: "File size exceeds 5MB limit.",
                };
            }
        }
        return null;
    };

    const totalSizeValidator = (newFiles) => {
        const newTotalSize = newFiles?.reduce((acc, file) => acc + file.size, 0);
        return newTotalSize > maxTotalSize;
    };

    const handleChangeImage = async (event) => {
        const allfiles = event.target.files;
        const files = Array.from(allfiles);
        if (files) {
            const invalidFiles = files
                .map(file => fileSizeValidator(file))
                .filter(error => error !== null);

            if (invalidFiles.length > 0) return;

            if (totalSizeValidator(files)) {
                showAlert("Total size exceeds the limit. Max 10MB allowed.", "error");
                return;
            }

            const updatedFiles = [];

            for (const file of files) {
                let base64 = null;
                if (file instanceof Blob || file instanceof File) {
                    base64 = await convertToBase64(file);
                } else {
                    base64 = file?.base64;
                }
                let fileObject = {
                    name: file?.name,
                    size: file?.size,
                    type: file?.type,
                    preview: URL.createObjectURL(file),
                    unique_id: generateUniqueId(),
                    base64,
                };
                updatedFiles.push(fileObject);
            }
            const limitedFiles = [...updatedFiles]?.filter((item) => item && (item !== null || item !== "null"));
            setTaskData(prev => ({ ...prev, attachments: [...prev?.attachments, ...limitedFiles] }));
        }
    };

    const isValidateTaskData = () => {
        let error = {};
        let estimationError = taskErrors?.estimation;

        if (taskData?.title?.trim()?.length) {
            error['title'] = taskData?.title?.trim()?.length < 20 ? "Minimum 20 character required" :
                taskData?.title?.trim()?.length > 255 ? "Maxmimum 255 characters allowed" : ""
            // error['title'] = ""
        } else {
            error['title'] = "Required"
        }
        if (taskData?.status?.length) {
            error['status'] = ""
        } else {
            error['status'] = "Required"
        }
        if (taskData?.priority?.length) {
            error['priority'] = ""
        } else {
            error['priority'] = "Required"
        }
        // if (taskData?.project_phase_id?.toString()?.length) {
        //     error['project_phase_id'] = ""
        // } else {
        //     error['project_phase_id'] = "Required"
        // }
        if (taskData?.type?.length) {
            error['type'] = ""
        } else {
            error['type'] = "Required"
        }
        // if (taskData?.assignee?.toString()?.length) {
        //     error['assignee'] = ""
        // } else {
        //     error['assignee'] = "Required"
        // }
        let descriptionLength = taskData?.description?.replace(/<(.|\n)*?>/g, '').trim()?.length
        if (descriptionLength > 1 && descriptionLength > 5000) {
            error['description'] = "Up to 5000 characters allowed"
        } else {
            error['description'] = ""
        }
        setTaskErrors(error)
        return Object.values(error)?.some(value => value?.length) || estimationError
    }

    const handleSubmitData = async () => {
        try {
            if (!isValidateTaskData()) {
                try {
                    setIsLoading(prev => ({ ...prev, taskSubmitLoading: true }))
                    function convertObjectToFormData(obj) {
                        const formData = new FormData();
                        obj?.attachments?.filter((value) => !!value?.base64)?.forEach((attachment, index) => {
                            formData.append(`attachments[${index}][name]`, attachment?.name);
                            formData.append(`attachments[${index}][size]`, attachment?.size);
                            formData.append(`attachments[${index}][type]`, attachment?.type);
                            formData.append(`attachments[${index}][base64]`, attachment?.base64);
                            formData.append(`attachments[${index}][unique_id]`, attachment?.unique_id);
                            formData.append(`attachments[${index}][extension]`, `.${attachment?.name?.split('.').pop()}`);
                        });
                        formData.append("project_id", Number(params?.projectId));
                        formData.append("user_id", obj?.assignee ?? null);
                        formData.append("title", obj.title);
                        formData.append("date", getFormattedDate(new Date(), "YYYY-MM-DD"));
                        formData.append("status", obj.status);
                        formData.append("priority", obj.priority);
                        formData.append("is_eod", true);
                        formData.append("project_phase_id", obj.project_phase_id ?? null);
                        formData.append("type", obj.type);
                        formData.append("description", obj.description);
                        formData.append("estimation", convertManualTimeToSeconds(obj.estimation));

                        return formData;
                    }
                    const formData = convertObjectToFormData(taskData);
                    const config = {
                        headers: { "content-type": "multipart/form-data" },
                    };

                    const response = await axiosInstance.post(
                        API.createTask,
                        formData,
                        config
                    );

                    if (response?.status === 200) {
                        handleCloseTaskDrawer();
                        showAlert(response?.data?.message);
                        let createdTaskData = {
                            ...response?.data?.task,
                        }
                        handleSubmitTaskData(createdTaskData);
                    }
                    setIsLoading(prev => ({ ...prev, taskSubmitLoading: false }))
                } catch (error) {
                    console.error(error);
                    setIsLoading(prev => ({ ...prev, taskSubmitLoading: false }))
                    showAlert(error?.response?.data?.message || "Something went wrong.");
                }
            } else {
                scrollToTop();
                showAlert('Please select all required fields', "error")
            }
        } catch (error) {

        }
    }

    const scrollToTop = () => {
        topDivRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <Box width={590} display={"flex"} flexDirection={"column"} flex={1}>
            {
                <>
                    <Box p={2} bgcolor={"white"} borderBottom={"1px solid rgba(0, 0, 0, 0.12)"} position={"sticky"} top={0} zIndex={1}>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                                <Typography variant="body1" color={"dark.800"} fontSize={16} fontWeight={600} lineHeight={"25px"} letterSpacing={"0.15px"}>New Task</Typography>
                            </Box>
                            <IconButton
                                aria-label="Close"
                                size="small"
                                onClick={handleCloseTaskDrawer}
                                sx={{ width: 24, minWidth: 24, height: 24, padding: '2px' }}
                                disabled={isLoading?.taskSubmitLoading}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box flex={1} overflow={"auto"} ref={topDivRef}>
                        <Box p={2} pb={0}>
                            <Input
                                id="title"
                                label="Task Title"
                                variant="standard"
                                type="text"
                                name={"title"}
                                fullWidth
                                required={false}
                                onChange={(e) => handleChangeTaskData(e)}
                                onBlur={(e) => handleBlurTitleData(e)}
                                value={taskData?.title}
                                error={taskErrors?.title?.length && Boolean(taskErrors.title)}
                                helperText={taskErrors?.title && taskErrors?.title}
                                sx={{ mb: 3 }}
                                inputProps={{
                                    width: 500,
                                    maxWidth: 500,
                                    style: {
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                    },
                                }}
                            />

                            <Box mb={3} px={1} width={414}>
                                <Stack spacing={1}>
                                    <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                        <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                            <TaskAltOutlinedIcon fontSize="small" />
                                            <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Type</Typography>
                                        </Box>
                                        <Box flex={1} minHeight={36}
                                            sx={{
                                                '.MuiInputBase-formControl': {
                                                    '&:before': {
                                                        opacity: 0,
                                                    },
                                                    '.MuiSelect-icon': {
                                                        opacity: 0,
                                                    },
                                                    '&:hover': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    }
                                                },
                                                '.Mui-focused': {
                                                    '.MuiSelect-icon': {
                                                        opacity: 1,
                                                    },
                                                },
                                                ".MuiSelect-select": {
                                                    borderRadius: '6px',
                                                    padding: '6px 28px 6px 6px',
                                                    '&:hover': {
                                                        bgcolor: '#F4F4F4',
                                                    },
                                                    'p': {
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        fontSize: 14,
                                                        lineHeight: '24px',
                                                        fontWeight: 600,
                                                        letterSpacing: '0.15px',
                                                    },
                                                },
                                            }}
                                        >
                                            <FormControl size="small" variant="standard" fullWidth>
                                                <Select
                                                    labelId="select-user"
                                                    id="select-user"
                                                    label="Task"
                                                    name='type'
                                                    value={taskData?.type || ''}
                                                    onChange={(e) => handleChangeTaskData(e)}
                                                    inputProps={{ id: getInputId("type", 0) }}
                                                >
                                                    {Object.keys(TASK_TYPE_LIST)?.map((key, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={key}
                                                            sx={{
                                                                padding: 1,
                                                            }}
                                                        >
                                                            <Box display={'flex'}>
                                                                <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}
                                                                    sx={{ 'svg': { width: 20, height: 20, minWidth: 20 } }}
                                                                >
                                                                    {getTaskTypeIcon(key)}
                                                                </Box>
                                                                <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                    {TASK_TYPE_LIST[key]}
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {!!taskErrors?.type?.length && (
                                                <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                    {taskErrors?.type}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                        <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                            <ModeStandbyOutlinedIcon fontSize="small" />
                                            <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Status</Typography>
                                        </Box>
                                        <Box flex={1} minHeight={36}
                                            sx={{
                                                '.MuiInputBase-formControl': {
                                                    '&:before': {
                                                        opacity: 0,
                                                    },
                                                    '.MuiSelect-icon': {
                                                        opacity: taskData?.status?.length ? 0 : 1,
                                                    },
                                                    '&:hover': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    }
                                                },
                                                '.Mui-focused': {
                                                    '.MuiSelect-icon': {
                                                        opacity: 1,
                                                    },
                                                },
                                                ".MuiSelect-select": {
                                                    borderRadius: '6px',
                                                    padding: '4px 28px 4px 6px',
                                                    '&:hover': {
                                                        bgcolor: '#F4F4F4',
                                                    },
                                                    'p': {
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        fontSize: 14,
                                                        lineHeight: '24px',
                                                        fontWeight: taskData?.status?.length ? 600 : 400,
                                                        letterSpacing: '0.15px',
                                                    },
                                                },
                                            }}
                                        >
                                            <FormControl variant="standard" fullWidth
                                                sx={{
                                                    '.MuiChip-root': {
                                                        height: 'auto',
                                                        overflow: "hidden",
                                                    },
                                                    '.MuiChip-label': {
                                                        padding: '1px 12px',
                                                        background: '#ffffff',
                                                    },
                                                }}
                                            >
                                                <Select
                                                    labelId="status-label"
                                                    id="status"
                                                    name="status"
                                                    value={taskData?.status || ""}
                                                    onChange={(e) => handleChangeTaskData(e)}
                                                    inputProps={{ id: getInputId("status", 0) }}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (selected?.toString()?.length === 0) {
                                                            return <>Select</>;
                                                        }
                                                        return getTaskStatus(selected, "chip")
                                                    }}
                                                >
                                                    {Object.keys(TASK_STATUS_LIST)?.map((key, index) => (
                                                        <MenuItem sx={{
                                                            padding: 1,
                                                            '.MuiChip-root': {
                                                                height: 'auto',
                                                                overflow: "hidden",
                                                            },
                                                            '.MuiChip-label': {
                                                                padding: '3px 12px',
                                                                background: '#ffffff',
                                                            },
                                                        }}
                                                            key={index}
                                                            value={key}>
                                                            {getTaskStatus(key, "chip")}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {!!taskErrors?.status?.length && (
                                                <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                    {taskErrors?.status}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                        <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                            <Box width={18} height={18} minWidth={18} sx={{ 'svg': { width: 18, height: 18 } }}>
                                                {ICONS.PriorityIcon}
                                            </Box>
                                            <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Priority</Typography>
                                        </Box>
                                        <Box flex={1} minHeight={36}
                                            sx={{
                                                '.MuiInputBase-formControl': {
                                                    '&:before': {
                                                        opacity: 0,
                                                    },
                                                    '.MuiSelect-icon': {
                                                        opacity: taskData?.priority?.length ? 0 : 1,
                                                    },
                                                    '&:hover': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    }
                                                },
                                                '.Mui-focused': {
                                                    '.MuiSelect-icon': {
                                                        opacity: 1,
                                                    },
                                                },
                                                ".MuiSelect-select": {
                                                    borderRadius: '6px',
                                                    padding: '6px 28px 6px 6px',
                                                    '&:hover': {
                                                        bgcolor: '#F4F4F4',
                                                    },
                                                    'p': {
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        fontSize: 14,
                                                        lineHeight: '24px',
                                                        fontWeight: taskData?.priority?.length ? 600 : 400,
                                                        letterSpacing: '0.15px',
                                                    },
                                                },
                                            }}
                                        >
                                            <FormControl size="small" variant="standard" fullWidth>
                                                <Select
                                                    labelId="select-user"
                                                    id="select-user"
                                                    label="Task"
                                                    name='priority'
                                                    value={taskData?.priority || ''}
                                                    onChange={(e) => handleChangeTaskData(e)}
                                                    inputProps={{ id: getInputId("priority", 0) }}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (selected?.toString()?.length === 0) {
                                                            return <>Select</>;
                                                        }
                                                        return (
                                                            <Box display={'flex'}>
                                                                <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                    {getPriorotyIcon(selected)}
                                                                </Box>
                                                                <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                    {PROJECT_TASK_PRIORITY[selected]}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    }}
                                                >
                                                    {Object.keys(PROJECT_TASK_PRIORITY)?.map((key, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={key}
                                                            sx={{
                                                                padding: 1,
                                                            }}
                                                        >
                                                            <Box display={'flex'}>
                                                                <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                    {getPriorotyIcon(key)}
                                                                </Box>
                                                                <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                    {PROJECT_TASK_PRIORITY[key]}
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {!!taskErrors?.priority?.length && (
                                                <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                    {taskErrors?.priority}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                        <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                            <Box width={18} height={18} minWidth={18} sx={{ 'svg': { width: 18, height: 18 } }}>
                                                {ICONS.PhaseIcon}
                                            </Box>
                                            <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Phase</Typography>
                                        </Box>
                                        <Box flex={1} minHeight={36}
                                            sx={{
                                                '.MuiInputBase-formControl': {
                                                    '&:before': {
                                                        opacity: 0,
                                                    },
                                                    '.MuiSelect-icon': {
                                                        opacity: taskData?.project_phase_id?.toString()?.length ? 0 : 1,
                                                    },
                                                    '&:hover': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    }
                                                },
                                                '.Mui-focused': {
                                                    '.MuiSelect-icon': {
                                                        opacity: 1,
                                                    },
                                                },
                                                ".MuiSelect-select": {
                                                    borderRadius: '6px',
                                                    padding: '6px 28px 6px 6px',
                                                    '&:hover': {
                                                        bgcolor: '#F4F4F4',
                                                    },
                                                    'p': {
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        fontSize: 14,
                                                        lineHeight: '24px',
                                                        fontWeight: taskData?.project_phase_id?.toString()?.length ? 600 : 400,
                                                        letterSpacing: '0.15px',
                                                    },
                                                },
                                            }}
                                        >
                                            <FormControl variant="standard" fullWidth>
                                                <Select
                                                    labelId="phase-label"
                                                    id="project_phase_id"
                                                    name="project_phase_id"
                                                    value={taskData?.project_phase_id || ''}
                                                    onChange={(e) => handleChangeTaskData(e)}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (selected?.toString()?.length === 0) {
                                                            return <>Select</>;
                                                        }
                                                        return titleCase(projectPhases?.find(phaseData => phaseData?.id === selected)?.title)
                                                    }}
                                                >
                                                    {getFilterdProjectPhase()?.map((phaseData, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={phaseData?.id}
                                                            sx={{ padding: 1, fontSize: 14, fontWeight: 500, lineHeight: "24px", letterSpacing: '0.15px', color: 'rgba(0,0,0,0.87)' }}
                                                        >
                                                            {titleCase(phaseData?.title)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {!!taskErrors?.project_phase_id?.length && (
                                                <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                    {taskErrors?.project_phase_id}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                        <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                            <PeopleAltOutlinedIcon fontSize="small" />
                                            <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Assignee</Typography>
                                        </Box>
                                        <Box flex={1} minHeight={36}
                                            sx={{
                                                '.MuiInputBase-formControl': {
                                                    '&:before': {
                                                        opacity: 0,
                                                    },
                                                    '.MuiSelect-icon': {
                                                        opacity: taskData?.assignee?.toString()?.length ? 0 : 1,
                                                    },
                                                    '&:hover': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    }
                                                },
                                                '.Mui-focused': {
                                                    '.MuiSelect-icon': {
                                                        opacity: 1,
                                                    },
                                                },
                                                ".MuiSelect-select": {
                                                    borderRadius: '6px',
                                                    padding: '6px 28px 5px 6px',
                                                    '&:hover': {
                                                        bgcolor: '#F4F4F4',
                                                    },
                                                    'p': {
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        fontSize: 14,
                                                        lineHeight: '24px',
                                                        fontWeight: taskData?.assignee?.toString()?.length ? 600 : 400,
                                                        letterSpacing: '0.15px',
                                                    },
                                                },
                                            }}
                                        >
                                            <FormControl variant="standard" fullWidth>
                                                <Select
                                                    labelId="assignee-label"
                                                    id="assignee"
                                                    name="assignee"
                                                    value={taskData?.assignee || ''}
                                                    onChange={(e) => handleChangeTaskData(e)}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (selected?.toString()?.length === 0) {
                                                            return <>Select</>;
                                                        }
                                                        return (
                                                            <Box display={"flex"} alignItems={"center"} width={"100%"}>
                                                                <Box minWidth={25} width={25} height={25}>
                                                                    <BackgroundLetterAvatars
                                                                        user={activeMembersData?.find(userDetails => userDetails?.id?.toString() === selected?.toString())}
                                                                        sx={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box flex={1} overflow={"hidden"} pl={1}>
                                                                    <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                        {titleCase(activeMembersData?.find(userDetails => userDetails?.id?.toString() === selected?.toString())?.name)}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }}
                                                >
                                                    {activeMembersData?.filter(userData => userData?.active)?.map((userData, index) => (
                                                        <MenuItem sx={{ padding: 1, }} key={index} value={userData?.id}>
                                                            <Box display={"flex"} alignItems={"center"} width={"100%"}>
                                                                <Box minWidth={32} width={32} height={32}>
                                                                    <BackgroundLetterAvatars
                                                                        user={userData}
                                                                        sx={{
                                                                            width: "32px",
                                                                            height: "32px",
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box flex={1} overflow={"hidden"} pl={1}>
                                                                    <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                        {titleCase(userData?.name)}
                                                                    </Typography>
                                                                    <Typography color={"rgba(63, 92, 118, 0.9)"} fontSize={12} fontWeight={400} lineHeight={"14px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                        {userData?.email}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {/* {!!taskErrors?.assignee?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.assignee}
                                                    </Typography>
                                                )} */}
                                        </Box>
                                    </Box>
                                    <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                        <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                            <WatchLaterOutlinedIcon fontSize="small" />
                                            <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Estimate</Typography>
                                        </Box>
                                        <Box flex={1} minHeight={36} sx={{ '> div': { margin: 0, }, 'div': { padding: 0 }, 'input': { height: 27, pl: '6px' }, '.error-box': { position: 'relative', } }}>
                                            {/* Edit Estimate Field */}
                                            <TimeInput value={taskData?.estimation} onChange={(value, isError) => handleTimeChange(value, isError)} onBlur={(value, isError) => handleTimeBlur(value, isError)} />
                                            {/* Edit Estimate Field */}
                                        </Box>
                                    </Box>
                                </Stack>
                            </Box>
                            <Divider />
                            <Box my={"18px"} className="text-editor text-desc-box" px={1}>
                                <Box display={"flex"} alignItems={"center"} gap={"6px"} mb={"10px"}>
                                    <SubjectIcon fontSize="small" sx={{ color: 'dark.main' }} />
                                    <Typography
                                        variant="body2"
                                        color={"dark.main"}
                                        fontSize={14}
                                        fontWeight={600}
                                        sx={{ lineHeight: "21px", letterSpacing: "0.15" }}
                                    >
                                        Description
                                    </Typography>
                                </Box>

                                <TextEditor
                                    value={taskData.description}
                                    onChange={(value) =>
                                        handleChangeDescription(value)
                                    }
                                />
                            </Box>
                            <Box mb={"18px"}>
                                <Box px={1} display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Box display={"flex"} alignItems={"center"} gap={"6px"} >
                                        <AttachFileIcon fontSize="small" sx={{ color: 'dark.main' }} />
                                        <Typography color={"text.main"}
                                            fontSize={14}
                                            fontWeight={600}
                                            sx={{ lineHeight: "21px", letterSpacing: "0.15" }}>Attachments</Typography>
                                        {taskData?.attachments?.length ? <Box py={"2px"} px={0.5} h={16} minWidth={24} textAlign={"center"} borderRadius={"50px"} bgcolor={"rgba(211, 215, 222, 0.5)"}>
                                            <Typography color={"text"} fontSize={10} fontWeight={400} lineHeight={"12px"}>{taskData?.attachments?.length}</Typography>
                                        </Box> : null}
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"} gap={2}>
                                        <Button
                                            component="label"
                                            variant="text"
                                            color="primary"
                                            sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                                            startIcon={<AddIcon fontSize="small" />}
                                            width={'300px'}
                                        >
                                            Upload
                                            <VisuallyHiddenInput
                                                name="attachments"
                                                type="file"
                                                onChange={handleChangeImage}
                                                accept="image/*, application/pdf"
                                                multiple
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                                {!taskData?.attachments?.length ?
                                    <CustomFileDropZone
                                        onDrop={(files) =>
                                            setTaskData(prev => ({
                                                ...prev,
                                                attachments: files,
                                            }))
                                        }
                                        initialFiles={taskData?.attachments}
                                        isConvertToBase64={true}
                                        isDownloadShow={false}
                                    /> : null}

                                <Box>
                                    {taskData?.attachments?.length ? <Typography color={"dark.600"} fontSize={11} fontWeight={400} lineHeight={"16px"} letterSpacing={"0.17px"} pl={1} mb={1}>PDF, PNG, JPG (max. 10MB per file)</Typography> : null}
                                    {taskData?.attachments?.length ?
                                        <Box display={"flex"} gap={"10px"} flexWrap={'wrap'}>
                                            <AttachmentLists attachments={taskData?.attachments} />
                                        </Box> : null}

                                </Box>
                            </Box>
                            <Divider />

                        </Box>

                    </Box>
                    <Box p={2.5} display={"flex"} gap={2.5} bgcolor={"white"} position={"sticky"} bottom={"0"} borderTop={"1px solid rgba(0, 0, 0, 0.12)"}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="button"
                            sx={{ textTransform: "none", boxShadow: 'none' }}
                            onClick={() => handleSubmitData()}
                            disabled={isLoading?.taskSubmitLoading}
                            isButtonLoading={isLoading.taskSubmitLoading}
                        >
                            Create
                        </Button>
                        <Button
                            variant="text"
                            color="secondary"
                            size="medium"
                            sx={{ textTransform: "none", boxShadow: 'none' }}
                            disabled={isLoading?.taskSubmitLoading}
                            onClick={() => handleCloseTaskDrawer()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </>
            }
        </Box>
    )
}

export default CreateTask